// NOTE: if updating this also update FilterClasses.cs / BaseTicketFilter

/**
 * In Progress = 1, Closed = 2
 */
export const inProgressStatus = 1

/**
 * The current user uuid
 */
export const currentUserUuidTemplate = "{{myself}}"

/**
 * The unassigned user uuid (null)
 */
export const unassignedUserUuidTemplate = "{{unassigned}}"

/**
 * Relative dates const, same as Constants.cs
 * +10 gaps to give space for new values
 */
export const relativeDates = {
    today: 10,
    yesterday: 20,
    thisWeek: 30,
    lastWeek: 40,
    thisMonth: 50,
    lastMonth: 60,
    past7Days: 70,
    past30Days: 80,
}
Object.freeze(relativeDates)

/**
 * Relative dates select options
 */
export const relativeDateOptions = [
    { value: 0, label: "" },
    { value: relativeDates.today, label: "Today" },
    { value: relativeDates.yesterday, label: "Yesterday" },
    { value: relativeDates.thisWeek, label: "This Week" },
    { value: relativeDates.lastWeek, label: "Last Week" },
    { value: relativeDates.thisMonth, label: "This Month" },
    { value: relativeDates.lastMonth, label: "Last Month" },
    { value: relativeDates.past7Days, label: "Past 7 Days" },
    { value: relativeDates.past30Days, label: "Past 30 Days" },
]
Object.freeze(relativeDateOptions)

/**
 * Defaul / In Progress filter
 */
const inProgressFilter = {
    uuid: null, // The saved uuid, used when updating saved filters
    filterName: "In Progress",
    isCustomFilter: false, // if the user customised it or not
    titleContains: null,
    assignedTo: [], // need one for unassigned! and one for "self"!
    createdBy: [], // need one for "self"!
    closedBy: [], // need one for unassigned! and one for "self"!
    organisations: [],
    ticketStatus: [inProgressStatus],
    ticketCategory: [],
    ticketTags: [],
    ticketPriority: [],
    seeSubscribed: null,
    isPrivate: null,
    openedRelativeDate: null, // options - Today, This week, This Month etc.
    closedRelativeDate: null,
    updatedRelativeDate: null,
    dueRelativeDate: null,
    // Don't save the below in the DB
    openedDateFrom: null,
    openedDateTo: null,
    closedDateFrom: null,
    closedDateTo: null,
    updatedDateFrom: null,
    updatedDateTo: null,
    ticketsPerPage: 25,
    // UI only, not needed for backend
    adminOnly: false,
}

/**
 * Only tickets assigned to me
 */
const assignedToMeFilter = { ...inProgressFilter, filterName: "Assigned to me", assignedTo: [currentUserUuidTemplate], adminOnly: true }

const notAssignedFilter = { ...inProgressFilter, filterName: "Unassigned tickets", assignedTo: ["{{unassigned}}"], adminOnly: true }

/**
 * Only tickets opened by me
 */
const createdByMeFilter = { ...inProgressFilter, filterName: "Opened by me", createdBy: [currentUserUuidTemplate] }

/**
 * Only tickets the user is subscribed to
 */
const subscribedToFilter = { ...inProgressFilter, filterName: "Subscribed", seeSubscribed: true }
/**
 * Opened today
 */
const openedTodayFilter = { ...inProgressFilter, filterName: "Opened today", ticketStatus: [], openedRelativeDate: relativeDates.today }
const opened7DaysFilter = { ...inProgressFilter, filterName: "Opened past 7 days", ticketStatus: [], openedRelativeDate: relativeDates.past7Days }

/**
 * Closed today
 */
const closedTodayFilter = { ...inProgressFilter, filterName: "Closed today", ticketStatus: [], closedRelativeDate: relativeDates.today }
const closed7DaysFilter = { ...inProgressFilter, filterName: "Closed past 7 days", ticketStatus: [], closedRelativeDate: relativeDates.past7Days }

/**
 * Due today
 */
const dueTodayFilter = { ...inProgressFilter, filterName: "Due today", ticketStatus: [inProgressStatus], dueRelativeDate: relativeDates.today, adminOnly: true }

/**
 * Freeze and export the objects
 */
Object.freeze(inProgressFilter)
Object.freeze(assignedToMeFilter)
Object.freeze(createdByMeFilter)
Object.freeze(subscribedToFilter)
Object.freeze(openedTodayFilter)
Object.freeze(closedTodayFilter)
Object.freeze(closed7DaysFilter)
Object.freeze(dueTodayFilter)
Object.freeze(notAssignedFilter)

/**
 * List of premade filters
 */
export const defaultFilters = [
    inProgressFilter,
    createdByMeFilter,
    assignedToMeFilter,
    notAssignedFilter,
    subscribedToFilter,
    openedTodayFilter,
    opened7DaysFilter,
    closedTodayFilter,
    closed7DaysFilter,
    dueTodayFilter,
]

/**
 * Blank 'default' filter
 */
export { inProgressFilter, subscribedToFilter, dueTodayFilter, opened7DaysFilter }
