import React, { useEffect, useState } from "react"

import "../styling/tickets.css"

import useUser from "../../hooks/useUser"
import { HowLongAgo, dateTimeFormat } from "../../components/utility/ConvertTime"
import Tooltip from "../../components/mui/Tooltip"
import NavDrawer from "../../components/mui/NavDrawer"
import axios, { axiosErrorMessage } from "../../components/axios"
import useAlertContext, { AlertTypes } from "../../hooks/useAlertContext"
import Loading from "../../components/Loading"

/**
 * Admin ticket change history
 */
function TicketAdminLog({ ticketId, isOpen = false, onClose }) {
    const { addSnackbarMessage } = useAlertContext()
    const { hasAuthRole_Admin } = useUser()
    const [ticketLogs, setTicketLogs] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    /**
     * Reload the adin messages
     */
    const refreshAdminMessages = async (abortController) => {
        setIsLoading(true)
        try {
            const res = await axios.get(`api/ticket/admin-log/${ticketId}`, { signal: abortController?.signal })
            if (res) setTicketLogs(res.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e, "Ticket history not available"), AlertTypes.Error)
                console.Error(e)
            }
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Called when the ticket ID changes
     */
    useEffect(() => {
        if (!isOpen) return
        const abortController = new AbortController()
        refreshAdminMessages(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [isOpen, ticketId])

    if (!hasAuthRole_Admin()) return <></>

    return (
        <NavDrawer anchor='right' title='Ticket Change History' isOpen={isOpen} onClose={onClose} drawerWidth={0}>
            <div className='padding-inline-16'>
                {isLoading ? (
                    <Loading />
                ) : ticketLogs && ticketLogs?.length > 0 ? (
                    ticketLogs?.map((tm) => {
                        return (
                            <div key={tm.uuid} className='log-message-wrapper'>
                                <div className='flex-column'>
                                    <p>{tm.message}</p>
                                    <p className='date-time-text' id='tm-datetime'>
                                        <Tooltip text={dateTimeFormat(tm.createdAt)}>
                                            <span>Updated {HowLongAgo(tm.createdAt)}</span>
                                        </Tooltip>
                                    </p>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div className='log-message-wrapper'>
                        <div className='flex-column'>
                            <p className='flex-center-item'>No Ticket Logs Found</p>
                        </div>
                    </div>
                )}
            </div>
        </NavDrawer>
    )
}

export default TicketAdminLog
