import { useState, useEffect } from "react"

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return [width, height]
}

/**
 * Hook to check the window sizes
 * [width, height] = useWindowDimensions()
 */
export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
}

const smallScreenWidth = 768

/**
 * Returns true if the screen size is <= 768px
 */
export function useIsSmallScreen() {
    const [width] = useWindowDimensions()
    const [isSmall, setIsSmall] = useState(width <= smallScreenWidth)

    useEffect(() => {
        setIsSmall(width <= smallScreenWidth)
    }, [width])

    return isSmall
}
