import React from "react"

import Tooltip from "../mui/Tooltip"

const TicketTagBar = ({ color, label }) => {
    const style = {
        height: "100%",
        width: "4px",
        backgroundColor: `#${color}`,
        color: `#${color}`,
    }
    return (
        <Tooltip text={label}>
            <div style={style}>.</div>
        </Tooltip>
    )
}

export default TicketTagBar
