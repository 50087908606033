import axios from "../axios"

/**
 * Gets a single ticket and all messages and data
 * @param {number} ticketId
 * @param {AbortController} abortController
 */
export const getSingleTicketFunc = async (ticketId, abortController) => {
    return axios.get(`/api/ticket/${ticketId}`, { signal: abortController?.signal })
}

/**
 * mark a single ticket as read
 * @param {number} ticketId
 * @param {AbortController} abortController
 */
export const patchTicketReadFunc = async (ticketId, abortController) => {
    return axios.patch(`/api/ticket/read/${ticketId}`, { signal: abortController?.signal })
}

/**
 * subscribe / unsubscribe from a ticket
 * @param {number} ticketId
 * @param {boolean} subscribed
 * @param {AbortController} abortController
 */
export const patchTicketSubscribeFunc = async (ticketId, subscribed, abortController) => {
    return axios.patch(
        `/api/ticket/subscribe`,
        {
            ticketId,
            bool: subscribed,
        },
        { signal: abortController?.signal }
    )
}

/**
 * updates the ticket title
 * @param {number} ticketId
 * @param {string} newTitle
 * @param {AbortController} abortController
 */
export const patchTicketTitleFunc = async (ticketId, newTitle, abortController) => {
    return axios.patch(
        "/api/ticket/title",
        {
            ticketId,
            newTitle,
        },
        { signal: abortController?.signal }
    )
}

/**
 * updates the ticket flag
 * @param {number} ticketId
 * @param {number} flag
 * @param {string|undefined} reason the flasg description / reason
 * @param {AbortController} abortController
 */
export const patchTicketFlageFunc = async (ticketId, flag, reason, abortController) => {
    return axios.patch(
        "/api/ticket/flag",
        {
            ticketId,
            flag,
            reason,
        },
        { signal: abortController?.signal }
    )
}

/**
 * Update a current search filter
 */
export const updateFilterFunc = async (filterSettings) => {
    return await axios.patch("/api/filter", {
        ...filterSettings,
    })
}

/**
 * Create a new search filter
 */
export const createNewFilterFunc = async (filterSettings, filterName) => {
    // remove the uuid
    const copyFilter = { ...filterSettings }
    copyFilter.filterName = filterName ? filterName : copyFilter.filterName
    delete copyFilter.uuid

    await axios.post(`/api/filter/`, copyFilter)
}

/**
 * Deletes the passed filter
 */
export const deleteFilterFunc = async (uuid) => {
    await axios.delete(`api/filter/${uuid}`)
}

/**
 * Gets the current users filter list
 * const controller = new AbortController();
 */
export const getFiltersFunc = async (abortController) => {
    return axios.get("/api/filter", { signal: abortController?.signal })
}

/**
 * Gets the ticket settings
 * const controller = new AbortController();
 */
export const getTicketSettingsFunc = async (abortController) => {
    return axios.get("/api/ticket/settings", { signal: abortController?.signal })
}

/**
 * Close or reopen a ticket
 * @param {number} ticketId
 * @param {boolean isTicketClosed
 * @param {string} assignedTo
 * @param {number} categoryId
 * @param {string} orgUuid
 * @param {string} message
 * @param {Array} mentions
 * @returns
 */
export const closeOrOpenTicketFunc = async (
    ticketId,
    isTicketClosed,
    assignedTo = undefined,
    categoryId = undefined,
    orgUuid = undefined,
    message = undefined,
    mentions = undefined
) => {
    return axios.patch("/api/ticket/close", {
        ticketId,
        isTicketClosed,
        assignedTo,
        categoryId,
        orgUuid,
        message,
        mentions,
    })
}

/**
 * Sends a new message to the API
 * @param {number} ticketId
 * @param {string} message
 * @param {boolean} isPrivate
 * @param {boolean} assignedToMe
 * @param {Array} mentions
 * @param {Array|undefined} acceptedFiles
 * @returns Promise
 */
export const newTicketMessageFunc = async (ticketId, message, isPrivate, assignedToMe, mentions, acceptedFiles) => {
    const data = {
        ticketId,
        message,
        isPrivate,
        assignedToMe,
        mentions,
    }

    const formData = new FormData()
    formData.append("data", JSON.stringify(data))

    // Check to see if we are sending files with this message
    if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
        acceptedFiles.forEach((file, index) => {
            formData.append(`formFile${index}`, file)
        })
    }

    return axios.post("/api/ticket/new-message", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}

/**
 * Creates a new ticket
 * @param {string} orgUuid
 * @param {number} categoryId
 * @param {string} ticketName
 * @param {string} ticketBody
 * @param {boolean} isPrivate
 * @param {string|undefined} assignedTo
 * @param {number|undefined} templateId
 * @param {array} tags
 * @param {array} mentions
 * @param {array} acceptedFiles
 * @returns
 */
export const newTicketFunc = async (orgUuid, categoryId, ticketName, ticketBody, isPrivate, assignedTo, templateId, tags, mentions, acceptedFiles) => {
    const data = {
        orgUuid,
        categoryId,
        assignedTo,
        ticketName,
        ticketBody,
        tags,
        isPrivate: isPrivate ?? false,
        templateId,
        mentions,
    }

    const formData = new FormData()
    formData.append("data", JSON.stringify(data))

    // Check to see if we are sending files with this message
    if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
        acceptedFiles.forEach((file, index) => {
            formData.append(`formFile${index}`, file)
        })
    }

    return axios.post("/api/newticket", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}

/**
 * Deletes the passed item
 */
export const deleteTicketAttachmentFunc = async (ticketMessageUuid, fileUuid, abortController) => {
    const res = await axios.delete(`/api/ticket/attachment/${ticketMessageUuid}/${fileUuid}`, { signal: abortController?.signal })
    return new Promise((resolve) => {
        resolve(res)
    })
}
