import React from "react"

import MetaData from "../../../components/MetaData"
import { Header2 } from "../../../components/headers/Headers"
import RequiredFieldHint from "../../../components/forms/RequiredFieldHint"
import { EditStorageForm } from "./EditStorageModal"
import { useNavigate, useParams } from "react-router-dom"

/**
 * Create or edit a knolage base item
 */
function EditKnowledgeBase() {
    const navigate = useNavigate()
    const { storageUuid } = useParams()

    const isNewItem = !storageUuid || storageUuid == null || storageUuid == undefined

    const pageTitle = `${isNewItem ? "New" : "Edit"} Knowledge Base Article`

    /**
     * called when the form was submited or data was not loaded
     * @param {string|undefined} storageUuid
     */
    const onRedirect = (storageUuid) => {
        navigate("/article/" + storageUuid)
    }

    return (
        <div className='page-wrapper'>
            <MetaData title={pageTitle} />
            <div className='page-form'>
                <Header2 title={pageTitle} />
                <RequiredFieldHint />
                <EditStorageForm storageUuid={storageUuid} isOrgStorage={false} onClose={onRedirect} showSubmit={true} />
            </div>
        </div>
    )
}

export default EditKnowledgeBase
