import React, { useState } from "react"

import "../../components/ComponentStyles.css"

import useTicketFilter from "../../hooks/useTicketFilter"
import { createNewFilterFunc } from "../../components/httpRequests/TicketsRequests"
import ConfirmDialog from "../../components/mui/ConfirmDialog"
import { inputMaxLength } from "../../Constants"

/**
 * Ticket filters popup
 */
const NewFilterModal = ({ closeModal, refreshFilters }) => {
    const { filterSettings } = useTicketFilter()

    const [filterName, setFilterName] = useState(null)

    const createNewFilter = async () => {
        await createNewFilterFunc(filterSettings, filterName)
        refreshFilters()
        closeModal(false)
    }

    const filterNameUpdate = (e) => {
        setFilterName(e.target.value)
    }

    const closeModalFunc = () => {
        closeModal(false)
    }

    return (
        <ConfirmDialog
            isOpen={true}
            title='New Filter'
            description=''
            onYesClick={createNewFilter}
            onNoClick={closeModalFunc}
            onClose={closeModalFunc}
            yesLabel='Create New Filter'
            noLabel='Cancel'
        >
            <div className='flex-column width-100'>
                <div className='flex-column width-100'>
                    <label htmlFor='filterName'>Filter Name</label>
                    <input
                        id='filterName'
                        type='text'
                        autoFocus={true}
                        className='width-100 rs-input'
                        maxLength={inputMaxLength.ticketFilterName}
                        onChange={(e) => filterNameUpdate(e)}
                    />
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default NewFilterModal
