import axios from "../axios"

/**
 * Update the users password
 * @param {string} password - The users current password
 * @param {string} newPassword - The users new password
 * @param {AbortController} abortController
 * @returns
 */
export const patchCurrentUserPassword = async (password, newPassword, abortController) => {
    const itemData = {
        password,
        newPassword,
    }

    const res = await axios.patch("/api/currentuser/password", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Gets info on the current user
 */
export const getCurrentUserFunc = async (abortController) => {
    return axios.get("/api/currentuser/", { signal: abortController?.signal })
}

/**
 * Gets all the notifications for the current user
 * @param {AbortController} abortController
 */
export const getCurrentUserNotificationsFunc = async (option, limit, abortController) => {
    if (!option) option = "current"
    return axios.get(`/api/notification/${option}/${limit}`, { signal: abortController?.signal })
}

/**
 * Update the current user
 * @param {object} itemData
 * @param {AbortController} abortController
 * @returns
 */
export const patchCurrentUser = async (itemData, abortController) => {
    return axios.patch("/api/currentuser/", itemData, {
        signal: abortController?.signal,
    })
}

/**
 * Gets info on the current user
 */
export const getCurrentUserGroupsFunc = async (abortController) => {
    return axios.get("/api/currentuser/groups/", { signal: abortController?.signal })
}

/**
 * Update the current users group settings
 * @param {object[]} itemData
 * @param {AbortController} abortController
 */
export const patchCurrentUserGroups = async (itemData, abortController) => {
    return axios.patch("/api/currentuser/groups/", itemData, {
        signal: abortController?.signal,
    })
}

/**
 * Gets info on the current user
 */
export const getConfigureAuthFunc = async (abortController) => {
    return axios.get("/api/currentuser/configure-auth", { signal: abortController?.signal })
}

/**
 * confirms the 2 factor code when setting-up 2FA
 * @param {string} authCode - the auth code genarated from the 2FA App
 * @param {AbortController} abortController
 * @returns object {}
 */
export const postConfirmAuthFunc = async (authCode, abortController) => {
    const res = await axios.post(
        "/api/currentuser/configure-auth-confirm",
        { authCode },
        {
            signal: abortController?.signal,
        }
    )
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * removes the auth from the user
 * @param {string} password - The users current password
 * @param {AbortController} abortController
 * @returns
 */
export const postRemoveAuthFunc = async (password, abortController) => {
    const res = await axios.post(
        "/api/currentuser/remove-auth",
        { password },
        {
            signal: abortController?.signal,
        }
    )
    return new Promise((resolve) => {
        resolve(res)
    })
}
