import React from "react"
import "../pages/styling/general.css"
import "./ComponentStyles.css"

const ThreeDots = ({ openDropdown }) => {
    return (
        <div className='three-dots flex-row flex-gap-2' onClick={openDropdown}>
            <svg height='4' width='4'>
                <circle cx='2' cy='2' r='2' fill='grey' />
            </svg>
            <svg height='4' width='4'>
                <circle cx='2' cy='2' r='2' fill='grey' />
            </svg>
            <svg height='4' width='4'>
                <circle cx='2' cy='2' r='2' fill='grey' />
            </svg>
        </div>
    )
}

export default ThreeDots
