import React, { useState } from "react"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import "./styling/general.css"
import "./styling/login.css"

import useAlertContext, { AlertTypes } from "../hooks/useAlertContext"
import LoginPageWapper from "../components/LoginPageWapper"
import { postRequestPasswordReset } from "../components/httpRequests/LoginRequests"
import { axiosErrorMessage } from "../components/axios"

/**
 * The forgot password screen
 */
function ForgotPassword() {
    const { addSnackbarMessage } = useAlertContext()

    const [email, setEmail] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    /**
     * Log the user into the side
     */
    const handleFormSubmit = async (e) => {
        e.preventDefault()

        if (!email || isSubmitting) return

        setIsSubmitting(true)
        try {
            await postRequestPasswordReset(email)
            setEmail(null)
            setSubmitted(true)
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <LoginPageWapper showForgotPassword={false} showLogin={true}>
            {submitted ? (
                <div className='form-section'>
                    <div className='alert alert-primary'>
                        <CheckCircleIcon className='icon-primary' />
                        <p>Please check your email for instructions to reset your password.</p>
                    </div>
                </div>
            ) : (
                <form onSubmit={(e) => handleFormSubmit(e)}>
                    <div className='form-section'>
                        <label htmlFor='email'>Email</label>
                        <input
                            id='email'
                            className='rs-input'
                            type='email'
                            value={email ?? ""}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            disabled={isSubmitting}
                            autoFocus={true}
                            autoComplete='email'
                        />
                    </div>

                    <div className='form-section'>
                        <button className='button-contained-100' disabled={isSubmitting || !email} type='submit'>
                            Reset
                        </button>
                    </div>
                </form>
            )}
        </LoginPageWapper>
    )
}

export default ForgotPassword
