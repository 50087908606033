import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Select from "react-select"

import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"

import "../styling/tickets.css"

import axios from "../../components/axios"
import { axiosErrorMessage } from "../../components/axios"

import Divider from "../../components/dividers/Divider"
import ConfirmDialog from "../../components/mui/ConfirmDialog"
import { formatExtendedLabel, formatTagsLabel, selectFilter, selectTheme, uuidValuePairsToSelectOptions } from "../../common/selectHelpers"
import { getTechniciansFunc } from "../../components/httpRequests/UserRequests"
import useAlertContext, { AlertTypes } from "../../hooks/useAlertContext"
import { dateTimeClass, dateTimeFormat, isoDateString } from "../../components/utility/ConvertTime"
import IconButton from "../../components/mui/IconButton"
import Tag from "../../components/data/Tag"
import Tooltip from "../../components/mui/Tooltip"
import { getOrganisationKeyValueListFunc } from "../../components/httpRequests/OrganisationRequests"
import { ProfilePhotoGroup } from "../../components/ProfilePhoto"

/**
 * Admin side bar on the tickets page
 */
const TicketSidebar = ({ isAdmin = false, isTechnician = false, isTicketClosed = false, ticketInfo, findQuery, ticketTags }) => {
    const navigate = useNavigate()
    const { addSnackbarMessage } = useAlertContext()

    const [assignedToSelection, setAssignedToSelection] = useState([])
    const [selectedAssignedOption, setSelectedAssignedOption] = useState(null)

    const [orgSelection, setOrgSelection] = useState([])
    const [selectedOrgOption, setSelectedOrgOption] = useState(null)
    const [dueOn, setDueOn] = useState(null)

    const [prioritySelection, setPrioritySelection] = useState([])
    const [selectedPriorityOption, setSelectedPriorityOption] = useState(null)

    const [ticketTypeSelection, setTicketTypeSelection] = useState([])
    const [ticketTypeOption, setTicketTypeOption] = useState(null)

    const [ticketTagSelection, setTicketTagSelection] = useState([])
    const [selectedTicketTags, setSelectedTicketTags] = useState(null)
    const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)

    /**
     * Get the admins list
     */
    const getUserList = async (abortController) => {
        if (!isAdmin && !isTechnician) return

        try {
            const admins = await getTechniciansFunc(ticketInfo.orgId, abortController)
            const keyValues = uuidValuePairsToSelectOptions(admins.data)

            setAssignedToSelection(keyValues)
            setSelectedAssignedOption(keyValues.find((p) => p.value === ticketInfo.assignedToId && p))
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Get a list of the ticket tags
     */
    const getTicketTags = async (abortController) => {
        try {
            const tags = await axios.get("/api/tag/list", { signal: abortController?.signal })
            setTicketTagSelection(tags.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e, "Error getting ticket tags."), AlertTypes.Error)
            }
        }
    }

    /**
     * Gets the options for the sidebar edits
     */
    const getTicketOptions = async (abortController) => {
        if (!isAdmin && !isTechnician) return

        try {
            const [types, priorities, orgs] = await Promise.all([
                axios.get("/api/ticket/ticket-categories", { signal: abortController?.signal }),
                axios.get("/api/ticket/ticket-priority", { signal: abortController?.signal }),
                getOrganisationKeyValueListFunc(abortController),
            ])

            setTicketTypeSelection(types.data)
            setTicketTypeOption(types.data.find((p) => p.label === ticketInfo.category && p))
            setPrioritySelection(priorities.data)
            setSelectedPriorityOption(priorities.data.find((p) => p.label === ticketInfo.priority && p))
            setDueOn(ticketInfo.dueOn)

            setOrgSelection(orgs.data)
            setSelectedOrgOption(orgs.data.find((o) => o.value === ticketInfo.orgId && o))
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e, "Error getting ticket options."), AlertTypes.Error)
            }
        }
    }

    /**
     * The org was changed
     */
    const onOrgChange = async (e) => {
        if (!isAdmin && !isTechnician) return
        try {
            setSelectedOrgOption(e)
            await axios.patch("/api/ticket/org", {
                ticketId: ticketInfo.id,
                orgUuid: e.value,
            })
            findQuery()
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e, "Error updating item."), AlertTypes.Error)
        }
    }

    /**
     * Due date is changed
     */
    const onDueOnChange = async (e) => {
        if (!isAdmin && !isTechnician) return
        try {
            const newValue = e.target.value ? e.target.value : null

            setDueOn(newValue)
            await axios.patch("/api/ticket/due-date", {
                ticketId: ticketInfo.id,
                dueOn: newValue,
            })
            findQuery()
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e, "Error updating item."), AlertTypes.Error)
            console.error(e)
        }
    }

    /**
     * Changed who it was assigned to
     */
    const onAssignedToChange = async (e) => {
        if (!isAdmin && !isTechnician) return
        try {
            setSelectedAssignedOption(e)
            await axios.patch("/api/ticket/assign", {
                ticketId: ticketInfo.id,
                userUuid: e?.value,
            })
            findQuery()
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e, "Error updating item."), AlertTypes.Error)
        }
    }

    /**
     * changed the priority
     */
    const onPriorityChanged = async (e) => {
        if (!isAdmin && !isTechnician) return
        try {
            setSelectedPriorityOption(e)
            await axios.patch("/api/ticket/priority", {
                ticketId: ticketInfo.id,
                ticketValue: e.value,
            })
            findQuery()
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e, "Error updating item."), AlertTypes.Error)
        }
    }

    /**
     * Changed the ticket category
     */
    const onTicketTypeChanged = async (e) => {
        if (!isAdmin && !isTechnician) return
        try {
            setTicketTypeOption(e)
            await axios.patch("/api/ticket/ticket-type", {
                ticketId: ticketInfo.id,
                ticketValue: e.value,
            })
            findQuery()
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e, "Error updating item."), AlertTypes.Error)
        }
    }

    /**
     * Tags were changed
     */
    const onTicketTagChange = async (e) => {
        if (!isAdmin && !isTechnician) return
        try {
            setSelectedTicketTags(e)
            await axios.patch("/api/tag/ticket/", {
                ticketId: ticketInfo.id,
                tagUuids: e.map((tt) => {
                    if (tt) return tt.value
                }),
            })
            findQuery()
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e, "Error updating item."), AlertTypes.Error)
        }
    }

    /**
     * Close the confirm dialog
     */
    const closeConfirmClick = () => {
        setConfirmDeleteIsOpen(false)
    }

    /**
     * The confirm delete ticket button
     */
    const yesDeleteTickeConfirmClickAsync = async () => {
        if (!isAdmin) return
        await axios.delete(`/api/ticket/${ticketInfo.id}`)
        navigate("/tickets")
    }

    /**
     * Called when the page is in edit mode
     */
    useEffect(() => {
        if (!isEditMode) return

        const adminController = new AbortController()
        getTicketOptions(adminController)

        // Clean up function
        return () => adminController.abort()
    }, [isEditMode])

    /**
     * Called when the page is in edit mode or the org id changes
     */
    useEffect(() => {
        const adminController = new AbortController()

        getTicketTags(adminController)
        if (isEditMode) getUserList(adminController)

        // Clean up function
        return () => adminController.abort()
    }, [isEditMode, ticketInfo?.orgId])

    /**
     * Update the tags once they are loaded
     */
    useEffect(() => {
        if (ticketTags && Array.isArray(ticketTags)) {
            // Get the UUID for the tags in this ticket
            const uuids = ticketTags.map((tag) => tag.tagUuid)

            setSelectedTicketTags(
                ticketTagSelection.filter((tts) => {
                    if (uuids.includes(tts.value)) return tts
                })
            )
        }
    }, [ticketTags, ticketTagSelection])

    return (
        <>
            <ConfirmDialog
                isOpen={confirmDeleteIsOpen}
                title='Delete Ticket'
                description='Are you sure you want to delete this ticket?'
                onYesClick={yesDeleteTickeConfirmClickAsync}
                onNoClick={closeConfirmClick}
                onClose={closeConfirmClick}
            />

            <div className='as-wrapper'>
                <div className='flex-row-center-vertical padding-inline-16 padding-top-16 flex-gap-none as-header'>
                    <span>Ticket Details</span>
                    {isAdmin || isTechnician ? (
                        <IconButton
                            hintText='Toggle edit mode'
                            onClick={() => {
                                setIsEditMode((currentValue) => !currentValue && (isAdmin || isTechnician))
                            }}
                        >
                            {isEditMode ? <ToggleOnIcon /> : <ToggleOffIcon />}
                        </IconButton>
                    ) : null}
                </div>

                <div className='padding-inline-16 padding-bottom-16 ticket-sidebar-edits'>
                    <div className='as-section'>
                        <label htmlFor='changeOrganisation'>Organisation</label>
                        {isEditMode && orgSelection ? (
                            <Select
                                inputId='changeOrganisation'
                                options={orgSelection}
                                onChange={onOrgChange}
                                filterOption={selectFilter()}
                                value={selectedOrgOption}
                                formatOptionLabel={formatExtendedLabel}
                                theme={selectTheme}
                                className='react-select-container'
                            />
                        ) : (
                            <>
                                <div className='as-section-value'>
                                    <Tooltip
                                        text={
                                            <>
                                                <p>{ticketInfo?.code}</p>
                                                <p>{ticketInfo?.address}</p>
                                                <p>{ticketInfo?.phone1}</p>
                                                <p>{ticketInfo?.phone2}</p>
                                                <p>{ticketInfo?.email}</p>
                                            </>
                                        }
                                    >
                                        <Link to={`/organisation/${ticketInfo.orgId}`}>{ticketInfo.orgName}</Link>
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </div>
                    <div className='as-section'>
                        <label htmlFor='as-assigned-to'>Assigned To</label>
                        {isEditMode && assignedToSelection ? (
                            <Select
                                inputId='as-assigned-to'
                                options={assignedToSelection}
                                onChange={onAssignedToChange}
                                value={selectedAssignedOption}
                                isClearable={true}
                                filterOption={selectFilter()}
                                theme={selectTheme}
                                className='react-select-container'
                            />
                        ) : (
                            <div className='as-section-value'>
                                <span>{ticketInfo?.assignedTo ?? "Not Assigned"}</span>
                            </div>
                        )}
                    </div>
                    <Divider padding={8} className='hidden-small' />

                    <div className='as-section'>
                        <label htmlFor='as-priority'>Priority</label>
                        {isEditMode && prioritySelection ? (
                            <Select
                                inputId='as-priority'
                                options={prioritySelection}
                                filterOption={selectFilter()}
                                onChange={(e) => onPriorityChanged(e)}
                                value={selectedPriorityOption}
                                theme={selectTheme}
                                className='react-select-container'
                            />
                        ) : (
                            <div className='as-section-value'>
                                <span>{ticketInfo?.priority ?? "Not Set"}</span>
                            </div>
                        )}
                    </div>
                    <div className='as-section'>
                        <label htmlFor='as-type'>Category</label>
                        {isEditMode ? (
                            <Select
                                inputId='as-type'
                                options={ticketTypeSelection}
                                filterOption={selectFilter()}
                                onChange={(e) => onTicketTypeChanged(e)}
                                value={ticketTypeOption}
                                theme={selectTheme}
                                className='react-select-container'
                            />
                        ) : (
                            <div className='as-section-value'>
                                <span>{ticketInfo?.category ?? "Not Set"}</span>
                            </div>
                        )}
                    </div>
                    <div className='as-section'>
                        <label htmlFor='ticketTags'>Ticket Tags</label>
                        {isEditMode && ticketTagSelection ? (
                            <Select
                                options={ticketTagSelection}
                                onChange={onTicketTagChange}
                                value={selectedTicketTags}
                                filterOption={selectFilter()}
                                isMulti
                                formatOptionLabel={formatTagsLabel}
                                inputId='ticketTags'
                                theme={selectTheme}
                                className='react-select-container'
                            />
                        ) : (
                            <div className='as-section-value'>
                                <div className='flex-row flex-gap-4'>
                                    {selectedTicketTags &&
                                        selectedTicketTags.map((tt, index) => <Tag key={index} label={tt.label} colour={tt.extendedLabel} />)}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='as-section'>
                        <label htmlFor='dueOn'>Due Date</label>
                        {isEditMode ? (
                            <input
                                type='date'
                                name='dueOn'
                                id='dueOn'
                                className='rs-input width-100'
                                defaultValue={dueOn ? isoDateString(dueOn) : undefined}
                                onChange={onDueOnChange}
                            />
                        ) : (
                            <div className='as-section-value'>
                                {isTicketClosed || ticketInfo.dueOn ? (
                                    <div className={`${isTicketClosed ? "date-closed" : "padding-8 " + dateTimeClass(ticketInfo.dueOn)}`}>
                                        {dateTimeFormat(ticketInfo.dueOn, "Not Set")}
                                    </div>
                                ) : (
                                    <span> {dateTimeFormat(ticketInfo.dueOn, "Not Set")}</span>
                                )}
                            </div>
                        )}
                    </div>
                    {isEditMode ? (
                        <>
                            <Divider padding={16} className='hidden-small' />
                            <div className='flex-column ticket-sidebar-edit-buttons'>
                                {isAdmin ? (
                                    <>
                                        <button className='button-contained-danger width-100' onClick={() => setConfirmDeleteIsOpen(true)}>
                                            <DeleteForeverIcon />
                                            Delete Ticket
                                        </button>
                                    </>
                                ) : null}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='as-section'>
                                <div>Ticket Status</div>
                                <div className='as-section-value'>{ticketInfo.status}</div>
                            </div>
                            <div className='as-section'>
                                <div>Created By</div>
                                <div className='as-section-value'>{ticketInfo.createdBy}</div>
                            </div>
                            <div className='as-section'>
                                <div>Created On</div>
                                <div className='as-section-value'>{dateTimeFormat(ticketInfo.createdAt)}</div>
                            </div>
                            <div className='as-section hidden-small'>
                                <div>Subscribed Users</div>
                                <div className='as-section-value'>
                                    <ProfilePhotoGroup users={ticketInfo.subscribedUsers} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default TicketSidebar
