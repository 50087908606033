import React from "react"

import "./NavMenu.css"

import { primaryColor } from "../../Theme"

/**
 * Hamburger Icon
 */
const HamburgerIcon = ({ navbarIsOpen, iconColour = primaryColor }) => {
    return (
        <div className='hamburger-wrapper'>
            {iconColour && (
                <div className={["hamburger", navbarIsOpen ? "is-active" : null].join(" ")} style={{ color: iconColour }} id='hamburger-1'>
                    <span className='line' style={{ backgroundColor: iconColour }}></span>
                    <span className='line' style={{ backgroundColor: iconColour }}></span>
                    <span className='line' style={{ backgroundColor: iconColour }}></span>
                </div>
            )}
        </div>
    )
}

export default HamburgerIcon
