import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AddBoxIcon from "@mui/icons-material/AddBox"
import { PieChart } from "@mui/x-charts"
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"

import "./styling/dashboard.css"

import axios from "../components/axios"
import MetaData from "../components/MetaData"
import { defaultFilters, opened7DaysFilter, dueTodayFilter } from "../common/searchFilters"
import useTicketFilter from "../hooks/useTicketFilter"
import Divider from "../components/dividers/Divider"
import Tooltip from "../components/mui/Tooltip"
import TemplateList from "../components/TemplateList"
import useUser from "../hooks/useUser"

/**
 * Main dashboard
 */
const Dashboard = () => {
    const navigate = useNavigate()
    const { hasAuthRole_TechnicianOrAbove } = useUser()
    const { setFilterSettings, getDefaultFilterForUser } = useTicketFilter()

    const [openTickets, setOpenTickets] = useState(0)
    const [ticketsOpened, setTicketsOpened] = useState(0)
    const [ticketsClosed, setTicketsClosed] = useState(0)
    const [ticketsDueToday, setTicketsDueToday] = useState(0)
    const [ticketsOverDue, setTicketsOverDue] = useState(0)
    const ticketsPercent = ticketsOpened === 0 ? 0 : Math.round((ticketsClosed / ticketsOpened) * 100)

    /**
     * Get the dashboard data
     */
    const getDashboardData = async (abortController) => {
        try {
            const res = await axios.get("/api/dashboard", { signal: abortController?.signal })
            setOpenTickets(res.data.openTickets)
            setTicketsOpened(res.data.ticketsOpened)
            setTicketsClosed(res.data.ticketsClosed)
            setTicketsDueToday(res.data.ticketsDueToday), setTicketsOverDue(res.data.ticketsOverdue)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    const setFilterAndNavigate = (filter) => {
        setFilterSettings(filter)
        navigate("/tickets")
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getDashboardData(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [])

    const ticketsDue = ticketsOverDue + ticketsDueToday
    const isTechnician = hasAuthRole_TechnicianOrAbove()

    return (
        <div className='flex-100'>
            <MetaData title={`Dashboard`} />
            <div className='flex-row flex-gap-16 flex-row-center dashboard-top-row-container'>
                <div className='dashboard-button flex-center-item dashboard-clickable' onClick={() => navigate("/new-ticket")}>
                    <span className='db-icon'>
                        <AddBoxIcon />{" "}
                    </span>
                    <p className='db-text'>Open a new ticket</p>
                </div>
                <div
                    className='dashboard-button dashboard-quick-glance-button dashboard-clickable'
                    onClick={() => {
                        setFilterAndNavigate(getDefaultFilterForUser())
                    }}
                >
                    <div>
                        <p className='db-stat'>{openTickets}</p>
                        <p className='db-text'>Ticket{openTickets > 1 && "s"} Open</p>
                    </div>
                </div>

                <div
                    className={`dashboard-button dashboard-quick-glance-button dashboard-clickable ${
                        ticketsOverDue > 0 ? "dashboard-button-danger" : ticketsDueToday > 0 ? "dashboard-button-warning" : "dashboard-button-success"
                    }`}
                    onClick={() => {
                        setFilterAndNavigate(dueTodayFilter)
                    }}
                >
                    <div>
                        <p className='db-stat'>{ticketsDue}</p>
                        <p className='db-text'>Ticket{ticketsDue > 1 && "s"} Due</p>
                    </div>
                </div>

                <div
                    className='dashboard-button dashboard-clickable'
                    onClick={() => {
                        setFilterAndNavigate(opened7DaysFilter)
                    }}
                >
                    <Tooltip text={`Tickets resolved in the past 7 days (${ticketsClosed} of ${ticketsOpened})`}>
                        <div className='dashboard-progress-bar' style={{ "--_progress-value": `${ticketsPercent}%` }}>
                            <p className='dashboard-progress-bar-text'>{ticketsPercent}%</p>
                        </div>
                    </Tooltip>
                </div>
            </div>
            <DashboardStatistics />
            <Divider padding={16} />
            <hgroup className='padding-inline-16'>
                <h2>View Your Tickets</h2>
                <p>All your tickets, messages & responses.</p>
            </hgroup>
            <div className='flex-row flex-gap-16 dashboard-row-container'>
                {defaultFilters?.map((filter, index) =>
                    filter.adminOnly && !isTechnician ? null : (
                        <div
                            className='dashboard-button dashboard-clickable'
                            key={index}
                            onClick={() => {
                                setFilterAndNavigate(filter)
                            }}
                        >
                            <span className='db-icon'>
                                <ConfirmationNumberIcon />
                            </span>
                            <p className='db-text'>View {filter.filterName?.toLowerCase()}</p>
                        </div>
                    )
                )}
            </div>
            <Divider padding={16} />
            <hgroup className='padding-inline-16'>
                <h2>New Ticket</h2>
                <p>Create a new support request.</p>
            </hgroup>
            <TemplateList />
        </div>
    )
}

/**
 * Statistics for the daskboard (admin only)
 */
const DashboardStatistics = () => {
    const { hasAuthRole_TechnicianOrAbove } = useUser()

    const [days, setDays] = useState(7)
    const [category, setCategory] = useState(null)
    const [dateCreated, setDateCreated] = useState(null)

    const isTechnician = hasAuthRole_TechnicianOrAbove()

    /**
     * Get the dashboard data
     */
    const getDashboardData = async (days, abortController) => {
        if (!isTechnician) return

        try {
            const res = await axios.get(`/api/dashboard/statistics/${days}`, { signal: abortController?.signal })
            setCategory(res.data?.category)
            setDateCreated(res.data?.dateCreated)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Update the days
     */
    const doDaysClick = (e, days) => {
        e.preventDefault()
        setDays(days)
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        if (!isTechnician) return

        const abortController = new AbortController()
        getDashboardData(days, abortController)

        // Clean up function
        return () => abortController.abort()
    }, [isTechnician, days])

    const daysArr = [7, 14, 21, 28, 35, 42]

    /**
     *  Render the charts
     */
    return isTechnician ? (
        <>
            <Divider padding={16} />
            <div className='padding-inline-16 flex-row flex-row-spacebetween flex-column-small'>
                {category && <DashbordPie title='Tickets By Category' subtitle={`Past ${days} days`} chartData={category} />}
                {dateCreated && <DashbordPie title='Tickets By Day' subtitle={`Past ${days} days`} chartData={dateCreated} />}
            </div>
            <div className='padding-inline-16 flex-row flex-row-center'>
                {daysArr.map((dayVal) => {
                    return (
                        <a href='#' key={dayVal} onClick={(e) => doDaysClick(e, dayVal)} className='pill-primary'>
                            {dayVal} days
                        </a>
                    )
                })}
            </div>
        </>
    ) : null
}

/**
 * Pie chart used on the dashboard
 */
const DashbordPie = ({ title, subtitle, chartData }) => {
    // chartData is an array e.g. { extendedLabel: 11, value: 1, label: "Monday" }
    const data = chartData?.map((value, index) => {
        return { id: index, value: value.extendedLabel, label: value.label }
    })

    const colors = ["#3399cc", "#70b8db", "#3081aa"]

    return (
        <div className='flex-column-center flex-center-item flex-100'>
            <hgroup className='text-center'>
                <h3>{title}</h3>
                <p>{subtitle}</p>
            </hgroup>
            <div>
                <PieChart
                    colors={colors}
                    slotProps={{
                        legend: { hidden: true, position: { vertical: "middle", horizontal: "right" } },
                    }}
                    series={[
                        {
                            cx: 150,
                            innerRadius: 30,
                            highlightScope: { faded: "global", highlighted: "item" },
                            faded: { innerRadius: 15, additionalRadius: -15, color: "gray" },
                            data,
                        },
                    ]}
                    width={300}
                    height={200}
                />
            </div>
        </div>
    )
}

export default Dashboard
