import React, { useState } from "react"
import { inputMaxLength } from "../../../Constants"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import { axiosErrorMessage } from "../../../components/axios"
import { postOrganisationGroupFunc } from "../../../components/httpRequests/OrganisationGroupRequests"

/**
 * Popup for creating a new group
 */
const NewGroupPopup = ({ closePopup, orgUuid }) => {
    const [groupName, setGroupName] = useState(null)
    const [groupDescription, setGroupDescription] = useState(null)
    const { addSnackbarMessage } = useAlertContext()

    // eslint-disable-next-line no-unused-vars
    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    const closeGroupPopup = (newItem = undefined) => {
        closePopup(newItem)
    }

    /**
     * Submit the group to the API
     */
    const submitGroup = async () => {
        if (!groupName) return

        try {
            const formData = {
                name: groupName,
                description: groupDescription,
                orgUuids: [orgUuid],
            }

            const res = await postOrganisationGroupFunc(formData)
            addSnackbarMessage("Group Created Successfully.", AlertTypes.Succes)
            closeGroupPopup(res.data)
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        }
    }

    return (
        <div className='fullscreen-overlay flex-center-item' onClick={() => closeGroupPopup()}>
            <div className='log-time-popup flex-column flex-gap-16 flex-100' onClick={stopPropagation}>
                <h3>New Group Name</h3>
                <div className='label-input-wrapper' onClick={stopPropagation}>
                    <label htmlFor='name'>Name </label>
                    <input
                        type='text'
                        name='name'
                        id='name'
                        required
                        className='rs-input'
                        onChange={(e) => setGroupName(e.target.value)}
                        maxLength={inputMaxLength.groupName}
                    />
                </div>
                <div className='label-input-wrapper' onClick={stopPropagation}>
                    <label htmlFor='desc'>Description</label>
                    <textarea className='rs-input' name='desc' id='desc' onChange={(e) => setGroupDescription(e.target.value)} />
                </div>
                <button className='button-contained' disabled={!groupName} onClick={submitGroup}>
                    Create Group
                </button>
            </div>
        </div>
    )
}

export default NewGroupPopup
