import React, { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams, useParams } from "react-router-dom"
import Select from "react-select"

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"

import "../styling/newticket.css"

import axios, { axiosErrorMessage } from "../../components/axios"
import useUser from "../../hooks/useUser"
import useAlertContext, { AlertTypes } from "../../hooks/useAlertContext"
import { inputMaxLength } from "../../Constants"
import { getTechniciansFunc, getTicketUsersFunc } from "../../components/httpRequests/UserRequests"
import { csvToSelectOptions, formatExtendedLabel, formatTagsLabel, selectFilter, selectTheme, uuidValuePairsToSelectOptions } from "../../common/selectHelpers"
import OrganisationKPIs from "../../components/OrganisationKPIs"
import MetaData from "../../components/MetaData"
import { getTicketTemplateFunc } from "../../components/httpRequests/TicketTemplateRequests"
import LabelledCheckbox from "../../components/forms/LabelledCheckbox"
import Loading from "../../components/Loading"
import { Header2 } from "../../components/headers/Headers"
import SlateTextArea from "../../components/forms/SlateTextArea"
import RequiredFieldHint from "../../components/forms/RequiredFieldHint"
import { getOrganisationKeyValueListFunc, getOrganisationKPIsFunc } from "../../components/httpRequests/OrganisationRequests"
import TicketAttachmentDropzone from "./TicketAttachmentDropzone"
import { newTicketFunc } from "../../components/httpRequests/TicketsRequests"
import { getSendUserNotificationsFunc } from "../../components/httpRequests/NotificationRequests"

/**
 * New ticket page
 */
const NewTicket = () => {
    const { templateId } = useParams()
    const { hasAuthRole_Admin, hasAuthRole_TechnicianOrAbove } = useUser()
    const navigate = useNavigate()
    const { addSnackbarMessage } = useAlertContext()
    const [searchParams] = useSearchParams()

    // Input values
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [selectedTicketType, setSelectedTicketType] = useState(null)
    const [selectedTags, setSelectedTags] = useState(null)
    const [selectedAssignedTo, setSelectedAssignedTo] = useState(null)
    const [ticketTitle, setTicketTitle] = useState("")
    const [ticketBody, setTicketBody] = useState("")
    const [isPrivate, setIsPrivate] = useState(false)
    const [mentions, setMentions] = useState([])
    const [acceptedFiles, setAcceptedFiles] = useState([])

    // Drop down options
    const [ticketTypeOptions, setTicketTypeOptions] = useState(null)
    const [orgSelectionOptions, setOrgSelectionOptions] = useState(null)
    const [adminUserOptions, setAdminUserOptions] = useState(null)
    const [userOptions, setUserOptions] = useState(null)
    const [ticketTagsOptions, setTicketTagsOptions] = useState(null)
    const [ticketTemplateInputs, setTicketTemplateInputs] = useState(null)
    const [ticketTemplateData, setTicketTemplateData] = useState(null)

    // Org KPIs state
    const [orgKPIs, setOrgKPIs] = useState(null)

    // Status state
    const [errMsg, setErrMsg] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    // if the user is an admin or not
    const isAdmin = hasAuthRole_Admin() ?? false
    const isTechnician = hasAuthRole_TechnicianOrAbove() ?? false
    const orgUuid = searchParams?.get("orgUuid")

    /**
     * Get the body
     */
    const getBodyText = () => {
        if (ticketTemplateInputs && ticketTemplateInputs.length > 0) {
            let body = ""
            for (const input of ticketTemplateInputs) {
                if (input.type === "checkbox") body += ` - [${input.value ? "x" : " "}] ${input.title}\n\n`
                else body += `### ${input.title}\n ${input.value}\n\n`
            }
            return body
        }
        return ticketBody?.trim()
    }

    /**
     * Validate theticket body
     */
    const validateBodyText = () => {
        if (ticketTemplateInputs && ticketTemplateInputs.length > 0) {
            let result = true
            for (const input of ticketTemplateInputs) {
                if (input.required) {
                    const value = input?.value ?? ""
                    result = value !== ""
                    if (!result) {
                        break
                    }
                }
            }
            return result
        }

        // no custom inoputs
        const value = ticketBody?.trim() ?? ""
        return value !== ""
    }

    /**
     * Get the user list
     */
    const getUserList = async (orgUuid, abortController) => {
        try {
            const [adminRes, usersRes] = await Promise.all([getTechniciansFunc(orgUuid, abortController), getTicketUsersFunc(0, orgUuid, abortController)])
            setAdminUserOptions(uuidValuePairsToSelectOptions(adminRes.data))
            setUserOptions(uuidValuePairsToSelectOptions(usersRes.data))
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    /**
     * Get the info for the new ticket
     */
    const getTicketInfo = async (abortController) => {
        setIsLoading(true)
        try {
            const templatePromise = templateId ? getTicketTemplateFunc(templateId, abortController) : Promise.resolve()

            const [tcktTypes, orgs, tagRes, templateInputsRes] = await Promise.all([
                axios.get("/api/newticket", { signal: abortController?.signal }),
                getOrganisationKeyValueListFunc(abortController),
                axios.get("/api/tag/list", { signal: abortController?.signal }),
                templatePromise,
            ])
            setTicketTypeOptions(tcktTypes.data)
            setOrgSelectionOptions(orgs.data)
            setTicketTagsOptions(tagRes.data)

            //Set the inputs and load the ticket category and tags here
            setTicketTemplateInputs(templateInputsRes?.data?.requestTemplateInputs)

            // delete the inputs (saved above)
            delete templateInputsRes?.data?.requestTemplateInputs
            setTicketTemplateData(templateInputsRes?.data)

            // If there is only one org select that
            // Else set the org basied on the selected param
            if (Array.isArray(orgs.data)) {
                if (orgs.data.length === 1) {
                    const item = orgs.data[0]
                    setSelectedOrg(item)
                } else if (orgs.data.length >= 1 && orgUuid) {
                    // Find the org we came from
                    const orgUuidItem = orgs.data.find((t) => t?.value === orgUuid)
                    if (orgUuidItem) setSelectedOrg(orgUuidItem)
                }
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
                navigate("/")
            }
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Update the tags, org, type etc.
     * @param {object} ticketTemplate
     */
    const updateTicketInputsFromTemplateData = (ticketTemplate) => {
        if (!ticketTemplate) return

        if (ticketTemplate.title && ticketTemplate.title?.trim() !== "") {
            setTicketTitle(ticketTemplate.title)
        }

        if (typeof ticketTemplate.isPrivate === "boolean") {
            setIsPrivate(ticketTemplate.isPrivate)
        }

        // update the category
        if (ticketTemplate.categoryId && ticketTypeOptions) {
            const newSelectedTicketType = ticketTypeOptions.find((tt) => tt.value === ticketTemplate.categoryId)
            if (newSelectedTicketType) {
                setSelectedTicketType(newSelectedTicketType)
            }
        }

        // update the tags
        if (ticketTemplate.requestTemplateTags.length > 0 && ticketTagsOptions) {
            const newTicketTags = ticketTagsOptions.filter((tto) => ticketTemplate.requestTemplateTags.find((tt) => tt.tagUuid === tto.value))
            if (newTicketTags.length > 0) {
                setSelectedTags(newTicketTags)
            }
        }
    }

    /**
     * Clears the user fields
     */
    const clearFields = () => {
        setAcceptedFiles([])
        setMentions([])
        setTicketTitle("")
        setTicketBody("")
        setSelectedOrg(null)
        setSelectedTicketType(null)
        setSelectedTags(null)
        setSelectedAssignedTo(null)
        setIsPrivate(false)
    }

    /**
     * Submit the ticket to the api
     */
    const submitTicket = async (e) => {
        e.preventDefault()
        if (isSubmitting) return
        if (!isTicketValid()) return

        setIsSubmitting(true)
        try {
            const res = await newTicketFunc(
                selectedOrg.value,
                selectedTicketType.value,
                ticketTitle,
                getBodyText(true),
                isPrivate ?? false,
                selectedAssignedTo?.value ?? null,
                ticketTemplateData?.id,
                selectedTags.map((st) => st.value),
                mentions,
                acceptedFiles
            )

            if (res.status === 200) {
                addSnackbarMessage("Ticket successfully created.", AlertTypes.Success)
                clearFields()
                const ticketId = res.data.id
                const isAdminOrTechnician = isAdmin || isTechnician

                // If this was logged by a user, request the notifications be sent out now
                if (ticketId && !isAdminOrTechnician) getSendUserNotificationsFunc(ticketId)

                navigate(isAdminOrTechnician ? `/ticket/${ticketId}` : "/dashboard")
            } else {
                addSnackbarMessage("Ticket was not created.", AlertTypes.Error)
            }
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    /**
     * Gets the ticket orders KPIs
     * @param {string} orgUuid
     */
    const getOrgKPIs = async (orgUuid) => {
        const res = await getOrganisationKPIsFunc(orgUuid)
        setOrgKPIs(res.data)
    }

    /**
     * Checks if the ticket is valid
     */
    const isTicketValid = () => {
        if (!selectedOrg || !selectedTags || !selectedTicketType) return false
        if (!ticketTitle?.trim()) return false
        if (!validateBodyText()) return false
        if (selectedTags.length === 0) return false
        return true
    }

    /**
     * Template input changed
     */
    const onTemplateInputChange = (input, value) => {
        setTicketTemplateInputs((currentValues) => {
            // Find the correct input and update it
            const index = currentValues.findIndex((t) => t.id == input.id)
            if (index >= 0) {
                currentValues[index] = { ...currentValues[index], value }
            }
            return [...currentValues]
        })
    }

    /**
     * Update the org label and get the org KPIs
     */
    useEffect(() => {
        const adminsController = new AbortController()

        if (selectedOrg) getOrgKPIs(selectedOrg.value)
        else setOrgKPIs(null)

        getUserList(selectedOrg?.value, adminsController)

        setErrMsg(selectedOrg?.label ? null : "Please select an organisation.")

        // Clean up function
        return () => adminsController.abort()
    }, [selectedOrg])

    /**
     * Update the inputs from the template data once it arives
     */
    useEffect(() => {
        if (ticketTemplateData) {
            updateTicketInputsFromTemplateData(ticketTemplateData)
        }
    }, [ticketTemplateData])

    /**
     * Called when the page is first loaded
     */
    useEffect(() => {
        const adminsController = new AbortController()

        getTicketInfo(adminsController)
        getUserList(undefined, adminsController)

        // Clean up function
        return () => adminsController.abort()
    }, [templateId])

    return (
        <div className='page-wrapper'>
            <div className='padding-16'>
                <MetaData title={`New Ticket`} />
                <Header2 title='New Ticket' />

                {isLoading ? (
                    <Loading />
                ) : (
                    <form onSubmit={(e) => submitTicket(e)} className='flex-column flex-column-stretch flex-column-reverse-small margin-vertical-8'>
                        <div>
                            {errMsg ? <p className='alert alert-warning'>{errMsg}</p> : null}
                            <RequiredFieldHint />

                            <div className='flex-row flex-column-small flex-gap-32'>
                                <div className='flex-column flex-gap-16 flex-100'>
                                    <div className='label-input-wrapper'>
                                        <label htmlFor='nt-title'>Title</label>
                                        <input
                                            id='nt-title'
                                            type='text'
                                            className='rs-input'
                                            maxLength={inputMaxLength.ticketTitle}
                                            required
                                            onChange={(e) => {
                                                setTicketTitle(e.target.value)
                                            }}
                                            value={ticketTitle ?? ""}
                                            autoFocus={true}
                                        />
                                    </div>
                                    <TemplateInputs
                                        inputs={ticketTemplateInputs}
                                        body={ticketBody ?? ""}
                                        onTemplateInputChange={onTemplateInputChange}
                                        onUpdateBody={(value) => setTicketBody(value)}
                                        userOptions={userOptions ?? undefined}
                                        onUpdateMentions={(value) => {
                                            setMentions(value)
                                        }}
                                    />
                                    <TicketAttachmentDropzone
                                        clickToOpen={true}
                                        showAcceptedFiles={true}
                                        setAcceptedFiles={setAcceptedFiles}
                                        acceptedFiles={acceptedFiles}
                                    />
                                </div>
                                <div className='flex-column flex-gap-16 flex-100'>
                                    <div className='label-input-wrapper'>
                                        <label htmlFor='nt-customer'>Account / Customer</label>
                                        <Select
                                            inputId='nt-customer'
                                            options={orgSelectionOptions ?? undefined}
                                            onChange={setSelectedOrg}
                                            isClearable={true}
                                            required
                                            formatOptionLabel={formatExtendedLabel}
                                            isLoading={!orgSelectionOptions}
                                            value={selectedOrg ?? undefined}
                                            filterOption={selectFilter()}
                                            theme={selectTheme}
                                            className='react-select-container'
                                        />
                                    </div>
                                    {templateId ? null : (
                                        <>
                                            <div className='label-input-wrapper'>
                                                <label htmlFor='nt-ticket-type'>Ticket Category</label>
                                                <Select
                                                    inputId='nt-ticket-type'
                                                    options={ticketTypeOptions ?? undefined}
                                                    onChange={setSelectedTicketType}
                                                    isLoading={!ticketTypeOptions}
                                                    value={selectedTicketType ?? undefined}
                                                    filterOption={selectFilter()}
                                                    theme={selectTheme}
                                                    required
                                                    className='react-select-container'
                                                />
                                            </div>
                                            <div className='label-input-wrapper'>
                                                <label htmlFor='nt-tags'>Tags</label>
                                                <Select
                                                    inputId='nt-tags'
                                                    options={ticketTagsOptions ?? undefined}
                                                    onChange={setSelectedTags}
                                                    isClearable={true}
                                                    isMulti
                                                    required
                                                    formatOptionLabel={formatTagsLabel}
                                                    isLoading={!ticketTagsOptions}
                                                    value={selectedTags ?? undefined}
                                                    filterOption={selectFilter()}
                                                    theme={selectTheme}
                                                    className='react-select-container'
                                                />
                                            </div>
                                        </>
                                    )}
                                    {isAdmin || isTechnician ? (
                                        <>
                                            <div className='label-input-wrapper'>
                                                <label htmlFor='nt-assign-to'>Assign To</label>
                                                <Select
                                                    inputId='nt-assign-to'
                                                    options={adminUserOptions ?? undefined}
                                                    value={selectedAssignedTo}
                                                    isClearable={true}
                                                    onChange={setSelectedAssignedTo}
                                                    isLoading={!adminUserOptions}
                                                    filterOption={selectFilter()}
                                                    theme={selectTheme}
                                                    className='react-select-container'
                                                />
                                            </div>
                                            {isAdmin ? (
                                                <div className='label-input-wrapper'>
                                                    <LabelledCheckbox
                                                        id='IsPrivate'
                                                        label={`Admin Ticket`}
                                                        checked={isPrivate ?? false}
                                                        hint={isPrivate ? "Only admins can see this ticket" : "Anyone can see this ticket"}
                                                        onChange={(e) => {
                                                            setIsPrivate(e.target.checked)
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </>
                                    ) : (
                                        <TicketHints />
                                    )}
                                </div>
                                {isAdmin && <div className='flex-column flex-gap-16 flex-100'>{orgKPIs ? <TicketKpiTable orgKPIs={orgKPIs} /> : null}</div>}
                            </div>
                        </div>
                        <div className='flex-row flex-end-small button-input-wrapper'>
                            <button className='button-contained' type='submit' disabled={!isTicketValid() || isSubmitting ? true : false}>
                                Send Ticket
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

/**
 * Inputs for the ticket template
 */
const TemplateInputs = ({ inputs, onTemplateInputChange, onUpdateBody, onUpdateMentions, body, userOptions }) => {
    if (inputs && Array.isArray(inputs) && inputs.length > 0) {
        return (
            <>
                {inputs.map((input) => (
                    <TemplateInput
                        key={input.id}
                        input={input}
                        onUpdateMentions={onUpdateMentions}
                        onTemplateInputChange={onTemplateInputChange}
                        userOptions={userOptions}
                    />
                ))}
            </>
        )
    } else {
        return (
            <div className='label-input-wrapper'>
                <label htmlFor='nt-issue'>Issue</label>
                <SlateTextArea
                    placeholder='Enter a description of your issue'
                    id='nt-issue'
                    className='rs-input'
                    required={true}
                    value={body ?? ""}
                    onChange={(event) => {
                        onUpdateBody(event.target.value)
                        onUpdateMentions([...event.target.mentions])
                    }}
                    userOptions={userOptions}
                />
            </div>
        )
    }
}

/**
 *  Input for the ticket template
 */
const TemplateInput = ({ input, onTemplateInputChange, onUpdateMentions, userOptions }) => {
    /**
     * Input on change event
     */
    const onInputChange = (e) => {
        onTemplateInputChange(input, e.target.value)
    }

    /**
     * checkbox on change event
     */
    const onCheckboxChange = (e) => {
        const isChecked = e.target.checked ? true : false
        onTemplateInputChange(input, isChecked)
    }

    /**
     * react select change
     */
    const onSelectInputChange = (e) => {
        const csv = Array.isArray(e) ? e?.map((v) => v.value) ?? [] : [e.value]
        onTemplateInputChange(input, csv.join(","))
    }

    const id = `input-${input.id}`

    if (input.type === "checkbox") {
        // check box
        return (
            <div>
                <LabelledCheckbox
                    id={id}
                    label={input.title}
                    required={input.required}
                    checked={input.value === true}
                    hint={input.hint}
                    onChange={onCheckboxChange}
                />
            </div>
        )
    } else if (input.type === "memo") {
        // memo
        return (
            <div className='label-input-wrapper'>
                <label htmlFor={id}>{input.title}</label>
                <SlateTextArea
                    placeholder={input.hint}
                    id={id}
                    className='rs-input'
                    required={input.required}
                    value={input?.value ?? ""}
                    onChange={(event) => {
                        onTemplateInputChange(input, event.target.value)
                        onUpdateMentions([...event.target.mentions])
                    }}
                    userOptions={userOptions}
                />

                <p className='text-hint'>{input.hint}</p>
            </div>
        )
    } else if (input.type === "options" || input.type === "multi-options") {
        // select
        return (
            <div className='label-input-wrapper'>
                <label htmlFor={id}>
                    {input.title} {input.required ? "*" : null}
                </label>
                <Select
                    inputId={id}
                    isClearable={!input.required}
                    required={input.required}
                    isMulti={input.type === "multi-options"}
                    options={csvToSelectOptions(input.options) ?? undefined}
                    value={csvToSelectOptions(input.value)}
                    onChange={onSelectInputChange}
                    filterOption={selectFilter()}
                    theme={selectTheme}
                    className='react-select-container'
                />
                <p className='text-hint'>{input.hint}</p>
            </div>
        )
    } else {
        // Input
        return (
            <div className='label-input-wrapper'>
                <label htmlFor={id}>{input.title}</label>
                <input
                    id={id}
                    type={input.type}
                    className='rs-input'
                    required={input.required}
                    placeholder={input.hint}
                    onChange={onInputChange}
                    value={input.value ?? ""}
                />
                <p className='text-hint'>{input.hint}</p>
            </div>
        )
    }
}

/**
 * Hints for how to write a good ticket
 */
const TicketHints = () => {
    return (
        <div className='nt-tips'>
            <h3 className='flex-row-center-vertical'>
                <AutoAwesomeIcon /> Ticket Tips
            </h3>
            <ol className='flex-column flex-gap-8'>
                <li>When speaking to the customer, make sure to get their Account Number.</li>
                <li>The customer number, address, or name can be entered in the &apos;Account/Customer&apos; to find the customer account.</li>
                <li>Explain the issue in as much detail as possible in the &apos;Issue&apos; box so we can rectify the problem as quick as we can.</li>
                <li>Make sure the ticket category is accurate to the issue given, so we can make sure the correct correspondent works on the ticket.</li>
            </ol>
        </div>
    )
}

/**
 * List of orgs tickets
 */
const TicketKpiTable = ({ orgKPIs }) => {
    return (
        <>
            <div className='flex-row'>
                <OrganisationKPIs
                    orgUuid={orgKPIs.orgUuid}
                    ticketsLoggedPastWeek={orgKPIs.ticketsLoggedPastWeek}
                    ticketsLoggedPastMonth={orgKPIs.ticketsLoggedPastMonth}
                />
            </div>
            <div>
                {orgKPIs.openTickets.length > 0 ? (
                    <table className='ticket-table'>
                        <thead>
                            <tr>
                                <th>Ticket ID</th>
                                <th>Title</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orgKPIs.openTickets.map((ot) => {
                                return (
                                    <tr key={ot.id}>
                                        <td>
                                            <Link to={`/ticket/${ot.id}`} target='_blank' rel='noopener noreferrer'>
                                                {ot.id}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/ticket/${ot.id}`} target='_blank' rel='noopener noreferrer'>
                                                {ot.name}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/ticket/${ot.id}`} target='_blank' rel='noopener noreferrer'>
                                                {ot.category}
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : null}
            </div>
        </>
    )
}

export default NewTicket
