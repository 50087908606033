import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import useUser from "../hooks/useUser"
import Loading from "./Loading"

/**
 * Checks the login and redirects if needed
 */
const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { auth, refreshAuth } = useUser()

    useEffect(() => {
        const verifySession = async () => {
            await refreshAuth()
            setIsLoading(false)
        }

        !auth?.loggedIn ? verifySession() : setIsLoading(false)
    }, [])

    return <>{isLoading ? <Loading /> : <Outlet />}</>
}

export default PersistLogin
