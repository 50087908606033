import React from "react"

/**
 * Virtual divider
 */
function VDivider({ padding = 16, paddingLeft = undefined, paddingRight = undefined, colour = "black", width = undefined, className = undefined }) {
    paddingLeft = isNaN(paddingLeft) ? padding : paddingLeft
    paddingRight = isNaN(paddingRight) ? padding : paddingRight

    const styles = {
        padding: `0 ${paddingRight}px 0 ${paddingLeft}px`,
    }
    if (width) styles.width = `${isNaN(width) ? width : width + "%"}`

    return (
        <div style={styles} className={`divider-vertical-line ${className ?? ""}`}>
            <div className={colour === "white" ? "divider-vertical-line-white" : "divider-vertical-line-black"} />
        </div>
    )
}

export default VDivider
