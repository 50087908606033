import axios from "../axios"

/**
 * Gets a full list items
 */
export const getOrganisationGroupListFunc = async (abortController) => {
    return axios.get(`/api/organisationgroup`, { signal: abortController?.signal })
}

/**
 * Gets a full list items in as value-label pairs
 */
export const getOrganisationGroupValuePairsListFunc = async (abortController) => {
    return axios.get(`/api/organisationgroup/valuepairs`, { signal: abortController?.signal })
}

/**
 * Gets a single of item
 */
export const getOrganisationGroupItemFunc = async (itemUuid, abortController) => {
    return axios.get(`/api/organisationgroup/${itemUuid}`, { signal: abortController?.signal })
}

/**
 * Patch the item
 */
export const patchOrganisationGroupFunc = async (itemData, abortController) => {
    const res = await axios.patch("/api/organisationgroup", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Post the item
 */
export const postOrganisationGroupFunc = async (itemData, abortController) => {
    const res = await axios.post("/api/organisationgroup", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Deletes the passed item
 */
export const deleteOrganisationGroupFunc = async (itemUuid, abortController) => {
    const res = await axios.delete(`/api/organisationgroup/${itemUuid}`, { signal: abortController?.signal })
    return new Promise((resolve) => {
        resolve(res)
    })
}
