import React, { useEffect, useState } from "react"
import AddBoxIcon from "@mui/icons-material/AddBox"
import Select from "react-select"
import { Link } from "react-router-dom"

import { axiosErrorMessage } from "../../../components/axios"
import DataTable from "../../../components/mui/DataTable"
import { Header2 } from "../../../components/headers/Headers"

import MetaData from "../../../components/MetaData"
import LabelledCheckbox from "../../../components/forms/LabelledCheckbox"
import useDebounceState from "../../../hooks/useDebounceState"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import useUser from "../../../hooks/useUser"
import { formatExtendedLabel, selectFilter, selectTheme } from "../../../common/selectHelpers"
import { getOrganisationListFunc } from "../../../components/httpRequests/OrganisationRequests"

/**
 * The basic link
 */
const BasicLink = (params) => <Link to={`/organisation/${params.id}`}>{params.value}</Link>

/**
 * Data Table fields
 */
const columns = [
    {
        field: "orgUuid",
        headerName: "Id",
        flex: 1,
        renderCell: BasicLink,
    },
    {
        field: "orgCode",
        headerName: "Code",
        flex: 0.5,
        renderCell: BasicLink,
    },
    {
        field: "orgName",
        headerName: "Name",
        flex: 1,
        minWidth: 100,
        hideable: false,
        renderCell: (params) => (
            <Link to={`/organisation/${params.id}`}>
                {params.row.disabledAt && (
                    <span className='pill-danger' style={{ marginRight: "4px" }}>
                        DISABLED
                    </span>
                )}
                {params.value}{" "}
            </Link>
        ),
    },
    {
        field: "description",
        headerName: "Description",
        flex: 1,
        minWidth: 100,
        renderCell: BasicLink,
    },
    {
        field: "orgNickName",
        headerName: "NickName",
        flex: 0.5,
        minWidth: 100,
        renderCell: BasicLink,
    },
    {
        field: "orgAddress",
        headerName: "Address",
        flex: 1,
        renderCell: BasicLink,
    },

    {
        field: "orgPhone1",
        headerName: "Phone 1",
        flex: 0.5,
        renderCell: BasicLink,
    },
    {
        field: "orgPhone2",
        headerName: "Phone 2",
        flex: 0.5,
        renderCell: BasicLink,
    },
    {
        field: "orgEmail",
        headerName: "Email",
        flex: 0.5,
        renderCell: BasicLink,
    },
    {
        field: "disabledAt",
        headerName: "Disabled",
        flex: 0.5,
        type: "boolean",
        minWidth: 50,
    },
    {
        field: "userAmount",
        headerName: "Amount of Users",
        description: "The number of users in this organisation",
        type: "number",
        flex: 0.5,
        minWidth: 50,
    },
    {
        field: "ticketAmount",
        headerName: "Amount of Tickets",
        description: "The number of tickects for this organisation",
        type: "number",
        flex: 0.5,
        minWidth: 50,
    },
    {
        field: "documentCount",
        headerName: "Documents",
        type: "number",
        flex: 0.5,
        minWidth: 50,
    },
    {
        field: "contractCount",
        headerName: "Contracts",
        type: "number",
        flex: 0.5,
        minWidth: 50,
    },
]

/**
 * Organisations page
 */
const Organisations = () => {
    const { addSnackbarMessage } = useAlertContext()
    const { hasAuthRole_TechnicianOrAbove } = useUser()

    const [orgs, setOrgs] = useState(null)
    const [filteredOrgs, setFilteredOrgs] = useState(null)
    const [showDisabled, setShowDisabled] = useState(false)
    const [searchValue, setSearchValue] = useDebounceState("")

    const [groupValue, setGroupValue] = useState(null)
    const [groupValues, setGroupValues] = useState([])

    const isTechnician = hasAuthRole_TechnicianOrAbove()

    /**
     * Get the org list from the API
     */
    const getOrgList = async (abortController) => {
        try {
            const response = await getOrganisationListFunc(abortController)
            setOrgs(response.data)
            setGroupValues(getGroups(response.data))
            setFilteredOrgs(getNewFilterArray(response.data, showDisabled, searchValue, groupValue))
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    /**
     * check if searchValueLC is in org
     */
    const orgIncludes = (org, searchValue) => {
        const searchValueLC = searchValue?.toLowerCase()?.trim()
        if (!searchValueLC) return true
        if (!org) return true

        // get the search string, lowercase and trimed
        const orgStr = `${org.orgAddress ?? ""} ${org.orgCode ?? ""} ${org.description ?? ""} ${org.orgEmail ?? ""} ${org.orgName ?? ""} ${
            org.orgNickName ?? ""
        } ${org.orgPhone1 ?? ""} ${org.orgPhone2 ?? ""}`
            .toLowerCase()
            .trim()

        return orgStr.includes(searchValue)
    }

    /**
     * Gets unique list of groups from the passed org array
     */
    const getGroups = (orgArr) => {
        if (!Array.isArray(orgArr)) return []
        if (orgArr.length === 0) return []
        const groups = []
        const groupIds = []

        // find a unique list of groups
        orgArr.forEach((o) => {
            if (!Array.isArray(o.groups)) return
            o.groups.forEach((g) => {
                if (groupIds.includes(g.value)) return
                groupIds.push(g.value)
                groups.push({ value: g.value, label: g.label })
            })
        })
        return groups
    }

    /**
     * Filters the array
     */
    const getNewFilterArray = (arr, showDisabled, searchValue, groupValue) => {
        let filteredArr = arr.filter((t) => showDisabled || !t?.disabledAt)

        if (groupValue) {
            // filter to just the group id
            filteredArr = filteredArr.filter((t) => t.groups.some((g) => g.value === groupValue.value))
        }

        return !searchValue?.trim() ? filteredArr : filteredArr.filter((o) => orgIncludes(o, searchValue))
    }

    /**
     * Search updated so need to re-filter the results
     */
    useEffect(() => {
        if (orgs) setFilteredOrgs(getNewFilterArray(orgs, showDisabled, searchValue, groupValue))
    }, [showDisabled, searchValue, groupValue])

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getOrgList(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [])

    return (
        <div className='page-wrapper'>
            <MetaData title={`Organisations`} />

            <div className='padding-16 divider-line-black-bottom flex-row flex-column-small'>
                <div className='flex-20'>
                    {isTechnician ? (
                        <Link to='/organisations/new' style={{ textDecoration: "none" }} className='button-contained'>
                            <AddBoxIcon />
                            New Organisation
                        </Link>
                    ) : (
                        false
                    )}
                </div>

                <div className='flex-80'>
                    <div className='flex-row flex-column-small'>
                        <input
                            id='searchValue'
                            className='rs-input flex-70'
                            type='text'
                            placeholder='Search Here'
                            defaultValue={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value?.toLowerCase())
                            }}
                        />
                        <Select
                            inputId='nu-role'
                            options={groupValues}
                            formatOptionLabel={formatExtendedLabel}
                            value={groupValue}
                            onChange={(e) => setGroupValue(e)}
                            filterOption={selectFilter()}
                            theme={selectTheme}
                            isClearable
                            className='react-select-container flex-30'
                            placeholder='Group Search'
                        />
                    </div>
                    <LabelledCheckbox
                        onChange={(e) => {
                            setShowDisabled(e.target.checked)
                        }}
                        label='Show Disabled Organisations'
                        id='show-disabled'
                        className='padding-vertical-8'
                    />
                </div>
            </div>

            <div className='padding-16'>
                <Header2 title='Organisations' />

                <DataTable
                    tableId={"organisations-table"}
                    rows={filteredOrgs}
                    columns={columns}
                    getRowId={(u) => u.orgUuid}
                    defaultColumnVisibility={{
                        orgUuid: false,
                        description: false,
                        orgNickName: false,
                        orgAddress: false,
                        orgPhone2: false,
                        orgEmail: false,
                        documentCount: false,
                    }}
                    checkboxSelection={false}
                    hideFooter={false}
                />
            </div>
        </div>
    )
}

export default Organisations
