import React, { useState } from "react"

import NavMenu from "../components/nav/NavMenu"
import TopBar from "../components/TopBar"
import MetaData from "../components/MetaData"
import Snackbar from "../components/mui/Snackbar"
import useUser from "../hooks/useUser"
import useAlertContext from "../hooks/useAlertContext"

import "../pages/styling/general.css"

/**
 * Wapper for the page layout
 */
const Layout = ({ children }) => {
    const { auth } = useUser()
    const { snackbarMessage, clearSnackbarMessage } = useAlertContext()
    const [navbarIsOpen, setNavbarIsOpen] = useState(false)

    return (
        <>
            <MetaData />
            <TopBar navbarIsOpen={navbarIsOpen} setNavbarIsOpen={setNavbarIsOpen} />
            <div className='layout-wrapper'>
                {auth.loggedIn ? <NavMenu navbarIsOpen={navbarIsOpen} setNavbarIsOpen={setNavbarIsOpen} /> : null}

                <div className='layout-content'>{children}</div>

                {snackbarMessage !== undefined ? (
                    <Snackbar open={true} message={snackbarMessage?.message} severity={`${snackbarMessage?.severity}`} onClose={clearSnackbarMessage} />
                ) : null}
            </div>
        </>
    )
}

export default Layout
