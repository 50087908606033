import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { patchUnsubscribeRequest } from "../components/httpRequests/LoginRequests"
import useAlertContext, { AlertTypes } from "../hooks/useAlertContext"
import LoginPageWapper from "../components/LoginPageWapper"
import { axiosErrorMessage } from "../components/axios"
import Loading from "../components/Loading"

/**
 * Unsubscribe the user from email notifications
 */
function UserUnsubscribe() {
    const navigate = useNavigate()
    const { userUuid } = useParams()
    const { addSnackbarMessage } = useAlertContext()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    /**
     * Log the user into the side
     */
    const doUnsubscribe = async (abortController) => {
        if (isSubmitting || !userUuid) return

        setIsSubmitting(true)
        try {
            const res = await patchUnsubscribeRequest(userUuid, abortController)
            console.log(res, res.status)
            setSubmitted(res.status === 200)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    /**
     * make sure we have a valid code
     */
    useEffect(() => {
        const abortController = new AbortController()

        if (!userUuid) {
            navigate("/")
        } else {
            doUnsubscribe(abortController)
        }

        // Clean up function
        return () => abortController.abort()
    }, [userUuid])

    return (
        <LoginPageWapper showForgotPassword={false} showRegister={false} showLogin={true}>
            {submitted ? (
                <p className='padding-bottom-16'>
                    You have been <strong>UNSUBSCRIBED</strong> from email notifications
                </p>
            ) : (
                <Loading />
            )}
        </LoginPageWapper>
    )
}

export default UserUnsubscribe
