import React, { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import "./styling/general.css"
import "./styling/login.css"

import { postLogin } from "../components/httpRequests/LoginRequests"
import { axiosErrorMessage } from "../components/axios"
import useUser from "../hooks/useUser"
import useAlertContext, { AlertTypes } from "../hooks/useAlertContext"
import { useLocalStorageState } from "../hooks/useStorageState"
import LoginPageWapper from "../components/LoginPageWapper"
import LabelledCheckbox from "../components/forms/LabelledCheckbox"
import { queryParamToReturnTo } from "../components/utility/Helpers"

/**
 * Login screen
 */
const Login = () => {
    const { addSnackbarMessage } = useAlertContext()
    const usr = useUser()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [authCode, setAuthCode] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isAuthCodeRequired, setIsAuthCodeRequired] = useState(false)
    const [remeberMe2FA, setRemeberMe2FA] = useState(false)
    const [deviceId, setDeviceId] = useLocalStorageState("device-id", null)

    /**
     * Log the user into the side
     */
    const handleFormSubmit = async (e) => {
        e.preventDefault()

        if (!email || !password || isSubmitting) return
        setIsSubmitting(true)
        try {
            const deviceIdOrFlag = deviceId ? deviceId : remeberMe2FA ? "set-device-id" : null

            const response = await postLogin(email, password, authCode, deviceIdOrFlag)
            if (response.data.loggedIn) {
                const data = response.data
                setDeviceId(data.deviceId)

                // Remove anything that should not be set in auth
                delete data.deviceId

                usr.setAuth((currentState) => {
                    return {
                        ...currentState,
                        ...data,
                    }
                })
                const returnTo = queryParamToReturnTo(searchParams?.get("returnTo"))
                navigate(returnTo)
            }
            setIsAuthCodeRequired(false)
            setAuthCode(null)
        } catch (e) {
            if (e?.response?.status === 401 && e.response?.data?.authCodeRequired === true && authCode === null) {
                setIsAuthCodeRequired(true)
                setDeviceId(null)
            } else {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    const isValid = email && password

    return (
        <LoginPageWapper>
            {isAuthCodeRequired && isValid ? (
                <form onSubmit={(e) => handleFormSubmit(e)}>
                    <div className='form-section'>
                        <label htmlFor='authCode'>Two-Factor Authentication</label>
                        <input
                            id='authCode'
                            className='rs-input'
                            type='text'
                            onChange={(e) => setAuthCode(e.target.value)}
                            value={authCode ?? ""}
                            required
                            disabled={isSubmitting}
                            autoFocus={true}
                            autoComplete='one-time-code'
                        />
                    </div>

                    <LabelledCheckbox
                        id='rememberMe'
                        onChange={(e) => setRemeberMe2FA(e.target.checked)}
                        checked={remeberMe2FA ?? false}
                        label='Remember this browser'
                    />

                    <div className='form-section'>
                        <button className='button-contained-100' disabled={isSubmitting || !authCode} type='submit'>
                            Validate
                        </button>
                    </div>
                </form>
            ) : (
                <form onSubmit={(e) => handleFormSubmit(e)}>
                    <div className='form-section'>
                        <label htmlFor='email'>Email</label>
                        <input
                            id='email'
                            className='rs-input'
                            type='email'
                            value={email ?? ""}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            disabled={isSubmitting}
                            autoFocus={true}
                            autoComplete='email'
                        />
                    </div>

                    <div className='form-section'>
                        <label htmlFor='password'>Password</label>
                        <input
                            id='password'
                            className='rs-input'
                            type='password'
                            value={password ?? ""}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            disabled={isSubmitting}
                            autoComplete='current-password'
                        />
                    </div>

                    <div className='form-section'>
                        <button className='button-contained-100' disabled={isSubmitting || !isValid} type='submit'>
                            Login
                        </button>
                    </div>
                </form>
            )}
        </LoginPageWapper>
    )
}

export default Login
