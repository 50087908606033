import React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { remarkAlert } from "remark-github-blockquote-alert"

import ErrorBoundary from "./errors/ErrorBoundary"
import ErrorBlock from "./errors/ErrorBlock"

/**
 * Wrapper for the react-markdown component wrapped in an error boundary
 * @param {*} param0
 */
function MarkdownWrapper({ className = "", children }) {
    if (typeof children === "string") {
        // sanitize the mark down
        // replace {LF} with {SPACE}{SPACE}{LF} for new lines
        // https://www.markdownguide.org/basic-syntax/
        children = children.replaceAll("\n", "  \n")
    }
    return (
        <ErrorBoundary fallback={<ErrorBlock error={children} errorTitle='Markdown Error' />}>
            <ReactMarkdown className={`markdown ${className}`} components={{ a: LinkRenderer }} remarkPlugins={[[remarkGfm], [remarkAlert]]}>
                {children}
            </ReactMarkdown>
        </ErrorBoundary>
    )
}

/**
 * Renderer for markdown links
 */
function LinkRenderer(props) {
    const isExternal = !props.href?.startsWith(process.env.REACT_APP_SITE_DOMAIN) && !props.href?.startsWith("/")
    const videoUrl = isExternal && typeof props.children === "string" ? isEmbeddedVideoUrl(props.href) : false

    return videoUrl ? (
        <VideoRenderer {...props} href={videoUrl} />
    ) : (
        <a href={props.href} className={props.className} target={isExternal ? "_blank" : undefined} rel={isExternal ? "noreferrer" : undefined}>
            {props.children}
        </a>
    )
}

/**
 * Renderer for markdown video
 */
function VideoRenderer(props) {
    const href = props.href
    const title = typeof props.children === "string" ? props.children : undefined

    return (
        <iframe
            title={title}
            src={href}
            height='315'
            style={{ borderWidth: 0, width: "min(560px, 80dvw)" }}
            referrerPolicy='strict-origin-when-cross-origin'
            allowfullscreen={true}
            allow='ccelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        />
    )
}

/**
 * Checks if the passed link is an Embedded video and returns the url to the embed link if it is
 * @param string $url the url to check
 * @return false|string
 */
function isEmbeddedVideoUrl(url) {
    if (!url) return false

    // We need to force https, as out site is https!
    url = url.replace("http://", "https://")
    if (!url.startsWith("https://")) return false

    // list of video sites, the second value if the url to replace with or false
    // NOTE: vimo links require https://player.vimeo.com/api/player.js to be included
    const video_sites = new Map([
        ["https://www.youtube.com/embed", false],
        ["https://www.youtube-nocookie.com/embed", false],
        ["https://youtu.be", "https://www.youtube.com/embed"],
        //["https://player.vimeo.com/video", false],
        //["https://vimeo.com", "https://player.vimeo.com/video"],
        //["https://drive.google.com/file/d", false],
    ])

    let result = false

    // loop the video sites checking for a match
    video_sites.forEach((value, key) => {
        if (url.toLocaleLowerCase().startsWith(key?.toLocaleLowerCase())) {
            // we need to do a replace the url to the embed one
            result = value ? url.replace(key, value) : url
        }
    })

    return result
}

export default MarkdownWrapper
