import React from "react"
import { Link } from "react-router-dom"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"

import "../ComponentStyles.css"

import Tag from "../data/Tag"
import { primaryColor } from "../../Theme"

/**
 * Card wrapper
 */
const AccordionCard = ({ children, id, pillLabel = undefined, title, className, expanded = false, handleExpandedChange = undefined }) => {
    return (
        <Accordion
            className={`accordion-card-wrapper ${className ? className : ""}`}
            //defaultExpanded={defaultExpanded}
            expanded={expanded}
            onChange={handleExpandedChange ? (e, expanded) => handleExpandedChange(id, expanded) : undefined}
            slotProps={{ transition: { unmountOnExit: true } }}
        >
            <AccordionSummary className='card-header' expandIcon={<ExpandMoreIcon />} id={id}>
                <div className='flex-row flex-wrap flex-spacebetween flex-100'>
                    <h2 className='padding-inline-8'>{title}</h2>
                    {pillLabel && <Tag label={pillLabel} colour={primaryColor} />}
                </div>
            </AccordionSummary>
            <AccordionDetails className='card-content'>
                <>{children}</>
            </AccordionDetails>
        </Accordion>
    )
}

/**
 * A card section
 */
export const AccordionCardSection = ({ children }) => {
    return <div className='card-section'>{children}</div>
}

/**
 * a link in a card section
 */
export const AccordionCardSectionLink = ({ children, to, target = undefined }) => {
    return (
        <Link to={to} className='card-section-link' target={target}>
            {children}
        </Link>
    )
}

export default AccordionCard
