import React from "react"
import "../pages/styling/general.css"
import "../pages/styling/animations.css"

const FullscreenLoading = () => {
    return (
        <div className='fullscreen-overlay flex-center-item'>
            <div className='loadingio-spinner-ripple-5jvviodeubo'>
                <div className='ldio-z1ak9e2kq2a-2'>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default FullscreenLoading
