import axios from "../axios"

/**
 * * Gets a user
 * @param {string} userUuid
 * @param {AbortController} abortController
 * @returns
 */
export const getUserFunc = async (userUuid, abortController) => {
    return axios.get(`/api/user/${userUuid}`, { signal: abortController?.signal })
}

/**
 * Gets the list of admins
 * const controller = new AbortController();
 * - return array of UuidValuePair (Uuid, Value)
 */
export const getAdminsFunc = async (abortController) => {
    return axios.get("/api/user/admins", { signal: abortController?.signal })
}

/**
 * Gets the list of technicians for the passed org
 * @param {string} orgUuid
 * @param {AbortController} abortController
 * @returns Array UuidValuePair (Uuid, Value)
 */
export const getTechniciansFunc = async (orgUuid, abortController) => {
    return axios.get(`/api/user/technicians/${orgUuid ?? ""}`, { signal: abortController?.signal })
}

/**
 * Gets the list of technicians for the passed organization
 * @param {number|undefined} ticketId
 * @param {string} orgUuid
 * @param {AbortController} abortController
 * @returns  Array UuidValuePair (Uuid, Value)
 */
export const getTicketUsersFunc = async (ticketId, orgUuid, abortController) => {
    return axios.get(`/api/user/ticket/${ticketId ?? 0}/${orgUuid ?? ""}`, { signal: abortController?.signal })
}

/**
 * Update a user
 * @param {object} itemData
 * @param {AbortController} abortController
 * @returns
 */
export const patchUser = async (itemData, abortController) => {
    const res = await axios.patch("/api/user/", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Create a user
 * @param {object} itemData
 * @param {AbortController} abortController
 * @returns
 */
export const postUser = async (itemData, abortController) => {
    const res = await axios.post("/api/user/", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}
