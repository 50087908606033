import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { AppBar } from "@mui/material"

import AddBoxIcon from "@mui/icons-material/AddBox"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"

import "../pages/styling/general.css"
import "./ComponentStyles.css"

import useUser from "../hooks/useUser"
import axios from "./axios"
import NotificationDropdown from "./notifications/NotificationDropdown"
import NumberBadge from "./notifications/NumberBadge"
import IconButton from "./mui/IconButton"
import useRepeatingFetch from "../hooks/useRepeatingFetch"
import HamburgerIcon from "./nav/HamburgerIcon"

/**
 * Top nav bar
 */
const TopBar = ({ navbarIsOpen = false, setNavbarIsOpen }) => {
    const { auth, currentRoute, setCurrentRoute, currentTicketInfo, currentOrgUuid } = useUser()
    const location = useLocation()

    const [notificationData, fetchNotificationData] = useRepeatingFetch("/api/notification/", 300 /* 5 mins */)

    const [openNotifs, setOpenNotifs] = useState(false)
    const [newNotifs, setNewNotifs] = useState(0)

    /**
     * Gets a list of notifications
     */
    const getUserNotifications = () => {
        if (auth.loggedIn) {
            fetchNotificationData()
        }
    }

    /**
     * Open/ Close the notification window
     */
    const toggleNotifications = async () => {
        // if we are open, then toggle will close it
        if (openNotifs) {
            await axios.patch("/api/notification/readall")
            getUserNotifications()
        }
        setOpenNotifs((value) => !value)
    }

    /**
     * Delete a single notification
     */
    const deleteNotification = async (tuuid) => {
        await axios.delete(`/api/notification/delete/${tuuid}`)
        getUserNotifications()
    }

    /**
     * Delete all single notifications
     */
    const deleteAllNotifications = async () => {
        await axios.delete("/api/notification/delete")
        getUserNotifications()
    }

    /**
     * Gets the query params for the new tickets route
     * @param {string|null} ordUuid
     */
    const getQueryParams = (ordUuid) => {
        if (!ordUuid) return ""
        return "?orgUuid=" + ordUuid
    }

    /**
     * Update icon when location changes
     */
    useEffect(() => {
        setCurrentRoute(location.pathname)
        getUserNotifications()
    }, [location.pathname, auth.loggedIn])

    /**
     * Update the notification icon
     */
    useEffect(() => {
        const count = notificationData?.reduce((accumulator, n) => (n.newNotification === true ? accumulator + 1 : accumulator), 0)
        setNewNotifs(count)
    }, [notificationData])

    return (
        auth?.loggedIn && (
            <AppBar position='sticky' color='transparent' className='hidden-print'>
                <div className='top-bar-wrapper'>
                    <IconButton onClick={() => setNavbarIsOpen((currentValue) => !currentValue)} color='primary' size='large'>
                        <HamburgerIcon navbarIsOpen={navbarIsOpen} />
                    </IconButton>

                    {/* org info */}
                    <div className='flex-row-center-vertical hidden-small flex-gap-16'>
                        <OrgDatanippet currentTicketInfo={currentTicketInfo} />
                    </div>
                    {/* action buttons */}
                    <div className='flex-row-center-vertical'>
                        {!currentRoute?.startsWith("/new-ticket") ? (
                            <Link to={`/new-ticket/template${getQueryParams(currentOrgUuid)}`} style={{ textDecoration: "none" }} className='button-contained'>
                                <AddBoxIcon />
                                New Ticket
                            </Link>
                        ) : (
                            <Link to='/dashboard' style={{ textDecoration: "none" }} className='button-contained'>
                                Back to Dashboard
                            </Link>
                        )}
                        <IconButton hintText='Notifications' onClick={toggleNotifications} color='primary' size='large'>
                            {newNotifs > 0 ? (
                                <>
                                    <NotificationsActiveIcon />
                                    <NumberBadge notifAmt={newNotifs} indent={true} />
                                </>
                            ) : (
                                <>
                                    <NotificationsNoneIcon />
                                </>
                            )}
                        </IconButton>
                        {openNotifs && (
                            <div className='fullscreen-overlay-clear' onClick={toggleNotifications}>
                                <NotificationDropdown
                                    userNotifications={notificationData}
                                    deleteAllNotifications={deleteAllNotifications}
                                    deleteNotification={deleteNotification}
                                    toggleOpenNotifs={toggleNotifications}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </AppBar>
        )
    )
}

/**
 * The Center org snippet
 */
const OrgDatanippet = ({ currentTicketInfo }) => {
    /**
     * Format the address
     * @param {string} address
     * @returns string
     */
    const formatAddress = (address) => {
        const addressArr = address
            .split("\n")
            .map((t) => t.trim()) // remove spaces
            .filter(Boolean) // remove empty elements

        return addressArr?.join(", ").replaceAll(",,", ",")
    }

    return currentTicketInfo ? (
        <>
            <div className='org-data-snippet flex-column flex-gap-4'>
                <span>Code</span>
                <p>{currentTicketInfo.code}</p>
            </div>
            {currentTicketInfo.phone1 && (
                <div className='org-data-snippet flex-column flex-gap-4'>
                    <span>Phone</span>
                    <p>{currentTicketInfo.phone1}</p>
                </div>
            )}
            {currentTicketInfo.phone2 && (
                <div className='org-data-snippet flex-column flex-gap-4'>
                    <span>Phone</span>
                    <p>{currentTicketInfo.phone2}</p>
                </div>
            )}
            {currentTicketInfo.email && (
                <div className='org-data-snippet flex-column flex-gap-4'>
                    <span>Email</span>
                    <a href={"mailto:" + currentTicketInfo.email}>{currentTicketInfo.email}</a>
                </div>
            )}
            {currentTicketInfo.address && (
                <div className='org-data-snippet flex-column flex-gap-4'>
                    <span>Address</span>
                    <p>{formatAddress(currentTicketInfo.address)}</p>
                </div>
            )}
        </>
    ) : null
}

export default TopBar
