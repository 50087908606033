import React from "react"
import "../../pages/styling/general.css"
import Tooltip from "../mui/Tooltip"

/**
 *
 * iconColors = danger | default
 */
const DataSnippet = ({ icon, iconColor = "default", iconHint = undefined, title, data }) => {
    const SnippetIcon = icon

    return (
        <div className='data-snippet-wrapper flex-column '>
            <div className='flex-row-center-vertical flex-gap-4'>
                <Tooltip text={iconHint}>
                    <SnippetIcon id='data-snippet-icon' className={`icon-${iconColor ?? "default"}`} />
                </Tooltip>
                <p className='text-no-wrap'>{title}</p>
            </div>
            <p>{data}</p>
        </div>
    )
}

export default DataSnippet
