/**
 * Converts a utc date string into a new date object
 * @param {string} utcDateString
 * @returns
 */
export const utcDateStringToUTCDate = (utcDateString) => {
    if (typeof utcDateString === "string") {
        if (!utcDateString.endsWith("Z")) utcDateString += "Z"
    }

    return new Date(utcDateString)
}

/**
 * Returns true if the time is not 00:00:00
 * @param {Date} datedateString
 */
const hasTime = (date) => {
    return date.getSeconds() + date.getMinutes() + date.getHours() > 0
}

/**
 * The date in the local date format format (dd/mm/yyyy)
 * - dateString should be in the local time format - 2023-08-22T23:00:00
 * @param {string|object} dateString
 */
export const dateFormat = (utcDateString, undefinedValue = "") => {
    if (utcDateString === undefined || utcDateString === null) return undefinedValue
    const dt = utcDateStringToUTCDate(utcDateString)
    return dt.toLocaleDateString()
}

/**
 * The date and time (local time) in the local date format
 * @param {string} dateString
 */
export const dateTimeFormat = (utcDateString, undefinedValue = "") => {
    if (utcDateString === undefined || utcDateString === null) return undefinedValue
    const dt = utcDateStringToUTCDate(utcDateString)
    return `${dt.toLocaleDateString()} ${hasTime(dt) ? dt.toLocaleTimeString() : ""}`.trim()
}

/**
 * converts the passed date strign into an ISO date without the time
 * @param {string} dateString
 * @returns
 */
export const isoDateString = (dateString) => {
    if (dateString === undefined || dateString === null) return undefined
    const dt = new Date(dateString)
    const m = dt.getMonth() + 1 > 9 ? dt.getMonth() + 1 : `0${dt.getMonth() + 1}` // make 2 digits month (note getMonth is zero indexed)
    const d = dt.getDate() > 9 ? dt.getDate() : `0${dt.getDate()}` // make 2 digits date
    return `${dt.getFullYear()}-${m}-${d}`
}

/**
 * Converts the date into a "time since"
 * @param {string} dateString the date in UTC format
 * @returns
 */
export const HowLongAgo = (utcDateString) => {
    const dateNow = new Date()
    const dateThen = utcDateStringToUTCDate(utcDateString)

    let tt3 = Math.abs(dateNow.getTime() - dateThen.getTime())

    tt3 /= 1000
    const seconds = Math.floor(tt3)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const weeks = Math.floor(days / 7)
    const months = Math.floor(weeks / 4)
    const years = Math.floor(months / 12)

    if (years >= 1) return `${years} year${years > 1 ? "s" : ""} ago.`
    if (months >= 1) return `${months} month${months > 1 ? "s" : ""} ago.`
    if (weeks >= 1) return `${weeks} week${weeks > 1 ? "s" : ""}ago.`
    if (days >= 1 && days < 2) return ` yesterday.`
    if (days >= 1) return `${days} day${days > 1 ? "s" : ""}ago.`
    if (hours >= 1) return `${hours} hour${hours > 1 ? "s" : ""} ago.`
    if (minutes >= 1) return `${minutes} minute${minutes > 1 ? "s" : ""} ago.`
    if (seconds >= 1) return `${seconds} second${seconds > 1 ? "s" : ""} ago.`

    return dateTimeFormat(utcDateString)
}

/**
 * Returns true if both dates are the same day
 * @param {Date} d1
 * @param {Date} d2
 * @returns bool
 */
const isSameDate = (d1, d2) => {
    return d1.toDateString() == d2.toDateString()
}

/**
 * Checks if the datetime is today
 * @param {Date} inputDate the date to check
 * @returns boolean
 */
const isTodaysDate = (inputDate) => {
    return isSameDate(new Date(), inputDate)
}

/**
 * Checks if the data is in the past
 * @param {Date} inputDate
 * @returns boolean
 */
const isDateInThePast = (inputDate) => {
    // Get today's date
    const todaysDate = new Date()
    todaysDate.setHours(0, 0, 0, 0)
    inputDate.setHours(0, 0, 0, 0)

    const todaysTime = todaysDate.getTime()
    const inputTime = inputDate.getTime()
    return todaysTime > inputTime
}

/**
 * Gets tomorrows date as a Date object
 * @returns Date
 */
const tomorrowsDate = () => {
    return new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
}

/**
 * The date and time (local time) to a class name
 * @param {string} dateString
 */
export const dateTimeClass = (dateString, undefinedValue = "") => {
    if (dateString === undefined || dateString === null) return undefinedValue
    const inputDate = new Date(dateString)

    if (isTodaysDate(inputDate)) return "date-today"
    else if (isDateInThePast(inputDate)) return "date-past"
    else if (isSameDate(inputDate, tomorrowsDate())) return "date-tomorrow"
    else return "date-future"
}

/**
 * Calcs the aprox number of days between two dates,
 * this is not 100%
 */
export const daysBetween = (dateStringThen, dateStringNow = undefined) => {
    const oneDay = 24 * 60 * 60 * 1000
    const dateThen = new Date(dateStringThen)
    const dateNow = dateStringNow ? new Date(dateStringNow) : new Date()

    dateThen.setHours(0, 0, 0)
    dateNow.setHours(0, 0, 0)

    return Math.ceil((dateThen - dateNow) / oneDay)
}

/**
 * Calcs the aprox number of months between two dates,
 * this is not 100%
 */
export const monthsBetween = (dateStringThen, dateStringNow = undefined) => {
    const approxDaysPerMonth = 30.4375 // copied from delphi dateutils
    return Math.ceil(daysBetween(dateStringThen, dateStringNow) / approxDaysPerMonth)
}

/**
 * Adds a number of dates to the passed date
 * @param {Date} date
 * @param {number} days increment the passed date this number of days
 */
export const addDays = (date, days) => {
    let newDate = new Date(date)
    newDate.setDate(date.getDate() + days)
    return newDate
}
