import axios from "../axios"

/**
 * Gets a full list of templates
 */
export const getTicketTemplatesListFunc = async (abortController, incDisabled = false) => {
    return axios.get(`/api/requesttemplates/${incDisabled ? "all" : ""}`, { signal: abortController?.signal })
}

/**
 * Gets a full list of templates
 */
export const getTicketTemplatesValuePairsListFunc = async (abortController) => {
    return axios.get(`/api/requesttemplates/valuepairs`, { signal: abortController?.signal })
}

/**
 * Gets a single templates
 */
export const getTicketTemplateFunc = async (itemId, abortController) => {
    return axios.get(`/api/requesttemplates/${itemId}`, { signal: abortController?.signal })
}

/**
 * Deletes the passed template
 * @param {integer} itemId the item to delete
 * @param {AbortController} abortController
 */
export const deleteTicketTemplateFunc = async (itemId, abortController) => {
    const res = await axios.delete(`/api/requesttemplates/${itemId}`, { signal: abortController?.signal })
    return new Promise((resolve) => {
        resolve(res)
    })
}
