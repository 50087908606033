import React from "react"
import { Link } from "react-router-dom"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import CircleIcon from "@mui/icons-material/Circle"

import "../ComponentStyles.css"

import { HowLongAgo } from "../utility/ConvertTime"

/**
 * A single user notification
 */
function NotificationItem({ item, deleteNotificationClick = undefined }) {
    /*                             
        "ticketTitle",
        "ticketId":0,
        "notificationType":3,           
        "updatedAt":"2024-07-25T11:08:07",      
    */

    return (
        <div className='notification'>
            <div className='flex-row-spacebetween flex-gap-none'>
                <Link className='flex-column flex-column-stretch notification-info flex-gap-2' to={`/ticket/${item.ticketId}`}>
                    <div className='flex-row'>
                        {item.newNotification || 1 != 2 ? <CircleIcon fontSize='small' className='icon-danger' /> : null}
                        <div className='flex-100'>
                            <p>{item.message}</p>
                            {item.ticketTitle ? <p>{item.ticketTitle}</p> : null}
                            <div className='notification-dt flex-row flex-spacebetween'>
                                <span>Sent {HowLongAgo(item.createdAt)}</span>
                                <span>[Ticket #{item.ticketId}]</span>
                            </div>
                        </div>
                    </div>
                </Link>
                {deleteNotificationClick && (
                    <div className='notification-delete-btn' onClick={() => deleteNotificationClick(item.uuid)}>
                        <DeleteForeverIcon />
                    </div>
                )}
            </div>
        </div>
    )
}

export default NotificationItem
