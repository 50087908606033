import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import React from "react"

/**
 * a popup menu
 */
function PoupMenu({ anchorEl, onClick, menuItems = [] }) {
    const open = Boolean(anchorEl)

    const handleClose = (item) => {
        onClick(item)
    }

    return (
        <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose(undefined)}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            {menuItems?.map((item, index) => {
                return (
                    <MenuItem key={item.id ?? index} onClick={() => handleClose(item)}>
                        <ListItemIcon>{item.icon ?? null}</ListItemIcon>
                        <ListItemText>{item.label ?? ""}</ListItemText>
                    </MenuItem>
                )
            })}
        </Menu>
    )
}

export default PoupMenu
