/**
 * Gets the returnTo query param
 * @param {string} pathname
 * @param {string} prefix the prefix to add to the result (? or &)
 */
export const returnToQueryParam = (pathname, prefix = "?") => {
    if (!pathname || pathname === "/" || pathname === "/dashboard" || pathname === "") return ""
    if (pathname.startsWith("/")) {
        pathname = pathname.substring(1)
    }
    if (pathname.endsWith("/")) {
        pathname = pathname.substring(0, pathname.length - 1)
    }
    return prefix + `returnTo=${encodeURIComponent(pathname)}`
}

/**
 * Converts the query param into a return value, or defaults to dashboard
 * @param {string} paramValue
 */
export const queryParamToReturnTo = (paramValue) => {
    if (!paramValue || paramValue === "") return "/dashboard"

    const decodedParamValue = decodeURIComponent(paramValue)
    if (decodedParamValue === "/" || decodedParamValue.startsWith("/") || decodedParamValue.startsWith("http")) return "/dashboard"
    return "/" + decodedParamValue
}
