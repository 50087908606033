import { TextareaAutosize } from "@mui/material"
import { forwardRef } from "react"

/**
 * Wrapper for the TextareaAutosize
 */
const AutoSizeTextArea = forwardRef(function AutoSizeTextArea(props, ref) {
    const propsCopy = { ...props }

    // get the classname and extra props
    const className = propsCopy.className ? propsCopy.className : ""
    const allowResize = propsCopy.allowResize ? propsCopy.allowResize : true

    // remove the override props
    delete propsCopy.className
    delete propsCopy.allowResize

    // build up the custom style
    const style = { resize: allowResize ? "none" : "both", overflow: "auto" }

    return <TextareaAutosize {...propsCopy} ref={ref} className={`${className} rs-input textarea`} style={style} />
})

export default AutoSizeTextArea
