import React, { useEffect, useState } from "react"
import Select from "react-select"

import axios from "../../../components/axios"
import { formatExtendedLabel, selectFilter, selectTheme } from "../../../common/selectHelpers"
import ConfirmDialog from "../../../components/mui/ConfirmDialog"
import { axiosErrorMessage } from "../../../components/axios"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import {
    deleteUserGroupRoleFunc,
    deleteUserOrganisationRoleFunc,
    getRolesListFunc,
    postUserGroupRoleFunc,
    postUserOrganisationRoleFunc,
} from "../../../components/httpRequests/RoleRequests"

/**
 * action: edit|delete|create
 */
export const userRoleObject = { userUuid: undefined, itemUuid: undefined, roleId: undefined, action: "create" }

Object.freeze(userRoleObject)

/**
 * Quick user permissions edit
 */
function UserRolesModal({ isOrganisation = true, isAdmin = false, onCloseModal, userRole }) {
    const { addSnackbarMessage } = useAlertContext()

    const [isLoading, setIsLoading] = useState(false)
    const [roles, setRoles] = useState(null)
    const [users, setUsers] = useState(null)

    // selected values
    const [selectedRole, setSelectedRole] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)

    /**
     * Load the data
     */
    const findUserData = async (abortController) => {
        if (userRole?.action === "delete") return

        setIsLoading(true)
        try {
            const [userRes, rolesRes] = await Promise.all([
                axios.get("/api/user/", { signal: abortController?.signal }),
                getRolesListFunc(false, abortController),
            ])
            setUsers(userRes.data)
            setRoles(rolesRes.data)

            setSelectedUser(userRes.data?.find((u) => u.value === userRole.userUuid))
            setSelectedRole(rolesRes.data?.find((u) => u.value === userRole.roleId))
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
                onCloseModal(false)
            }
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Do the delete action
     */
    const doDelete = async (userUuid, itemUuid) => {
        if (!isAdmin) return

        try {
            const res = isOrganisation ? await deleteUserOrganisationRoleFunc(itemUuid, userUuid) : await deleteUserGroupRoleFunc(itemUuid, userUuid)
            onCloseModal(res?.status === 200)
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        }
    }

    /**
     * Do the edit action
     */
    const doCreateOrEdit = async (userUuid, itemUuid, roleId) => {
        if (!isAdmin) return

        try {
            const itemData = { itemUuid, userUuid, roleId }
            const res = isOrganisation ? await postUserOrganisationRoleFunc(itemData) : await postUserGroupRoleFunc(itemData)
            onCloseModal(res?.status === 200)
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        }

        onCloseModal(true)
    }

    /**
     * Called when the user is ready to save / delete
     */
    const onYesClick = () => {
        switch (userRole?.action) {
            case "delete":
                doDelete(userRole.userUuid, userRole.itemUuid)
                break
            case "edit":
                doCreateOrEdit(userRole.userUuid, userRole.itemUuid, selectedRole.value)
                break
            case "create":
                doCreateOrEdit(selectedUser.value, userRole.itemUuid, selectedRole.value)
                break
        }
    }

    const onNoClick = () => {
        onCloseModal(false)
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        findUserData(abortController)

        // Clean up function
        return () => {
            abortController.abort()
        }
    }, [])

    return (
        <>
            {userRole?.action === "delete" ? (
                <ConfirmDialog
                    isOpen={true}
                    title='Delete User Role'
                    description={`Are you sure that you want to permanently delete the user role?`}
                    onClose={onNoClick}
                    onNoClick={onNoClick}
                    onYesClick={onYesClick}
                />
            ) : (
                <ConfirmDialog
                    isOpen={!isLoading}
                    title={`${isOrganisation ? "Organisation" : "Group"} Role`}
                    description={`Select the users role in the ${isOrganisation ? "organisation" : "group"}`}
                    yesDisabled={!selectedRole || !selectedUser}
                    onYesClick={onYesClick}
                    onNoClick={onNoClick}
                    onClose={onNoClick}
                    yesLabel={userRole.action}
                    noLabel='Cancel'
                    showCloseButton={true}
                    applyOverflowFix={true}
                >
                    <div className='flex-column width-100'>
                        <div className='form-section form-section-condensed'>
                            <label htmlFor='userUuid'>User</label>
                            <Select
                                inputId='userUuid'
                                options={users ?? undefined}
                                isDisabled={userRole?.userUuid}
                                value={selectedUser}
                                onChange={setSelectedUser}
                                formatOptionLabel={formatExtendedLabel}
                                filterOption={selectFilter()}
                                className='width-100 react-select-container'
                                theme={selectTheme}
                                required={true}
                            />
                        </div>

                        <div className='form-section form-section-condensed'>
                            <label htmlFor='roleId'>Role</label>
                            <Select
                                inputId='roleId'
                                options={roles ?? undefined}
                                value={selectedRole}
                                onChange={setSelectedRole}
                                formatOptionLabel={formatExtendedLabel}
                                filterOption={selectFilter()}
                                className='width-100 react-select-container'
                                theme={selectTheme}
                                required={true}
                            />
                        </div>
                    </div>
                </ConfirmDialog>
            )}
        </>
    )
}

export default UserRolesModal
