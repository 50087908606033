import React from "react"
import { Link } from "react-router-dom"
import { default as MuiCard } from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"

import "../ComponentStyles.css"
import { CardActions } from "@mui/material"

/**
 * Card wrapper
 * @param {{ children, actions, title: string|undefined className: string|undefined, overflowHidden: boolean }} param0
 */
const Card = ({ children, actions, title, className, overflowHidden = true }) => {
    const sx = {}
    if (!overflowHidden) sx.overflow = "unset"

    return (
        <MuiCard className={`card-wrapper ${className ? className : ""}`} sx={sx}>
            {title ? <CardHeader className='card-header' title={title} /> : null}
            <CardContent className='card-content'>
                <>{children}</>
            </CardContent>
            {actions ? <CardActions>{actions}</CardActions> : null}
        </MuiCard>
    )
}

/**
 * A card section
 */
export const CardSection = ({ children }) => {
    return <div className='card-section'>{children}</div>
}

/**
 * a link in a card section
 */
export const CardSectionLink = ({ children, to, target = undefined }) => {
    return (
        <Link to={to} className='card-section-link' target={target}>
            {children}
        </Link>
    )
}

export default Card
