import React, { useEffect } from "react"
import InputTip from "./InputTip"

/**
 * Displays the password input tips for the passed value
 */
function PasswordInputTips({ value1 = "", value2 = "", setIsValid }) {
    const minPasswordLen = 8
    const hasLower = /[a-z]/.test(value1 ?? "")
    const hasUpper = /[A-Z]/.test(value1 ?? "")
    const hasNumbers = /[0-9]/.test(value1 ?? "")
    const hasMatch = value1 === value2
    const hasLength = value1?.length >= minPasswordLen

    const isValid = hasLength && hasLower && hasUpper && hasNumbers && hasMatch

    /**
     * Updates when the user types something
     */
    useEffect(() => {
        if (setIsValid instanceof Function) setIsValid(isValid)
    }, [value1, value2, isValid, setIsValid])

    return value1 ? (
        <div className='help-tip'>
            <InputTip
                valid={hasLength}
                successText={
                    <p>
                        At least <b>8</b> characters in length.
                    </p>
                }
            />
            <InputTip
                valid={hasLower}
                successText={
                    <p>
                        At least <b>one</b> lowercase letter.
                    </p>
                }
            />
            <InputTip
                valid={hasUpper}
                successText={
                    <p>
                        At least <b>one</b> uppercase letter.
                    </p>
                }
            />
            <InputTip
                valid={hasNumbers}
                successText={
                    <p>
                        At least <b>one</b> number.
                    </p>
                }
            />
            <InputTip
                valid={hasMatch && value1?.length > 0}
                successText={
                    <p>
                        New password and confirmation password <b>must</b> match.
                    </p>
                }
            />
        </div>
    ) : null
}

export default PasswordInputTips
