import React from "react"
import { default as MuiTooltip } from "@mui/material/Tooltip"

import "../../pages/styling/general.css"

/**
 * Tooltip
 * side = left, right, top, bottom
 * nl2Br = Converts new lines to line breaks
 * date = if date is passed it will be used in place of text
 */
const Tooltip = ({ date, text, side, isEnabled = true, nl2Br = false, className = undefined, followCursor = undefined, children }) => {
    // we need children
    if (!children) return <></>
    if (!isEnabled || !text) return <>{children}</>

    if (Array.isArray(children)) console.log(`Tooltip error on tip ${text}, children can not be an array`)

    // if the date is past use that as the title
    let title = text
    if (date) {
        const dt = new Date(date)
        title = `${dt.toDateString()} at ${dt.getUTCHours()}:${dt.getUTCMinutes().toString().length > 1 ? dt.getUTCMinutes() : "0" + dt.getUTCMinutes()}`
    } else if (nl2Br && typeof text === "string") {
        // Split the lines on the \n char and map them to lines with a br tag in between them
        const lines = text.split("\n")
        title = (
            <>
                {lines.map((line, index) => (
                    <React.Fragment key={index}>
                        {index === 0 || line === "" ? (
                            line
                        ) : (
                            <>
                                <br />
                                {line}
                            </>
                        )}
                    </React.Fragment>
                ))}
            </>
        )
    }

    const placement = side ? side.toLowerCase() : "bottom"

    return (
        <MuiTooltip title={<>{title}</>} className={className} placement={placement} followCursor={followCursor} enterDelay={250}>
            {children}
        </MuiTooltip>
    )
}

export default Tooltip
