import React from "react"
import { default as MuiSnackbar } from "@mui/material/Snackbar"
import { default as MuiAlert } from "@mui/material/Alert"
import Slide from "@mui/material/Slide"

function TransitionDown(props) {
    return <Slide {...props} direction='down' />
}

/**
 * Wrapper around MUI Snackbar
 */
function Snackbar({ open, message, severity, onClose }) {
    return (
        <MuiSnackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            TransitionComponent={TransitionDown}
        >
            <MuiAlert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
                {message}
            </MuiAlert>
        </MuiSnackbar>
    )
}

export default Snackbar
