import DoneIcon from "@mui/icons-material/Done"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

function InputTip({ valid = false, visible = true, successText, errorText = "" }) {
    if (!visible) return <></>

    return (
        <div className='help-tip-row-wrapper flex-row'>
            {valid ? <DoneIcon className='icon-success' /> : <ErrorOutlineIcon className='icon-danger' />}
            {valid ? successText : errorText.length === 0 ? successText : errorText}
        </div>
    )
}

export default InputTip
