import React, { createContext, useState } from "react"

import { inProgressFilter, subscribedToFilter } from "../../common/searchFilters"
import useUser from "../../hooks/useUser"

const FilterContext = createContext({})

/**
 * Holds the filter context for the tickets
 */
export const FilterProvider = ({ children }) => {
    const { hasAuthRole_StoreAdminOrAbove } = useUser()
    const [filterSettings, setFilterSettings] = useState(inProgressFilter)

    /**
     * Gets the default filter
     */
    const getDefaultFilterForUser = () => {
        return hasAuthRole_StoreAdminOrAbove() ? inProgressFilter : subscribedToFilter
    }

    /**
     * Resets the filters back to the default
     */
    const resetFilters = async () => {
        setFilterSettings(getDefaultFilterForUser())
    }

    return <FilterContext.Provider value={{ filterSettings, setFilterSettings, resetFilters, getDefaultFilterForUser }}>{children}</FilterContext.Provider>
}

export default FilterContext
