import React from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { CssBaseline } from "@mui/material"

import "./variables.css"

import { themeOptionsLight } from "./Theme"
import { roles } from "./Constants"
import { UserProvider } from "./components/userContext/UserContext"
import { FilterProvider } from "./components/filterContext/FilterContext"
import { AlertProvider } from "./components/alertContext/AlertContext"
import PersistLogin from "./components/PersistLogin"
import RequireAuth from "./components/RequireAuth"

import Layout from "./pages/Layout"
import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
/*import Register from "./pages/Register"*/
import NewTicket from "./pages/tickets/NewTicket"
import NewTicketTemplates from "./pages/tickets/NewTicketTemplates"
import Tickets from "./pages/tickets/Tickets"
import Ticket from "./pages/tickets/Ticket"
import Organisations from "./pages/admin/organisations/Organisations"
import Organisation from "./pages/admin/organisations/Organisation"
import NewOrganisation from "./pages/admin/organisations/NewOrganisation"
import PageNotFound from "./pages/PageNotFound"

import Users from "./pages/admin/users/Users"
import User from "./pages/admin/users/User"
import NewUser from "./pages/admin/users/NewUser"
import UserSettings from "./pages/UserSettings"
import UserNotifications from "./pages/UserNotifications"

import AdminSettings from "./pages/admin/settings/AdminSettings"
import Storage from "./pages/admin/storage/Storage"
import NewGroup from "./pages/admin/organisations/NewGroup"
import Groups from "./pages/admin/organisations/Groups"
import Group from "./pages/admin/organisations/Group"
import TicketTemplates from "./pages/admin/tickettemplates/TicketTemplates"
import EditTicketTemplate from "./pages/admin/tickettemplates/EditTicketTemplate"

import StatsDashboard from "./pages/admin/statistics/StatsDashboard"
import ArticlePage from "./pages/ArticlePage"
import ArticleListPage from "./pages/ArticleListPage"
import ForgotPassword from "./pages/ForgotPassword"
import ResetPassword from "./pages/ResetPassword"
import EditKnowledgeBase from "./pages/admin/storage/EditKnowledgeBase"
import UserUnsubscribe from "./pages/UserUnsubscribe"

// Create a theme
const theme = createTheme(themeOptionsLight)
const helmetContext = {}

const allUserRoles = [roles.admin, roles.technician, roles.store_admin, roles.store_user, roles.telesales]
const allUserRolesExceptTelesales = [roles.admin, roles.technician, roles.store_admin, roles.store_user]
const storeAdminRoles = [roles.admin, roles.technician, roles.store_admin]

function App() {
    return (
        <React.StrictMode>
            <HelmetProvider context={helmetContext}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <AlertProvider>
                        <UserProvider>
                            <FilterProvider>
                                <Layout>
                                    <Routes>
                                        <Route path='/login' element={<Login />} />
                                        <Route path='/forgot-password' element={<ForgotPassword />} />
                                        <Route path='/reset-password/:userUuid/:passwordResetCode' element={<ResetPassword />} />
                                        <Route path='/unsubscribe/:userUuid' element={<UserUnsubscribe />} />

                                        {/*<Route path='/register' element={<Register />} />*/}

                                        <Route element={<PersistLogin />}>
                                            <Route element={<RequireAuth allowedRoles={allUserRolesExceptTelesales} />}>
                                                <Route path='/' element={<Dashboard />} />
                                                <Route path='/user/settings' element={<UserSettings />} />
                                                <Route path='/user/notifications' element={<UserNotifications />} />
                                                <Route path='/tickets' element={<Tickets />} />
                                                <Route path='/ticket/:ticketId' element={<Ticket />} />
                                            </Route>

                                            <Route element={<RequireAuth allowedRoles={allUserRoles} />}>
                                                <Route path='/dashboard' element={<Dashboard />} />
                                                <Route path='/new-ticket' element={<NewTicket />} />
                                                <Route path='/new-ticket/template/:templateId' element={<NewTicket />} />
                                                <Route path='/new-ticket/template' element={<NewTicketTemplates />} />
                                                <Route path='/organisation/:orgUuid' element={<Organisation />} />
                                            </Route>
                                            <Route element={<RequireAuth allowedRoles={storeAdminRoles} />}>
                                                <Route path='/organisations' element={<Organisations />} />
                                                <Route path='/organisation/:orgUuid/edit' element={<NewOrganisation />} />
                                            </Route>
                                            <Route element={<RequireAuth allowedRoles={[roles.admin, roles.technician]} />}>
                                                <Route path='/statistics' element={<StatsDashboard />} />
                                                <Route path='/organisations/new' element={<NewOrganisation />} />
                                            </Route>
                                            <Route element={<RequireAuth allowedRoles={[roles.admin]} />}>
                                                <Route path='/admin/settings' element={<AdminSettings />} />
                                                <Route path='/admin/groups/' element={<Groups />} />
                                                <Route path='/admin/groups/new' element={<NewGroup />} />
                                                <Route path='/admin/groups/:groupUuid' element={<Group />} />
                                                <Route path='/admin/groups/:groupUuid/edit' element={<NewGroup />} />
                                                <Route path='/admin/knowledge-base' element={<Storage />} />
                                                <Route path='/admin/knowledge-base/new' element={<EditKnowledgeBase />} />
                                                <Route path='/admin/knowledge-base/:storageUuid/edit' element={<EditKnowledgeBase />} />

                                                <Route path='/admin/ticket-templates' element={<TicketTemplates />} />
                                                <Route path='/admin/ticket-templates/new' element={<EditTicketTemplate />} />
                                                <Route path='/admin/ticket-templates/:templateId/edit' element={<EditTicketTemplate />} />

                                                <Route path='/admin/users' element={<Users />} />
                                                <Route path='/admin/users/new' element={<NewUser />} />
                                                <Route path='/admin/user/:userUuid' element={<User />} />
                                                <Route path='/admin/user/:userUuid/edit' element={<NewUser />} />
                                            </Route>

                                            <Route path='/article/:storageId' element={<ArticlePage />} />
                                            <Route path='/knowledge-base' element={<ArticleListPage />} />
                                            <Route path='/article' element={<Navigate to='/knowledge-base' replace={true} />} />
                                        </Route>

                                        <Route path='*' element={<PageNotFound />} />
                                    </Routes>
                                </Layout>
                            </FilterProvider>
                        </UserProvider>
                    </AlertProvider>
                </ThemeProvider>
            </HelmetProvider>
        </React.StrictMode>
    )
}

export default App
