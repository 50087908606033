import React, { useMemo, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { createEditor, Editor, Range, Transforms, Text, Node } from "slate"
import { Slate, Editable, ReactEditor, withReact } from "slate-react"
import PersonIcon from "@mui/icons-material/Person"
import GroupsIcon from "@mui/icons-material/Groups"

import "./styling/slatetextarea.css"

import ErrorBoundary from "../errors/ErrorBoundary"
import ErrorBlock from "../errors/ErrorBlock"

// Define our own custom set of helpers.
const SlateFormater = {
    isMarkActive(editor, markName) {
        const marks = Editor.marks(editor)
        return marks ? marks[markName] === true : false
    },

    toggleBoldMark(editor) {
        const isActive = SlateFormater.isMarkActive(editor, "bold")
        if (isActive) {
            Editor.removeMark(editor, "bold")
        } else {
            Editor.addMark(editor, "bold", true)
        }
    },

    toggleItalicMark(editor) {
        const isActive = SlateFormater.isMarkActive(editor, "italic")
        if (isActive) {
            Editor.removeMark(editor, "italic")
        } else {
            Editor.addMark(editor, "italic", true)
        }
    },

    toggleCodeMark(editor) {
        const isActive = SlateFormater.isMarkActive(editor, "code")
        if (isActive) {
            Editor.removeMark(editor, "code")
        } else {
            Editor.addMark(editor, "code", true)
        }
    },

    toggleQuoteMark(editor) {
        const isActive = SlateFormater.isMarkActive(editor, "quote")
        if (isActive) {
            Editor.removeMark(editor, "quote")
        } else {
            Editor.addMark(editor, "quote", true)
        }
    },
}

/**
 * Helper function to see if the current text been typed is a mention or not
 * @param {Editor} editor
 */
const isSelectionMention = (editor) => {
    let [range, word] = currentWord(editor)
    if (range && word?.startsWith("@")) {
        return [range, word?.slice(1)?.trim()]
    }
    return [null, null]
}

/**
 * Expand collapsed selection to range containing exactly the
 * current word, even if selection potentially spans multiple
 * text nodes.  If cursor is not *inside* a word (being on edge
 * is not inside) then returns undefined.  Otherwise, returns
 * the Range containing the current word.
 * @see https://github.com/ianstormtaylor/slate/issues/4162
 * @param {Editor} editor
 * @returns [range, word]
 */

function currentWord(editor) {
    const wordRegex = /[\w@_-]/

    const { selection } = editor
    if (selection == null || !Range.isCollapsed(selection)) {
        return [null, undefined] // nothing to do -- no current word.
    }
    const { focus } = selection
    const [node, path] = Editor.node(editor, focus)
    if (!Text.isText(node)) {
        // focus must be in a text node.
        return [null, undefined]
    }
    const { offset } = focus
    const siblings = Node.parent(editor, path).children

    // We move to the left from the cursor until leaving the current
    // word and to the right as well in order to find the
    // start and end of the current word.
    let start = { i: path[path.length - 1], offset }
    let end = { i: path[path.length - 1], offset }
    if (offset == siblings[start.i]?.text?.length) {
        // special case when starting at the right hand edge of text node.
        moveRight(start)
        moveRight(end)
    }
    const start0 = { ...start }
    const end0 = { ...end }

    function len(node) {
        // being careful that there could be some non-text nodes in there, which
        // we just treat as length 0.
        return node?.text?.length ?? 0
    }

    function charAt(pos) {
        const c = siblings[pos.i]?.text?.[pos.offset] ?? ""
        return c
    }

    function moveLeft(pos) {
        if (pos.offset == 0) {
            pos.i -= 1
            pos.offset = Math.max(0, len(siblings[pos.i]) - 1)
            return true
        } else {
            pos.offset -= 1
            return true
        }
    }

    function moveRight(pos) {
        if (pos.offset + 1 < len(siblings[pos.i])) {
            pos.offset += 1
            return true
        } else {
            if (pos.i + 1 < siblings.length) {
                pos.offset = 0
                pos.i += 1
                return true
            } else {
                if (pos.offset < len(siblings[pos.i])) {
                    pos.offset += 1 // end of the last block.
                    return true
                }
            }
        }
        return false
    }

    // if the current char is a space or empty, the move back a space as we may be at the end of a word
    if (charAt(start) == "") moveLeft(start)

    while (charAt(start).match(wordRegex) && moveLeft(start)) {
        // keep going until a match
    }
    // move right 1.
    moveRight(start)
    while (charAt(end).match(wordRegex) && moveRight(end)) {
        // keep goinf until a match
    }
    if (start == start0 || end == end0) {
        // if at least one endpoint doesn't change, cursor was not inside a word,
        // so we do not select.
        return [null, undefined]
    }

    const path0 = path.slice(0, path.length - 1)
    const range = {
        anchor: { path: path0.concat([start.i]), offset: start.offset },
        focus: { path: path0.concat([end.i]), offset: end.offset },
    }
    const text = Editor.string(editor, range)

    return text ? [range, text] : [null, undefined]
}

/**
 * extension to add mentions to the editor
 * @param {Editor} editor
 */
const withMentions = (editor) => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = (element) => {
        return element.type === "mention" ? true : isInline(element)
    }

    editor.isVoid = (element) => {
        return element.type === "mention" ? true : isVoid(element)
    }

    editor.markableVoid = (element) => {
        return element.type === "mention" || markableVoid(element)
    }

    return editor
}

/**
 * Insert a mentions element
 */
const insertMention = (editor, user) => {
    const element = {
        type: "mention",
        user,
        children: [{ text: "" }],
    }
    Transforms.insertNodes(editor, [element, { type: "text", text: " " }])
    Transforms.move(editor)
}

/**
 * Convert the passed node into markdown
 * @param {Node} node
 * @param {Array} mentions
 * @returns the node formated as markdown
 */
const nodeToMarkdownText = (node, mentions) => {
    let tags = node.code ? "`" : ""
    tags += node.bold ? "**" : ""
    tags += node.italic ? "*" : ""

    const reverse = (s) => {
        return s.split("").reverse().join("")
    }

    let text = ""
    if (Text.isText(node)) {
        text = Node.string(node)
    } else if (node.type === "mention" && node.user) {
        // mention format
        text = `[${node.user.label}](#mention_${node.user.value})`

        if (mentions.findIndex((u) => node.user.value === u.value) === -1) mentions.push(node.user)
    } else {
        console.log("other", node)
    }

    const result = tags + text + reverse(tags)

    // blockquotes come last
    if (node.quote) {
        return `\n > ${result} \n \n`
    }

    return result
}

/**
 * Wrapper for the slate text editor
 * see - https://github.com/ianstormtaylor/slate
 * see - https://docs.slatejs.org/walkthroughs
 * @param {{
 *   id: string|undefined,
 *   placeholder: string|undefined,
 *   readOnly:boolean|undefined,
 *   required:boolean|undefined,
 *   autoFocus:boolean|undefined
 *   rows:number|undefined
 *   minRows:number|undefined
 *   maxRows:number|undefined
 *   allowResize:boolean|undefined
 *   className: string|undefined
 *   value: string|undefined
 *   onChange: (textValue: string, mentions: Array, id: string|undefined)
 *   userOptions: Array|undefined
 *   toolbarAction: {action: string, data: string}|undefined
 * }} params
 */
function SlateTextArea({
    id = undefined,
    placeholder = undefined,
    readOnly = undefined,
    required = undefined,
    autoFocus = undefined,
    rows = 10,
    minRows = undefined,
    maxRows = undefined,
    allowResize = true,
    className = undefined,
    value = undefined,
    onChange = undefined,
    userOptions = [],
    toolbarAction = undefined,
}) {
    const mentionsRef = useRef()
    const [editor] = useState(() => withMentions(withReact(createEditor())))
    const [searchTarget, setSearchTarget] = useState(null)
    const [search, setSearch] = useState("")
    const [searchIndex, setSearchIndex] = useState(0)

    /**
     * A username in the Mention box was clicked
     * @param {MouseEvent} e
     * @param {Editor} editor
     * @param {*} user
     */
    const userMentionsClick = (e, editor, user) => {
        e.preventDefault()

        if (searchTarget) {
            // Select the current node and overwrite it
            Transforms.select(editor, searchTarget)
            insertMention(editor, user)
            Transforms.move(editor)
        }
        closeSearchPopup()
    }

    /**
     * closes the mentions popup
     */
    const closeSearchPopup = () => {
        setSearchTarget(null)
        setSearch("")
        setSearchIndex(0)
    }

    /**
     * filtered list of searched users
     */
    const userFilter = useMemo(() => {
        const userList = [
            ...(userOptions ?? []),
            { label: "Everyone", value: "__everyone", isGroup: true, hint: "Notify everyone who has permission to view this ticket" },
            { label: "Organization", value: "__organization", isGroup: true, hint: "Notify from this organization who has permission to view this ticket" },
            { label: "Technicians", value: "__technicians", isGroup: true, hint: "Notify the technicians who have permission to view this ticket" },
            { label: "Admins", value: "__admins", isGroup: true, hint: "Notify the site admins" },
        ]

        return userList?.filter((c) => c.label?.toLowerCase().startsWith(search.toLowerCase())).slice(0, 10)
    }, [userOptions, search])

    /**
     * The keypress bindings for the editor
     * @param {KeyboardEvent} event
     */
    const onEditorKeyDown = (event) => {
        // Ctrl+key shortcuts
        if (event.ctrlKey) {
            switch (event.key) {
                case "`": {
                    event.preventDefault()
                    SlateFormater.toggleCodeMark(editor)
                    return true
                }
                case "b": {
                    event.preventDefault()
                    SlateFormater.toggleBoldMark(editor)
                    return true
                }
                case "i": {
                    event.preventDefault()
                    SlateFormater.toggleItalicMark(editor)
                    return true
                }
                case "q": {
                    event.preventDefault()
                    SlateFormater.toggleQuoteMark(editor)
                    return true
                }
            }
        }

        if (searchTarget && userFilter.length > 0) {
            switch (event.key) {
                case "ArrowDown":
                    event.preventDefault()
                    event.stopPropagation()
                    setSearchIndex((index) => (index >= userFilter.length - 1 ? 0 : index + 1))
                    return true
                case "ArrowUp":
                    event.preventDefault()
                    event.stopPropagation()
                    setSearchIndex((index) => (index <= 0 ? userFilter.length - 1 : index - 1))
                    return true
                case "Tab":
                case "Enter":
                    event.preventDefault()
                    event.stopPropagation()
                    userMentionsClick(event, editor, userFilter[searchIndex])
                    closeSearchPopup()
                    return true
                case "Escape":
                    event.preventDefault()
                    event.stopPropagation()
                    closeSearchPopup()
                    return true
            }
        }
        return false
    }

    /**
     * Updates the text version fo the state when the text changes
     * @param {Descendant[]} value
     */
    const updateTextState = (value) => {
        // ******************************************************
        // Resude the paragraph nodes into a single text element
        const mentions = []
        const textValue = value.reduce((result, currentNode) => {
            const isP = currentNode.type === "paragraph"
            let paragraphText = Node.string(currentNode)

            // ******************************************************
            // Loop the child nodes into a single text element
            if (Array.isArray(currentNode.children) && currentNode.children.length > 0) {
                paragraphText = currentNode.children.reduce((result, childNode) => result + nodeToMarkdownText(childNode, mentions), "")
            }

            // Join the current text to the new text
            return result + paragraphText + (isP ? "\n" : "")
        }, "")

        if (onChange instanceof Function) {
            // Mimic the onchange event
            const event = {
                type: "change",
                preventDefault() {},
                stopPropagation() {},
                target: {
                    id,
                    value: textValue,
                    mentions: [...mentions],
                },
            }
            onChange(event)
        }
    }

    /**
     * Called when changes are made to the slate textarea
     * @see https://docs.slatejs.org/walkthroughs/06-saving-to-a-database
     */
    const onSlateChange = (value) => {
        // Check if we need to update the state of the prop
        const isAstChange = editor.operations.some((op) => "set_selection" !== op.type)
        if (isAstChange) {
            updateTextState(value)
        }

        // Check if the user started a mention
        const [mentionRange, mentionText] = isSelectionMention(editor)
        if (mentionRange && mentionText !== null) {
            setSearchTarget(mentionRange)
            setSearch(mentionText ?? "")
            setSearchIndex(0)
            return
        }

        // Close the popup is no mention is active
        closeSearchPopup()
    }

    /**
     * Use effect used to position the mentions box
     */
    useEffect(() => {
        if (searchTarget /*&& chars.length > 0*/) {
            const mentionsEl = mentionsRef.current
            if (!mentionsEl) return

            const editableNode = ReactEditor.toDOMNode(editor, editor)
            const editableRect = editableNode?.getBoundingClientRect()
            const mentionsRect = mentionsEl.getBoundingClientRect()
            if (!editableRect || !mentionsRect) closeSearchPopup()

            const editableTop = editableRect.top + window.scrollY
            mentionsEl.style.top = `${editableTop - mentionsRect.height}px`
            mentionsEl.style.left = `${editableRect.left + window.scrollX}px`
            mentionsEl.style.width = `${editableRect.width}px`
        }
    }, [userFilter?.length, editor, searchIndex, search, searchTarget])

    /**
     * The text changed
     */
    useEffect(() => {
        if (!value) {
            // if the text is blank clear the editor
            Transforms.delete(editor, {
                at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                },
            })
        }
    }, [value])

    /**
     * Toolbar action trigger
     */
    useEffect(() => {
        if (!toolbarAction) return
        console.log(toolbarAction) // TODO: todo
    }, [toolbarAction])

    let style = rows > 0 ? { height: `${rows}lh` } : {}
    if (allowResize !== undefined) style = { ...style, resize: allowResize ? "none" : "both", overflow: "auto" }
    if (typeof minRows == "number") style = { ...style, minHeight: `${minRows}lh` }
    if (typeof maxRows == "number") style = { ...style, maxHeight: `${maxRows}lh` }

    return (
        <ErrorBoundary fallback={<ErrorBlock error={"Editor error"} />}>
            <>
                <textarea
                    required={required}
                    value={value ?? ""}
                    onChange={() => {
                        // TODO: support this as a fallback
                    }}
                    style={{ display: "none", visibility: "hidden" }}
                />
                <Slate
                    editor={editor}
                    onChange={onSlateChange}
                    initialValue={[
                        {
                            type: "paragraph",
                            children: [{ text: value ?? "" }],
                        },
                    ]}
                >
                    <Editable
                        id={id}
                        className={`slate-editable ${className ? className : ""}`}
                        // ex is 1/2 the height of the largest letter, so we double it and * by 1.5 for line height
                        style={style}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        autoFocus={autoFocus}
                        role='textbox'
                        aria-multiline={true}
                        aria-required={true}
                        renderElement={(props) => <Element {...props} />}
                        renderLeaf={(props) => <Leaf {...props} />}
                        onKeyDown={onEditorKeyDown}
                        onBlur={closeSearchPopup}
                    />
                    {/* The search popup */}
                    {searchTarget && (userFilter?.length ?? 0) > 0
                        ? createPortal(
                              <div ref={mentionsRef} className='slate-popup' style={{}} onMouseDown={(e) => e.preventDefault()}>
                                  {userFilter.map((user, i) => (
                                      <div
                                          key={user.value}
                                          title={user.hint ?? "Notify this user"}
                                          onClick={(e) => userMentionsClick(e, editor, user)}
                                          className={`slate-popup-item ${i === searchIndex ? "slate-popup-item-selected" : ""} ${
                                              user.isGroup ? "slate-popup-item-group" : "slate-popup-item-user"
                                          }`}
                                      >
                                          <span>{user.isGroup ? <GroupsIcon /> : <PersonIcon />}</span>
                                          <span>@{user.label}</span>
                                      </div>
                                  ))}
                              </div>,
                              document.body
                          )
                        : null}
                </Slate>
            </>
        </ErrorBoundary>
    )
}

/**
 * A single text leaf used or formating text
 */
const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    // quote should be last
    if (leaf.quote) {
        children = <abbr>{children}</abbr>
    }

    return <span {...attributes}>{children}</span>
}

/**
 * A single text element
 */
const Element = (props) => {
    const { attributes, children, element } = props

    switch (element.type) {
        case "mention":
            return (
                <span {...attributes} contentEditable={false} style={{ userSelect: "none" }}>
                    {children}
                    <span className='user-mention'>{element.user?.label ?? "unknown"}</span>
                </span>
            )
        default:
            // paragraph
            return <p {...attributes}>{children}</p>
    }
}

export default SlateTextArea
