import React, { forwardRef, useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import "../../../components/ComponentStyles.css"

import { dateFormat } from "../../../components/utility/ConvertTime"
import ConfirmDialog from "../../../components/mui/ConfirmDialog"
import Tooltip from "../../../components/mui/Tooltip"
import {
    getOrganizatioStorageItemFunc,
    getStorageItemFunc,
    getStorageCategoriesListFunc,
    patchOrganizationStorageFunc,
    patchStorageFunc,
    postOrganizationStorageFunc,
    postStorageFunc,
} from "../../../components/httpRequests/StorageRequests"
import Switch from "../../../components/mui/Switch"
import FileIcon from "../../../components/FileIcon"
import ErrorBlock from "../../../components/errors/ErrorBlock"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import { axiosErrorMessage } from "../../../components/axios"
import InputSelect from "../../../components/forms/InputSelect"
import MarkdownTextArea from "../../../components/mui/MarkdownTextArea"
import { inputMaxLength, roles } from "../../../Constants"
import useUser from "../../../hooks/useUser"
import { getRolesListFunc } from "../../../components/httpRequests/RoleRequests"
import { Header3 } from "../../../components/headers/Headers"
import { Tab, TabPanel, Tabs } from "../../../components/mui/Tabs"
import Divider from "../../../components/dividers/Divider"
import IconButton from "../../../components/mui/IconButton"
import VDivider from "../../../components/dividers/VDivider"

/**
 * Adds  the public role to the passed array of roles
 * @param {Array} rolesArr
 */
const addPublicRole = (rolesArr) => {
    if (!rolesArr) return rolesArr
    if (!Array.isArray(rolesArr)) return rolesArr

    rolesArr.push({ value: 0, label: "Public" })
    return rolesArr.filter((t) => t.value !== roles.telesales)
}

/**
 * Edit storage items (Knowledge Base and site info)
 */
const EditStorageModal = ({ storageUuid, orgUuid, isOrgStorage = false, onClose }) => {
    const formRef = useRef()
    const [isValid, setIsValid] = useState(false)

    const isNewItem = !storageUuid || storageUuid == null || storageUuid == undefined

    const pageType = isOrgStorage ? "Site Information" : "Knowledge Base Article"
    const pageTitle = `${isNewItem ? "New" : "Edit"} ${pageType}`

    return (
        <ConfirmDialog
            onClose={onClose}
            onNoClick={onClose}
            title={pageTitle}
            onYesClick={() => {
                if (formRef?.current) {
                    formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))
                }
            }}
            yesDisabled={!isValid}
            yesLabel='Save'
            noLabel='Cancel'
            maxWidth='lg'
            isOpen={true}
            showCloseButton={true}
            fullWidth={true}
        >
            <EditStorageForm
                ref={formRef}
                isOrgStorage={isOrgStorage}
                orgUuid={orgUuid}
                storageUuid={storageUuid}
                onUpdateState={(isValid) => {
                    setIsValid(isValid)
                }}
                onClose={onClose}
            />
        </ConfirmDialog>
    )
}

/**
 * The input form for editing storage
 * @param {{
 *  innerRef,
 *  orgUuid: string|undefined
 *  storageUuid: string|undefined
 *  isOrgStorage: boolean
 *  onClose: (success: boolean) => {}
 *  onUpdateState: (isValid: boolean) => {}
 *  showSubmit: boolean|undefined
 * }} param0
 */
export const EditStorageForm = forwardRef(function EditStorageForm(props, ref) {
    // forwardRef only pass props as a param, so we been to break them down
    const { orgUuid, storageUuid, isOrgStorage, onClose, onUpdateState, showSubmit } = props

    const { addSnackbarMessage } = useAlertContext()
    const { hasAuthRole_Admin } = useUser()

    const [tabIndex, setTabIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [categoriesData, setCategoriesData] = useState([])
    const [rolesData, setRolesData] = useState([])
    const [newFiles, setNewFiles] = useState([])
    const [storageData, setStorageData] = useState({ categoryId: 1, roleId: roles.admin, isContract: false, showFiles: true })
    const [fileToDelete, setFileToDelete] = useState(null)

    const isAdmin = hasAuthRole_Admin()
    const isNewItem = !storageUuid || storageUuid == null || storageUuid == undefined

    if (!isAdmin) return <ErrorBlock error='You can not edit this item' />
    if (isOrgStorage && !orgUuid) return <ErrorBlock error='Organization is can not be blank' />

    const pageType = isOrgStorage ? "Site Information" : "Knowledge Base Article"

    /**
     * Called when the popup should close
     * @param {boolean} success
     */
    const doClose = (storageUuid) => {
        if (onClose instanceof Function) onClose(storageUuid)
    }

    /**
     * Close the file delete confirmation dialog
     */
    const doCloseFileDeleteConfirmationDialog = () => {
        setFileToDelete(null)
    }

    /**
     * Deletes the file clicked by the user
     */
    const doFileDelete = () => {
        if (fileToDelete) {
            if (fileToDelete.uuid) removeCurrentFile(fileToDelete.uuid)
            else removeNewFile(fileToDelete.name)
        }

        doCloseFileDeleteConfirmationDialog()
    }

    /**
     * Called when the save button is pressed
     */
    const submitUpdateItem = async (e) => {
        e?.preventDefault()
        if (isLoading) return
        if (!isAdmin) return

        if (!isDataValid(storageData)) {
            addSnackbarMessage(getDataError(storageData), AlertTypes.Error)
            return
        }

        setIsLoading(true)
        try {
            // Create the form data and add the files to it
            const formData = new FormData()
            newFiles.forEach((file, index) => {
                formData.append(`formFile${index}`, file)
            })

            const newStorageData = { ...storageData }
            // Delete items we dont want to send to the api
            delete newStorageData.orgUu
            delete newStorageData.storageInformationFiles
            delete newStorageData.updatedAt
            delete newStorageData.createdAt
            delete newStorageData.category
            if (isNewItem) delete newStorageData.uuid
            // Add in the orguuid if needed
            if (isNewItem && isOrgStorage) newStorageData.orgUuid = orgUuid

            formData.append("storageData", JSON.stringify(newStorageData))

            // Send the data to the api
            let res
            if (isNewItem) {
                res = orgUuid ? await postOrganizationStorageFunc(formData) : await postStorageFunc(formData)
            } else {
                res = orgUuid ? await patchOrganizationStorageFunc(formData) : await patchStorageFunc(formData)
            }

            if (res.status === 200) {
                addSnackbarMessage("Storage item successfully saved.", AlertTypes.Success)

                const id = res?.data?.value ? res.data.value : undefined

                doClose(id)
            } else {
                addSnackbarMessage("Error saving storage item.", AlertTypes.Error)
            }
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e, "Error saving storage item."), AlertTypes.Error)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Gets the current data error
     */
    const getDataError = (storageData) => {
        if (typeof storageData !== "object") return "storageData is not an object"
        if (!Object.prototype.hasOwnProperty.call(storageData, "title")) return "title is not set"
        if (storageData.title.trim() === "") return "title can not be blank"
        if (storageData) return true
    }
    /**
     * checks if the data is valid or not
     */
    const isDataValid = (storageData) => {
        return typeof getDataError(storageData) !== "string"
    }

    /**
     * Gets the data from the uri
     */
    const getStorageData = async (abortController) => {
        setIsLoading(true)
        try {
            const [catRes, rolesRes] = await Promise.all([getStorageCategoriesListFunc(abortController), getRolesListFunc(true, abortController)])
            setCategoriesData(catRes.data)
            setRolesData(addPublicRole(rolesRes.data))

            if (!isNewItem) {
                abortController.AbortController
                const res = orgUuid
                    ? await getOrganizatioStorageItemFunc(orgUuid, storageUuid, abortController)
                    : await getStorageItemFunc(storageUuid, abortController)

                setStorageData(res.data)
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e, "Error saving storage item."), AlertTypes.Error)
                doClose(undefined)
            }
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * File dropped in the dropzone
     * Add to the new file list
     */
    const onAddNewFile = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setNewFiles((currentFiles) => [...currentFiles, ...acceptedFiles])
        }
    }

    /**
     * Remove a file from the new file list
     */
    const removeNewFile = (fileName) => {
        setNewFiles((currentFiles) => currentFiles.filter((f) => f.name !== fileName))
    }

    /**
     * Remove a file from the current file list
     */
    const removeCurrentFile = (fileUuid) => {
        // update the ui list and the files removed list
        const storageInformationFiles = storageData?.storageInformationFiles.filter((f) => f.uuid !== fileUuid)
        const removedFiles = [...(storageData?.removedFiles ?? []), fileUuid]

        setStorageData((currentValue) => {
            return { ...currentValue, storageInformationFiles, removedFiles }
        })
    }

    /**
     * Validates the file is ok
     */
    const fileValidation = (file) => {
        const fileFound = newFiles.find((f) => f.name === file.name)
        if (fileFound) {
            addSnackbarMessage("You have already uploaded a file with the name of " + file.name, AlertTypes.Error)
            return {
                code: "File already uploaded",
                message: "File with the same name has already been uploaded",
            }
        }
        return null
    }

    /**
     * Check box changed
     */
    const onCheckboxChanged = (e) => {
        const name = e.target.id
        const isChecked = e.target.checked ? true : false

        setStorageData((currentValue) => {
            return { ...currentValue, [name]: isChecked }
        })
    }

    /**
     * Text input changed
     */
    const onInputChange = (e) => {
        const name = e.target.id

        setStorageData((currentValue) => {
            return { ...currentValue, [name]: e.target.value }
        })
    }

    /**
     * Called when the page first loads or the storage id changes
     */
    useEffect(() => {
        const abortController = new AbortController()
        getStorageData(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [storageUuid])

    /**
     * Update the state callbase
     */
    useEffect(() => {
        if (onUpdateState instanceof Function) onUpdateState(isDataValid(storageData))
    }, [storageData])

    return (
        <form onSubmit={submitUpdateItem} ref={ref}>
            <Tabs
                value={tabIndex}
                onChange={(event, newValue) => {
                    setTabIndex(newValue)
                }}
                className='margin-vertical-8'
            >
                <Tab label='Settings' id='simple-tab-0' />
                <Tab label='Notes' id='simple-tab-1' />
                <Tab label='Technician Notes' id='simple-tab-2' />
                <Tab label='Contract Details ' id='simple-tab-3' />
                <Tab label='Files' id='simple-tab-4' />
            </Tabs>
            <TabPanel value={tabIndex} index={0} className='width-100'>
                <div className='form-section'>
                    <label htmlFor='title'>Title</label>
                    <input
                        type='text'
                        id='title'
                        maxLength={inputMaxLength.storageTitle}
                        required
                        name='title'
                        className='width-100 rs-input'
                        value={storageData?.title ?? ""}
                        onChange={onInputChange}
                        autoFocus={true}
                    />
                </div>
                <div className='form-section'>
                    <label htmlFor='categoryId'>Category</label>
                    <InputSelect options={categoriesData} id='categoryId' value={storageData?.categoryId} onChange={onInputChange} required />
                </div>
                <div className='form-section'>
                    <label htmlFor='roleId'>Visibility</label>
                    <InputSelect options={rolesData} id='roleId' value={storageData?.roleId ?? 0} onChange={onInputChange} required />
                    <p className='text-hint'>{`Visibility applies to both the ${pageType} an all attached files.`}</p>
                </div>

                <div className='form-section'>
                    <label htmlFor='keywords'>Keywords</label>
                    <input
                        type='text'
                        id='keywords'
                        maxLength={inputMaxLength.storageKeywords}
                        name='keywords'
                        className='width-100 rs-input'
                        value={storageData?.keywords ?? ""}
                        onChange={onInputChange}
                    />
                    <p className='text-hint'>Separate keywords with a comma, keywords are used to help users find articles</p>
                </div>
            </TabPanel>
            <TabPanel value={tabIndex} index={1} className='width-100'>
                <div className='form-section'>
                    <label htmlFor='notes' className='hidden'>
                        Standard Notes (Optional)
                    </label>
                    <p className='text-hint'>Standard notes are viewable by everyone with the correct visibility</p>
                    <MarkdownTextArea
                        id='notes'
                        name='notes'
                        required
                        className='width-100 rs-input'
                        value={storageData?.notes ?? ""}
                        minRows={10}
                        onChange={onInputChange}
                    />
                </div>
            </TabPanel>
            <TabPanel value={tabIndex} index={2} className='width-100'>
                <div className='form-section'>
                    <label htmlFor='technicianNotes' className='hidden'>
                        Technician Notes (Optional)
                    </label>
                    <p className='text-hint'>Technician notes are only viewable / editable by technicians &amp; admins</p>
                    <MarkdownTextArea
                        id='technicianNotes'
                        name='technicianNotes'
                        required
                        className='width-100 rs-input'
                        value={storageData?.technicianNotes ?? ""}
                        minRows={10}
                        onChange={onInputChange}
                    />
                </div>
            </TabPanel>
            <TabPanel value={tabIndex} index={3} className='width-100'>
                <div className='flex-column flex-gap-16'>
                    <Header3
                        title='Contract Details'
                        subtitle='Contract start and end date are only used if this item is flagged as a contract'
                        subtitleClassName='text-hint'
                        showDivider={false}
                    />

                    <Switch
                        id='isContract'
                        checkedLabel='Item is a contract'
                        uncheckedLabel='Item is not a contract'
                        checked={storageData?.isContract ?? false}
                        onChange={onCheckboxChanged}
                    />

                    {storageData?.isContract && (
                        <div className='flex-row'>
                            <div className='flex-column flex-100 flex-gap-none'>
                                <div className='form-section'>
                                    <label htmlFor='contractStartDate'>Contract Start Date</label>
                                    <input
                                        id='contractStartDate'
                                        name='contractStartDate'
                                        type='date'
                                        className='rs-input'
                                        value={storageData?.contractStartDate ?? new Date().toISOString().slice(0, 10)}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className='flex-column flex-100'>
                                <div className='form-section'>
                                    <label htmlFor='contractLength'>Contract Length (Months)</label>
                                    <input
                                        id='contractLength'
                                        name='contractLength'
                                        type='number'
                                        className='rs-input'
                                        value={storageData?.contractLength ?? 0}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </TabPanel>
            <TabPanel value={tabIndex} index={4} className='width-100'>
                <>
                    <Header3
                        title='Files &amp; Downloads'
                        subtitle={
                            <>
                                Files have the same visibility as the {pageType}
                                <br /> If the file list is not displayed, the user can still access the files and view the list in the browsers dev tools.
                                <br /> Do NOT use this flag for file security, use visibility!
                            </>
                        }
                        subtitleClassName='alert alert-danger small margin-vertical-8'
                        showDivider={false}
                    />

                    <div className='label-input-wrapper'>
                        <Switch
                            id='showFiles'
                            checkedLabel='Display the file list on the page'
                            uncheckedLabel='Display the file list on the page'
                            checked={storageData?.showFiles ?? true}
                            onChange={onCheckboxChanged}
                        />
                    </div>
                    <Divider padding={16} />

                    <ConfirmDialog
                        isOpen={Boolean(fileToDelete)}
                        title='Delete File'
                        description={`Are you sure that you want to delete the file ${fileToDelete?.filename ?? fileToDelete?.name}?`}
                        onClose={doCloseFileDeleteConfirmationDialog}
                        onNoClick={doCloseFileDeleteConfirmationDialog}
                        onYesClick={doFileDelete}
                    />

                    <div className='flex-row flex-column-small flex-gap-16'>
                        <div className='flex-50'>
                            <Header3
                                title='New Files'
                                subtitle='Add new files by clicking the area below, click a new image to delete it'
                                subtitleClassName='text-hint'
                                showDivider={false}
                            />
                            <div className='padding-vertical-16'>
                                <Dropzone onDrop={(acceptedFiles) => onAddNewFile(acceptedFiles)} validator={fileValidation}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()} className='dropzone-target'>
                                                <input {...getInputProps()} />
                                                <p>Drag and drop some files here, or click to select files</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                            <div className='flex-row flex-wrap'>
                                {newFiles?.map((f, i) => {
                                    return (
                                        <Tooltip key={i} text={`Click to delete ${f.name}`}>
                                            <div
                                                className='added-file flex-column flex-center-item'
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setFileToDelete(f)
                                                }}
                                            >
                                                <FileIcon fileName={f.name} />
                                                <p>{f.name}</p>
                                            </div>
                                        </Tooltip>
                                    )
                                })}
                            </div>
                        </div>
                        <VDivider className='hidden-small' />

                        <div className='flex-50'>
                            <Header3
                                title='Saved Files'
                                subtitle='Files will show in the below list once they have been saved'
                                subtitleClassName='text-hint'
                                showDivider={false}
                            />
                            <table className='ticket-table padding-bottom-16'>
                                <thead>
                                    <tr>
                                        <th>Filename</th>
                                        <th>Created</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {storageData?.storageInformationFiles?.map((f) => {
                                        const url = `/api/public/attachment/${encodeURIComponent(f.uuid)}/${encodeURIComponent(f.filename)}`
                                        return (
                                            <tr key={f.uuid}>
                                                <td>
                                                    <Link className='flex-row' target='_blank' to={url}>
                                                        <FileIcon fileName={f.filename} />
                                                        {f.filename}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <time dateTime={f.createdAt}>{dateFormat(f.createdAt)}</time>
                                                </td>
                                                <td>
                                                    <IconButton
                                                        hintText={`Copy url to clipboard`}
                                                        onClick={() => {
                                                            navigator?.clipboard?.writeText(url)
                                                        }}
                                                        color='primary'
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>

                                                    <IconButton
                                                        hintText={`Delete this file`}
                                                        onClick={() => {
                                                            setFileToDelete(f)
                                                        }}
                                                        color='error'
                                                    >
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            </TabPanel>

            {showSubmit && (
                <div className='button-input-wrapper'>
                    <button className='button-contained' type='submit' disabled={!isDataValid(storageData)}>
                        Save Article
                    </button>
                </div>
            )}
        </form>
    )
})

export default EditStorageModal
