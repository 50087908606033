import React from "react"
import { Drawer, IconButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useIsSmallScreen } from "../../hooks/useWindowDimensions"
import { lightBackground } from "../../Theme"

export const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}))

/**
 * Popout nav draw
 */
function NavDrawer({ anchor = "left", isOpen = true, title = "", onClose = undefined, overlayPage = true, drawerMarginTop = 0, drawerWidth = 0, children }) {
    const isSmallScreen = useIsSmallScreen()

    let headerTitle = title
    if (anchor === "right") {
        headerTitle = (
            <>
                <IconButton onClick={onClose}>
                    <ChevronRightIcon />
                </IconButton>
                {title}
            </>
        )
    } else if (anchor === "left") {
        headerTitle = (
            <>
                {title}
                <IconButton onClick={onClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </>
        )
    } else if (anchor === "top") {
        headerTitle = (
            <>
                <IconButton onClick={onClose}>
                    <ExpandLessIcon />
                </IconButton>
                {title}
            </>
        )
    } else if (anchor === "bottom") {
        headerTitle = (
            <>
                <IconButton onClick={onClose}>
                    <ExpandMoreIcon />
                </IconButton>
                {title}
            </>
        )
    }

    if (isSmallScreen || !isOpen) drawerWidth = 0

    let style = {
        width: drawerWidth,
        backgroundColor: lightBackground,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
            width: drawerWidth,
            marginTop: drawerMarginTop,
            paddingBottom: drawerMarginTop,
        },
    }

    if (drawerWidth <= 0) {
        style = { width: "50%" }
    }

    if (!isOpen) return <></>

    return (
        <Drawer sx={style} variant={isSmallScreen || overlayPage ? "temporary" : "persistent"} anchor={anchor} open={isOpen}>
            <DrawerHeader>{headerTitle}</DrawerHeader>
            <div>{children}</div>
        </Drawer>
    )
}

export default NavDrawer
