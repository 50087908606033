import axios, { axiosNoInterceptors } from "../axios"

/**
 * Gets a full list categories for the storage
 */
export const getStorageCategoriesListFunc = async (abortController) => {
    return axios.get(`/api/storage/categories`, { signal: abortController?.signal })
}

/**
 * Patch the storage
 */
export const patchStorageFunc = async (formData, abortController) => {
    const res = await axios.patch("/api/storage", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Post the storage
 */
export const postStorageFunc = async (formData, abortController) => {
    const res = await axios.post("/api/storage", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Deletes the passed storage
 */
export const deleteStorageItemFunc = async (storageUuid, abortController) => {
    const res = await axios.delete(`/api/storage/${storageUuid}`, { signal: abortController?.signal })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Gets a full list of storage
 */
export const getStorageListFunc = async (abortController) => {
    return axios.get(`/api/storage`, { signal: abortController?.signal })
}

/**
 * Gets a single of storage
 */
export const getStorageItemFunc = async (storageUuid, abortController) => {
    return axios.get(`/api/storage/${storageUuid}`, { signal: abortController?.signal })
}

/**
 * Gets a single of storage (public view)
 */
export const getPublicStorageItemFunc = async (storageUuid, redirectIfUnauthorized, abortController) => {
    if (redirectIfUnauthorized) return axios.get(`/api/public/storage/${storageUuid}`, { signal: abortController?.signal })
    else return axiosNoInterceptors.get(`/api/public/storage/${storageUuid}`, { signal: abortController?.signal })
}

/**
 * Gets a list of storage (public view)
 */
export const getPublicStorageFunc = async (abortController) => {
    return axios.get(`/api/public/storage/`, { signal: abortController?.signal })
}

// *********************************** organization ***********************************

/**
 * Patch the storage for the organization
 */
export const patchOrganizationStorageFunc = async (formData, abortController) => {
    const res = await axios.patch("/api/organisationstorage", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Post the storage for the organization
 */
export const postOrganizationStorageFunc = async (formData, abortController) => {
    const res = await axios.post("/api/organisationstorage", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Deletes the passed storage form an organization
 */
export const deleteOrganizationStorageItemFunc = async (orgUuid, storageUuid, abortController) => {
    const res = await axios.delete(`/api/organisationstorage/${orgUuid}/${storageUuid}`, { signal: abortController?.signal })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Gets a full list of storage for the organization
 */
export const getOrganizationStorageListFunc = async (orgUuid, abortController) => {
    return axios.get(`/api/organisationstorage/${orgUuid}`, { signal: abortController?.signal })
}

/**
 * Gets a single of storage from an organizatio
 */
export const getOrganizatioStorageItemFunc = async (orgUuid, storageUuid, abortController) => {
    return axios.get(`/api/organisationstorage/${orgUuid}/${storageUuid}`, { signal: abortController?.signal })
}
