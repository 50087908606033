import React from "react"
import { Link } from "react-router-dom"
import MetaData from "../components/MetaData"

/**
 * Basic 404 error page
 */
const PageNotFound = () => {
    return (
        <div className='page-wrapper flex-center-item flex-100'>
            <div className='center-404'>
                <MetaData title={`404 Not Found`} />
                <h1>404</h1>
                <h3>Page Not Found</h3>
                <p>This page does not exist or you do not have sufficient authorisation to view it.</p>
                <p>
                    <Link style={{ textDecoration: "none" }} to='/'>
                        Return by clicking HERE.
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default PageNotFound
