// colors taken from variables.css

export const lightBackground = "#ffffff"
export const lightText = "#ffffff"
export const darkText = "#222222"

export const primaryColor = "#3399cc"
export const secondaryColor = "#B233CC"
export const backgroundColor = "#eeeeee"

export const successColor = "#41a447"
export const warningColor = "#feba32"
export const dangerColor = "#cf1c1c"
export const fontFamily = "Open Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif"

/**
 * @see https://mui.com/material-ui/customization/default-theme/
 */
export const themeOptionsLight = {
    palette: {
        mode: "light",
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor,
        },
        success: {
            main: successColor,
        },
        warning: {
            main: warningColor,
        },
        error: {
            main: dangerColor,
        },
        background: {
            default: backgroundColor,
            paper: lightBackground,
        },
        text: {
            primary: darkText,
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "0.833rem",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    color: "rgba(255, 255, 255, 0.6)",
                    backgroundColor: "#3b3f41",
                    fontFamily: fontFamily,
                },
            },
        },
    },
    typography: {
        fontFamily: fontFamily,
        fontSize: 14,
    },
}
