import React, { useEffect, useState } from "react"
import Select from "react-select"
import { v4 as uuidv4 } from "uuid"
import { DateRangePicker } from "react-date-range"

import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"

import axios, { axiosErrorMessage } from "../../../components/axios"

import StatsColumn from "./StatsColumn"
import { Header2 } from "../../../components/headers/Headers"
import { Tab, TabPanel, Tabs } from "../../../components/mui/Tabs"
import FullscreenLoading from "../../../components/FullscreenLoading"
import MetaData from "../../../components/MetaData"
import Divider from "../../../components/dividers/Divider"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import { getTicketSettingsFunc } from "../../../components/httpRequests/TicketsRequests"
import { getOrganisationGroupValuePairsListFunc } from "../../../components/httpRequests/OrganisationGroupRequests"
import { addDays } from "../../../components/utility/ConvertTime"
import { selectFilter, selectTheme } from "../../../common/selectHelpers"
import { PieChart } from "@mui/x-charts"
import ErrorBoundary from "../../../components/errors/ErrorBoundary"
import ErrorBlock from "../../../components/errors/ErrorBlock"
import DataTable from "../../../components/mui/DataTable"

/**
 * Gets a new blank column
 */
const getBlankColumn = () => {
    return {
        uuid: uuidv4(),
        categories: [],
        tagFilters: [],
    }
}

/**
 * Dashboard screen
 */
const StatsDashboard = () => {
    const { addSnackbarMessage } = useAlertContext()

    // TODO: rename thease
    const [tags, setTags] = useState(null)
    const [orgGroups, setOrgGroups] = useState(null)
    const [categories, setCategories] = useState(null)

    const [columns, setColumns] = useState([getBlankColumn()])
    const [selectedOrgGroups, setSelectedOrgGroups] = useState([])

    const [tabIndex, setTabIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [dataToExport, setDataToExport] = useState(null)

    const [dateState, setDateState] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: "selection",
        },
    ])

    /**
     * Adds a new blank column to the report
     */
    const addNewColumn = () => {
        setColumns((currentValue) => [...currentValue, getBlankColumn()])
    }

    /**
     * Update the passed column data
     */
    const updateColumnData = (index, key, options) => {
        const item = columns[index]

        if (key === "categories") item.categories = options
        else if (key === "tagFilters") item.tagFilters = options
        else return

        setColumns([...columns])
    }

    /**
     * Create the report
     */
    const createReport = async () => {
        let cols = []

        columns.forEach((c) => {
            let categoryId = []
            let tagUuid = []
            c.categories.forEach((tt) => {
                categoryId.push(tt.value)
            })
            c.tagFilters.forEach((f) => {
                tagUuid.push(f.value)
            })
            // Each col needs at least one ticket type
            if (categoryId.length > 0) {
                cols.push({ categoryId, tagUuid })
            }
        })

        let customers = []
        selectedOrgGroups.forEach((c) => customers.push(c.value))

        const requestData = {
            startDate: dateState[0].startDate,
            endDate: dateState[0].endDate,
            customerCodes: customers,
            columns: cols,
            addSum: false,
            addTotalPercent: false,
        }

        if (requestData.customerCodes.length === 0) {
            addSnackbarMessage("No customer selected", AlertTypes.Error)
            return
        } else if (requestData.columns.length === 0) {
            addSnackbarMessage("No columns selected", AlertTypes.Error)
            return
        }

        setIsLoading(true)
        try {
            const res = await axios.post("/api/statistics", requestData)
            setDataToExport(res.data)
            setTabIndex(1)
            addSnackbarMessage("Report ready to be downloaded", AlertTypes.Success)
        } catch {
            setDataToExport(null)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Get the init data
     */
    const getInitialData = async (abortController) => {
        try {
            const [prioritiesCatsTypes, orgGroupsData, tags] = await Promise.all([
                getTicketSettingsFunc(abortController),
                getOrganisationGroupValuePairsListFunc(abortController),
                axios.get("/api/tag/", { signal: abortController?.signal }),
            ])
            setTags(tags.data)
            setCategories(prioritiesCatsTypes.data.ticketCategories)
            setOrgGroups(orgGroupsData.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    /**
     * Called when the page loads
     */
    useEffect(() => {
        const abortController = new AbortController()

        getInitialData(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [])

    return (
        <div className='page-wrapper'>
            <MetaData title='Statistics' />
            {isLoading && <FullscreenLoading />}
            <div className='padding-16'>
                <Header2 title='Statistics' />

                <Tabs
                    value={tabIndex}
                    onChange={(event, newValue) => {
                        setTabIndex(newValue)
                    }}
                    className='margin-vertical-8'
                >
                    <Tab label='Options' id='statistics-tab-0' />
                    <Tab label='Table' id='statistics-tab-1' disabled={!dataToExport} />
                    <Tab label='Graph' id='statistics-tab-2' disabled={!dataToExport} />
                </Tabs>

                <TabPanel value={tabIndex} index={0} className='width-100'>
                    <div className='padding-16'>
                        <div className='flex-row'>
                            <div className='label-input-wrapper'>
                                <label htmlFor='customerGroups'>Organisation Groups</label>
                                <Select
                                    inputId='customerGroups'
                                    name='customerGroups'
                                    required
                                    isMulti
                                    options={orgGroups ?? undefined}
                                    filterOption={selectFilter()}
                                    onChange={(e) => setSelectedOrgGroups(e)}
                                    isLoading={!orgGroups}
                                    value={selectedOrgGroups}
                                    theme={selectTheme}
                                    className='react-select-container'
                                />
                            </div>
                        </div>

                        <Divider padding={16} />

                        <div className='flex-column flex-gap-16'>
                            <h3>Columns</h3>
                            <div className='flex-column flex-column-stretch flex-gap-16 width-100'>
                                {columns.length > 0 &&
                                    columns.map((c, i) => {
                                        return (
                                            <StatsColumn key={i} categories={categories} tags={tags} columnData={c} index={i} updateData={updateColumnData} />
                                        )
                                    })}

                                <button className='button-outlined' onClick={addNewColumn}>
                                    + New Column
                                </button>
                            </div>
                        </div>

                        <Divider padding={16} />

                        <div className='flex-column flex-gap-16'>
                            <h3>Date Range</h3>
                            <DateRangePicker
                                onChange={(item) => setDateState([item.selection])}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                direction='horizontal'
                                ranges={dateState}
                                inputRanges={[]}
                                weekStartsOn={1}
                            />
                        </div>

                        <Divider padding={16} />

                        <div className='flex-row'>
                            <button className='button-contained' onClick={createReport} disabled={isLoading}>
                                Run Report
                            </button>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={tabIndex} index={1} className='width-100'>
                    <StatisticsTable data={dataToExport?.data} headers={dataToExport?.headers} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2} className='width-100'>
                    <div className='flex-row flex-column-small'>
                        <StatisticsGraph data={dataToExport?.data} headers={dataToExport?.headers} />
                    </div>
                </TabPanel>
            </div>
        </div>
    )
}

/**
 * Statistics table
 */
const StatisticsTable = ({ data, headers }) => {
    if (!Array.isArray(data)) return null
    if (!Array.isArray(headers)) return null

    /**
     * Map the returned headers into  MUI data table
     */
    const mapColumns = () => {
        let columns = []
        headers.forEach((header, index) => {
            columns.push({
                field: header.key,
                headerName: header.label,
                flex: 0.5,
                type: index === 0 ? "string" : "number",
                // renderCell: basicColumns,
            })
        })
        return columns
    }

    return (
        <ErrorBoundary fallback={<ErrorBlock error={"Try a different query"} />}>
            <DataTable
                tableId={"statistics-table"}
                rows={data}
                columns={mapColumns()}
                getRowId={(u) => u.name}
                hideFooter={false}
                //onRowsSelectionChange={onRowsSelectionChange}
            />
        </ErrorBoundary>
    )
}

/**
 * Statistics graph
 */
const StatisticsGraph = ({ data, headers }) => {
    if (!Array.isArray(data)) return null
    if (!Array.isArray(headers)) return null

    /**
     * Gets the data array
     */
    const getData = (data, key) => {
        return data.map((d, id) => {
            return { id, value: +d[key], label: d.name }
        })
    }

    // TODO: make colors the same as dashboard
    const colors = ["#3399cc", "#70b8db", "#3081aa"]

    return headers.map((header) => {
        return header.key === "name" ? null : (
            <div className='flex-column-center flex-center-item flex-100' key={header.key}>
                <div className='text-center'>
                    <h3>{header.label}</h3>
                </div>
                <div>
                    <PieChart
                        colors={colors}
                        slotProps={{
                            legend: { hidden: true, direction: "row", position: { vertical: "bottom", horizontal: "middle" } },
                        }}
                        series={[
                            {
                                cx: 200,
                                innerRadius: 30,
                                highlightScope: { faded: "global", highlighted: "item" },
                                faded: { innerRadius: 15, additionalRadius: -15, color: "gray" },
                                data: getData(data, header.key),
                            },
                        ]}
                        width={400}
                        height={400}
                        sx={{ "& .MuiChartsLegend-series text": { fontSize: "0.7em !important" } }}
                    />
                </div>
            </div>
        )
    })
}

export default StatsDashboard
