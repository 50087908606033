import React from "react"
import DescriptionIcon from "@mui/icons-material/Description"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import ImageIcon from "@mui/icons-material/Image"
import VideoFileIcon from "@mui/icons-material/VideoFile"
import HtmlIcon from "@mui/icons-material/Html"
import FolderZipIcon from "@mui/icons-material/FolderZip"

/**
 * Outputs the correct file icon
 */
function FileIcon({ fileName = "", fontSize = undefined, color = undefined }) {
    const file = fileName?.toLowerCase() ?? ""

    if (file == "") return <DescriptionIcon fontSize={fontSize} color={color} />
    if (file.endsWith(".pdf")) return <PictureAsPdfIcon fontSize={fontSize} color={color} />

    if (
        file.endsWith(".png") ||
        file.endsWith(".gif") ||
        file.endsWith(".jpg") ||
        file.endsWith(".jepg") ||
        file.endsWith(".webp") ||
        file.endsWith(".png") ||
        file.endsWith(".tiff")
    )
        return <ImageIcon fontSize={fontSize} color={color} />

    if (file.endsWith(".wmv") || file.endsWith(".mp4") || file.endsWith(".avi") || file.endsWith(".webv"))
        return <VideoFileIcon fontSize={fontSize} color={color} />

    if (file.endsWith(".html") || file.endsWith(".htm")) return <HtmlIcon fontSize={fontSize} color={color} />

    if (file.endsWith(".zip") || file.endsWith(".rar")) return <FolderZipIcon fontSize={fontSize} color={color} />

    return <DescriptionIcon fontSize={fontSize} color={color} />
}

export default FileIcon
