import { useContext } from "react"
import AdminContext from "../components/alertContext/AlertContext"

export const AlertTypes = {
    Info: "info",
    Success: "success",
    Warning: "warning",
    Error: "error",
}
Object.freeze(AlertTypes)

function useAlertContext() {
    return useContext(AdminContext)
}

export default useAlertContext
