import { useLocation } from "react-router-dom"
import useUser from "../hooks/useUser"
import { Navigate, Outlet } from "react-router-dom"
import { returnToQueryParam } from "./utility/Helpers"

/**
 * Wrapper for the RequireAuth Outlet
 */
const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useUser()
    const loc = useLocation()
    const returnTo = returnToQueryParam(loc.pathname, "?")

    // Does the user have the required auth
    return auth?.roles?.find((role) => allowedRoles?.includes(role)) ? (
        <Outlet />
    ) : auth?.loggedIn ? (
        <Navigate to={`/unauthorised/${returnTo}`} state={{ from: loc }} replace />
    ) : (
        <Navigate to={`/login/${returnTo}`} state={{ from: loc }} replace />
    )
}

export default RequireAuth
