import axios from "axios"
import { useEffect, useState } from "react"

/**
 * Runs a fetch operation on a repeting schedule
 */
const useRepeatingFetch = (url, timeoutSeconds = 60, usePost = false) => {
    const [postData, setPostData] = useState(null)
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [enabled, setEnabled] = useState(false)
    const [missedEvent, setMissedEvent] = useState(false)

    /**
     * Do the url fetch if the page has focus and the state is enabled
     * @param {AbortController} controller
     */
    const doFetchDataAsync = async (controller) => {
        if (isLoading || !enabled) return

        if (document.hidden ?? false) {
            // flag the missed even so we know we need to re-run the task
            setMissedEvent(true)
            return
        }

        setMissedEvent(false)
        setIsLoading(true)
        try {
            if (usePost) {
                const axiosData = await axios.post(url, { ...postData }, { signal: controller?.signal })
                setData(axiosData?.data)
            } else {
                const axiosData = await axios.get(url, { signal: controller?.signal })
                setData(axiosData?.data)
            }
        } catch (e) {
            setData(null)
            if (!controller?.signal?.aborted) console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Do the fetch by toggleing the data
     * @param {object|null} postData if an object is sent the request will be a POST else it will be a GET
     */
    const fetchData = (newPostData = null) => {
        if (usePost) {
            setPostData(newPostData)
        }
        setToggle((currentValue) => !currentValue)
        setEnabled(true)
    }

    /**
     * Called when the page get focus
     */
    const handleVisibilityChange = () => {
        if (document.hidden || !missedEvent) return
        setToggle((currentValue) => !currentValue)
        setMissedEvent(false)
    }

    /**
     * Timeout interval
     */
    useEffect(() => {
        if (!timeoutSeconds || !enabled) return
        const controller = new AbortController()
        const interval = setInterval(doFetchDataAsync, timeoutSeconds * 1000)

        doFetchDataAsync(controller)

        return () => {
            clearInterval(interval)
            controller.abort()
        }
    }, [toggle, enabled])

    /**
     * Set up the page watcher
     */
    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange)
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange)
        }
    }, [])

    // return the data and a function to request it
    return [data, fetchData]
}

export default useRepeatingFetch
