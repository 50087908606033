import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Select from "react-select"

import "../../styling/dashboard.css"

import { axiosErrorMessage } from "../../../components/axios"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import MetaData from "../../../components/MetaData"
import Divider from "../../../components/dividers/Divider"
import { Header2 } from "../../../components/headers/Headers"
import LabelledCheckbox from "../../../components/forms/LabelledCheckbox"
import { inputMaxLength } from "../../../Constants"
import { getOrganisationGroupValuePairsListFunc } from "../../../components/httpRequests/OrganisationGroupRequests"
import { formatExtendedLabel, selectFilter, selectTheme } from "../../../common/selectHelpers"
import { getRolesListFunc } from "../../../components/httpRequests/RoleRequests"
import { getOrganisationKeyValueListFunc } from "../../../components/httpRequests/OrganisationRequests"
import { getUserFunc, patchUser, postUser } from "../../../components/httpRequests/UserRequests"
import Switch from "../../../components/mui/Switch"
import RequiredFieldHint from "../../../components/forms/RequiredFieldHint"
import PasswordInputTips from "../../../components/forms/PasswordInputTips"

const NewUser = () => {
    const navigate = useNavigate()
    const { userUuid } = useParams()
    const { addSnackbarMessage } = useAlertContext()

    const [isEdit, setIsEdit] = useState(false)

    const [orgList, setOrgList] = useState(null)
    const [selectedOrg, setSelectedOrg] = useState([])
    const [groupList, setGroupList] = useState(null)
    const [selectedGroups, setSelectedGroups] = useState(null)
    const [roleList, setRoleList] = useState(null)
    const [selectedRole, setSelectedRole] = useState(null)

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [enabled, setEnabled] = useState(true)
    const [password, setPassword] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [emailNotification, setEmailNotification] = useState(false)
    const [subscribeToTickets, setSubscribeToTickets] = useState(true)

    /**
     * Get the lists
     */
    const getOrgList = async (abortController) => {
        try {
            const [pairList, roleListRes, groupListRes] = await Promise.all([
                getOrganisationKeyValueListFunc(abortController),
                getRolesListFunc(true, abortController),
                getOrganisationGroupValuePairsListFunc(abortController),
            ])
            setOrgList(pairList.data)
            setRoleList(roleListRes.data)
            setGroupList(groupListRes.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    /**
     * Get the user data
     */
    const getUserData = async (abortController) => {
        try {
            const res = await getUserFunc(userUuid, abortController)
            const userData = res.data

            setName(userData.name)
            setEmail(userData.email)
            setPhone(userData.phone)
            setSelectedGroups(userData.userGroups)
            setEmailNotification(userData.emailNotification)
            setSubscribeToTickets(userData.subscribeToTickets)
            setEnabled(userData.enabled)

            const orgs = []
            orgList.forEach((ol) => {
                if (userData.orgUuids.includes(ol.value)) orgs.push(ol)
            })
            setSelectedOrg(orgs)

            setSelectedRole(userData.role)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Form submit
     */
    const submitNewUser = async (e) => {
        e.preventDefault()
        if (isSubmitting) return

        setIsSubmitting(true)
        try {
            const data = {
                name,
                phone,
                email,
                emailNotification,
                subscribeToTickets,
                enabled,
                orgUuids: selectedOrg ? selectedOrg.map((so) => so.value) : [],
                userGroupUuids: selectedGroups ? selectedGroups.map((g) => g.value) : [],
                roleId: selectedRole?.value,
            }

            if (isEdit) {
                data.uuid = userUuid

                const res = await patchUser(data)
                if (res.status === 200) {
                    addSnackbarMessage("User Updated Successfully.", AlertTypes.Success)
                    const queryString = res?.data?.value ? `?id=${res.data?.value}` : ""
                    navigate("/admin/users" + queryString)
                } else {
                    addSnackbarMessage("User Not Updated.", AlertTypes.Error)
                }
            } else {
                data.password = password

                const res = await postUser(data)
                if (res.status === 200) {
                    addSnackbarMessage("User Created Successfully.", AlertTypes.Success)
                    const queryString = res?.data?.value ? `?id=${res.data?.value}` : ""
                    navigate("/admin/users" + queryString)
                } else {
                    addSnackbarMessage("User Not Created.", AlertTypes.Error)
                }
            }
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        if (orgList) {
            if (userUuid) {
                getUserData()
                setIsEdit(true)
            }
        }
    }, [orgList, userUuid])

    /**
     * Called when the page forst loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getOrgList(abortController)

        // Clean up function
        return () => {
            abortController.abort()
        }
    }, [userUuid])

    return (
        <div className='page-wrapper'>
            <MetaData title={`${isEdit ? "Edit User" : "New User"}`} />

            <form onSubmit={submitNewUser} className='page-form'>
                <Header2 title={isEdit ? "Edit User" : "New User"} />
                <RequiredFieldHint />

                <div className='flex-column flex-gap-16 flex-100'>
                    <h3>Key Information</h3>
                    <div className='label-input-wrapper'>
                        <label htmlFor='nu-name'>Name</label>
                        <input
                            id='nu-name'
                            value={name}
                            type='text'
                            className='rs-input'
                            required
                            maxLength={inputMaxLength.userName}
                            onChange={(e) => setName(e.target.value)}
                            autoFocus={true}
                        />
                    </div>
                    {!isEdit && (
                        <>
                            <div className='label-input-wrapper'>
                                <label htmlFor='nu-name'>Password</label>
                                <input id='nu-name' type='password' className='rs-input' onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <PasswordInputTips value1={password} value2={password} />
                        </>
                    )}
                    <div className='flex-row width-100'>
                        <div className='label-input-wrapper'>
                            <label htmlFor='nu-phone'>Phone</label>
                            <input
                                id='nu-phone'
                                value={phone}
                                type='tel'
                                maxLength={inputMaxLength.userPhone}
                                className='rs-input'
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className='label-input-wrapper'>
                            <label htmlFor='nu-email'>Email</label>

                            <input
                                id='nu-email'
                                value={email}
                                type='email'
                                required
                                maxLength={inputMaxLength.userEmail}
                                className='rs-input'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='flex-row width-100'>
                        <div className='flex-50'>
                            <div className='label-input-wrapper'>
                                <LabelledCheckbox
                                    label='Enable email notifications'
                                    id='emailNotification'
                                    checked={emailNotification}
                                    onChange={(e) => setEmailNotification(e.target.checked)}
                                />
                            </div>

                            <div className='label-input-wrapper'>
                                <LabelledCheckbox
                                    label='Auto subscribe to tickets'
                                    id='subscribeToTickets'
                                    checked={subscribeToTickets}
                                    onChange={(e) => setSubscribeToTickets(e.target.checked)}
                                />
                            </div>
                        </div>

                        <div className='label-input-wrapper flex-50'>
                            <Switch
                                id='isDisabled'
                                checkedLabel='User Enabled'
                                uncheckedLabel='User Disabled'
                                checked={enabled ?? false}
                                onChange={(e) => {
                                    setEnabled(e.target.checked)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <Divider padding={16} />

                <div className='flex-column flex-gap-16 flex-100'>
                    <div className='label-input-wrapper'>
                        <label htmlFor='nu-org'>Organisation</label>
                        <Select
                            inputId='nu-org'
                            options={orgList}
                            value={selectedOrg}
                            formatOptionLabel={formatExtendedLabel}
                            onChange={(e) => setSelectedOrg(e)}
                            isMulti
                            isClearable={true}
                            filterOption={selectFilter()}
                            theme={selectTheme}
                            className='react-select-container'
                        />
                        <div className='text-hint'>
                            New organisation will have the <code>Store User</code> role, this can be changed in the Organisation page.
                        </div>
                    </div>
                    <div className='label-input-wrapper'>
                        <label htmlFor='nu-org-group'>Organisation Groups</label>
                        <Select
                            inputId='nu-org-group'
                            options={groupList}
                            value={selectedGroups}
                            onChange={(e) => setSelectedGroups(e)}
                            isMulti
                            isClearable={true}
                            filterOption={selectFilter()}
                            theme={selectTheme}
                            className='react-select-container'
                        />
                        <div className='text-hint'>
                            New organisation will have the <code>Store User</code> role, this can be changed in the Groups page.
                        </div>
                    </div>
                    <div className='label-input-wrapper'>
                        <label htmlFor='nu-role'>Global Role</label>
                        <Select
                            inputId='nu-role'
                            options={roleList}
                            formatOptionLabel={formatExtendedLabel}
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e)}
                            filterOption={selectFilter()}
                            theme={selectTheme}
                            className='react-select-container'
                        />
                        <div className='text-hint'>The users global role takes priority over the group or organization roles.</div>
                    </div>
                </div>

                <div className='button-input-wrapper'>
                    {isEdit ? (
                        <button className='button-contained' type='submit' disabled={!name || !email || isSubmitting}>
                            Save User
                        </button>
                    ) : (
                        <button className='button-contained' type='submit' disabled={!name || !email || !password || isSubmitting}>
                            Create New User
                        </button>
                    )}
                </div>
            </form>
        </div>
    )
}

export default NewUser
