import React from "react"

import { VERSION_INFO_REVISION_DATE } from "../automatic/version_info"
import { Link } from "react-router-dom"

/**
 * The blue login page
 * @param {{children, showForgotPassword:boolean, showRegister:boolean, showLogin:boolean}}} param0
 */
function LoginPageWapper({ children, showForgotPassword = true, showRegister = true, showLogin = false }) {
    const showRegisterHack = 1 == 2 // dont show it yet
    return (
        <div className='login-page-wrapper'>
            <div className='login-wrapper'>
                <div className='login-logo-wrapper'>
                    <h1>RE-SCAN</h1>
                    <h2>SUPPORT DESK</h2>
                </div>
                <div>{children}</div>
                <div className='login-bottom-links'>
                    {showLogin && <Link to='/'>Click here to Login.</Link>}
                    {showForgotPassword && <Link to='/forgot-password'>Forgot your password? Reset it here.</Link>}
                    {showRegisterHack && showRegister && <Link to='/register'>Don&apos;t have an account? Register here.</Link>}
                </div>
                <div className='version-info'>Version {VERSION_INFO_REVISION_DATE}</div>
            </div>
        </div>
    )
}

export default LoginPageWapper
