import React from "react"

/**
 * Hint for required fields (used to unify style)
 */
function RequiredFieldHint() {
    return <p className='text-hint margin-vertical-8'>* = Required Field</p>
}

export default RequiredFieldHint
