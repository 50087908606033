import React, { useState } from "react"

import "../../pages/styling/general.css"
import "../ComponentStyles.css"

import Divider from "../dividers/Divider"
import ConfirmDialog from "../mui/ConfirmDialog"

const Dropdown = ({ options, removeDropdown, size }) => {
    const [chosenOption, setChosenOption] = useState(null)

    const onOptionClick = (option) => {
        if (option.confirmation === true) {
            setChosenOption(option)
        } else {
            option.func(option)
            removeDropdown()
        }
    }

    /**
     * Confirm closed
     */
    const closeConfirmationModal = () => {
        setChosenOption(null)
        removeDropdown()
    }

    /**
     * Yes confirm was clicked
     */
    const yesConfirmationResult = () => {
        if (chosenOption) {
            chosenOption.func(chosenOption)
        }
        closeConfirmationModal()
    }

    return (
        <div className={size === "large" ? "dropdown-wrapper-large flex-column flex-gap-none" : "dropdown-wrapper flex-column"}>
            <ConfirmDialog
                isOpen={chosenOption !== null}
                title={chosenOption && chosenOption?.confirmationTitle ? chosenOption?.confirmationTitle : "Question"}
                description={chosenOption && chosenOption?.confirmationDescription ? chosenOption?.confirmationDescription : "Are you sure?"}
                onClose={closeConfirmationModal}
                onNoClick={closeConfirmationModal}
                onYesClick={yesConfirmationResult}
            />

            {options?.map((option, i) => {
                if (option.hidden === true) return null

                let Icon = null
                try {
                    Icon = option.icon
                } catch (e) {
                    console.error(e)
                }

                return option.isDivider ? (
                    <Divider key={i} />
                ) : (
                    <div key={i} className={`dropdown-option flex-row-center-vertical`} onClick={() => onOptionClick(option)}>
                        {option.icon && <Icon id='data-snippet-icon' />}
                        <p>{option.text}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default Dropdown
