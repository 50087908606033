import React, { useEffect, useState } from "react"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import Select from "react-select"

import "../../components/ComponentStyles.css"

import useTicketFilter from "../../hooks/useTicketFilter"
import LabelledCheckbox from "../../components/forms/LabelledCheckbox"
import { createNewFilterFunc, getTicketSettingsFunc, updateFilterFunc, deleteFilterFunc } from "../../components/httpRequests/TicketsRequests"
import ConfirmDialog from "../../components/mui/ConfirmDialog"
import Tooltip from "../../components/mui/Tooltip"
import Loading from "../../components/Loading"
import { Tab, TabPanel, Tabs } from "../../components/mui/Tabs"
import InputSelect from "../../components/forms/InputSelect"
import { filterSelectOptions, selectFilter, selectOptionsToValuesArray, selectTheme, uuidValuePairsToSelectOptions } from "../../common/selectHelpers"
import { inputMaxLength } from "../../Constants"
import { relativeDateOptions } from "../../common/searchFilters"

/**
 * Ticket filters
 * closeModal = function to call when closing the modal (bool) => void
 * usersFilters = array of the users current functions
 * refreshUserFilters = function to call to refresh the usersFilters list () => void
 */
const TicketFilterSettingsModal = ({ closeModal, usersFilters, refreshUserFilters }) => {
    const { filterSettings, setFilterSettings } = useTicketFilter()

    /**
     * Priorities, Priorities, Priorities etc
     */
    const [ticketSettings, setTicketSettings] = useState(null)
    const [currentFilter, setCurrentFilter] = useState({ ...filterSettings })

    const [tabIndex, setTabIndex] = useState(0) // 0 = Filters, 1 = Delete
    const [deleteOption, setDeleteOption] = useState(null)

    /**
     * Get the admin list and other filter settings
     */
    const getFilterSettings = async (settingsController) => {
        try {
            const settings = await getTicketSettingsFunc(settingsController)

            // swap the users into a format react select can use
            settings.data.ticketUsers = uuidValuePairsToSelectOptions(settings.data.ticketUsers)

            setTicketSettings(settings.data)
        } catch (e) {
            if (!settingsController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * React-Select dropdown changed
     */
    const onSelectChange = (newValue, actionMeta) => {
        const name = actionMeta?.name
        if (!name) {
            console.error("name is not defined")
            return
        }

        // Update the selected filter
        setCurrentFilter((currentFilter) => {
            return { ...currentFilter, [name]: selectOptionsToValuesArray(newValue), isCustomFilter: true }
        })
    }

    /**
     * Check box changed
     */
    const onCheckboxChanged = (e) => {
        const name = e.target.id
        const isChecked = e.target.checked ? true : null

        setCurrentFilter((currentFilter) => {
            return { ...currentFilter, [name]: isChecked, isCustomFilter: true }
        })
    }

    /**
     * Text input changed
     */
    const onInputChange = (e) => {
        const name = e.target.id
        setCurrentFilter((currentFilter) => {
            return { ...currentFilter, [name]: e.target.value, isCustomFilter: true }
        })
    }

    /**
     * Called when the tab changes page
     */
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue)
    }

    const closeModalFunc = () => {
        setFilterSettings(currentFilter)
        closeModal(false)
    }

    const onUpdateFilter = async () => {
        await updateFilterFunc(currentFilter)
        closeModalFunc()
    }

    const onCreateNewFilter = async () => {
        await createNewFilterFunc(currentFilter)
        closeModalFunc()
    }

    /**
     * Delete the selected filter
     */
    const yesConfirmDeleteResult = async () => {
        if (deleteOption) {
            await deleteFilterFunc(deleteOption.uuid)
            setDeleteOption(null)
            refreshUserFilters()
        }
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const settingsController = new AbortController()
        getFilterSettings(settingsController)

        // Clean up function
        return () => {
            settingsController.abort()
        }
    }, [])

    // TODO-FILTERS other filters options here
    /*
        isPrivate: null,
        openedDateFrom: null,
        openedDateTo: null,
        closedDateFrom: null,
        closedDateTo: null,
        updatedDateFrom: null,
        updatedDateTo: null,
    */

    return (
        <>
            <ConfirmDialog
                isOpen={deleteOption !== null}
                title='Delete Filter'
                description={`Are you sure that you want to permanently delete the ${deleteOption?.filterName} filter?`}
                onClose={() => {
                    setDeleteOption(null)
                }}
                onNoClick={() => {
                    setDeleteOption(null)
                }}
                onYesClick={yesConfirmDeleteResult}
            />

            <ConfirmDialog
                isOpen={true}
                title='Advanced Filter Settings'
                description=''
                fullWidth={true}
                maxWidth='lg'
                onClose={closeModalFunc}
                onYesClick={onCreateNewFilter}
                yesLabel='Save As New Filter'
                yesDisabled={tabIndex !== 0}
                onNoClick={onUpdateFilter}
                noLabel='Update Current Filter'
                noDisabled={tabIndex !== 0}
                showCloseButton={true}
            >
                {ticketSettings ? (
                    <>
                        <Tabs value={tabIndex} onChange={handleTabChange}>
                            <Tab label='Ticket Filters' id='simple-tab-0' />
                            <Tab label='Saved Filters' id='simple-tab-1' />
                        </Tabs>

                        <TabPanel value={tabIndex} index={0}>
                            <div className='flex-column'>
                                {/* top row */}
                                <div className='flex-row width-100 flex-gap-32 divider-line-black-bottom'>
                                    <div className='form-section form-section-condensed'>
                                        <label htmlFor='filterName'>Filter Name</label>
                                        <input
                                            type='text'
                                            id='filterName'
                                            className='rs-input'
                                            autoFocus={true}
                                            onChange={onInputChange}
                                            defaultValue={currentFilter?.filterName}
                                            maxLength={inputMaxLength.ticketFilterName}
                                        />
                                    </div>
                                </div>

                                {/* bottom row */}
                                <div className='flex-row flex-column-small width-100 flex-gap-16 padding-bottom-16'>
                                    <div className='flex-100 flex-column'>
                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='organisations'>Organisation</label>
                                            <Select
                                                options={ticketSettings?.organisations ?? undefined}
                                                defaultValue={filterSelectOptions(ticketSettings?.organisations, currentFilter?.organisations)}
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                isMulti
                                                inputId='organisations'
                                                name='organisations'
                                                filterOption={selectFilter()}
                                                theme={selectTheme}
                                                className='react-select-container'
                                            />
                                        </div>

                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='ticketTags'>Ticket Tags</label>
                                            <Select
                                                options={ticketSettings.ticketTags}
                                                defaultValue={filterSelectOptions(ticketSettings?.ticketTags, currentFilter?.ticketTags)}
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                isMulti
                                                inputId='ticketTags'
                                                name='ticketTags'
                                                filterOption={selectFilter()}
                                                theme={selectTheme}
                                                className='react-select-container'
                                            />
                                        </div>

                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='ticketCategory'>Ticket Category</label>
                                            <Select
                                                options={ticketSettings.ticketCategories}
                                                defaultValue={filterSelectOptions(ticketSettings?.ticketCategories, currentFilter?.ticketCategory)}
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                isMulti
                                                inputId='ticketCategory'
                                                name='ticketCategory'
                                                filterOption={selectFilter()}
                                                theme={selectTheme}
                                                className='react-select-container'
                                            />
                                        </div>

                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='ticketStatus'>Ticket Status Type</label>
                                            <Select
                                                options={ticketSettings.ticketStatusTypes}
                                                defaultValue={filterSelectOptions(ticketSettings?.ticketStatusTypes, currentFilter?.ticketStatus)}
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                isMulti
                                                inputId='ticketStatus'
                                                name='ticketStatus'
                                                filterOption={selectFilter()}
                                                theme={selectTheme}
                                                className='react-select-container'
                                            />
                                        </div>

                                        <LabelledCheckbox
                                            id='seeSubscribed'
                                            onChange={onCheckboxChanged}
                                            checked={currentFilter?.seeSubscribed}
                                            label='See Subscribed'
                                        />
                                    </div>
                                    <div className='flex-100 flex-column'>
                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='ticketPriority'>Ticket Priority</label>
                                            <Select
                                                options={ticketSettings.ticketPriorities}
                                                defaultValue={filterSelectOptions(ticketSettings?.ticketPriorities, currentFilter?.ticketPriority)}
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                isMulti
                                                inputId='ticketPriority'
                                                name='ticketPriority'
                                                filterOption={selectFilter()}
                                                theme={selectTheme}
                                                className='react-select-container'
                                            />
                                        </div>

                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='assignedTo'>Assigned To</label>
                                            <Select
                                                options={ticketSettings.ticketUsers}
                                                defaultValue={filterSelectOptions(ticketSettings?.ticketUsers, currentFilter?.assignedTo)}
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                isMulti
                                                inputId='assignedTo'
                                                name='assignedTo'
                                                filterOption={selectFilter()}
                                                theme={selectTheme}
                                                className='react-select-container'
                                            />
                                        </div>

                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='createdBy'>Created By</label>
                                            <Select
                                                options={ticketSettings.ticketUsers}
                                                defaultValue={filterSelectOptions(ticketSettings?.ticketUsers, currentFilter?.createdBy)}
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                isMulti
                                                inputId='createdBy'
                                                name='createdBy'
                                                filterOption={selectFilter()}
                                                theme={selectTheme}
                                                className='react-select-container'
                                            />
                                        </div>

                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='closedBy'>Closed By</label>
                                            <Select
                                                options={ticketSettings.ticketUsers}
                                                defaultValue={filterSelectOptions(ticketSettings?.ticketUsers, currentFilter?.closedBy)}
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                isMulti
                                                inputId='closedBy'
                                                name='closedBy'
                                                filterOption={selectFilter()}
                                                theme={selectTheme}
                                                className='react-select-container'
                                            />
                                        </div>

                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='titleContains'>Ticket Title Contains</label>
                                            <input
                                                type='text'
                                                id='titleContains'
                                                className='rs-input'
                                                maxLength={inputMaxLength.ticketTitle}
                                                onChange={onInputChange}
                                                value={currentFilter?.titleContains}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex-100 flex-column'>
                                        {/* dates */}
                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='openedRelativeDate'>Date Opened</label>
                                            <InputSelect
                                                options={relativeDateOptions}
                                                id='openedRelativeDate'
                                                defaultValue={currentFilter?.openedRelativeDate}
                                                onChange={onInputChange}
                                                required
                                            />
                                        </div>
                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='updatedRelativeDate'>Date Updated</label>
                                            <InputSelect
                                                options={relativeDateOptions}
                                                id='updatedRelativeDate'
                                                defaultValue={currentFilter?.updatedRelativeDate}
                                                onChange={onInputChange}
                                                required
                                            />
                                        </div>
                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='dueRelativeDate'>Date Due</label>
                                            <InputSelect
                                                options={relativeDateOptions}
                                                id='dueRelativeDate'
                                                defaultValue={currentFilter?.dueRelativeDate}
                                                onChange={onInputChange}
                                                required
                                            />
                                        </div>
                                        <div className='form-section form-section-condensed'>
                                            <label htmlFor='closedRelativeDate'>Date Closed</label>
                                            <InputSelect
                                                options={relativeDateOptions}
                                                id='closedRelativeDate'
                                                defaultValue={currentFilter?.closedRelativeDate}
                                                onChange={onInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <div className='flex-column flex-100 margin-vertical-8 flex-gap-16'>
                                {usersFilters.map((filter) => {
                                    return (
                                        <div key={filter.uuid} className='flex-row-spacebetween width-100'>
                                            <p>{filter.filterName}</p>
                                            <div>
                                                <Tooltip text='Delete filter'>
                                                    <DeleteForeverIcon className='faded-icon' onClick={() => setDeleteOption(filter)} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </TabPanel>
                    </>
                ) : (
                    <Loading />
                )}
            </ConfirmDialog>
        </>
    )
}

export default TicketFilterSettingsModal
