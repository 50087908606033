import { useEffect, useState } from "react"

/**
 * Delayed state update
 */
function useDebounceState(defaultValue, delay = 500) {
    const [debouncedValue, setDebouncedValue] = useState(defaultValue)
    const [newValue, setNewValue] = useState(defaultValue)

    useEffect(() => {
        if (debouncedValue === newValue) return

        const timer = setTimeout(() => setDebouncedValue(newValue), delay)
        return () => {
            clearTimeout(timer)
        }
    }, [newValue, delay])

    return [debouncedValue, setNewValue]
}

export default useDebounceState
