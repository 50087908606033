import React from "react"
import { Link } from "react-router-dom"
import Tooltip from "../mui/Tooltip"

import "./NavMenu.css"

/**
 * A single nav button
 */
const NavItem = ({ path, icon, label, currentPath, showLabel, onClick, display = true }) => {
    /**
     * On click handler
     */
    const doOnClick = (e) => {
        if (onClick) onClick(e)
    }

    return display ? (
        <div className={currentPath === path ? "nav-item nav-item-selected" : "nav-item"}>
            <Tooltip text={label} side='right' isEnabled={!showLabel} followCursor={true}>
                <Link className='nav-link' to={path} onClick={doOnClick}>
                    {icon}
                    <span className={`nav-link-label ${showLabel ? "" : "nav-link-hidden-label"}`}>{label}</span>
                </Link>
            </Tooltip>
        </div>
    ) : null
}

export default NavItem
