import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import SecurityIcon from "@mui/icons-material/Security"

import "../../styling/users.css"

import Divider from "../../../components/dividers/Divider"
import MetaData from "../../../components/MetaData"
import axios, { axiosErrorMessage } from "../../../components/axios"
import Loading from "../../../components/Loading"
import DataSnippet from "../../../components/data/DataSnippet"
import ConfirmDialog from "../../../components/mui/ConfirmDialog"
import Tooltip from "../../../components/mui/Tooltip"
import Card from "../../../components/mui/Card"
import Tag from "../../../components/data/Tag"

import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"

import { dateTimeFormat } from "../../../components/utility/ConvertTime"
import { primaryColor } from "../../../Theme"
import NavBack from "../../../components/nav/NavBack"
import ProfilePhoto from "../../../components/ProfilePhoto"
import { Header2 } from "../../../components/headers/Headers"

/**
 * Single user screen
 */
const User = () => {
    const navigate = useNavigate()
    const { userUuid } = useParams()
    const { addSnackbarMessage } = useAlertContext()

    const [userInfo, setUserInfo] = useState(null)
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

    /**
     * Find a users data
     */
    const findQuery = async (abortController) => {
        if (!userUuid) returnToUserList()

        try {
            const user = await axios.get(`/api/user/${userUuid}`, { signal: abortController?.signal })
            setUserInfo(user.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
                returnToUserList()
            }
        }
    }

    /**
     * Delete the passed users
     */
    const deleteUser = async (uuid) => {
        try {
            await axios.delete(`/api/user/${uuid}`)
            addSnackbarMessage("User Deleted Successfully.", AlertTypes.Success)
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        }
        returnToUserList()
    }

    const returnToUserList = () => {
        navigate("/admin/users")
    }

    const editUser = (uuid) => {
        navigate(`/admin/user/${uuid}/edit`)
    }

    /**
     * Close the confirmation modal
     */
    const closeConfirmationModal = () => {
        setOpenConfirmationModal(false)
    }

    /**
     * Confirm user deletion
     */
    const yesConfirmationResult = () => {
        deleteUser(userInfo.uuid)
        closeConfirmationModal()
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        findQuery(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [userUuid])

    if (!userInfo) return <Loading />

    return (
        <div className='page-wrapper'>
            <ConfirmDialog
                isOpen={openConfirmationModal}
                title='Delete User'
                description={`Are you sure you want to delete ${userInfo?.name}?`}
                onClose={closeConfirmationModal}
                onNoClick={closeConfirmationModal}
                onYesClick={yesConfirmationResult}
            />

            <MetaData title={`${userInfo.name} | User`} />

            <div className='padding-16 divider-line-black-bottom'>
                <div className='flex-row'>
                    <NavBack to={`/admin/users`} />
                    <Link to={`/admin/user/${userUuid}/edit`} style={{ textDecoration: "none" }} className='button-outlined'>
                        Edit User
                    </Link>
                </div>
            </div>
            <div className='padding-16'>
                <div className='user-info-title'>
                    <div className='flex-row'>
                        {userInfo.enabled ? null : <span className='pill-danger'>DISABLED</span>}
                        {userInfo.twoFactorAuthEnabled ? <span className='pill-success'>2FA ENABLED</span> : <span className='pill-danger'>2FA DISABLED</span>}
                    </div>

                    <div className='flex-row flex-spacebetween'>
                        <div className='flex-row-center-vertical padding-vertical-8'>
                            <ProfilePhoto userUuid={userInfo.uuid} userName={userInfo.name} size='large' />

                            <Header2
                                title={userInfo.name}
                                subtitle={userInfo.uuid}
                                titleClassName='heading'
                                subtitleClassName='text-hint'
                                showDivider={false}
                            />
                        </div>

                        <div className='flex-row'>
                            <Tooltip text='Edit user'>
                                <EditIcon className='faded-icon' onClick={() => editUser(userInfo.uuid)} />
                            </Tooltip>
                            <Tooltip text='Delete user'>
                                <DeleteForeverIcon className='faded-icon' onClick={() => setOpenConfirmationModal(true)} />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <Divider padding={16} />
                <div className='flex-row flex-spacebetween flex-wrap flex-gap-16'>
                    {userInfo.email && (
                        <DataSnippet
                            icon={userInfo.emailNotification ? EmailIcon : UnsubscribeIcon}
                            iconColor={userInfo.emailNotification ? "default" : "danger"}
                            iconHint={userInfo.emailNotification ? "Email notifications enabled" : "Email notifications disabled"}
                            title='Email'
                            data={<a href={"mailto:" + userInfo.email}>{userInfo.email}</a>}
                        />
                    )}
                    {userInfo.phone && <DataSnippet icon={PhoneIcon} title='Phone' data={userInfo.phone} />}
                    {userInfo.role && <DataSnippet icon={SecurityIcon} title='Role' data={userInfo.role.label ?? "unknown"} />}
                    {userInfo.lastSeen && <DataSnippet icon={AccessTimeIcon} title='Last Seen' data={dateTimeFormat(userInfo.lastSeen, "never")} />}
                </div>
                <Divider padding={16} />
                <div className='flex-row-stretch flex-wrap flex-gap-16'>
                    <Card title='Organisations' overflowHidden={false} className='flex-100'>
                        <ul className='styled-list'>
                            {userInfo.organisations?.map((org) => {
                                return (
                                    <li key={org.value}>
                                        <Link to={`/organisation/${org.value}`} className='flex-row flex-wrap flex-spacebetween'>
                                            <span>{org.label}</span>
                                            <Tag label={org.extendedLabel?.label ?? "unknown"} colour={primaryColor} />
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </Card>
                    <Card title='Groups' overflowHidden={false} className='flex-100'>
                        <ul className='styled-list'>
                            {userInfo.userGroups?.map((g) => {
                                return (
                                    <li key={g.value}>
                                        <Link to={`/admin/groups/${g.value}`} className='flex-row flex-wrap flex-spacebetween'>
                                            <span>{g.label}</span>
                                            <Tag label={g.extendedLabel?.label ?? "unknown"} colour={primaryColor} />
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default User
