import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton, Slide, useMediaQuery } from "@mui/material"
import { useTheme } from "@emotion/react"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

export default function ConfirmDialog({
    isOpen,
    title,
    description,
    onYesClick,
    onNoClick,
    onClose,
    yesLabel = "Yes",
    noLabel = "No",
    yesDisabled = false,
    noDisabled = false,
    fullWidth = undefined,
    maxWidth = undefined,
    showCloseButton = false,
    useTransition = true,
    applyOverflowFix = false,
    children,
}) {
    const theme = useTheme()
    const fullScreen = fullWidth && useMediaQuery(theme.breakpoints.down("md"))

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            classes={{ paper: `alert-dialog-wrapper ${applyOverflowFix && "alert-dialog-wrapper-overflow-y-fix"}` }}
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            TransitionComponent={useTransition ? Transition : undefined}
        >
            {title && <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>}
            {showCloseButton && (
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
            <DialogContent>
                {description && <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>}
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onYesClick} autoFocus disabled={yesDisabled} variant='contained'>
                    {yesLabel}
                </Button>
                <Button onClick={onNoClick} disabled={noDisabled} variant='outlined'>
                    {noLabel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
