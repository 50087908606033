import React, { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import AddBoxIcon from "@mui/icons-material/AddBox"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import {
    deleteOrganizationStorageItemFunc,
    deleteStorageItemFunc,
    getOrganizationStorageListFunc,
    getStorageListFunc,
} from "../../../components/httpRequests/StorageRequests"
import { dateFormat, dateTimeFormat } from "../../../components/utility/ConvertTime"
import ConfirmDialog from "../../../components/mui/ConfirmDialog"
import ErrorBoundary from "../../../components/errors/ErrorBoundary"
import ErrorBlock from "../../../components/errors/ErrorBlock"
import DataTable from "../../../components/mui/DataTable"
import Tooltip from "../../../components/mui/Tooltip"
import ButtonMenu from "../../../components/mui/ButtonMenu"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import EditStorageModal from "./EditStorageModal"
import useDebounceState from "../../../hooks/useDebounceState"
import useUser from "../../../hooks/useUser"
import { Header2 } from "../../../components/headers/Headers"

/**
 * Basic column with a link
 */
const basicColumns = (params) => {
    return <Link to={`/article/${params.id}`}>{params.value}</Link>
}

/**
 * Formated column with a link and tool tip
 */
const formatedColumnsWithTip = (id, formatedValue, toolTip) => {
    return (
        <Tooltip text={toolTip}>
            <Link to={`/article/${id}`}>{formatedValue}</Link>
        </Tooltip>
    )
}

/**
 * List of storage items
 */
function StorageList({ title = undefined, isOrgStorage = false, orgUuid = null }) {
    const navigate = useNavigate()
    const { addSnackbarMessage } = useAlertContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const { hasAuthRole_Admin } = useUser()

    const [deleteItem, setDeleteItem] = useState(null)
    const [storageData, setStorageData] = useState(null)
    const [storageDataFiltered, setStorageDataFiltered] = useState(null)
    const [searchValue, setSearchValue] = useDebounceState("")
    const [editItem, setEditItem] = useState(null) // {uuid:null, orgUuid: null, isOrgStorage,}

    const isAdmin = hasAuthRole_Admin()

    // *************************** table columns (uuid/orgUuid) ***************************

    const columns = [
        {
            field: "uuid",
            headerName: "#",
            flex: 0.2,
            renderCell: basicColumns,
        },
        {
            field: "title",
            headerName: "Title",
            flex: 2,
            minWidth: 100,
            hideable: false,
            renderCell: basicColumns,
        },
        {
            field: "categoryName",
            headerName: "Category",
            flex: 1,
            minWidth: 100,
            renderCell: basicColumns,
        },
        {
            field: "isContract",
            headerName: "Is Contract",
            flex: 0.5,
            type: "boolean",
            minWidth: 50,
        },
        {
            field: "contractStartDate",
            headerName: "Contract Start",
            flex: 0.5,
            minWidth: 50,
            renderCell: (params) => (params.row.isContract ? formatedColumnsWithTip(params.id, dateFormat(params.value), dateTimeFormat(params.value)) : "-"),
        },
        {
            field: "contractEndDate",
            headerName: "Contract End",
            flex: 0.5,
            minWidth: 50,
            renderCell: (params) => (params.row.isContract ? formatedColumnsWithTip(params.id, dateFormat(params.value), dateTimeFormat(params.value)) : "-"),
        },
        {
            field: "contractLength",
            headerName: "Contract Length",
            flex: 0.5,
            minWidth: 50,
            renderCell: (params) => (params.row.isContract ? formatedColumnsWithTip(params.id, params.value, `${params.value} months`) : "-"),
        },
        {
            field: "createdAt",
            headerName: "Created",
            flex: 0.5,
            minWidth: 50,
            renderCell: (params) => formatedColumnsWithTip(params.id, dateFormat(params.value), dateTimeFormat(params.value)),
        },
        {
            field: "updatedAt",
            headerName: "Updated",
            flex: 0.5,
            minWidth: 50,
            renderCell: (params) => formatedColumnsWithTip(params.id, dateFormat(params.value), dateTimeFormat(params.value)),
        },
        {
            field: "roleName",
            headerName: "Visibility",
            flex: 1,
            minWidth: 100,
            hideable: true,
            renderCell: basicColumns,
        },
        {
            field: "fileCount",
            headerName: "Files",
            flex: 0.5,
            minWidth: 50,
            type: "number",
            renderCell: basicColumns,
        },
        {
            field: "__actions",
            headerName: "...",
            flex: 1,
            hideable: false,
            headerAlign: "right",
            align: "right",
            maxWidth: 50,
            renderCell: (params) =>
                isAdmin ? (
                    <>
                        <ButtonMenu
                            id={params.id}
                            menuItems={[
                                {
                                    label: "Edit",
                                    icon: EditIcon,
                                    func: () => editItemMenuClick(params.id),
                                },
                                {
                                    label: "Delete",
                                    icon: DeleteForeverIcon,
                                    func: () => deleteItemMenuClick(params.id),
                                },
                            ]}
                        />
                    </>
                ) : null,
        },
    ]
    // *************************** table columns (uuid/orgUuid) ***************************

    /**
     * Edit item menu clicked
     * @param {string|null} storageUuid
     */
    const editItemMenuClick = (storageUuid) => {
        if (!isAdmin) return

        // open the correct editor
        const openEditor = (item, isNewItem) => {
            if (isOrgStorage) {
                setEditItem(item)
            } else {
                const url = isNewItem ? `/admin/knowledge-base/new` : `/admin/knowledge-base/${item.uuid}/edit`
                navigate(url)
            }
        }

        // check if this is an edit or a new item
        if (storageUuid != null) {
            const item = storageData?.find((d) => d.uuid === storageUuid)
            if (!item) return
            openEditor({ uuid: item.uuid, orgUuid: item.orgUuid, isOrgStorage }, false)
        } else {
            openEditor({ uuid: storageUuid, orgUuid, isOrgStorage }, true)
        }
    }

    /**
     * Delete item menu clicked
     */
    const deleteItemMenuClick = (storageUuid) => {
        if (!isAdmin) return

        const item = storageData?.find((d) => d.uuid === storageUuid)
        if (!item) return
        setDeleteItem(item)
    }

    // Load the list of storage data from the url
    const getStorageInfo = async (abortController) => {
        try {
            const storage = isOrgStorage ? await getOrganizationStorageListFunc(orgUuid, abortController) : await getStorageListFunc(abortController)
            setStorageData(storage.data)
            handelCloseModal(false)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                console.error(e)
            }
        }
    }

    /**
     * Delete the correct storage item
     */
    const deleteStorageItem = async () => {
        if (!isAdmin) return
        if (deleteItem == null) return

        const res = isOrgStorage ? await deleteOrganizationStorageItemFunc(deleteItem.orgUuid, deleteItem.uuid) : await deleteStorageItemFunc(deleteItem.uuid)
        handelCloseModal(false)
        if (res.status === 200) addSnackbarMessage("Storage item successfully deleted.", AlertTypes.Success)
        await getStorageInfo()
    }

    /**
     * Close the modals
     */
    const handelCloseModal = async (storageUuid) => {
        setDeleteItem(null)
        setEditItem(null)
        if (storageUuid) {
            if (storageUuid) {
                setSearchParams({ ...searchParams, id: storageUuid }, false)
            }
            await getStorageInfo()
        }
    }

    /**
     * Filter the data using the passed searcg value
     */
    const doDataFilter = (storageData, searchValue) => {
        let tempData = []

        if (!searchValue || !storageData) {
            return storageData
        }

        storageData.forEach((d) => {
            if (d?.title?.toLowerCase().includes(searchValue)) {
                tempData.push(d)
            } else if (d?.categoryName?.toLowerCase().includes(searchValue)) {
                tempData.push(d)
            } else if (d?.keywords?.toLowerCase().includes(searchValue)) {
                tempData.push(d)
            }
        })
        return tempData
    }

    /**
     * Update the storage data
     */
    useEffect(() => {
        setStorageDataFiltered(doDataFilter(storageData, searchValue))
    }, [storageData, searchValue])

    /**
     * Called when the page is first loaded
     */
    useEffect(() => {
        const abortController = new AbortController()
        getStorageInfo(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [isOrgStorage, orgUuid])

    return (
        <>
            <ConfirmDialog
                isOpen={deleteItem != null}
                title={`Delete ${deleteItem?.title}`}
                description='Are you sure that you want to permanently delete this item?'
                onClose={handelCloseModal}
                onNoClick={handelCloseModal}
                onYesClick={deleteStorageItem}
            />

            {editItem && (
                <EditStorageModal storageUuid={editItem?.uuid} orgUuid={editItem?.orgUuid} isOrgStorage={editItem?.isOrgStorage} onClose={handelCloseModal} />
            )}

            <div className='padding-16 divider-line-black-bottom flex-row flex-column-small'>
                <div className='flex-20'>
                    {isAdmin ? (
                        <>
                            {isOrgStorage ? (
                                <button className='button-contained' onClick={() => editItemMenuClick(null)}>
                                    <AddBoxIcon />
                                    New Item
                                </button>
                            ) : (
                                <Link to='/admin/knowledge-base/new' style={{ textDecoration: "none" }} className='button-contained'>
                                    <AddBoxIcon />
                                    New Item
                                </Link>
                            )}
                        </>
                    ) : null}
                </div>
                <div className='flex-80'>
                    <input
                        id='searchValue'
                        className='rs-input width-100'
                        type='text'
                        placeholder='Search Here'
                        defaultValue={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value?.toLowerCase())
                        }}
                    />
                </div>
            </div>

            {title ? (
                <div className='padding-16'>
                    <Header2 title={title} />
                </div>
            ) : null}

            <ErrorBoundary fallback={<ErrorBlock error={"Unable to load storage table"} />}>
                <DataTable
                    tableId={"storage-table"}
                    rows={storageDataFiltered}
                    columns={columns}
                    getRowId={(u) => u.uuid}
                    defaultColumnVisibility={{
                        uuid: false,
                        isContract: false,
                        contractEndDate: false,
                        contractStartDate: false,
                        contractLength: false,
                        updatedAt: false,
                    }}
                    hideFooter={false}
                />
            </ErrorBoundary>
        </>
    )
}

export default StorageList
