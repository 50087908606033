import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Select from "react-select"

import AddIcon from "@mui/icons-material/Add"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import "../../styling/dashboard.css"

import axios, { axiosErrorMessage } from "../../../components/axios"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import MetaData from "../../../components/MetaData"
import Divider from "../../../components/dividers/Divider"
import LabelledCheckbox from "../../../components/forms/LabelledCheckbox"
import { inputMaxLength } from "../../../Constants"
import { filterSelectOptions, formatExtendedLabel, formatTagsLabel, selectFilter, selectOptionsToValuesArray, selectTheme } from "../../../common/selectHelpers"
import { getTicketTemplateFunc } from "../../../components/httpRequests/TicketTemplateRequests"
import { ticketTemplateIconOptions } from "../../../components/TicketTemplateIcon"
import { Tab, TabPanel, Tabs } from "../../../components/mui/Tabs"
import Tooltip from "../../../components/mui/Tooltip"
import IconButton from "../../../components/mui/IconButton"
import RequiredFieldHint from "../../../components/forms/RequiredFieldHint"
import { Header2 } from "../../../components/headers/Headers"

/**
 * Create or edit a ticket template
 */
const EditTicketTemplate = () => {
    const navigate = useNavigate()
    const { templateId } = useParams()
    const { addSnackbarMessage } = useAlertContext()

    const [templateData, setTemplateData] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)

    // Drop down options
    const [ticketTypeOptions, setTicketTypeOptions] = useState(null)
    const [ticketTagsOptions, setTicketTagsOptions] = useState(null)

    const isEdit = templateId !== undefined

    const isPrivateOptions = [
        { value: false, label: "Anyone", extendedLabel: "Anyone can view tickets created with this template" },
        { value: true, label: "Admins", extendedLabel: "Only Admins can view tickets created with this template" },
    ]

    /**
     * Get the data
     */
    const getItemData = async (abortController) => {
        try {
            const request = isEdit ? getTicketTemplateFunc(templateId, abortController) : Promise.resolve()

            const [ticketTypesRes, templateRes, tagRes] = await Promise.all([axios.get("/api/newticket"), request, axios.get("/api/tag/list")])

            if (isEdit) {
                const data = templateRes?.data

                // remap the tags
                data.tags = data?.requestTemplateTags?.map((tag) => tag.tagUuid)
                data.inputs = data?.requestTemplateInputs ? [...data.requestTemplateInputs] : []
                delete data.requestTemplateTags
                delete data.requestTemplateInputs

                setTemplateData(templateRes?.data)
            }
            setTabIndex(0)
            setTicketTypeOptions(ticketTypesRes.data)
            setTicketTagsOptions(tagRes.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
                navigate("/admin/ticket-templates")
            }
        }
    }

    /**
     * Form submit
     */
    const submitTicketTemplate = async (e) => {
        e.preventDefault()
        if (isSubmitting) return
        if (!isRecordValid()) return

        setIsSubmitting(true)
        try {
            const data = {
                title: templateData.title,
                icon: templateData.icon,
                categoryId: templateData.categoryId,
                isPrivate: templateData.isPrivate,
                adminOnly: templateData.adminOnly,
                enabled: templateData.enabled,
                slaDays: templateData.slaDays,
                tags: templateData.tags,
                inputs: templateData.inputs,
            }

            let res = { status: 500 }
            if (isEdit) {
                data.id = templateId
                res = await axios.patch("/api/requesttemplates/", data)
            } else {
                res = await axios.post("/api/requesttemplates/", data)
            }

            if (res.status === 200) {
                addSnackbarMessage("Template Saved Successfully.", AlertTypes.Success)
                const queryString = res?.data?.value ? `?id=${res.data?.value}` : ""
                navigate("/admin/ticket-templates" + queryString)
            } else {
                addSnackbarMessage("Template Not Saved.", AlertTypes.Error)
            }
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    /**
     * Check box changed
     */
    const onCheckboxChanged = (e) => {
        const name = e.target.id
        const isChecked = e.target.checked ? true : false

        setTemplateData((currentFilter) => {
            return { ...currentFilter, [name]: isChecked }
        })
    }

    /**
     * Text input changed
     */
    const onInputChange = (e) => {
        const name = e.target.id
        setTemplateData((currentFilter) => {
            return { ...currentFilter, [name]: e.target.value }
        })
    }

    /**
     * React-Select dropdown changed
     */
    const onSelectChange = (newValue, actionMeta, isMulti = false) => {
        const name = actionMeta?.name
        if (!name) {
            console.error("name is not defined")
            return
        }

        let value = selectOptionsToValuesArray(newValue)
        if (!isMulti) {
            value = value.length >= 1 ? value[0] : undefined
        }

        // Update the selected filter
        setTemplateData((currentFilter) => {
            return { ...currentFilter, [name]: value }
        })
    }

    /**
     * React multichange
     */
    const onSelectMuliChange = (newValue, actionMeta) => {
        onSelectChange(newValue, actionMeta, true)
    }

    /**
     * Add new input click
     */
    const onNewInputClick = (e) => {
        e.preventDefault()

        setTemplateData((currentFilter) => {
            const newInputs = currentFilter?.inputs ?? []
            newInputs.push({
                newId: crypto.randomUUID(),
                title: "New Input",
                type: "input",
                required: false,
            })
            return { ...currentFilter, inputs: newInputs }
        })

        setTabIndex((templateData?.inputs?.length ?? 1) - 1)
    }

    /**
     * Update from one of the input items
     */
    const onUpdateInputItem = (index, name, value) => {
        setTemplateData((currentFilter) => {
            const newInputs = currentFilter?.inputs ?? []
            if (newInputs.length > index) {
                newInputs[index][name] = value
            }
            return { ...currentFilter, inputs: newInputs }
        })
    }

    /**
     * If the current record is valid or not
     */
    const isRecordValid = () => {
        if (!templateData) return false
        if (!templateData.title) return false
        if (!templateData.categoryId) return false
        if (Array.isArray(templateData.categoryId)) return false
        if (!Array.isArray(templateData.tags)) return false
        if (templateData.tags.length == 0) return false
        return true
    }

    /**
     * Called when the page is first loaded
     */
    useEffect(() => {
        const abortController1 = new AbortController()
        getItemData(abortController1)

        // Clean up function
        return () => {
            abortController1.abort()
        }
    }, [templateId])

    return (
        <div className='page-wrapper'>
            <MetaData title={`${isEdit ? "Edit" : "New"} Ticket Template`} />

            <form onSubmit={submitTicketTemplate} className='page-form'>
                <Header2 title={`${isEdit ? "Edit" : "New"} Ticket Template`} />
                <RequiredFieldHint />
                <div className='flex-row flex-column-small flex-gap-32'>
                    <div className='flex-column flex-gap-16 flex-100'>
                        <div className='label-input-wrapper'>
                            <label htmlFor='nu-name'>Title</label>
                            <input
                                id='title'
                                value={templateData?.title ?? ""}
                                type='text'
                                className='rs-input'
                                required
                                maxLength={inputMaxLength.ticketTitle}
                                onChange={onInputChange}
                                autoFocus={true}
                            />
                        </div>

                        <div className='label-input-wrapper'>
                            <label htmlFor='icon'>Template Icon *</label>
                            <Select
                                inputId='icon'
                                name='icon'
                                options={ticketTemplateIconOptions ?? undefined}
                                filterOption={selectFilter()}
                                onChange={onSelectChange}
                                isLoading={!ticketTemplateIconOptions}
                                value={filterSelectOptions(ticketTemplateIconOptions, templateData?.icon)}
                                theme={selectTheme}
                                className='react-select-container'
                            />
                        </div>

                        <div className='label-input-wrapper'>
                            <label htmlFor='nu-name'>SLA (Business Days)</label>
                            <input
                                id='slaDays'
                                value={templateData?.slaDays ?? 0}
                                type='number'
                                min={0}
                                className='rs-input'
                                required
                                onChange={onInputChange}
                                autoFocus={true}
                            />
                            <p className='text-hint'>Setting this value to zero causes the ticket to use the SLA from the Ticket Category.</p>
                        </div>

                        <div className='label-input-wrapper'>
                            <label htmlFor='categoryId'>Ticket Category *</label>
                            <Select
                                inputId='categoryId'
                                name='categoryId'
                                options={ticketTypeOptions ?? undefined}
                                filterOption={selectFilter()}
                                onChange={onSelectChange}
                                isLoading={!ticketTypeOptions}
                                value={filterSelectOptions(ticketTypeOptions, templateData?.categoryId)}
                                theme={selectTheme}
                                className='react-select-container'
                            />
                        </div>

                        <div className='label-input-wrapper'>
                            <label htmlFor='categoryId'>Ticket Visibility *</label>
                            <Select
                                inputId='isPrivate'
                                name='isPrivate'
                                options={isPrivateOptions}
                                filterOption={selectFilter()}
                                onChange={onSelectChange}
                                formatOptionLabel={formatExtendedLabel}
                                value={filterSelectOptions(isPrivateOptions, templateData?.isPrivate)}
                                theme={selectTheme}
                                className='react-select-container'
                            />
                            <p className='text-hint'>Who can see tickets created using this template.</p>
                        </div>

                        <div className='label-input-wrapper'>
                            <label htmlFor='tags'>Tags *</label>
                            <Select
                                inputId='tags'
                                name='tags'
                                options={ticketTagsOptions ?? undefined}
                                onChange={onSelectMuliChange}
                                filterOption={selectFilter()}
                                isClearable={true}
                                isMulti
                                formatOptionLabel={formatTagsLabel}
                                isLoading={!ticketTagsOptions}
                                value={filterSelectOptions(ticketTagsOptions, templateData?.tags)}
                                theme={selectTheme}
                                className='react-select-container'
                            />
                        </div>

                        <div className='label-input-wrapper'>
                            <LabelledCheckbox label='Template Enabled' id='enabled' checked={templateData?.enabled ?? true} onChange={onCheckboxChanged} />
                        </div>

                        <div className='label-input-wrapper'>
                            <LabelledCheckbox
                                label='Only Admins Can Use This Template'
                                id='adminOnly'
                                checked={templateData?.adminOnly ?? false}
                                onChange={onCheckboxChanged}
                            />
                        </div>
                    </div>
                </div>
                <Divider padding={16} />
                {/* =========================================== TABS ==========================================  */}
                <div className='flex flex-row-center-vertical'>
                    <Tooltip text='Add new input.'>
                        <button onClick={onNewInputClick} className='button-outlined-blue-square'>
                            <AddIcon />
                        </button>
                    </Tooltip>
                    <h3>Template Inputs</h3>
                </div>
                <p className='text-hint padding-8'>
                    If no inputs are setup the template will use a basic Memo input. <br />
                    If an inputs title is left blank it will be removed when saved.
                </p>
                <div className='flex flex-row width-100'>
                    <Tabs
                        value={tabIndex}
                        onChange={(event, newValue) => {
                            setTabIndex(newValue)
                        }}
                        orientation='vertical'
                        className='margin-vertical-8'
                    >
                        {templateData?.inputs?.map((t, index) => {
                            return <Tab label={t.title ?? <span className='icon-danger'>Deleted</span>} key={t.id ?? t.newId} value={index} />
                        })}
                    </Tabs>
                    {templateData?.inputs?.map((t, index) => {
                        return (
                            <TabPanel value={tabIndex} key={t.id ?? t.newId} index={index} className='flex-100'>
                                <TicketTemplateInputPanel item={t} index={index} updateInputItem={onUpdateInputItem} />
                            </TabPanel>
                        )
                    })}
                </div>
                {/* =========================================== TABS ==========================================  */}
                <div className='button-input-wrapper'>
                    <button className='button-contained' type='submit' disabled={!isRecordValid() || isSubmitting}>
                        Save Template
                    </button>
                </div>
            </form>
        </div>
    )
}

/**
 * Input edit panel
 */
const TicketTemplateInputPanel = ({ item, index, updateInputItem }) => {
    // TODO finish this off, events and formatting, also add new and remove old ones

    /**
     * Update the item
     */
    const updateItem = (name, value) => {
        updateInputItem(index, name, value)
    }

    /**
     * Input on change event
     */
    const onInputChange = (e) => {
        updateItem(e.target.id, e.target.value)
    }

    /**
     * checkbox on change event
     */
    const onCheckboxChange = (e) => {
        const isChecked = e.target.checked ? true : false
        updateItem(e.target.id, isChecked)
    }

    const isDropdown = item.type === "options" || item.type === "multi-options"
    const isCheckBox = item.type === "checkbox"

    return (
        <div className='flex-row padding-16'>
            <div className='flex-column flex-gap-16 flex-100'>
                <div className='label-input-wrapper'>
                    <label htmlFor='title'>Title</label>
                    <input
                        id='title'
                        value={item?.title ?? ""}
                        type='text'
                        placeholder='The title that shown and will be included on the ticket'
                        className='rs-input'
                        maxLength={inputMaxLength.templateTitle}
                        onChange={onInputChange}
                    />
                </div>

                <div className='label-input-wrapper'>
                    <label htmlFor='title'>Hint</label>
                    <input
                        id='hint'
                        placeholder='Hint shown for this input, only shown when creating the ticket'
                        type='text'
                        maxLength={inputMaxLength.templateTitle}
                        className='rs-input'
                        value={item.hint ?? ""}
                        onChange={onInputChange}
                    />
                </div>

                <div className='label-input-wrapper'>
                    <label htmlFor='type'>Type</label>
                    <select id='type' className='rs-input' required value={item.type ?? "text"} onChange={onInputChange}>
                        <option value='text'>Basic Input (Single Line)</option>
                        <option value='memo'>Memo (Multiple Lines)</option>
                        <option value='checkbox'>Checkbox</option>
                        <option value='color'>Colour</option>
                        <option value='date'>Date Input</option>
                        <option value='datetime-local'>Date / Time Input</option>
                        <option value='email'>Email Input</option>
                        <option value='number'>Number Input</option>
                        <option value='options'>Options (Single)</option>
                        <option value='multi-options'>Options (Multi-Select)</option>
                        <option value='tel'>Phone Input</option>
                        <option value='time'>Time Input</option>
                        <option value='url'>Web Address</option>
                    </select>
                </div>

                <div className={`${isCheckBox ? "hidden" : "label-input-wrapper"}`}>
                    <label htmlFor='value'>Default Value</label>
                    <input
                        id='value'
                        type='text'
                        maxLength={inputMaxLength.templateTitle}
                        className='rs-input'
                        placeholder='Leave blank for no default'
                        value={item.value ?? ""}
                        onChange={onInputChange}
                    />
                    <DefaultValue type={item.type} />
                </div>

                <div className='label-input-wrapper'>
                    <LabelledCheckbox label='Required' id='required' checked={item?.required ?? false} onChange={onCheckboxChange} />
                </div>

                <div className={`${isDropdown ? "label-input-wrapper" : "hidden"}`}>
                    <label htmlFor='options'>Options</label>
                    <textarea
                        id='options'
                        type='text'
                        className='rs-input'
                        value={item.options ?? ""}
                        placeholder='CSV list of dropdown options'
                        rows='5'
                        onChange={onInputChange}
                    />
                </div>

                <div className='text-right width-100'>
                    <IconButton
                        hintText={`Delete input`}
                        onClick={() => {
                            updateItem("title", undefined)
                            updateItem("hint", undefined)
                            updateItem("value", undefined)
                            updateItem("required", false)
                            updateItem("options", undefined)
                            updateItem("type", "text")
                        }}
                        color='error'
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

/**
 * The hint for this iput type
 */
const DefaultValue = ({ type }) => {
    let hint = "An optional default value for this input"
    switch (type) {
        case "color":
            hint = "An optional default colour for this input, in the format #rrggbb"
            break
        case "date":
            hint = "An optional default date for this input, in the format yyyy-mm-dd"
            break
        case "datetime-local":
            hint = "An optional default date/time for this input, in the format yyyy-mm-ddTHH:nn"
            break
        case "number":
            hint = "An optional default integer for this input, between 0 and 2147483647"
            break
        case "options":
        case "multi-options":
            hint = "An optional default value for the dropdown, must be included in the options list."
            break
        case "time":
            hint = "An optional default time for this input, in the format HH:nn:ss"
            break
    }

    return <p className='text-hint'>{hint}</p>
}

export default EditTicketTemplate
