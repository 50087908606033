import axios from "axios"
import React, { createContext, useState } from "react"

import { roles } from "../../Constants"

const UserContext = createContext({})

export const defaultUserState = {
    loggedIn: false,
    uuid: null,
    name: null,
    roles: [],
}

export const UserProvider = ({ children }) => {
    const [auth, setAuth] = useState(defaultUserState)

    const [currentOrgUuid, setCurrentOrgUuid] = useState(null)
    const [currentRoute, setCurrentRoute] = useState(null)
    const [currentTicketInfo, setCurrentTicketInfo] = useState(null)

    const refreshAuth = async () => {
        try {
            const response = await axios.get("/api/login")
            if (response.data.loggedIn) {
                setAuth((currentState) => {
                    return {
                        ...currentState,
                        ...response.data,
                    }
                })
            } else {
                setAuth(defaultUserState)
            }
        } catch (e) {
            setAuth(defaultUserState)
        }
    }

    /**
     * Checks if the passed auth exists on the auth object
     */
    const hasAuthRole = (values) => {
        if (!auth || !auth.roles || !Array.isArray(auth.roles)) return false
        if (!values) return false

        if (Array.isArray(values)) {
            for (let i = 0; i < values.length; i++) {
                if (auth?.roles?.includes(values[i]) ?? false) return true
            }
        } else if (typeof values == "number") {
            return auth?.roles?.includes(values) ?? false
        }
        return false
    }

    /**
     * If the user is a store admin or higher
     */
    const hasAuthRole_StoreAdminOrAbove = () => {
        return hasAuthRole(roles.admin) || hasAuthRole(roles.store_admin) || hasAuthRole(roles.technician)
    }

    /**
     * If the user is a technician  or higher
     */
    const hasAuthRole_TechnicianOrAbove = () => {
        return hasAuthRole(roles.admin) || hasAuthRole(roles.technician)
    }

    /**
     * If the user is a admin
     */
    const hasAuthRole_Admin = () => {
        return hasAuthRole(roles.admin)
    }

    return (
        <UserContext.Provider
            value={{
                auth,
                setAuth,
                refreshAuth,
                currentOrgUuid,
                setCurrentOrgUuid,
                currentRoute,
                setCurrentRoute,
                currentTicketInfo,
                setCurrentTicketInfo,
                hasAuthRole,
                hasAuthRole_StoreAdminOrAbove,
                hasAuthRole_TechnicianOrAbove,
                hasAuthRole_Admin,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserContext
