import axios from "../axios"

/**
 * Gets a full list roles for the storage
 */
export const getRolesListFunc = async (incGlobalRoles, abortController) => {
    return axios.get(`/api/role/${incGlobalRoles ? "global" : ""}`, { signal: abortController?.signal })
}

/**
 * Post the passed data
 */
export const postUserOrganisationRoleFunc = async (itemData, abortController) => {
    const res = await axios.post("/api/role/organisation", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Deletes the passed item
 */
export const deleteUserOrganisationRoleFunc = async (orgUuid, userUuid, abortController) => {
    const res = await axios.delete(`/api/role/organisation/${orgUuid}/${userUuid}`, { signal: abortController?.signal })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Post the passed data
 */
export const postUserGroupRoleFunc = async (itemData, abortController) => {
    const res = await axios.post("/api/role/group", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Deletes the passed item
 */
export const deleteUserGroupRoleFunc = async (groupUuid, userUuid, abortController) => {
    const res = await axios.delete(`/api/role/group/${groupUuid}/${userUuid}`, { signal: abortController?.signal })
    return new Promise((resolve) => {
        resolve(res)
    })
}
