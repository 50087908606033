import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from "react-select"

import "../../components/ComponentStyles.css"

import axios, { axiosErrorMessage } from "../../components/axios"
import ConfirmDialog from "../../components/mui/ConfirmDialog"
import { selectFilter, selectTheme, uuidValuePairsToSelectOptions } from "../../common/selectHelpers"
import { getTechniciansFunc, getTicketUsersFunc } from "../../components/httpRequests/UserRequests"
import useUser from "../../hooks/useUser"
import useAlertContext, { AlertTypes } from "../../hooks/useAlertContext"
import { closeOrOpenTicketFunc } from "../../components/httpRequests/TicketsRequests"
import { organisations } from "../../Constants"
import { getOrganisationKeyValueListFunc } from "../../components/httpRequests/OrganisationRequests"
import SlateTextArea from "../../components/forms/SlateTextArea"

/**
 * Close the ticket popup box
 */
const AdminConfirmCloseTicket = ({ closeModal, ticketId, currentAssignedId, currentCategoryId, currentOrgUuid, isTicketClosed = false }) => {
    const navigate = useNavigate()
    const { addSnackbarMessage } = useAlertContext()
    const { auth } = useUser()

    const [newMessage, setNewMessage] = useState("")
    const [assignedTo, setAssignedTo] = useState()
    const [mentions, setMentions] = useState([])
    const [adminUserOptions, setAdminUserOptions] = useState(null)
    const [userOptions, setUserOptions] = useState(null)

    const [category, setCategory] = useState()
    const [categoryOptions, setCategoryOptions] = useState(null)

    const [organisation, setOrganisation] = useState()
    const [orgOptions, setOrgOptions] = useState(null)

    const needsNewOrg = currentOrgUuid === organisations.unassigned

    /**
     * Gets the list of admins and ticket types
     */
    const getSettings = async (abortController) => {
        try {
            const [types, admins, orgs, users] = await Promise.all([
                axios.get("/api/ticket/ticket-categories", { signal: abortController?.signal }),
                getTechniciansFunc(currentOrgUuid, abortController), // TODO: what about when org changes
                getOrganisationKeyValueListFunc(abortController),
                getTicketUsersFunc(ticketId, currentOrgUuid, abortController), // TODO: what about when org changes
            ])

            const keyValues = uuidValuePairsToSelectOptions(admins.data)
            setAdminUserOptions(keyValues)
            setUserOptions(uuidValuePairsToSelectOptions(users.data))
            setCategoryOptions(types.data)
            setOrgOptions(orgs.data)

            const findAssignedId = currentAssignedId ? currentAssignedId : auth.uuid

            const valueAssignedTo = keyValues?.find((element) => element.value === findAssignedId)
            setAssignedTo(valueAssignedTo)

            const valueCategory = types.data?.find((element) => element.value === currentCategoryId)
            setCategory(valueCategory)

            const valueOrg = orgs.data?.find((element) => element.value === currentOrgUuid)
            setOrganisation(valueOrg)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    /**
     * Close or Re-Open the ticket
     */
    const doCloseOrReopenTicket = async () => {
        // Close or Re-Open the ticket
        // Add any final updates to the ticket
        try {
            const closeRes = await closeOrOpenTicketFunc(ticketId, isTicketClosed, assignedTo.value, category.value, organisation.value, newMessage, mentions)

            if (closeRes.data?.success) {
                closeModal(isTicketClosed)

                // ticket will now be closed so go back to main list
                if (!isTicketClosed) navigate("/tickets")
            }
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        }
    }

    const onAssignedToChange = async (e) => {
        setAssignedTo(e)
    }

    const onCategoryChange = async (e) => {
        setCategory(e)
    }

    const onOrgChange = async (e) => {
        setOrganisation(e)
    }

    const closeModalFunc = () => {
        closeModal(false)
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()

        getSettings(abortController)

        // Clean up function
        return () => {
            abortController.abort()
        }
    }, [ticketId])

    return (
        <ConfirmDialog
            isOpen={true}
            title={`Confirm Ticket ${isTicketClosed ? "Re-Opening" : "Closing"}`}
            noLabel='Cancel'
            yesLabel={isTicketClosed ? "Open Ticket" : "Close Ticket"}
            onNoClick={closeModalFunc}
            onClose={closeModalFunc}
            yesDisabled={!newMessage || !assignedTo || !organisation}
            onYesClick={doCloseOrReopenTicket}
            fullWidth={true}
            applyOverflowFix={true}
        >
            <div className='flex-column flex-gap-16 width-100'>
                <div className='flex-column flex-gap-2 width-100'>
                    <p>Assigned To</p>
                    <Select
                        options={adminUserOptions}
                        className='width-100 react-select-container'
                        filterOption={selectFilter()}
                        onChange={onAssignedToChange}
                        value={assignedTo}
                        theme={selectTheme}
                    />
                </div>
                <div className={`flex-column flex-gap-2 width-100 ${isTicketClosed ? "hidden" : ""}`}>
                    <p>Category</p>
                    <Select
                        options={categoryOptions}
                        className='width-100 react-select-container'
                        filterOption={selectFilter()}
                        onChange={onCategoryChange}
                        value={category}
                        theme={selectTheme}
                    />
                </div>

                <div className={`flex-column flex-gap-2 width-100 ${needsNewOrg ? "" : "hidden"}`}>
                    <p>Organisation</p>
                    <Select
                        options={orgOptions}
                        className='width-100 react-select-container'
                        filterOption={selectFilter()}
                        onChange={onOrgChange}
                        value={organisation}
                        theme={selectTheme}
                        required={needsNewOrg}
                        maxMenuHeight={200}
                    />
                </div>

                <div className='flex-column flex-gap-2 width-100'>
                    <p>Reason for {isTicketClosed ? "Opening" : "Closing"}</p>
                    <SlateTextArea
                        className='close-ticket-text-area rs-input'
                        value={newMessage}
                        rows={5}
                        autoFocus={true}
                        placeholder='Enter a new message here...'
                        onChange={(event) => {
                            setNewMessage(event.target.value)
                            setMentions([...event.target.mentions])
                        }}
                        userOptions={userOptions}
                    />
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default AdminConfirmCloseTicket
