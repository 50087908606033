import React from "react"
import Divider from "../dividers/Divider"

/**
 * A Basic header
 * @param {{
 * TitleComponent: () => {}
 * title: string,
 * subtitle: string|undefined,
 * className: string|undefined,
 * subtitleClassname: string|undefined,
 * paddingBottom: number
 * showDivider: boolean
 * }}} param0
 * @returns
 */
function BaseHeader({
    TitleComponent,
    title,
    subtitle = undefined,
    className = undefined,
    subtitleClassName = undefined,
    paddingBottom = 0,
    showDivider = true,
}) {
    return (
        <hgroup className={className}>
            <TitleComponent>{title}</TitleComponent>
            {subtitle ? <p className={subtitleClassName}>{subtitle}</p> : null}
            {showDivider ? <Divider paddingTop={16} paddingBottom={paddingBottom} /> : null}
        </hgroup>
    )
}

/**
 * H1 header
 */
export function Header1(props) {
    return <BaseHeader TitleComponent={({ children }) => <h1 className={props.titleClassName}>{children}</h1>} {...props} />
}

/**
 * H2 header
 */
export function Header2(props) {
    return <BaseHeader TitleComponent={({ children }) => <h2 className={props.titleClassName}>{children}</h2>} {...props} />
}

/**
 * H3 header
 */
export function Header3(props) {
    return <BaseHeader TitleComponent={({ children }) => <h3 className={props.titleClassName}>{children}</h3>} {...props} />
}

/**
 * H4 header
 */
export function Header4(props) {
    return <BaseHeader TitleComponent={({ children }) => <h4 className={props.titleClassName}>{children}</h4>} {...props} />
}
