import React from "react"

/**
 * Basic divider line
 */
const Divider = ({ padding = 16, paddingTop = undefined, paddingBottom = undefined, colour = "black", width = undefined, className = undefined }) => {
    paddingTop = isNaN(paddingTop) ? padding : paddingTop
    paddingBottom = isNaN(paddingBottom) ? padding : paddingBottom

    const styles = {
        padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
    }
    if (width) styles.width = `${isNaN(width) ? width : width + "%"}`

    return (
        <div style={styles} className={`divider-line ${className ?? ""}`}>
            <div className={colour === "white" ? "divider-line-white" : "divider-line-black"} />
        </div>
    )
}

export default Divider
