import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

import AddBoxIcon from "@mui/icons-material/AddBox"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import EditIcon from "@mui/icons-material/Edit"

import DataTable from "../../../components/mui/DataTable"
import { Header2 } from "../../../components/headers/Headers"
import MetaData from "../../../components/MetaData"
import ButtonMenu from "../../../components/mui/ButtonMenu"
import ConfirmDialog from "../../../components/mui/ConfirmDialog"
import Tooltip from "../../../components/mui/Tooltip"
import { dateFormat, dateTimeFormat } from "../../../components/utility/ConvertTime"
import { deleteOrganisationGroupFunc, getOrganisationGroupListFunc } from "../../../components/httpRequests/OrganisationGroupRequests"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"

/**
 * Basic column with a link
 */
const basicColumns = (params) => {
    return <Link to={`/admin/groups/${params.id}`}>{params.value}</Link>
}

/**
 * Formated column with a link and tool tip
 */
const formatedColumnsWithTip = (id, formatedValue, toolTip) => {
    return (
        <Tooltip text={toolTip}>
            <Link to={`/admin/groups/${id}`}>{formatedValue}</Link>
        </Tooltip>
    )
}

/**
 * Groups list
 */
const Groups = () => {
    const navigate = useNavigate()
    const { addSnackbarMessage } = useAlertContext()
    const [groups, setGroups] = useState(null)
    const [deleteItem, setDeleteItem] = useState(null)

    const getInitialData = async (abortController) => {
        try {
            const res = await getOrganisationGroupListFunc(abortController)
            setGroups(res.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Set the delete item (menu clicked)
     */
    const deleteItemMenuClick = (groupUuid) => {
        const item = groups?.find((d) => d.uuid === groupUuid)
        if (!item) return
        setDeleteItem(item)
    }

    const handelCloseModal = () => {
        setDeleteItem(null)
    }

    /**
     * Send the delete to the server
     */
    const deleteItemConfirm = async () => {
        if (deleteItem == null) return

        const res = await deleteOrganisationGroupFunc(deleteItem.uuid)
        handelCloseModal()
        if (res.status === 200) addSnackbarMessage("Group successfully deleted.", AlertTypes.Success)
        await getInitialData()
    }

    /**
     * Edit item menu clicked
     */
    const editItemMenuClick = (itemUuid) => {
        navigate(`/admin/groups/${itemUuid}/edit`)
    }

    useEffect(() => {
        getInitialData()
    }, [])

    /**
     * Data Table fields
     */
    const columns = [
        {
            field: "uuid",
            headerName: "Id",
            flex: 1,
            renderCell: basicColumns,
        },
        {
            field: "name",
            headerName: "Group Name",
            hideable: false,
            flex: 0.5,
            renderCell: basicColumns,
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            minWidth: 100,
            renderCell: basicColumns,
        },
        {
            field: "createdAt",
            headerName: "Created",
            flex: 0.5,
            minWidth: 50,
            renderCell: (params) => formatedColumnsWithTip(params.id, dateFormat(params.value), dateTimeFormat(params.value)),
        },
        {
            field: "updatedAt",
            headerName: "Updated",
            flex: 0.5,
            minWidth: 50,
            renderCell: (params) => formatedColumnsWithTip(params.id, dateFormat(params.value), dateTimeFormat(params.value)),
        },
        {
            field: "organisationCount",
            headerName: "Organisations",
            description: "The number of organisations in this group",
            type: "number",
            flex: 0.5,
            minWidth: 50,
            renderCell: basicColumns,
        },
        {
            field: "userCount",
            headerName: "Users",
            description: "The number of uses in this group",
            type: "number",
            flex: 0.5,
            minWidth: 50,
            renderCell: basicColumns,
        },
        {
            field: "__actions",
            headerName: "...",
            flex: 1,
            hideable: false,
            headerAlign: "right",
            align: "right",
            maxWidth: 50,
            renderCell: (params) => (
                <>
                    <ButtonMenu
                        id={params.id}
                        menuItems={[
                            {
                                label: "Edit",
                                icon: EditIcon,
                                func: () => editItemMenuClick(params.id),
                            },
                            {
                                label: "Delete",
                                icon: DeleteForeverIcon,
                                func: () => deleteItemMenuClick(params.id),
                            },
                        ]}
                    />
                </>
            ),
        },
    ]

    return (
        <>
            <ConfirmDialog
                isOpen={deleteItem != null}
                title={`Delete ${deleteItem?.name}`}
                description='Are you sure that you want to permanently delete this item?'
                onClose={handelCloseModal}
                onNoClick={handelCloseModal}
                onYesClick={deleteItemConfirm}
            />

            <div className='page-wrapper'>
                <MetaData title={`Groups`} />
                <div className='padding-16 divider-line-black-bottom'>
                    <Link to='/admin/groups/new' style={{ textDecoration: "none" }} className='button-contained'>
                        <AddBoxIcon />
                        New Group
                    </Link>
                </div>
                <div className='padding-16'>
                    <Header2 title='Groups' />

                    <DataTable
                        tableId={"groups-table"}
                        rows={groups}
                        columns={columns}
                        getRowId={(u) => u.uuid}
                        defaultColumnVisibility={{
                            uuid: false,
                            createdAt: false,
                            updatedAt: false,
                        }}
                        checkboxSelection={false}
                        hideFooter={true}
                    />
                </div>
            </div>
        </>
    )
}

export default Groups
