import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import CloseIcon from "@mui/icons-material/Close"
import ReplayIcon from "@mui/icons-material/Replay"
import AdminConfirmCloseTicket from "./AdminConfirmCloseTicket"
import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import NotificationsIcon from "@mui/icons-material/Notifications"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import MoreTimeIcon from "@mui/icons-material/MoreTime"
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory"

import "../styling/tickets.css"

import { axiosErrorMessage } from "../../components/axios"
import useUser from "../../hooks/useUser"
import useAlertContext, { AlertTypes } from "../../hooks/useAlertContext"

import ProfilePhoto from "../../components/ProfilePhoto"
import { dateTimeFormat, HowLongAgo } from "../../components/utility/ConvertTime"
import TicketSidebar from "./TicketSidebar"
import TicketAdminLog from "./TicketAdminLog"
import Tick from "../../components/notifications/Tick"
import Cross from "../../components/notifications/Cross"
import LogTimePopup from "../../components/LogTimePopup"
import { inputMaxLength } from "../../Constants"
import Tooltip from "../../components/mui/Tooltip"
import IconButton from "../../components/mui/IconButton"
import MarkdownWrapper from "../../components/MarkdownWrapper"
import ConfirmDialog from "../../components/mui/ConfirmDialog"
import Loading from "../../components/Loading"
import MetaData from "../../components/MetaData"
import ErrorBoundary from "../../components/errors/ErrorBoundary"
import ErrorBlock from "../../components/errors/ErrorBlock"
import {
    closeOrOpenTicketFunc,
    getSingleTicketFunc,
    patchTicketFlageFunc,
    patchTicketReadFunc,
    patchTicketSubscribeFunc,
    patchTicketTitleFunc,
} from "../../components/httpRequests/TicketsRequests"
import TicketMessageEditor from "./TicketMessageEditor"
import TicketMessageInput from "./TicketMessageInput"
import { uuidValuePairsToSelectOptions } from "../../common/selectHelpers"
import FileIcon from "../../components/FileIcon"
import FlagIcon, { flagIdToLabel, maxFlagIcons } from "../../components/FlagIcon"
import PoupMenu from "../../components/mui/PoupMenu"

/**
 * The open/ close ticket button
 */
const CloseTicketButton = ({ isTicketClosed, onClick }) => {
    return isTicketClosed ? (
        <button onClick={onClick} className='button-contained-success'>
            <ReplayIcon /> Open Ticket
        </button>
    ) : (
        <button onClick={onClick} className='button-contained-danger'>
            <CloseIcon /> Close Ticket
        </button>
    )
}

/**
 * The flag button
 */
const FlagTicketButton = ({ flag, onClick }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const menuItems = []
    for (let i = 0; i <= maxFlagIcons; i++) {
        menuItems.push({
            id: i,
            icon: <FlagIcon flag={i} fontSize='small' />,
            label: flagIdToLabel(i),
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClick = (item) => {
        setAnchorEl(null)
        if (item) onClick(item?.id ?? 0, item?.label)
    }

    return (
        <>
            <button onClick={handleClick} className='button-outlined-blue-square'>
                <FlagIcon flag={flag} showIfZero={true} />
            </button>
            <PoupMenu anchorEl={anchorEl} onClick={handleMenuClick} menuItems={menuItems} />
        </>
    )
}

/**
 * The Subscribe / UnSubscribe button
 */
const SubscribeButton = ({ isUserSubscribed, onClick }) => {
    return isUserSubscribed ? (
        <Tooltip text='You are subscribed to notifications for this ticket.'>
            <button onClick={onClick} className='button-outlined-blue-square'>
                <NotificationsActiveIcon />
                <Tick />
            </button>
        </Tooltip>
    ) : (
        <Tooltip text='You are not subscribed to notifications for this ticket.'>
            <button onClick={onClick} className='button-outlined-blue-square'>
                <NotificationsIcon />
                <Cross />
            </button>
        </Tooltip>
    )
}

/**
 * the admin ticket logs button
 */
const AdminTicketLogsIcon = ({ onClick }) => {
    return (
        <Tooltip text='Ticket Change History'>
            <button onClick={onClick} className='button-outlined-blue-square'>
                <ChangeHistoryIcon />
            </button>
        </Tooltip>
    )
}

/**
 * the admin log time button
 */
const AdminLogTimeIcon = ({ onClick }) => {
    return (
        <Tooltip text='Log Time'>
            <button onClick={onClick} className='button-outlined-blue-square'>
                <MoreTimeIcon />
            </button>
        </Tooltip>
    )
}

const defaultTitleEditor = { title: undefined, originalTitle: undefined, editing: false }
Object.freeze(defaultTitleEditor)

/**
 * The main ticket page
 * TODO break this down into chunks
 */
const Ticket = () => {
    const { addSnackbarMessage } = useAlertContext()
    const { ticketId } = useParams()
    const { auth, hasAuthRole_TechnicianOrAbove, hasAuthRole_Admin, setCurrentTicketInfo, setCurrentOrgUuid } = useUser()
    const navigate = useNavigate()

    const [ticketInfo, setTicketInfo] = useState(null)
    const [ticketTags, setTicketTags] = useState(null)
    const [isTicketClosed, setIsTicketClosed] = useState(false)
    const [isUserSubscribed, setIsUserSubscribed] = useState(false)

    const [isChangeHistoryOpen, setIsChangeHistoryOpen] = useState(false)

    const [isLogTimePopupOpen, setIsLogTimePopupOpen] = useState(false)
    const [confirmCloseOpen, setConfirmCloseOpen] = useState(false)

    const [openAdminCloseTicket, setOpenAdminCloseTicket] = useState(false)
    const [editTitle, setEditTitle] = useState({ ...defaultTitleEditor })

    let lastMessageUserUuid = null
    let lastMessageType = null
    let hasRead = false

    const isAdmin = hasAuthRole_Admin() ?? false
    const isTechnicianOrAbove = hasAuthRole_TechnicianOrAbove() ?? false

    /**
     * Find the ticket
     */
    const findQuery = async (abortController) => {
        if (isNaN(ticketId)) {
            navigate("/tickets")
            return
        }

        try {
            const ticket = await getSingleTicketFunc(ticketId, abortController)
            if (!ticket) {
                addSnackbarMessage("Ticket not found", AlertTypes.Error)
                navigate("/tickets")
            } else {
                const ticketRtn = ticket.data.ticketRtn

                setTicketInfo(ticketRtn)
                setIsTicketClosed(ticketRtn?.closedOn ? true : false)
                setIsUserSubscribed(ticketRtn?.isUserSubscribed ?? false)
                setTicketTags(ticket.data.ticketTags)
                if (!hasRead) {
                    try {
                        await patchTicketReadFunc(ticketId, abortController)
                    } catch (e) {
                        // Dont fail on this, its not that important
                        console.error(e)
                    }
                    hasRead = true
                }
                setCurrentTicketInfo(ticketRtn)
                setCurrentOrgUuid(ticketRtn?.orgId ?? null)
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e, "Ticket not available"), AlertTypes.Error)
                navigate("/tickets")
            }
        }
    }

    /**
     * Open and close ticket event
     */
    const handleTicketClosing = async () => {
        if (hasAuthRole_TechnicianOrAbove()) {
            setOpenAdminCloseTicket(true)
        } else {
            setConfirmCloseOpen(true)
        }
        //COO = Close Or Open Bool
    }

    /**
     * The ticket was flagged
     */
    const handleTicketFlagged = async (flagId, flagReason = undefined) => {
        if (isNaN(ticketId)) return

        try {
            const response = await patchTicketFlageFunc(ticketId, flagId, flagReason)
            if (response.data.success) findQuery()
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e, "Ticket not available"), AlertTypes.Error)
        }
    }

    /**
     * Toggles the ticket change history open / closed
     */
    const toggleTicketChangeHistory = () => {
        setIsChangeHistoryOpen((currentValue) => !currentValue)
    }

    /**
     * Toggles the ticket log time open / closed
     */
    const toggleOpenLogTimePopup = () => {
        setIsLogTimePopupOpen((currentValue) => !currentValue)
    }

    /**
     * Subscribing event
     */
    const handleTicketSubscribing = async () => {
        const response = await patchTicketSubscribeFunc(ticketId, !isUserSubscribed)
        if (response.data.success) findQuery()
    }

    /**
     * Save the title
     */
    const saveTitle = async () => {
        // Make sure we have a tile
        if (editTitle && !editTitle.title) return

        try {
            if (!editTitle || !editTitle.editing) return
            if (editTitle.title === editTitle.originalTitle) return

            await patchTicketTitleFunc(ticketId, editTitle?.title?.trim())
            findQuery()
        } finally {
            setEditTitle({ ...defaultTitleEditor })
        }
    }

    /**
     * Delete the ticket
     */
    const yesConfirmClickAsync = async () => {
        try {
            const response = await closeOrOpenTicketFunc(ticketInfo.id, isTicketClosed)
            if (response.data.success) findQuery()
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            closeConfirmClick()
        }
    }

    /**
     * Close the confirm dialog
     */
    const closeConfirmClick = () => {
        setConfirmCloseOpen(false)
    }

    /**
     * Called when the admin confirm popup needs to close
     */
    const onCloseAdminConfirmModal = (shouldRefreshData) => {
        setOpenAdminCloseTicket(false)
        if (shouldRefreshData) {
            findQuery()
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        findQuery(abortController)

        return () => {
            abortController.abort()
            setCurrentTicketInfo(null)
            setCurrentOrgUuid(null)
        }
    }, [ticketId])

    // If the admin size is not open and we are not in small screen, then make the wrapper full width by adjusting the maring to -250px

    return (
        <div className='page-wrapper'>
            {openAdminCloseTicket && (
                <AdminConfirmCloseTicket
                    closeModal={onCloseAdminConfirmModal}
                    ticketId={ticketId}
                    isTicketClosed={isTicketClosed}
                    currentAssignedId={ticketInfo.assignedToId}
                    currentCategoryId={ticketInfo.categoryId}
                    currentOrgUuid={ticketInfo.orgId}
                />
            )}
            {/* Open/Close Ticket dialog */}
            <ConfirmDialog
                isOpen={confirmCloseOpen}
                title={`${isTicketClosed ? "Open" : "Closed"} Ticket`}
                description={`Are you sure that you want to ${isTicketClosed ? "open" : "close"} this ticket?`}
                onYesClick={yesConfirmClickAsync}
                onNoClick={closeConfirmClick}
                onClose={closeConfirmClick}
            />

            {/* Main page */}
            <div className='ticket-page-wrapper'>
                {ticketInfo ? (
                    <ErrorBoundary fallback={<ErrorBlock error='Unable to load ticket details' />}>
                        <div className='st-full-page-wrapper'>
                            <MetaData title={`[#${ticketId}] ${ticketInfo?.name ?? "No Title"}`} />
                            <div className='st-outer-wrapper'>
                                <div className='st-inner-top-wrapper'>
                                    <div className='st-top-title flex-100'>
                                        {!editTitle.editing ? (
                                            <h1>
                                                {ticketInfo.name}
                                                {isTechnicianOrAbove && (
                                                    <IconButton
                                                        hintText='Edit ticket title.'
                                                        onClick={() => {
                                                            setEditTitle({
                                                                ...defaultTitleEditor,
                                                                title: ticketInfo.name,
                                                                originalTitle: ticketInfo.name,
                                                                editing: true,
                                                            })
                                                        }}
                                                    >
                                                        <EditIcon className='faded-icon-small' />
                                                    </IconButton>
                                                )}
                                            </h1>
                                        ) : (
                                            <div className='flex-row-center-vertical width-100'>
                                                <input
                                                    type='text'
                                                    required
                                                    value={editTitle.title}
                                                    className='rs-input flex flex-100'
                                                    onChange={(e) =>
                                                        setEditTitle((currentValue) => {
                                                            return { ...currentValue, title: e.target.value }
                                                        })
                                                    }
                                                    maxLength={inputMaxLength.ticketTitle}
                                                />
                                                <IconButton hintText='Save ticket title.' onClick={saveTitle} color='success'>
                                                    <CheckIcon />
                                                </IconButton>
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex-row flex-row-spacebetween st-top-button-wrapper'>
                                        <CloseTicketButton isTicketClosed={isTicketClosed} onClick={handleTicketClosing} />
                                        <FlagTicketButton flag={ticketInfo?.flag} onClick={handleTicketFlagged} />
                                        {isAdmin ? (
                                            <>
                                                <AdminTicketLogsIcon onClick={toggleTicketChangeHistory} />
                                                <TicketAdminLog ticketId={ticketId} onClose={toggleTicketChangeHistory} isOpen={isChangeHistoryOpen} />
                                            </>
                                        ) : null}
                                        {isTechnicianOrAbove ? (
                                            <>
                                                <AdminLogTimeIcon onClick={toggleOpenLogTimePopup} />
                                                <LogTimePopup onClose={toggleOpenLogTimePopup} ticketId={ticketId} isOpen={isLogTimePopupOpen} />
                                            </>
                                        ) : null}
                                        <SubscribeButton onClick={handleTicketSubscribing} isUserSubscribed={isUserSubscribed} />
                                    </div>
                                </div>

                                {ticketInfo?.isPrivate ? (
                                    <div className='alert alert-danger flex-row-center border-radius-0'>
                                        <VisibilityOffIcon /> Admin Ticket
                                    </div>
                                ) : null}

                                <div className='ticket-content-wrapper'>
                                    <div className='ticket-sidebar-wrapper'>
                                        <TicketSidebar
                                            ticketInfo={ticketInfo}
                                            findQuery={findQuery}
                                            ticketTags={ticketTags}
                                            isAdmin={isAdmin}
                                            isTechnician={isTechnicianOrAbove}
                                            isTicketClosed={isTicketClosed}
                                        />
                                    </div>
                                    <div className='ticket-messages-wrapper'>
                                        <div>
                                            <ErrorBoundary fallback={<ErrorBlock error='Unable to load ticket messages' />}>
                                                {ticketInfo.messages.map((tm, index) => {
                                                    const showHeader = tm.userUuid !== lastMessageUserUuid || lastMessageType !== tm.isPrivate
                                                    const isPrivate = tm.isPrivate ?? false
                                                    lastMessageUserUuid = tm.userUuid
                                                    lastMessageType = isPrivate

                                                    return (
                                                        <TicketMessage
                                                            key={tm.uuid}
                                                            message={tm}
                                                            isPrivate={isPrivate}
                                                            showHeader={showHeader}
                                                            isFirstMessage={index === 0}
                                                            isMessageFromCurrentUser={tm.userUuid === auth.uuid}
                                                            findQuery={findQuery}
                                                        />
                                                    )
                                                })}
                                            </ErrorBoundary>
                                        </div>
                                        <TicketMessageInput
                                            ticketId={ticketId}
                                            refreshTicketQuery={findQuery}
                                            isTicketClosed={isTicketClosed}
                                            currentAssignedId={ticketInfo?.assignedToId}
                                            userOptions={ticketInfo ? uuidValuePairsToSelectOptions(ticketInfo?.users) : undefined}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>
                ) : (
                    <div className='dashboard-full-notice'>
                        <p>Loading Ticket...</p>
                        <Loading />
                    </div>
                )}
            </div>
        </div>
    )
}

/**
 * A Single message in the ticket
 * @param {{message: {}, showHeader: boolean, isFirstMessage: boolean, isPrivate: boolean, isMessageFromCurrentUser: boolean, findQuery: () => {} }} param0
 * @returns
 */
const TicketMessage = ({ message, showHeader = true, isFirstMessage = false, isPrivate = false, isMessageFromCurrentUser = false, findQuery = undefined }) => {
    return (
        <div
            className={[
                isMessageFromCurrentUser ? "ticket-message-wrapper-personal" : "",
                isFirstMessage ? "tm-first-message" : "",
                isPrivate ? "alert alert-danger border-radius-0" : "",
                "ticket-message-wrapper",
            ].join(" ")}
        >
            <ErrorBoundary fallback={<ErrorBlock error='Unable to load ticket message' />}>
                {showHeader ? (
                    <div className='tm-header flex-spacebetween'>
                        <div className='flex-row-center-vertical'>
                            <ProfilePhoto userUuid={message.userUuid} userName={message.userName} />
                            <b>{message.userName}</b>
                        </div>
                        {isPrivate && (
                            <IconButton hintText='Private Admin Message'>
                                <VisibilityOffIcon className='faded-icon' />
                            </IconButton>
                        )}
                    </div>
                ) : null}

                <div className='flex-column ticket-message'>
                    <MarkdownWrapper>{message.message}</MarkdownWrapper>
                    <FileList files={message.files} messageUuid={message.uuid} />
                    <div className='date-time-text'>
                        <TicketMessageEditor ticketMessage={message} findQuery={findQuery} />
                        <div>
                            <Tooltip text={dateTimeFormat(message.createdAt)}>
                                <p id='tm-datetime'>
                                    Sent {HowLongAgo(message.createdAt)}
                                    {message.editedAt && <i> - Message has been edited</i>}
                                </p>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    )
}

/**
 * List of files attached to a message
 * @param {{files: array, messageUuid: string}} param0
 */
const FileList = ({ files, messageUuid }) => {
    if (!Array.isArray(files)) return null
    if (files.length === 0) return null
    if (!messageUuid) return null

    return (
        <div className='flex-row margin-bottom-8'>
            {files.map((f) => {
                const url = `/api/public/ticket-attachment/${encodeURIComponent(messageUuid)}/${encodeURIComponent(f.uuid)}/${encodeURIComponent(f.filename)}`
                return (
                    <span key={f.uuid} className='flex-row-center-vertical pill pill-primary'>
                        <Link to={url} target='_blank' className='flex-row-center-vertical'>
                            <FileIcon fileName={f.filename} fontSize='small' />
                            <span>{f.filename}</span>
                        </Link>
                    </span>
                )
            })}
        </div>
    )
}

export default Ticket
