import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Select from "react-select"

import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import { axiosErrorMessage } from "../../../components/axios"
import Loading from "../../../components/Loading"
import MetaData from "../../../components/MetaData"
import { Header2 } from "../../../components/headers/Headers"
import { inputMaxLength } from "../../../Constants"
import { getOrganisationGroupItemFunc, patchOrganisationGroupFunc, postOrganisationGroupFunc } from "../../../components/httpRequests/OrganisationGroupRequests"
import { formatExtendedLabel, selectFilter, selectTheme, uuidValuePairsToSelectOptions } from "../../../common/selectHelpers"
import RequiredFieldHint from "../../../components/forms/RequiredFieldHint"
import { getOrganisationKeyValueListFunc } from "../../../components/httpRequests/OrganisationRequests"

/**
 * New org group
 */
const NewGroup = () => {
    const navigate = useNavigate()
    const { groupUuid } = useParams()
    const { addSnackbarMessage } = useAlertContext()

    const [groupData, setGroupData] = useState(null)
    const [groupOrgs, setGroupOrgs] = useState([])

    const [orgList, setOrgList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    // If this is a new item or an edit
    const isNewItem = !groupUuid || groupUuid == null || groupUuid == undefined

    /**
     * Text input changed
     */
    const onInputChange = (e) => {
        const name = e.target.id
        setGroupData((currentValue) => {
            return { ...currentValue, [name]: e.target.value }
        })
    }

    /**
     * Gets the org data
     */
    const getOrganisationData = async (abortController) => {
        setIsLoading(true)
        try {
            const res = await getOrganisationKeyValueListFunc(abortController)
            setOrgList(res.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Gets the group data
     */
    const getEditData = async (abortController) => {
        setIsLoading(true)
        try {
            const res = await getOrganisationGroupItemFunc(groupUuid, abortController)
            setGroupData(res.data)
            setGroupOrgs(uuidValuePairsToSelectOptions(res.data.organisations, "orgUuid", "orgName"))
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Called when the form submits
     */
    const submitNewGroup = async (e) => {
        e.preventDefault()
        if (isLoading) return

        setIsLoading(true)
        try {
            const formData = {
                name: groupData.name,
                description: groupData.description,
                orgUuids: groupOrgs.map((so) => so.value),
            }

            let res
            if (!isNewItem) {
                formData.uuid = groupUuid
                res = await patchOrganisationGroupFunc(formData)
                addSnackbarMessage("Group Edited Successfully.", AlertTypes.Success)
            } else {
                res = await postOrganisationGroupFunc(formData)
                addSnackbarMessage("Group Created Successfully.", AlertTypes.Success)
            }

            const queryString = res?.data?.value ? `?id=${res.data?.value}` : ""
            navigate("/admin/groups" + queryString)
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Calledw hen the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getOrganisationData(abortController)
        if (!isNewItem) getEditData(abortController)

        // Clean up function
        return () => {
            abortController.abort()
        }
    }, [groupUuid])

    return (
        <div className='page-wrapper'>
            <MetaData title={`${isNewItem ? "New" : "Edit"} Group`} />
            {isLoading ? (
                <Loading />
            ) : (
                <form onSubmit={submitNewGroup} className='page-form'>
                    <Header2 title={isNewItem ? "New Group" : "Edit Group"} />
                    <RequiredFieldHint />

                    <div className='flex-row flex-column-small flex-gap-32'>
                        <div className='flex-column flex-gap-16 flex-100'>
                            <div className='label-input-wrapper'>
                                <label htmlFor='name'>Name </label>
                                <input
                                    type='text'
                                    id='name'
                                    name='name'
                                    className='rs-input'
                                    required
                                    maxLength={inputMaxLength.groupName}
                                    defaultValue={groupData?.name ?? ""}
                                    onChange={onInputChange}
                                />
                            </div>

                            <div className='label-input-wrapper'>
                                <label htmlFor='description'>Description</label>
                                <textarea
                                    id='description'
                                    name='description'
                                    className='rs-input'
                                    type='text'
                                    rows='5'
                                    defaultValue={groupData?.description ?? ""}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className='label-input-wrapper'>
                                <label htmlFor='nu-org'>Organisations</label>
                                <Select
                                    inputId='nu-org'
                                    options={orgList}
                                    value={groupOrgs ?? []}
                                    formatOptionLabel={formatExtendedLabel}
                                    onChange={(e) => setGroupOrgs(e)}
                                    filterOption={selectFilter()}
                                    isMulti
                                    required
                                    className='react-select-container'
                                    isClearable={true}
                                    theme={selectTheme}
                                />
                            </div>
                            <br />
                        </div>
                    </div>

                    <div className='button-input-wrapper'>
                        <button className='button-contained' type='submit' disabled={!groupData?.name || isLoading}>
                            {isNewItem ? "Create New " : "Save "} Group
                        </button>
                    </div>
                </form>
            )}
        </div>
    )
}

export default NewGroup
