import React from "react"
import Select from "react-select"
import { formatTagsLabel, selectFilter, selectTheme } from "../../../common/selectHelpers"

/**
 * Statistics column
 */
const StatsColumn = ({ columnData, index, updateData, categories, tags }) => {
    const setItemsChanged = (options, key) => {
        updateData(index, key, options)
    }

    return (
        <div className='flex-row'>
            <div className='label-input-wrapper'>
                <label htmlFor='categories'>Ticket Categories</label>
                <Select
                    inputId='categories'
                    name='categories'
                    required
                    isMulti
                    options={categories ?? undefined}
                    filterOption={selectFilter()}
                    onChange={(e) => setItemsChanged(e, "categories")}
                    isLoading={!categories}
                    value={columnData.categories}
                    theme={selectTheme}
                    className='react-select-container'
                />
            </div>

            <div className='label-input-wrapper'>
                <label htmlFor='tagFilters'>Tag Filters</label>
                <Select
                    inputId='tagFilters'
                    name='tagFilters'
                    required
                    isMulti
                    options={tags ?? undefined}
                    filterOption={selectFilter()}
                    isClearable={true}
                    onChange={(e) => setItemsChanged(e, "tagFilters")}
                    isLoading={!tags}
                    value={columnData.tagFilters}
                    theme={selectTheme}
                    formatOptionLabel={formatTagsLabel}
                    className='react-select-container'
                />
            </div>
        </div>
    )
}

export default StatsColumn
