import { Avatar, AvatarGroup, Tooltip } from "@mui/material"

/**
 * Converts the passed string into a html colour
 * @param {string} string
 */
function stringToColor(string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = "#"

    for (i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}

/**
 * Gets the first letter of the passed string or blank ifs not a letter
 * @param {string} str
 */
function toLetterOrBlank(str) {
    const char = str?.charAt(0) ?? ""
    return char.length === 1 && char.match(/[a-zA-Z0-9]/i) ? char : ""
}

/**
 * Gets the props form the user avatar
 * @param {string} name
 * @param {string} size Avatar size 'small' | 'medium' | 'large'
 */
function stringAvatar(name, size) {
    const nameSplit = typeof name === "string" ? name.split(" ") : ["", ""]
    const letter1 = toLetterOrBlank(nameSplit[0] ?? "")
    const letter2 = toLetterOrBlank(nameSplit[1] ?? "")

    let sizePx = 32
    let fontSize = 16
    switch (size) {
        case "small":
            sizePx = 24
            fontSize = 12
            break
        case "large":
            sizePx = 56
            fontSize = 24
            break
    }

    const bdColor = stringToColor(name)
    return {
        sx: {
            bgcolor: bdColor,
            width: sizePx,
            height: sizePx,
            fontSize: fontSize,
        },
        children: `${letter1}${letter2}`,
    }
}

/**
 * User profile photo
 * size 'small' | 'medium' | 'large'
 * @param {{ userName:string, size:string, showToolTip:boolean, className:string|undefined }} param0
 */
const ProfilePhoto = ({ userName = "", size = "medium", showToolTip = false, className = undefined }) => {
    return typeof userName === "string" ? (
        <Tooltip title={userName} open={showToolTip ? undefined : false}>
            <Avatar {...stringAvatar(userName ?? "Username", size)} alt={userName} className={className} />
        </Tooltip>
    ) : null
}

/**
 * A group of users
 * @param {{users:array, max:number|undefined}}} param0
 */
export const ProfilePhotoGroup = ({ users, max = 5 }) => {
    const props = stringAvatar("")

    return (
        <div className='flex profile-group'>
            <AvatarGroup
                max={max}
                spacing='small'
                sx={{
                    "& .MuiAvatar-root": { width: props.sx.width, height: props.sx.height, fontSize: props.sx.fontSize },
                }}
            >
                {users?.map((u) => (
                    <ProfilePhoto key={u.uuid} userName={u.value} userUuid={u.uuid} showToolTip={true} />
                ))}
            </AvatarGroup>
        </div>
    )
}

export default ProfilePhoto
