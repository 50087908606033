import React from "react"
import { useNavigate } from "react-router-dom"
import useTicketFilter from "../hooks/useTicketFilter"
import { inProgressFilter, relativeDates } from "../common/searchFilters"

/**
 * Organisation KPIs
 */
function OrganisationKPIs({ orgUuid, ticketsLoggedPastWeek = 0, ticketsLoggedPastMonth = 0 }) {
    const navigate = useNavigate()
    const { setFilterSettings } = useTicketFilter()

    const customFilter = (openedRelativeDate) => {
        const filter = { ...inProgressFilter }
        filter.filterName = "Organisation KPIs"
        filter.organisations = [orgUuid]
        filter.ticketStatus = []
        filter.openedRelativeDate = openedRelativeDate
        return filter
    }

    const onPastWeekClick = () => {
        setFilterSettings(customFilter(relativeDates.past7Days))
        navigate("/tickets")
    }

    const onPastMonthClick = () => {
        setFilterSettings(customFilter(relativeDates.past30Days))
        navigate("/tickets")
    }

    return (
        <div className='flex-row'>
            <div className='flex-center-item KPI-wrapper clickable' onClick={onPastWeekClick}>
                <p>
                    <span className='kpi-title'>Tickets Logged Past Week</span>
                    <br />
                    <span>{ticketsLoggedPastWeek}</span>
                </p>
            </div>
            <div className='flex-center-item KPI-wrapper clickable' onClick={onPastMonthClick}>
                <p>
                    <span className='kpi-title'>Tickets Logged Past Month</span>
                    <br />
                    <span>{ticketsLoggedPastMonth}</span>
                </p>
            </div>
        </div>
    )
}

export default OrganisationKPIs
