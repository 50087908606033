import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Select from "react-select"
import AddBoxIcon from "@mui/icons-material/AddBox"

import "../../styling/users.css"

import { Header2 } from "../../../components/headers/Headers"
import axios, { axiosErrorMessage } from "../../../components/axios"
import DataTable, { CommonColumnUserRole } from "../../../components/mui/DataTable"
import MetaData from "../../../components/MetaData"
import { dateTimeFormat } from "../../../components/utility/ConvertTime"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import LabelledCheckbox from "../../../components/forms/LabelledCheckbox"
import useDebounceState from "../../../hooks/useDebounceState"
import { formatExtendedLabel, selectFilter, selectTheme } from "../../../common/selectHelpers"
import ProfilePhoto from "../../../components/ProfilePhoto"

const basicCellFormat = (params) => <Link to={`/admin/user/${params.id}`}>{params.value}</Link>

/**
 * Data Table fields
 */
const columns = [
    {
        field: "uuid",
        headerName: "Id",
        flex: 1,
        renderCell: basicCellFormat,
    },
    {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 100,
        hideable: false,
        renderCell: (params) => (
            <Link to={`/admin/user/${params.id}`} className='flex-row-center-vertical'>
                <ProfilePhoto userUuid={params.row.uuid} userName={params.value} size='small' />
                {!params.row.enabled && (
                    <span className='pill-danger' style={{ marginRight: "4px" }}>
                        DISABLED
                    </span>
                )}
                {params.value}{" "}
            </Link>
        ),
    },
    {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 250,
        renderCell: basicCellFormat,
    },
    {
        field: "phone",
        headerName: "Phone",
        flex: 1,
        minWidth: 150,
        renderCell: basicCellFormat,
    },
    {
        field: "role",
        headerName: "Global Role",
        flex: 1,
        renderCell: CommonColumnUserRole,
    },
    {
        field: "lastSeen",
        headerName: "Last Seen",
        flex: 1,
        minWidth: 50,
        renderCell: (params) => <Link to={`/admin/user/${params.id}`}>{dateTimeFormat(params.value, "never")}</Link>,
    },
    {
        field: "enabled",
        headerName: "Enabled",
        flex: 0.5,
        type: "boolean",
        minWidth: 50,
    },
    {
        field: "twoFactorAuthEnabled",
        headerName: "2FA Enabled",
        flex: 0.5,
        type: "boolean",
        minWidth: 50,
    },
    {
        field: "ticketsOpened",
        headerName: "Tickets Opened",
        description: "The number of tickects opened in total",
        type: "number",
        flex: 1,
        minWidth: 50,
    },
]

/**
 * Users page
 */
const Users = () => {
    const { addSnackbarMessage } = useAlertContext()
    const [users, setUsers] = useDebounceState("")

    const [filteredUsers, setFilteredUsers] = useState(null)
    const [showDisabled, setShowDisabled] = useState(false)
    const [searchValue, setSearchValue] = useDebounceState("")

    const [groupValue, setGroupValue] = useState(null)
    const [groupValues, setGroupValues] = useState([])

    /**
     * Get the users from the API
     */
    const getUsersList = async (abortController) => {
        try {
            const response = await axios.get(`/api/user/all`, { signal: abortController?.signal })
            setUsers(response?.data)
            setGroupValues(getGroups(response?.data))
            setFilteredUsers(getNewFilterArray(response.data, showDisabled, searchValue, groupValue))
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    /**
     * Gets unique list of groups from the passed org array
     */
    const getGroups = (orgArr) => {
        if (!Array.isArray(orgArr)) return []
        if (orgArr.length === 0) return []
        const groups = []
        const groupIds = []

        // find a unique list of groups
        orgArr.forEach((o) => {
            if (!Array.isArray(o.userGroups)) return
            o.userGroups.forEach((g) => {
                if (groupIds.includes(g.value)) return
                groupIds.push(g.value)
                groups.push({ value: g.value, label: g.label })
            })
        })
        return groups
    }

    /**
     * check if searchValueLC is in org
     */
    const userIncludes = (item, searchValue) => {
        const searchValueLC = searchValue?.toLowerCase()?.trim()
        if (!searchValueLC) return true
        if (!item) return true
        // get the search string, lowercase and trimed
        const searchStr = `${item.name ?? ""} ${item.role ?? ""} ${item.email ?? ""} ${item.phone ?? ""}`.toLowerCase().trim()
        return searchStr.includes(searchValue)
    }

    /**
     * Filters the array
     */
    const getNewFilterArray = (arr, showDisabled, searchValue, groupValue) => {
        let filteredArr = arr.filter((t) => showDisabled || t?.enabled)

        if (groupValue) {
            // filter to just the group id
            filteredArr = filteredArr.filter((t) => t.userGroups.some((g) => g.value === groupValue.value))
        }

        return !searchValue?.trim() ? filteredArr : filteredArr.filter((o) => userIncludes(o, searchValue))
    }

    /**
     * Search input changed
     */
    useEffect(() => {
        if (users) setFilteredUsers(getNewFilterArray(users, showDisabled, searchValue, groupValue))
    }, [showDisabled, searchValue, groupValue])

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getUsersList(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [])

    return (
        <div className='page-wrapper'>
            <MetaData title={`Users`} />

            <div className='padding-16 divider-line-black-bottom flex-row flex-column-small'>
                <div className='flex-20'>
                    <Link to='/admin/users/new' style={{ textDecoration: "none" }} className='button-contained'>
                        <AddBoxIcon />
                        New User
                    </Link>
                </div>
                <div className='flex-80'>
                    <div className='flex-row flex-column-small'>
                        <input
                            id='searchValue'
                            className='rs-input flex-70'
                            type='text'
                            placeholder='Search Here'
                            defaultValue={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value?.toLowerCase())
                            }}
                        />

                        <Select
                            inputId='nu-role'
                            options={groupValues}
                            formatOptionLabel={formatExtendedLabel}
                            value={groupValue}
                            onChange={(e) => setGroupValue(e)}
                            filterOption={selectFilter()}
                            theme={selectTheme}
                            isClearable
                            className='react-select-container flex-30'
                            placeholder='Group Search'
                        />
                    </div>
                    <LabelledCheckbox
                        onChange={(e) => {
                            setShowDisabled(e.target.checked)
                        }}
                        label='Show Disabled Users'
                        id='show-disabled'
                        className='padding-vertical-8'
                    />
                </div>
            </div>

            <div className='padding-16'>
                <Header2 title='Users' />

                <DataTable
                    tableId={"users-table"}
                    rows={filteredUsers}
                    columns={columns}
                    getRowId={(u) => u.uuid}
                    defaultColumnVisibility={{ uuid: false }}
                    checkboxSelection={false}
                    hideFooter={false}
                />
            </div>
        </div>
    )
}

export default Users
