import React, { useState } from "react"

import "./ComponentStyles.css"

import axios, { axiosErrorMessage } from "./axios"
import ConfirmDialog from "../components/mui/ConfirmDialog"
import useAlertContext, { AlertTypes } from "../hooks/useAlertContext"

const LogTimePopup = ({ ticketId, isOpen = false, onClose }) => {
    const { addSnackbarMessage } = useAlertContext()
    const [loggedTime, setLoggedTime] = useState(5)
    const [logNote, setLogNote] = useState("")

    const submitLoggedTime = async () => {
        try {
            const res = await axios.post("/api/ticket/log-time", {
                ticketId,
                timeSpent: loggedTime,
                message: logNote,
            })
            onClose()

            addSnackbarMessage(
                <>
                    <p>{`Ticket time logged.`}</p>
                    <p>{`Your time spent ${res?.data?.userTimeSpent ?? 0}m`}</p>
                    <p>{`Total time spent ${res?.data?.totalTimeSpent ?? 0}m`}</p>
                </>,
                AlertTypes.Success
            )
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e, "Ticket time log failed"), AlertTypes.Error)
        }
    }

    return (
        <ConfirmDialog
            isOpen={isOpen}
            title='Log Time Spent on Ticket'
            // description={`Are you sure that you want to ${isTicketClosed ? "open" : "close"} this ticket?`}
            // onYesClick={yesConfirmClickAsync}
            noLabel='Cancel'
            yesLabel='Submit Time'
            onNoClick={onClose}
            onClose={onClose}
            yesDisabled={!loggedTime}
            onYesClick={submitLoggedTime}
        >
            <div className='flex-column flex-gap-16 width-100'>
                <div className='flex-column flex-gap-2 width-100'>
                    <p>Time (Minutes)</p>
                    <input type='number' className='rs-input' min='1' value={loggedTime} onChange={(e) => setLoggedTime(e.target.value)} />
                    <div className='flex-row flex-gap-2 width-100'>
                        <button className='button-outlined-blue-square' onClick={() => setLoggedTime(5)}>
                            5
                        </button>
                        <button className='button-outlined-blue-square' onClick={() => setLoggedTime(10)}>
                            10
                        </button>
                        <button className='button-outlined-blue-square' onClick={() => setLoggedTime(15)}>
                            15
                        </button>
                        <button className='button-outlined-blue-square' onClick={() => setLoggedTime(30)}>
                            30
                        </button>
                        <button className='button-outlined-blue-square' onClick={() => setLoggedTime(60)}>
                            60
                        </button>
                    </div>
                </div>
                <div className='flex-column flex-gap-2 width-100'>
                    <p>Note (Optional)</p>
                    <textarea onChange={(e) => setLogNote(e.target.value)} value={logNote ?? ""} className='rs-input width-100' rows={6} />
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default LogTimePopup
