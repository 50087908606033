import React from "react"

import StorageList from "./StorageList"
import MetaData from "../../../components/MetaData"

/**
 * The storage page
 */
const Storage = () => {
    return (
        <div className='page-wrapper'>
            <MetaData title={`Storage`} />
            <StorageList title='Knowledge Base' isOrgStorage={false} orgUuid={null} />
        </div>
    )
}

export default Storage
