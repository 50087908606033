import React, { useState } from "react"
import { IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"

/**
 * Buttoned menu, used when we need a menu in a DataTable cell
 */
function ButtonMenu({ id = "basic", menuItems = [] }) {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleItemClick = (item) => {
        if (item.func) item.func(item)
        handleClose()
    }

    return (
        <>
            <IconButton id={`menu-${id}-button`} onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu id={`menu-${id}-menu`} anchorEl={anchorEl} open={anchorEl != null} onClose={handleClose}>
                {menuItems?.map((item, index) => {
                    let Icon = item?.icon ?? null

                    return (
                        <MenuItem key={index} onClick={() => handleItemClick(item)} className={`dropdown-option flex-row-center-vertical`}>
                            {Icon && <Icon id='data-snippet-icon' />}
                            <p>{item.label}</p>
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

export default ButtonMenu
