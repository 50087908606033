import React, { useEffect, useState } from "react"
import Select from "react-select"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import Divider from "../../../components/dividers/Divider"
import axios, { axiosErrorMessage } from "../../../components/axios"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import MetaData from "../../../components/MetaData"
import { inputMaxLength } from "../../../Constants"
import { Tab, Tabs, TabPanel } from "../../../components/mui/Tabs"
import IconButton from "../../../components/mui/IconButton"
import { formatTagsLabel, selectFilter, selectTheme } from "../../../common/selectHelpers"
import ConfirmDialog from "../../../components/mui/ConfirmDialog"
import Card, { CardSection, CardSectionLink } from "../../../components/mui/Card"
import Loading from "../../../components/Loading"
import AdminEposCategoriesList from "./AdminEposCategoriesList"
import { Header2 } from "../../../components/headers/Headers"

const defaultTag = {
    uuid: null,
    label: "",
    colour: "#000000",
}
Object.freeze(defaultTag)

const defaultTagStyle = {
    backgroundColor: "#000000",
    color: "#000000",
    height: "100%",
    padding: "0 8px",
}
Object.freeze(defaultTagStyle)

/**
 * The admin settings page
 */
const AdminSettings = () => {
    const { addSnackbarMessage } = useAlertContext()

    const [tabIndex, setTabIndex] = useState(0)

    const [tags, setTags] = useState(null)
    const [selectedTag, setSelectedTag] = useState(null)
    const [newTag, setNewTag] = useState({ ...defaultTag })

    /* ========== TAG FUNCTIONS ========== */

    const [tagStyles, setTagStyles] = useState({ ...defaultTagStyle })
    const [newTagStyles, setNewTagStyles] = useState({ ...defaultTagStyle })

    /**
     * Gets a random color for the new tag
     */
    const getRandomColor = () => {
        const r = Math.floor(Math.random() * 17).toString(16) // 17 = 0..16
        const g = Math.floor(Math.random() * 17).toString(16) // 17 = 0..16
        const b = Math.floor(Math.random() * 17).toString(16) // 17 = 0..16
        return `#${r}${r}${g}${g}${b}${b}`
    }

    /**
     * Update an existing tag
     */
    const updateTag = async () => {
        const res = await axios.patch("/api/tag/", {
            tagUuid: selectedTag.uuid,
            label: selectedTag.label,
            colour: selectedTag.colour,
        })
        if (res.status === 200) addSnackbarMessage("Tag successfully updated.", AlertTypes.Success)
        window.location.reload()
    }

    /**
     * Create a new tag
     */
    const createTag = async () => {
        const res = await axios.post("/api/tag", {
            label: newTag.label,
            colour: newTag.colour,
        })
        if (res.status === 200) addSnackbarMessage("Tag successfully created.", AlertTypes.Success)
        window.location.reload()
    }

    /**
     * Delete an existing tag
     */
    const deleteTag = async () => {
        // TODO confirmation needed here
        const res = await axios.delete(`/api/tag/${selectedTag.uuid}`)
        if (res.status === 200) addSnackbarMessage("Tag successfully deleted.", AlertTypes.Success)
        window.location.reload()
    }

    /**
     * The tab was edited
     * @param {string} id
     * @param {string} value
     */
    const onTagChange = (id, value) => {
        setSelectedTag((current) => {
            return { ...current, [id]: value }
        })

        if (id === "colour") {
            setTagStyles((current) => {
                return { ...current, backgroundColor: `${value}`, color: `${value}` }
            })
        }
    }

    /**
     * The new tab was edited
     * @param {string} id
     * @param {string} value
     */
    const onNewTagChange = (id, value) => {
        setNewTag((current) => {
            return { ...current, [id]: value }
        })

        if (id === "colour") {
            setNewTagStyles((current) => {
                return { ...current, backgroundColor: `${value}`, color: `${value}` }
            })
        }
    }

    /**
     * The ticket tag dropdown changed
     */
    const onTicketTagChange = async (e) => {
        const { value, label, extendedLabel } = e
        setSelectedTag({
            uuid: value,
            label,
            colour: extendedLabel,
        })
    }

    /**
     * Get the data
     */
    const getInitialData = async (abortController) => {
        try {
            const tagList = await axios.get("/api/tag/list", { signal: abortController?.signal })
            setTags(tagList.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    useEffect(() => {
        if (selectedTag)
            setTagStyles({
                backgroundColor: `${selectedTag.colour}`,
                color: `${selectedTag.colour}`,
                height: "100%",
                padding: "0 8px",
            })
    }, [selectedTag])

    /**
     * Called when the page is first loaded
     */
    useEffect(() => {
        const abortController = new AbortController()
        getInitialData(abortController)
        onNewTagChange("colour", getRandomColor())

        // Cleanup function
        return () => {
            abortController.abort()
        }
    }, [])

    return (
        <div className='page-wrapper'>
            <div className='padding-16'>
                <MetaData title={`Admin Settings`} />
                <Header2 title='Admin Settings' />

                <Tabs
                    value={tabIndex}
                    onChange={(event, newValue) => {
                        setTabIndex(newValue)
                    }}
                >
                    <Tab label='Ticket Tags' />
                    <Tab label='Ticket Categories' />
                    <Tab label='Ticket Status' />
                    <Tab label='Ticket Priorites' />
                    <Tab label='KB Categories' />
                    <Tab label='EPOS Categories' />
                    <Tab label='Actions' />
                </Tabs>

                <TabPanel value={tabIndex} index={0}>
                    <div className='padding-16 flex-column'>
                        <h3>Edit Tag</h3>
                        <label htmlFor='admin-tags'>Please select a tag below to edit:</label>
                        {tags && (
                            <Select
                                options={tags}
                                className='width-100 react-select-container'
                                onChange={onTicketTagChange}
                                value={selectedTag}
                                formatOptionLabel={formatTagsLabel}
                                inputId='admin-tags'
                                filterOption={selectFilter()}
                                theme={selectTheme}
                            />
                        )}
                        {selectedTag && (
                            <Card title='Edit Tag'>
                                <div className='flex-column'>
                                    <div className='flex-row-center-vertical flex-gap-32'>
                                        <label htmlFor='edit-label'>Label:</label>
                                        <input
                                            id='edit-label'
                                            type='text'
                                            className='rs-input'
                                            value={selectedTag?.label ?? undefined}
                                            maxLength={inputMaxLength.tagLabel}
                                            required
                                            onChange={(e) => onTagChange("label", e.target.value)}
                                        />
                                    </div>
                                    <div className='flex-row-center-vertical flex-gap-32'>
                                        <label htmlFor='edit-color'>Color:</label>
                                        <div className='flex-row-center-vertical'>
                                            <input
                                                id='edit-color'
                                                type='text'
                                                className='rs-input'
                                                value={selectedTag?.colour ?? undefined}
                                                onChange={(e) => onTagChange("colour", e.target.value)}
                                            />
                                            <div style={tagStyles}>.</div>
                                            <input
                                                type='color'
                                                value={selectedTag?.colour ?? undefined}
                                                onChange={(e) => onTagChange("colour", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex-row-center-vertical flex-gap-16'>
                                        <button className='button-contained margin-vertical-8' onClick={updateTag}>
                                            Save Tag
                                        </button>
                                        <IconButton hintText='Delete Tag' onClick={deleteTag}>
                                            <DeleteForeverIcon className='faded-icon' />
                                        </IconButton>
                                    </div>
                                </div>
                            </Card>
                        )}
                    </div>
                    <Divider padding={16} />
                    <div className='padding-16 flex-column'>
                        <Card title='New Tag' className='min-width-50'>
                            <div className='flex-column flex-column-stretch'>
                                <div className='flex-row-center-vertical flex-spacebetween flex-gap-16'>
                                    <label htmlFor='new-label'>Label:</label>
                                    <input
                                        id='new-label'
                                        type='text'
                                        className='rs-input flex-100'
                                        value={newTag.label ?? undefined}
                                        maxLength={inputMaxLength.tagLabel}
                                        required
                                        onChange={(e) => onNewTagChange("label", e.target.value)}
                                    />
                                </div>
                                <div className='flex-row-center-vertical flex-spacebetween flex-gap-16'>
                                    <label htmlFor='edit-color'>Color:</label>
                                    <div className='flex-row-center-vertical flex-100'>
                                        <input
                                            id='edit-color'
                                            type='text'
                                            className='rs-input'
                                            value={newTag.colour ?? undefined}
                                            onChange={(e) => onNewTagChange("colour", e.target.value)}
                                        />
                                        <div style={newTagStyles}>.</div>
                                        <input type='color' value={newTag.colour ?? undefined} onChange={(e) => onNewTagChange("colour", e.target.value)} />
                                    </div>
                                </div>
                                <button className='button-contained margin-vertical-8' onClick={createTag}>
                                    Create Tag
                                </button>
                            </div>
                        </Card>
                    </div>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <AdminKeyValuePanel
                        title='Ticket Categories'
                        extendedLabel={"SLA (days)"}
                        extendedLabelType='number'
                        maxLength={inputMaxLength.ticketCategoryName}
                        onGetListCallbackAsync={async () => axios.get("/api/ticket/ticket-categories")}
                        onDeleteCallbackAsync={async (id) => axios.delete(`/api/adminlists/ticket-categories/${id}`)}
                        onCreteCallbackAsync={async (data) => axios.post("/api/adminlists/ticket-categories", data)}
                        onUpdateCallbackAsync={async (data) => axios.patch("/api/adminlists/ticket-categories", data)}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <AdminKeyValuePanel
                        title='Ticket Status'
                        maxLength={inputMaxLength.ticketStatusName}
                        onGetListCallbackAsync={async () => axios.get("/api/ticket/ticket-status")}
                        onDeleteCallbackAsync={async (id) => axios.delete(`/api/adminlists/ticket-status/${id}`)}
                        onCreteCallbackAsync={async (data) => axios.post("/api/adminlists/ticket-status", data)}
                        onUpdateCallbackAsync={async (data) => axios.patch("/api/adminlists/ticket-status", data)}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    <AdminKeyValuePanel
                        title='Ticket Priorites'
                        maxLength={inputMaxLength.ticketPriorityName}
                        onGetListCallbackAsync={async () => axios.get("/api/ticket/ticket-priority")}
                        onDeleteCallbackAsync={async (id) => axios.delete(`/api/adminlists/ticket-priority/${id}`)}
                        onCreteCallbackAsync={async (data) => axios.post("/api/adminlists/ticket-priority", data)}
                        onUpdateCallbackAsync={async (data) => axios.patch("/api/adminlists/ticket-priority", data)}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <AdminKeyValuePanel
                        title='Knowledge Base Category'
                        maxLength={inputMaxLength.storageCategoryName}
                        onGetListCallbackAsync={async () => axios.get("/api/storage/categories")}
                        onDeleteCallbackAsync={async (id) => axios.delete(`/api/adminlists/storage-categories/${id}`)}
                        onCreteCallbackAsync={async (data) => axios.post("/api/adminlists/storage-categories", data)}
                        onUpdateCallbackAsync={async (data) => axios.patch("/api/adminlists/storage-categories", data)}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={5}>
                    <Divider padding={16} />
                    <AdminEposCategoriesList />
                </TabPanel>
                <TabPanel value={tabIndex} index={6}>
                    <Divider padding={16} />
                    <div className='padding-16'>
                        <Card title='Admin Actions'>
                            <CardSection>
                                <CardSectionLink to='/api/cron/clean-up' target='_blank'>
                                    <div>Database Clean Up</div>
                                    <div className='text-faded small'>
                                        Clean-up the ticket database, by removing old notifications, subscriptions and log files.
                                    </div>
                                </CardSectionLink>
                                <CardSectionLink to='/api/cron/send-user-emails' target='_blank'>
                                    <div>Send User Notifications</div>
                                    <div className='text-faded small'>Email any outstanding user notifications to the users.</div>
                                </CardSectionLink>
                            </CardSection>
                        </Card>
                    </div>
                </TabPanel>
            </div>
        </div>
    )
}

/**
 * Basic keyvalue panel
 * @param {Array} currentList - should be an array { value: 7, label: "Customer Request" }
 * @param {Function} onGetListCallbackAsync - (abortController) => {}
 * @param {Function} onDeleteCallbackAsync - (id) => {}
 * @param {Function} onCreteCallbackAsync - (data) => {}
 * @param {Function} onUpdateCallbackAsync - (data) => {}
 */
const AdminKeyValuePanel = ({
    title = "Item Not Set",
    maxLength = 50,
    extendedLabel = false,
    extendedLabelType = "text",
    onGetListCallbackAsync = undefined,
    onDeleteCallbackAsync = undefined,
    onCreteCallbackAsync = undefined,
    onUpdateCallbackAsync = undefined,
}) => {
    const { addSnackbarMessage } = useAlertContext()
    const [isLoading, setIsLoading] = useState(true)
    const [currentList, setCurrentList] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isConfirmOpen, setIsConfirmOpen] = useState(false)

    const isEdit = selectedItem?.value ? true : false
    const editTitle = isEdit ? "Update" : "Create"

    /**
     * The list change
     */
    const onListChange = (e) => {
        setSelectedItem(e)
    }

    /**
     * Input change
     */
    const onInputChange = (e) => {
        setSelectedItem((currentValue) => {
            return {
                ...currentValue,
                [e.target.id]: e.target.value ?? undefined,
            }
        })
    }

    /**
     * Form submit
     */
    const onSubmit = async (e) => {
        e.preventDefault()
        if (isLoading) return
        if (!selectedItem || !selectedItem?.label) return

        setIsLoading(true)
        try {
            if (isEdit) {
                if (onUpdateCallbackAsync) {
                    const res = await onUpdateCallbackAsync(selectedItem)
                    if (res.status === 200) addSnackbarMessage(`${title} successfully updated.`, AlertTypes.Success)
                }
            } else {
                if (onCreteCallbackAsync) {
                    const res = await onCreteCallbackAsync(selectedItem)
                    if (res.status === 200) addSnackbarMessage(`${title} successfully created.`, AlertTypes.Success)
                }
            }

            await refreshList()
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Delete button clicked
     */
    const deleteItemconfirm = async () => {
        setIsLoading(true)
        try {
            if (onDeleteCallbackAsync && selectedItem && selectedItem.value) {
                try {
                    const res = await onDeleteCallbackAsync(selectedItem.value)
                    if (res.status === 200) addSnackbarMessage(`${title} successfully deleted.`, AlertTypes.Success)
                    else addSnackbarMessage(`${title} was NOT deleted (check usages).`, AlertTypes.Error)
                    await refreshList()
                } catch (e) {
                    addSnackbarMessage(`${title} was NOT deleted (check usages).`, AlertTypes.Error)
                }
            }
        } finally {
            setIsLoading(false)
            handelCloseModal()
        }
    }

    /**
     * Close the confirm popup
     */
    const handelCloseModal = () => {
        setIsConfirmOpen(false)
    }

    /**
     * Delete button was clicked
     */
    const onDeleteClick = () => {
        setIsConfirmOpen(isEdit) // can only delete if is edit
    }

    /**
     * Refresh the list and clear the selected item
     */
    const refreshList = async (abortController) => {
        setSelectedItem(null)
        handelCloseModal()
        await getInitData(abortController)
    }

    /**
     * Loading data for the dropdowns
     */
    const getInitData = async (abortController) => {
        setIsLoading(true)
        try {
            if (onGetListCallbackAsync) {
                const data = await onGetListCallbackAsync(abortController)
                setCurrentList(data.data)
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                console.error(e)
            }
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Init data
     */
    useEffect(() => {
        const abortController = new AbortController()
        refreshList(abortController)
        return () => abortController.abort()
    }, [])

    if (isLoading) return <Loading />

    return (
        <>
            <ConfirmDialog
                isOpen={isConfirmOpen}
                title={`Delete ${selectedItem?.label}`}
                description={`Are you sure that you want to permanently delete this ${title?.toLocaleLowerCase()}?`}
                onClose={handelCloseModal}
                onNoClick={handelCloseModal}
                onYesClick={deleteItemconfirm}
            >
                <p>
                    <strong>Note: Items in use can not be deleted</strong>
                </p>
            </ConfirmDialog>

            <div className='padding-16 flex-column'>
                {currentList ? (
                    <>
                        <p>Please select a {title?.toLocaleLowerCase()} below to edit:</p>
                        <div className='flex-row flex-gap-16 width-100'>
                            <Select
                                options={currentList}
                                className='width-100 react-select-container'
                                onChange={onListChange}
                                filterOption={selectFilter()}
                                value={selectedItem}
                                isClearable={true}
                                theme={selectTheme}
                            />

                            {isEdit && (
                                <IconButton hintText={`Delete ${title}`} onClick={onDeleteClick}>
                                    <DeleteForeverIcon className='faded-icon' />
                                </IconButton>
                            )}
                        </div>
                    </>
                ) : null}
            </div>
            <Divider padding={16} />
            <div className='padding-16 flex-column'>
                <Card title={`${editTitle} ${title}`} className='min-width-50'>
                    <form onSubmit={onSubmit}>
                        <div className='flex-column flex-column-stretch '>
                            <div className='flex-row-center-vertical flex-spacebetween flex-gap-16'>
                                <label htmlFor='label'>Label:</label>
                                <input
                                    id='label'
                                    type='text'
                                    className='rs-input flex-100'
                                    value={selectedItem?.label ?? ""}
                                    maxLength={maxLength}
                                    required
                                    onChange={onInputChange}
                                />
                            </div>
                            {extendedLabel ? (
                                <div className='flex-row-center-vertical flex-spacebetween flex-gap-16'>
                                    <label htmlFor='extendedLabel'>{extendedLabel}:</label>
                                    <input
                                        id='extendedLabel'
                                        type={extendedLabelType}
                                        className='rs-input flex-100'
                                        value={selectedItem?.extendedLabel ?? ""}
                                        maxLength={maxLength}
                                        required
                                        onChange={onInputChange}
                                    />
                                </div>
                            ) : null}
                            <button className='button-contained' type='submit' disabled={!selectedItem?.label || isLoading}>
                                {editTitle}
                            </button>
                        </div>
                    </form>
                </Card>
            </div>
        </>
    )
}

export default AdminSettings
