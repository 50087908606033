import React, { useEffect, useState } from "react"

import "../pages/styling/general.css"
import "../pages/styling/storage.css"

import MetaData from "../components/MetaData"
import ErrorBoundary from "../components/errors/ErrorBoundary"
import ErrorBlock from "../components/errors/ErrorBlock"
import Loading from "../components/Loading"
import { getPublicStorageFunc } from "../components/httpRequests/StorageRequests"
import { dateFormat } from "../components/utility/ConvertTime"
import AccordionCard, { AccordionCardSection, AccordionCardSectionLink } from "../components/mui/AccordionCard"
import useDebounceState from "../hooks/useDebounceState"
import { axiosErrorMessage } from "../components/axios"
import useAlertContext, { AlertTypes } from "../hooks/useAlertContext"
import { Header2 } from "../components/headers/Headers"

/**
 * Page of articles
 */
function ArticleListPage() {
    const { addSnackbarMessage } = useAlertContext()

    const [storageData, setStorageData] = useState(null)
    const [storageDataFiltered, setStorageDataFiltered] = useState(null)
    const [categories, setCategories] = useState(null)
    const [searchValue, setSearchValue] = useDebounceState("")

    const [expanded, setExpanded] = useState([])

    /**
     * handle the knolage base expand and collapse
     */
    const handleCategoryCardExpandedChange = (id, isExpanded) => {
        setExpanded((currentValue) => {
            const index = currentValue.indexOf(id)
            if (index === -1) {
                if (isExpanded) currentValue.push(id)
            } else {
                if (!isExpanded) currentValue.splice(index, 1)
            }
            return [...currentValue]
        })
    }

    /**
     * Load the list of storage data from the url
     */
    const getStorageInfo = async (abortController) => {
        try {
            const storage = await getPublicStorageFunc(abortController)
            setStorageData(storage.data?.list)
            setCategories(storage.data?.categories)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    /**
     * Filter the data using the passed searcg value
     */
    const doDataFilter = (storageData, searchValue) => {
        let tempData = []

        if (!searchValue || !storageData) {
            return storageData
        }

        storageData.forEach((d) => {
            if (d?.title?.toLowerCase().includes(searchValue)) {
                tempData.push(d)
            } else if (d?.categoryName?.toLowerCase().includes(searchValue)) {
                tempData.push(d)
            } else if (d?.keywords?.toLowerCase().includes(searchValue)) {
                tempData.push(d)
            }
        })
        return tempData
    }

    /**
     * Update the storage data
     */
    useEffect(() => {
        setStorageDataFiltered(doDataFilter(storageData, searchValue))
    }, [storageData, searchValue])

    /**
     * Called when the page is first loaded
     */
    useEffect(() => {
        const abortController = new AbortController()
        getStorageInfo(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [])

    return (
        <div className='page-wrapper'>
            <MetaData title={`Knowledge Base`} />
            <div className='padding-16'>
                <Header2 title='Knowledge Base' paddingBottom={16} />

                <div className='flex-column flex-gap-16 width-100'>
                    <div className='flex-100 width-100'>
                        <input
                            id='searchValue'
                            className='rs-input width-100'
                            type='text'
                            placeholder='Search Here'
                            defaultValue={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value?.toLowerCase())
                            }}
                        />
                    </div>
                    <div className='width-100'>
                        <ErrorBoundary fallback={<ErrorBlock error={"Unable to load Knowledge Base"} />}>
                            {categories == null ? (
                                <Loading />
                            ) : categories.length == 0 ? (
                                <ErrorBlock error='No results found' errorTitle='Search' />
                            ) : (
                                <>
                                    {categories?.map((category) => (
                                        <CategoryCard
                                            key={category.value}
                                            category={category}
                                            storageData={storageDataFiltered}
                                            categoryCardExpanded={expanded}
                                            handleCategoryCardExpandedChange={handleCategoryCardExpandedChange}
                                        />
                                    ))}
                                </>
                            )}
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </div>
    )
}

/**
 * single catogory card
 */
const CategoryCard = ({ category, storageData, categoryCardExpanded = false, handleCategoryCardExpandedChange = undefined }) => {
    const data = storageData?.filter((s) => s?.categoryId === category?.value)

    // check if anything was found
    if (!data || data.length == 0) {
        return <></>
    }

    const id = `kb-category-${category.value}`
    const autoExpand = data?.length === storageData?.length // only one category

    return (
        <AccordionCard
            id={id}
            title={category?.label}
            pillLabel={data.length}
            className='width-100'
            expanded={autoExpand || categoryCardExpanded.includes(id)}
            handleExpandedChange={handleCategoryCardExpandedChange}
        >
            {data.map((item) => {
                return (
                    <AccordionCardSection key={item.uuid}>
                        <AccordionCardSectionLink to={`/article/${item.uuid}`}>
                            <div>{item.title}</div>
                            <div className='text-right text-faded small'>{dateFormat(item.updatedAt)}</div>
                        </AccordionCardSectionLink>
                    </AccordionCardSection>
                )
            })}
        </AccordionCard>
    )
}

export default ArticleListPage
