// Helpers for react-select
import { createFilter } from "react-select"

/**
 * Converts the uuidValuePairs objects to values that react-select can use
 * @param {array} uuidValuePairs - array of objects (uuid, value)
 * @param {string} uuid the key for the key-value pair
 * @param {string} value the value for the key-value pair
 * @param {undefined|(uuid: string) => boolean} isFixed callback function to get it the key is fixed or not
 * @returns
 */
export const uuidValuePairsToSelectOptions = (uuidValuePairs, uuid = "uuid", value = "value", isFixed = undefined) => {
    const result = Array.isArray(uuidValuePairs)
        ? uuidValuePairs.map((uuidValuePair) => {
              return {
                  value: uuidValuePair[uuid],
                  label: uuidValuePair[value],
                  isFixed: isFixed instanceof Function ? isFixed(uuidValuePair[uuid]) : false,
              }
          })
        : []

    // Order the result so that the fixed options are first in the list
    return result.filter((v) => v.isFixed).concat(result.filter((v) => !v.isFixed))
}

/**
 * Converts the react-select array into a uuidValuePairs array
 * @param {array} valueLabelArr - array recured from React-select in the format [{value: '', label: ''}]
 */
export const selectOptionsToUuidValuesArray = (valueLabelArr) => {
    return Array.isArray(valueLabelArr)
        ? valueLabelArr.map((uuidValuePair) => {
              return uuidValuePair
                  ? {
                        uuid: uuidValuePair.value,
                        value: uuidValuePair.label,
                    }
                  : null
          })
        : []
}

/**
 * Convetrs the passed options into an array of the values
 * [{value:1}, {value:2}, ...]
 */
export const selectOptionsToValuesArray = (options) => {
    if (!options) return []
    if (!Array.isArray(options)) return [options.value] // single object selected
    return options.map((option) => option.value)
}

/**
 * Converts the passed csv into react-select options
 */
export const csvToSelectOptions = (csv) => {
    if (!csv) return
    const options = csv.split(",")
    return options.map((option) => {
        return {
            value: option,
            label: option,
        }
    })
}

/**
 * Filters the options to just the ones that match the passed values
 * - options needs to be an array of objects [{value:1}, {value:2}, ...]
 * - values needs to be an array of values
 */
export const filterSelectOptions = (options, values) => {
    if (!options) return []
    if (!values && typeof values !== "boolean") return []
    if (!Array.isArray(options)) return []

    const matchWith = Array.isArray(values) ? values : [values]

    // Filter to just the object that match the values
    return options.filter((o) => matchWith.includes(o.value))
}

/**
 * React-select formatExtendedLabel for UserRoles
 * @param {import("react-select").Option} data
 * @param {import("react-select").FormatOptionLabelMeta<import("react-select").Option>} formatOptionLabelMeta
 */
export const formatExtendedLabel = (data, formatOptionLabelMeta) => {
    const { label, extendedLabel, extendedLabel2 } = data
    const isExtendedMenu = formatOptionLabelMeta.context == "menu" && extendedLabel

    let isSelected = false
    if (isExtendedMenu && Array.isArray(formatOptionLabelMeta?.selectValue)) {
        isSelected = formatOptionLabelMeta?.selectValue?.includes(data) ?? false
    }

    return isExtendedMenu ? (
        // Dropdown menu item
        <span className='flex-column flex-gap-none'>
            {label}
            {extendedLabel ? <span className={isSelected ? "react-select-address-selected" : "react-select-address"}>{extendedLabel}</span> : null}
            {extendedLabel2 ? <span className={isSelected ? "react-select-address-selected" : "react-select-address"}>{extendedLabel2}</span> : null}
        </span>
    ) : (
        // Don't show the address when selected
        <span className='flex-column flex-gap-none'>{label}</span>
    )
}

/**
 * React-select formatOptionLabel for Tags
 * Get the data from : /api/tag/list
 * @param {import("react-select").Options} data
 */
export const formatTagsLabel = (data) => {
    const { label, extendedLabel } = data ?? { label: undefined, extendedLabel: undefined }

    const colourDiv = {
        borderLeft: `4px solid ${extendedLabel ?? "transparent"}`,
        paddingLeft: "8px",
    }

    return (
        <span className='flex-column flex-gap-none' style={colourDiv}>
            {label}
        </span>
    )
}

/**
 * Creates and returns a custom select filter used for searching
 */
export const selectFilter = () => {
    return createFilter({
        stringify: (option) =>
            `${option.label} ${option.value} ${option.data?.extendedLabel ?? ""} ${option.data?.extendedLabel2 ?? ""} ${option.data?.extendedLabel3 ?? ""}`,
    })
}

/**
 * theme for the react select control
 */
export const selectTheme = (theme) => {
    return {
        ...theme,
        // Copied from variables.css, if needed add
        colors: {
            ...theme.colors,
            primary: "var(--input-border-focus)",
            // primary75: "green", primary50: "yellow",
            primary25: "var(--select-background-hover)",
            // neutral20 = border color, values are 0, 5, 10..90 (in 10 increments) : neutral90: hsl(0, 0%, 10%)
            neutral20: "var(--input-border)",
        },
    }
}
