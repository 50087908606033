import React from "react"
import { darkText, lightText, lightBackground } from "../../Theme"

/**
 * A tag
 */
const Tag = ({ label, colour, className }) => {
    /**
     * If the color is light or not
     */
    const isLightColor = (hexColor) => {
        if (!hexColor || !hexColor.startsWith("#")) return true

        const rgbColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)

        try {
            const r = parseInt(rgbColor[1], 16)
            const g = parseInt(rgbColor[2], 16)
            const b = parseInt(rgbColor[3], 16)
            return Math.round(0.299 * r + 0.587 * g + 0.114 * b) > 128 // 255 / 2 = 127.5, rounded to 128
        } catch {
            return true
        }
    }

    const pillStyle = {
        backgroundColor: `${colour ?? lightBackground}`,
        color: isLightColor(colour) ? darkText : lightText,
    }

    return (
        <div style={pillStyle} className={`flex-column-center flex-gap-name pill ${className}`}>
            <span>{label}</span>
        </div>
    )
}

export default Tag
