import React from "react"

import "../../pages/styling/general.css"

const LabelledCheckbox = ({ onChange, label, checked, id = undefined, className = "", hint = undefined, required = undefined }) => {
    return (
        <>
            <div className={`flex-row-center-vertical input-checkbox ${className}`}>
                <label htmlFor={id}>
                    <input id={id} name={id} type='checkbox' onChange={onChange} checked={checked} required={required} />
                    <span className='label-right'>
                        {label} {required ? "*" : null}
                    </span>
                </label>
            </div>
            {hint ? <p className='text-hint'>{hint}</p> : null}
        </>
    )
}

export default LabelledCheckbox
