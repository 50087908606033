import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"

import "./styling/settings.css"
import "./styling/login.css"

import useAlertContext, { AlertTypes } from "../hooks/useAlertContext"
import { axiosErrorMessage } from "../components/axios"
import { inputMaxLength } from "../Constants"
import ConfirmDialog from "../components/mui/ConfirmDialog"
import MetaData from "../components/MetaData"
import { Header2 } from "../components/headers/Headers"
import Card from "../components/mui/Card"
import Loading from "../components/Loading"
import LabelledCheckbox from "../components/forms/LabelledCheckbox"
import PasswordInputTips from "../components/forms/PasswordInputTips"
import {
    getConfigureAuthFunc,
    getCurrentUserGroupsFunc,
    postConfirmAuthFunc,
    getCurrentUserFunc,
    postRemoveAuthFunc,
    patchCurrentUser,
    patchCurrentUserPassword,
    patchCurrentUserGroups,
} from "../components/httpRequests/CurrentUserRequests"
import useUser from "../hooks/useUser"

/**
 * The users settings page
 */
const UserSettings = () => {
    const navigate = useNavigate()
    const { addSnackbarMessage } = useAlertContext()
    const [user, setUser] = useState()
    const [groups, setGroups] = useState([])

    /**
     * Gets the current user info
     */
    const getUserInfo = async (abortController) => {
        try {
            const [userRes, groupRes] = await Promise.all([getCurrentUserFunc(abortController), getCurrentUserGroupsFunc(abortController)])
            setUser(userRes?.data)
            setGroups(groupRes?.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
                navigate("/")
            }
        }
    }

    /**
     * Called when the page loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getUserInfo(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [])

    return (
        <div className='page-wrapper'>
            <div className='padding-16'>
                <MetaData title={`Settings`} />
                <Header2 title='Settings' />
                {user ? (
                    <div className='settings-wrapper padding-16'>
                        <ChangeYourDetailsCard user={user} setUser={setUser} groups={groups} setGroups={setGroups} />
                        <div className='flex-column flex-column-stretch flex-gap-16'>
                            <ChangeYourPasswordCard />
                            <TwoFactorAuthenticationCard user={user} setUser={setUser} />
                        </div>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

/**
 * Card for changing your details
 */
const ChangeYourDetailsCard = ({ user, setUser, groups, setGroups }) => {
    const { addSnackbarMessage } = useAlertContext()
    const { hasAuthRole_TechnicianOrAbove } = useUser()
    const [isSubmitting, setIsSubmitting] = useState(false)

    /**
     * Save the changes to the user
     */
    const submitUserChanges = async (e) => {
        e.preventDefault()

        setIsSubmitting(true)
        try {
            const [userRed, groupRes] = await Promise.all([patchCurrentUser(user), patchCurrentUserGroups(groups)])

            if (userRed.status === 200 && groupRes.status === 200) {
                addSnackbarMessage("Details Changed Successfully.", AlertTypes.Success)
            } else {
                addSnackbarMessage("Error Saving Changes.", AlertTypes.Error)
            }
        } catch (err) {
            addSnackbarMessage(axiosErrorMessage(err), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    /**
     * User check box changed
     */
    const onUserCheckboxChanged = (e) => {
        const name = e.target.id
        const isChecked = e.target.checked ? true : false
        setUser((currentValue) => {
            return { ...currentValue, [name]: isChecked }
        })
    }

    /**
     * User group check box changed
     */
    const onUserGroupCheckboxChanged = (e) => {
        const name = e.target.id
        const isChecked = e.target.checked ? true : false

        setGroups((currentValue) => {
            // find the item to change
            const item = currentValue.find((t) => t.value === name)
            item.extendedLabel = isChecked

            return [...currentValue]
        })
    }

    /**
     * User input changed
     */
    const onUserInputChange = (e) => {
        const name = e.target.id
        setUser((currentValue) => {
            return { ...currentValue, [name]: e.target.value }
        })
    }

    return (
        <form onSubmit={submitUserChanges} className='flex-column flex-column-stretch flex-gap-16'>
            <Card title='Change Your Details'>
                <div className='flex-column flex-gap-16'>
                    <div className='label-input-wrapper'>
                        <label htmlFor='name'>Name</label>
                        <input
                            id='name'
                            value={user?.name ?? ""}
                            type='text'
                            className='rs-input'
                            required
                            maxLength={inputMaxLength.userName}
                            onChange={onUserInputChange}
                            autoFocus={true}
                        />
                    </div>
                    <div className='flex-row width-100'>
                        <div className='label-input-wrapper'>
                            <label htmlFor='phone'>Phone</label>
                            <input
                                id='phone'
                                value={user?.phone ?? ""}
                                type='tel'
                                maxLength={inputMaxLength.userPhone}
                                className='rs-input'
                                onChange={onUserInputChange}
                            />
                        </div>
                    </div>
                    <div className='label-input-wrapper'>
                        <label htmlFor='email'>Email</label>
                        <input
                            id='email'
                            value={user?.email ?? ""}
                            type='email'
                            className='rs-input'
                            required
                            maxLength={inputMaxLength.userEmail}
                            onChange={undefined}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>
                    <div className='label-input-wrapper'>
                        <LabelledCheckbox
                            label='Enable email notifications'
                            id='emailNotification'
                            checked={user?.emailNotification ?? false}
                            onChange={onUserCheckboxChanged}
                        />
                    </div>

                    <button className='button-contained' type='submit' disabled={!user || !user?.name || isSubmitting}>
                        Save Changes
                    </button>
                </div>
            </Card>

            <Card title='Subscribe To Tickets'>
                <div className='flex-column flex-gap-16'>
                    <div className='label-input-wrapper'>
                        <LabelledCheckbox
                            label='When Created By Anyone'
                            id='subscribeToTickets'
                            checked={user?.subscribeToTickets ?? true}
                            onChange={onUserCheckboxChanged}
                        />
                    </div>
                    <div className='label-input-wrapper'>
                        <LabelledCheckbox
                            label='When I Am Mentioned'
                            id='subscribeToTicketsWhenMentioned'
                            checked={user?.subscribeToTicketsWhenMentioned ?? true}
                            onChange={onUserCheckboxChanged}
                        />
                    </div>
                    <div className='label-input-wrapper'>
                        <LabelledCheckbox
                            label='When I Comment'
                            id='subscribeToTicketsWhenCommented'
                            checked={user?.subscribeToTicketsWhenCommented ?? true}
                            onChange={onUserCheckboxChanged}
                        />
                    </div>
                    <div className={`label-input-wrapper ${hasAuthRole_TechnicianOrAbove() ? "" : "hidden"}`}>
                        <LabelledCheckbox
                            label='When Assigned To Me'
                            id='subscribeToTicketsWhenAssigned'
                            checked={user?.subscribeToTicketsWhenAssigned ?? true}
                            onChange={onUserCheckboxChanged}
                        />
                    </div>

                    {Array.isArray(groups) && groups.length > 0 ? (
                        <>
                            <Header2
                                title='Group Notifications'
                                subtitle='Notify me when tickets are created in the below groups'
                                subtitleClassName='text-hint'
                                showDivider={false}
                            />
                            {groups.map((group) => {
                                return (
                                    <div className='label-input-wrapper' key={group.value}>
                                        <LabelledCheckbox
                                            label={group.label}
                                            id={group.value}
                                            checked={group.extendedLabel}
                                            onChange={onUserGroupCheckboxChanged}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    ) : null}

                    <button className='button-contained' type='submit' disabled={!user || !user?.name || isSubmitting}>
                        Save Changes
                    </button>
                </div>
            </Card>
        </form>
    )
}

/**
 * Card for changing passwords
 */
const ChangeYourPasswordCard = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { addSnackbarMessage } = useAlertContext()

    const [currentPassword, setCurrentPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [confirmNewPassword, setConfirmNewPassword] = useState(null)
    const [isPasswordValid, setIsPasswordValid] = useState(false)

    /**
     * Submit the password change
     */
    const submitPasswordChange = async (e) => {
        e.preventDefault()

        setIsSubmitting(true)
        try {
            const res = await patchCurrentUserPassword(currentPassword, newPassword)
            if (res.status === 401) {
                addSnackbarMessage("Incorrect Password.", AlertTypes.Warning)
            } else if (res.status === 200) {
                addSnackbarMessage("Password Changed Successfully.", AlertTypes.Success)

                setCurrentPassword(null)
                setNewPassword(null)
                setConfirmNewPassword(null)
            }
        } catch (err) {
            addSnackbarMessage(axiosErrorMessage(err), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Card title='Change Your Password' className='flex-100'>
            <form onSubmit={submitPasswordChange} className='flex-column flex-gap-16'>
                <div className='flex-column flex-gap-16 width-100'>
                    <div className='label-input-wrapper'>
                        <label htmlFor='currentPassword'>Current Password</label>
                        <input
                            id='currentPassword'
                            name='currentPassword'
                            type='password'
                            className='rs-input'
                            required
                            value={currentPassword ?? ""}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            autoComplete='current-password'
                        />
                    </div>
                    <div className='label-input-wrapper'>
                        <label htmlFor='newPassword'>New Password</label>
                        <input
                            id='newPassword'
                            name='newPassword'
                            type='password'
                            value={newPassword ?? ""}
                            required
                            onChange={(e) => setNewPassword(e.target.value)}
                            className='rs-input'
                            autoComplete='new-password'
                        />
                    </div>
                    <PasswordInputTips value1={newPassword} value2={confirmNewPassword} setIsValid={(value) => setIsPasswordValid(value)} />
                    <div className='label-input-wrapper'>
                        <label htmlFor='confirmNewPassword'>New Password Confirmation</label>
                        <input
                            id='confirmNewPassword'
                            name='confirmNewPassword'
                            value={confirmNewPassword ?? ""}
                            type='password'
                            required
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            className='rs-input'
                            autoComplete='new-password'
                        />
                    </div>
                </div>
                <button type='submit' className='button-contained' disabled={isSubmitting || !currentPassword || !isPasswordValid}>
                    Update Password
                </button>
            </form>
        </Card>
    )
}

/**
 * The 2 factor auth card, used to setup 2fa
 */
const TwoFactorAuthenticationCard = ({ user, setUser }) => {
    const { addSnackbarMessage } = useAlertContext()
    const [twoFactorData, setTwoFactor] = useState(null)
    const [authCode, setAuthCode] = useState(null)
    const [isConfirm2FARemovalOpen, setConfirm2FARemovalOpen] = useState(false)
    const [currentPassword, setCurrentPassword] = useState(null)
    const twoFactorAuthEnabled = user?.twoFactorAuthEnabled //?? false

    /**
     * Start the 2fa removal process
     */
    const remove2FAClick = async (e) => {
        e.preventDefault()
        setConfirm2FARemovalOpen(true)
    }

    /**
     * Close the confirmation modal
     */
    const closeConfirmationModal = () => {
        setCurrentPassword(null)
        setConfirm2FARemovalOpen(false)
    }

    /**
     * Confirm 2FA removal
     */
    const yesConfirmationResult = async () => {
        if (!currentPassword) {
            addSnackbarMessage("Password required", AlertTypes.Error)
            return
        }

        closeConfirmationModal()
        try {
            await postRemoveAuthFunc(currentPassword)
            setTwoFactor(null)
            setUser((currentValue) => {
                return { ...currentValue, twoFactorAuthEnabled: false }
            })
            addSnackbarMessage("Two-factor authentication removed", AlertTypes.Success)
        } catch (err) {
            addSnackbarMessage(axiosErrorMessage(err), AlertTypes.Error)
        }
    }

    /**
     * Start the 2fa configuration process
     */
    const configure2FAClick = async (e) => {
        e.preventDefault()
        try {
            const res = await getConfigureAuthFunc()
            setTwoFactor(res?.data ?? null)
        } catch (err) {
            addSnackbarMessage(axiosErrorMessage(err), AlertTypes.Error)
        }
    }

    /**
     * Confirm the users auth code has been set up correctly
     */
    const onConfirmAuthCode = async (e) => {
        e.preventDefault()
        if (!authCode) return

        try {
            const res = await postConfirmAuthFunc(authCode)
            setTwoFactor(null)
            setUser((currentValue) => {
                return { ...currentValue, twoFactorAuthEnabled: res?.data?.validated ?? false }
            })
            addSnackbarMessage("Two-factor authentication set-up", AlertTypes.Success)
        } catch (err) {
            addSnackbarMessage(axiosErrorMessage(err), AlertTypes.Error)
        }
    }

    return (
        <Card title='Authentication'>
            <div className='flex-column flex-gap-16'>
                <div className='flex-align-self-stretch'>
                    {twoFactorAuthEnabled ? (
                        <div className='alert alert-success'>
                            <LockIcon className='icon-success' />
                            Two-factor authentication is enabled
                        </div>
                    ) : (
                        <div className='alert alert-danger small'>
                            <LockOpenIcon className='icon-danger' fontSize='small' /> Two-factor authentication is not enabled
                        </div>
                    )}
                </div>
                <div>
                    <p>Configuring an authenticator app is a good way to add an extra layer of security to your account.</p>
                </div>
                {twoFactorData ? (
                    <div className='flex-align-self-center alert alert-primary flex-column flex-gap-16'>
                        <h2>Set-up Two-Factor Authentication</h2>
                        <div className='width-100'>
                            <h3 className='padding-8'>Step 1. Scan the QR Code Below</h3>
                            <div className='text-center padding-inline-8'>
                                <img src={twoFactorData?.imageUrl} alt='2FA Setup code' className='display-inline-block' />
                                <p className='text-hint'>Manual Entry: {twoFactorData?.manualEntryKey}</p>
                            </div>
                        </div>
                        <div>
                            <h3 className='padding-8'>Step 2. Confirm the Authentication Code</h3>
                            <form className='flex-row padding-8' onSubmit={onConfirmAuthCode}>
                                <div className='label-input-wrapper'>
                                    <label htmlFor='authCode' className='hidden'>
                                        Authentication Code
                                    </label>
                                    <div className='flex-row'>
                                        <input
                                            id='authCode'
                                            name='authCode'
                                            value={authCode ?? ""}
                                            type='text'
                                            className='rs-input'
                                            required
                                            onChange={(e) => setAuthCode(e.target.value)}
                                            autoFocus={true}
                                        />
                                        <button type='submit' className='button-contained-20' disabled={!authCode}>
                                            Enable 2FA
                                        </button>
                                    </div>
                                    <div className='text-hint'>Enter the code generated by your authenticator app to finish the set-up.</div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : twoFactorAuthEnabled ? (
                    <>
                        <ConfirmDialog
                            isOpen={isConfirm2FARemovalOpen}
                            title={"Remove Two-factor Authentication?"}
                            onClose={closeConfirmationModal}
                            onNoClick={closeConfirmationModal}
                            onYesClick={yesConfirmationResult}
                            yesLabel='OK'
                            noLabel='Cancel'
                            yesDisabled={!currentPassword}
                        >
                            <p className='padding-vertical-8'>Enter your password to remove 2FA from your account.</p>
                            <div className='label-input-wrapper'>
                                <label htmlFor='currentPasswordRem'>Password</label>
                                <input
                                    id='currentPasswordRem'
                                    name='currentPasswordRem'
                                    type='password'
                                    className='rs-input'
                                    required
                                    value={currentPassword ?? ""}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    autoComplete='current-password'
                                />
                            </div>
                        </ConfirmDialog>
                        <button className='button-outlined-danger' onClick={remove2FAClick}>
                            <LockOpenIcon /> Remove 2FA
                        </button>
                    </>
                ) : (
                    <button className='button-contained' onClick={configure2FAClick}>
                        <LockIcon /> Configure 2FA
                    </button>
                )}
            </div>
        </Card>
    )
}

export default UserSettings
