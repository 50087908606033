import React from "react"
import { default as MuiSwitch } from "@mui/material/Switch"

/**
 * Wrapper for the MUI switch control
 */
function Switch({ id, checkedLabel, uncheckedLabel, rightAlignLabel = false, checked = undefined, onChange = undefined }) {
    return (
        <label htmlFor={id} className={`flex-align-items-center ${rightAlignLabel ? "flex-row-reverse" : "flex-row"}`}>
            <span>{checked ? checkedLabel : uncheckedLabel}</span>
            <MuiSwitch id={id} name={id} checked={checked} onChange={onChange} />
        </label>
    )
}

export default Switch
