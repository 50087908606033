import React, { useEffect, useState } from "react"
import Select from "react-select"

import axios, { axiosErrorMessage } from "../../../components/axios"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import Loading from "../../../components/Loading"
import Divider from "../../../components/dividers/Divider"
import Card from "../../../components/mui/Card"
import { formatTagsLabel, selectFilter, selectTheme } from "../../../common/selectHelpers"
import { inputMaxLength } from "../../../Constants"

/**
 * A list of epos categories an how they are mapped
 */
function AdminEposCategoriesList() {
    const { addSnackbarMessage } = useAlertContext()
    const [eposList, setEposList] = useState(null)
    const [categoryList, setCategoryList] = useState(null)
    const [tagsList, setTagsList] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    // Selected items
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedTags, setSelectedTags] = useState([])

    /**
     * Get the data
     */
    const getInitialData = async (abortController) => {
        setIsLoading(true)
        try {
            const [eposRes, tagRes, catRes] = await Promise.all([
                axios.get("/api/adminlists/epos-categories", { signal: abortController?.signal }),
                axios.get("/api/tag/list", { signal: abortController?.signal }),
                axios.get("/api/ticket/ticket-categories", { signal: abortController?.signal }),
            ])
            setEposList(eposRes.data)
            setCategoryList(catRes.data)
            setTagsList(tagRes.data)

            setSelectedItem(null)
            setSelectedTags([])
            setSelectedCategory(null)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * The list change
     */
    const onListChange = (e) => {
        setSelectedItem(e)
    }

    /**
     * Form submit
     */
    const onSubmit = async (e) => {
        e.preventDefault()
        if (isLoading) return
        if (!selectedItem || !selectedItem?.value) return

        setIsLoading(true)
        try {
            const data = {
                id: selectedItem.value,
                name: selectedItem.label,
                categoryId: selectedCategory.value,
                tagUuids: selectedTags?.map((t) => t.value) ?? [],
            }
            await axios.patch("/api/adminlists/epos-categories", data)

            addSnackbarMessage(`Epos category successfully updated.`, AlertTypes.Success)
            await getInitialData()
        } catch (e) {
            addSnackbarMessage(`An error occurred.`, AlertTypes.Error)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Called when the page is first loaded
     */
    useEffect(() => {
        const abortController = new AbortController()
        getInitialData(abortController)

        // Cleanup function
        return () => {
            abortController.abort()
        }
    }, [])

    /**
     * Selected item has changed
     */
    useEffect(() => {
        if (selectedItem) {
            const tags = Array.isArray(selectedItem.extendedLabel2) ? selectedItem.extendedLabel2 : []
            setSelectedCategory(categoryList?.find((c) => c.value == selectedItem.extendedLabel) ?? null)
            setSelectedTags(tagsList.filter((tag) => tags.includes(tag.value)))
        } else {
            setSelectedTags([])
            setSelectedCategory(null)
        }
    }, [selectedItem, tagsList, categoryList, setSelectedCategory, setSelectedTags])

    if (isLoading) return <Loading />

    return (
        <>
            <div className='padding-16 flex-column'>
                {eposList ? (
                    <>
                        <p>Please select a epos categories below to edit:</p>
                        <div className='flex-row flex-gap-16 width-100'>
                            <Select
                                options={eposList}
                                className='width-100 react-select-container'
                                onChange={onListChange}
                                filterOption={selectFilter()}
                                value={selectedItem}
                                isClearable={true}
                                theme={selectTheme}
                            />
                        </div>
                    </>
                ) : null}
            </div>
            <Divider padding={16} />
            <div className='padding-16 flex-column'>
                <Card title={`Edit Epos Category`} className='min-width-50' overflowHidden={false}>
                    <form onSubmit={onSubmit}>
                        <div className='flex-column flex-column-stretch '>
                            <div className='flex-row-center-vertical flex-spacebetween flex-gap-16'>
                                <label htmlFor='label'>Epos Category:</label>
                                <input
                                    id='label'
                                    type='text'
                                    className='rs-input flex-100'
                                    value={selectedItem?.label ?? ""}
                                    maxLength={inputMaxLength.eposCategoryName}
                                    required
                                    readOnly
                                    disabled={true}
                                />
                            </div>

                            <div className='flex-row-center-vertical flex-spacebetween flex-gap-16'>
                                <label htmlFor='category'>Category:</label>
                                <Select
                                    inputId='category'
                                    required
                                    filterOption={selectFilter()}
                                    className='width-80 react-select-container'
                                    theme={selectTheme}
                                    options={categoryList ?? undefined}
                                    isLoading={!tagsList}
                                    isDisabled={!selectedItem?.label}
                                    value={selectedCategory}
                                    onChange={(e) => {
                                        setSelectedCategory(e)
                                    }}
                                />
                            </div>
                            <div className='flex-row-center-vertical flex-spacebetween flex-gap-16'>
                                <label htmlFor='tags'>Tags:</label>
                                <Select
                                    inputId='tags'
                                    className='width-80 react-select-container'
                                    isClearable={true}
                                    filterOption={selectFilter()}
                                    formatOptionLabel={formatTagsLabel}
                                    theme={selectTheme}
                                    options={tagsList ?? undefined}
                                    isLoading={!tagsList}
                                    isDisabled={!selectedItem?.label}
                                    value={selectedTags}
                                    isMulti
                                    onChange={(e) => {
                                        setSelectedTags(e)
                                    }}
                                />
                            </div>

                            <button className='button-contained' type='submit' disabled={!selectedItem?.value || isLoading}>
                                Save
                            </button>
                        </div>
                    </form>
                </Card>
            </div>
        </>
    )
}

export default AdminEposCategoriesList
