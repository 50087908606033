import { default as MuiTabs } from "@mui/material/Tabs"
import { default as MuiTab } from "@mui/material/Tab"

/**
 * Wrapper around MUI Tabs
 */
export function Tabs(props) {
    const { children, ...tabProps } = props
    return (
        <MuiTabs {...tabProps} scrollButtons='auto' variant='scrollable' allowScrollButtonsMobile>
            {children}
        </MuiTabs>
    )
}

/**
 * Wrapper around Tab Tabs
 */
export function Tab(props) {
    const { children, ...tabProps } = props
    return <MuiTab {...tabProps}>{children}</MuiTab>
}

/**
 * Simple tab panel to go with Tabs & Tab
 */
export function TabPanel(props) {
    const { children, value, index, ...other } = props
    return (
        <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
            {value === index ? children : null}
        </div>
    )
}
