import React from "react"
import { Link } from "react-router-dom"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import NotificationItem from "./NotificationItem"

/**
 * A list of notifications in a dropdown
 */
const NotificationDropdown = ({ userNotifications, deleteAllNotifications, deleteNotification }) => {
    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    return (
        <div className='notification-outer-wrapper' onClick={stopPropagation}>
            <div className='notifications-wrapper'>
                {userNotifications?.length > 0 ? (
                    <>
                        <div className='notifications-items'>
                            {userNotifications.map((notif) => {
                                return <NotificationItem key={notif.uuid} item={notif} deleteNotificationClick={deleteNotification} />
                            })}
                        </div>
                        <div className='notifications-quick-btns'>
                            <a className='notification-clear-btn width-100' onClick={deleteAllNotifications}>
                                <DeleteForeverIcon />
                                Clear All
                            </a>
                        </div>
                    </>
                ) : (
                    <div className='notifications-no-items'>
                        <p>No new notifications!</p>
                        <p>
                            <Link to='/user/notifications'>View all notifications</Link>
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default NotificationDropdown
