import React, { useRef, useEffect, useState } from "react"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import VisibilityIcon from "@mui/icons-material/Visibility"

import { newTicketMessageFunc } from "../../components/httpRequests/TicketsRequests"
import Switch from "../../components/mui/Switch"
import useUser from "../../hooks/useUser"
import SlateTextArea from "../../components/forms/SlateTextArea"
import IconButton from "../../components/mui/IconButton"
import useAlertContext, { AlertTypes } from "../../hooks/useAlertContext"
import { axiosErrorMessage } from "../../components/axios"
import TicketAttachmentDropzone from "./TicketAttachmentDropzone"

/**
 * Ticket Message input area
 * @param {{
 * ticketId: number,
 * refreshTicketQuery: () => {},
 * currentAssignedId: string|undefined,
 * isTicketClosed: boolean,
 * userOptions: Array|undefined
 * }} param0
 */
const TicketMessageInput = ({ ticketId, refreshTicketQuery, currentAssignedId = undefined, isTicketClosed = false, userOptions = undefined }) => {
    const { auth, hasAuthRole_TechnicianOrAbove, hasAuthRole_Admin } = useUser()
    const { addSnackbarMessage } = useAlertContext()
    const dropzoneRef = useRef()

    const [isPrivate, setIsPrivate] = useState(false)
    const [assignedToMe, setAssignedToMe] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [newMessage, setNewMessage] = useState("")
    const [mentions, setMentions] = useState([])
    const [acceptedFiles, setAcceptedFiles] = useState([])

    const isAdmin = hasAuthRole_Admin() ?? false
    const isTechnicianOrAbove = hasAuthRole_TechnicianOrAbove() ?? false

    /**
     * Submit the new
     */
    const onSubmitMessage = async (e) => {
        e.preventDefault()
        if (isSubmitting) return

        setIsSubmitting(true)
        try {
            await newTicketMessageFunc(ticketId, newMessage, isPrivate, assignedToMe, mentions, acceptedFiles)

            refreshTicketQuery()
            setNewMessage("")
            setIsPrivate(false)
            setMentions([])
            setAcceptedFiles([])
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    /**
     * Called when the login uuid or current assigned to user changes
     */
    useEffect(() => {
        if (isTechnicianOrAbove) setAssignedToMe(auth?.uuid === currentAssignedId)
    }, [auth?.uuid, currentAssignedId])

    // Can the message be submitted
    const canSubmitMessage = newMessage?.trim() && !isSubmitting && !isTicketClosed

    // Render the component
    return isTicketClosed ? (
        <div className='alert alert-success border-radius-0 text-faded '>
            <p className='padding-16 text-center flex-100'>Please re-open the ticket to send a new message.</p>
        </div>
    ) : (
        <div className='padding-top-16'>
            <form onSubmit={onSubmitMessage} className='bg-light'>
                <TicketAttachmentDropzone
                    ref={dropzoneRef}
                    showAcceptedFiles={true}
                    clickToOpen={false}
                    setAcceptedFiles={setAcceptedFiles}
                    acceptedFiles={acceptedFiles}
                >
                    <div className=' flex-row-center-vertical flex-gap-none'>
                        <IconButton
                            hintText='Add Attachment.'
                            onClick={() => {
                                dropzoneRef.current.open()
                            }}
                        >
                            <AddCircleIcon />
                        </IconButton>
                        {isAdmin ? (
                            <IconButton
                                color={isPrivate ? "error" : "default"}
                                hintText={isPrivate ? "Private Admin Message" : "Public Message"}
                                onClick={() => setIsPrivate((currentValue) => !currentValue)}
                            >
                                {isPrivate ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        ) : null}

                        <div className='flex-100'></div>
                        {isAdmin || isTechnicianOrAbove ? (
                            <Switch
                                id='assignedToMe'
                                checkedLabel='Assigned To Me'
                                uncheckedLabel='Not Assigned To Me'
                                checked={assignedToMe ?? false}
                                onChange={(e) => {
                                    setAssignedToMe(e.target.checked ? true : false)
                                }}
                            />
                        ) : null}
                    </div>

                    <div className='flex-row-stretch flex-gap-none'>
                        <SlateTextArea
                            className='rs-input ticket-text-area flex-100'
                            value={newMessage}
                            placeholder='Enter a new message here...'
                            rows={0}
                            minRows={6}
                            maxRows={15}
                            onChange={(e) => {
                                setNewMessage(e.target.value)
                                setMentions(e.target.mentions)
                            }}
                            allowResize={false}
                            autoFocus={true}
                            required={true}
                            disabled={isSubmitting || isTicketClosed}
                            userOptions={userOptions}
                        />

                        <button className={`ticket-button ${isPrivate ? "button-contained-danger" : "button-contained-20"}`} disabled={!canSubmitMessage}>
                            Send Message
                        </button>
                    </div>
                </TicketAttachmentDropzone>
            </form>
        </div>
    )
}

export default TicketMessageInput
