export const roles = {
    admin: 2099,
    telesales: 2050,
    technician: 2090,
    store_admin: 2009,
    store_user: 2001,
}

Object.freeze(roles)

/**
 * Fixed organisations
 */
export const organisations = {
    unassigned: "00000000-0000-0000-0000-000000000000",
}

Object.freeze(organisations)

/**
 * Input max lengths
 */
export const inputMaxLength = {
    /* storage */
    storageTitle: 128,
    storageCategoryName: 50,
    storageKeywords: 255,
    /* Tickets */
    ticketTitle: 128,
    ticketCategoryName: 50,
    ticketPriorityName: 50,
    ticketStatusName: 50,
    /* Users */
    userName: 50,
    userEmail: 50,
    userPhone: 50,
    /* Filters */
    ticketFilterName: 50,
    /* Roles */
    roleName: 50,
    /* organisation */
    organisationCode: 50,
    organisationName: 50,
    organisationNickname: 1024,
    organisationContact: 50,
    organisationPhone1: 50,
    organisationPhone2: 50,
    organisationEmail: 50,
    organisationAddress: 1024,
    /* tags */
    tagLabel: 50,
    /* group */
    groupName: 50,
    /* templates */
    templateTitle: 128,
    /* epos catogory */
    eposCategoryName: 128,
}

Object.freeze(inputMaxLength)
