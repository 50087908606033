import React, { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"

import "../pages/styling/dashboard.css"

import { getTicketTemplatesValuePairsListFunc } from "./httpRequests/TicketTemplateRequests"
import { axiosErrorMessage } from "./axios"
import useAlertContext, { AlertTypes } from "../hooks/useAlertContext"
import Loading from "./Loading"
import TicketTemplateIcon from "./TicketTemplateIcon"

/**
 * List of ticket templates
 */
function TemplateList() {
    const { addSnackbarMessage } = useAlertContext()
    const [searchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [templates, setTemplates] = useState(null)

    const orgUuid = searchParams?.get("orgUuid")

    /**
     * Load tghe template list
     */
    const getTicketTemplates = async (abortController) => {
        setIsLoading(true)
        try {
            const templateRes = await getTicketTemplatesValuePairsListFunc(abortController)
            setTemplates(templateRes?.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Called when the page is first loaded
     */
    useEffect(() => {
        const abortController = new AbortController()
        getTicketTemplates(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [])

    return (
        <div className='flex-row flex-gap-16 dashboard-row-container'>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <TemplateButton icon={0} label={"Open New Ticket"} to={`/new-ticket${orgUuid ? `?orgUuid=${orgUuid}` : ""}`} />

                    {templates?.map((t) => {
                        return (
                            <TemplateButton
                                key={t.value}
                                icon={t.extendedLabel}
                                label={t.label}
                                to={`/new-ticket/template/${t.value}${orgUuid ? `?orgUuid=${orgUuid}` : ""}`}
                            />
                        )
                    })}
                </>
            )}
        </div>
    )
}

/**
 * A single template button
 */
const TemplateButton = ({ icon, label, to }) => {
    return (
        <Link className='dashboard-button dashboard-clickable' to={to}>
            <span className='db-icon'>{<TicketTemplateIcon icon={icon} />}</span>
            <p className='db-text'>{label}</p>
        </Link>
    )
}

export default TemplateList
