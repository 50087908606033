import React from "react"
import { useLocation } from "react-router-dom"

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"
import PersonIcon from "@mui/icons-material/Person"
import SettingsIcon from "@mui/icons-material/Settings"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import SpeedIcon from "@mui/icons-material/Speed"
import BarChartIcon from "@mui/icons-material/BarChart"
import EngineeringIcon from "@mui/icons-material/Engineering"
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates"
import LightbulbIcon from "@mui/icons-material/Lightbulb"
import GroupsIcon from "@mui/icons-material/Groups"
import StoreIcon from "@mui/icons-material/Store"
import AccountTreeIcon from "@mui/icons-material/AccountTree"

import "../../pages/styling/general.css"
import "./NavMenu.css"

import axios from "../axios"
import NavItem from "./NavItem"
import useUser from "../../hooks/useUser"
import Divider from "../dividers/Divider"
import { useIsSmallScreen } from "../../hooks/useWindowDimensions"

/**
 * The left hand sidebar
 */
const NavMenu = ({ navbarIsOpen = false, setNavbarIsOpen }) => {
    const currentPath = useLocation().pathname
    const { hasAuthRole_Admin, hasAuthRole_StoreAdminOrAbove, hasAuthRole_TechnicianOrAbove } = useUser()
    const isSmallScreen = useIsSmallScreen()

    const isAdmin = hasAuthRole_Admin()
    const isTechnicianOrAbove = hasAuthRole_TechnicianOrAbove()
    const isStoreAdminOrAbove = hasAuthRole_StoreAdminOrAbove()

    /**
     * Call the logout route
     */
    const logoutClick = async (e) => {
        e.preventDefault()
        try {
            await axios.get("api/logout")
        } catch (e) {
            console.log("logout error")
        } finally {
            window.location.reload()
        }
    }

    /**
     * Close the draw
     */
    const closeDrawClick = () => {
        if (!isSmallScreen) return
        if (setNavbarIsOpen) setNavbarIsOpen(false)
    }

    return (
        <nav className={`${navbarIsOpen ? "" : "sidebar-wrapper-closed"} sidebar-wrapper`}>
            <div className='menu-wrapper'>
                <div className='nav-items-wrapper'>
                    <NavItem
                        path='/dashboard'
                        currentPath={currentPath}
                        icon={<SpeedIcon />}
                        label='Dashboard'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                    />
                    <NavItem
                        path='/tickets'
                        currentPath={currentPath}
                        icon={<ConfirmationNumberIcon />}
                        label='Tickets'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                    />
                    <NavItem
                        path='/knowledge-base'
                        currentPath={currentPath}
                        icon={<TipsAndUpdatesIcon />}
                        label='Knowledge Base'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                    />

                    <NavItem
                        path='/statistics'
                        currentPath={currentPath}
                        icon={<BarChartIcon />}
                        label='Statistics'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                        display={isTechnicianOrAbove}
                    />

                    <Divider colour='white' width={100} />

                    <NavItem
                        path='/organisations'
                        currentPath={currentPath}
                        icon={<StoreIcon />}
                        label='Organisations'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                        display={isStoreAdminOrAbove}
                    />
                    <NavItem
                        path='/admin/groups'
                        currentPath={currentPath}
                        icon={<GroupsIcon />}
                        label='Groups'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                        display={isAdmin}
                    />
                    <NavItem
                        path='/admin/users'
                        currentPath={currentPath}
                        icon={<PersonIcon />}
                        label='Users'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                        display={isAdmin}
                    />
                    <NavItem
                        path='/admin/knowledge-base'
                        currentPath={currentPath}
                        icon={<LightbulbIcon />}
                        label='Edit Knowledge Base'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                        display={isAdmin}
                    />

                    <NavItem
                        path='/admin/ticket-templates'
                        currentPath={currentPath}
                        icon={<AccountTreeIcon />}
                        label='Edit Ticket Templates'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                        display={isAdmin}
                    />

                    <NavItem
                        path='/admin/settings'
                        currentPath={currentPath}
                        icon={<EngineeringIcon />}
                        label='Admin Settings'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                        display={isAdmin}
                    />
                </div>
                <div className='nav-items-wrapper'>
                    <NavItem
                        path='/user/settings'
                        currentPath={currentPath}
                        icon={<SettingsIcon />}
                        label='User Settings'
                        showLabel={navbarIsOpen}
                        onClick={closeDrawClick}
                    />

                    <NavItem path='#' icon={<ExitToAppIcon />} label='Logout' showLabel={navbarIsOpen} onClick={logoutClick} />
                </div>
            </div>
        </nav>
    )
}

export default NavMenu
