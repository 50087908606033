import React, { useState } from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import "../../pages/styling/general.css"
import "../ComponentStyles.css"

import ThreeDots from "../ThreeDots"
import Dropdown from "./Dropdown"

/**
 * Dropdown menu with options and a title
 */
const DropdownMenu = ({ options, type, label, text, size }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const removeDropdown = () => {
        setIsDropdownOpen(false)
    }

    const openDropdown = (e, bool) => {
        e.stopPropagation()
        if (bool === false || bool === true) setIsDropdownOpen(bool)
        else setIsDropdownOpen(!isDropdownOpen)
    }

    return (
        <>
            {isDropdownOpen && <div className='transparent-full-screen' onClick={removeDropdown} />}
            <div className='dropdown-container flex-column flex-gap-2'>
                {label && <p>{label}</p>}
                {type === "BUTTON" && (
                    <button className={`${size === "large" ? "button-input" : "button-input-small"} flex-row flex-gap-2`} onClick={openDropdown}>
                        {text}
                        <KeyboardArrowDownIcon />
                    </button>
                )}
                {(type === "DOTS" || !type) && <ThreeDots openDropdown={openDropdown} />}
                {isDropdownOpen && <Dropdown options={options} removeDropdown={removeDropdown} size={size} />}
            </div>
        </>
    )
}

export default DropdownMenu
