import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import AddBoxIcon from "@mui/icons-material/AddBox"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import EditIcon from "@mui/icons-material/Edit"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import VisibilityIcon from "@mui/icons-material/Visibility"

import "../../styling/users.css"
import { Header2 } from "../../../components/headers/Headers"
import DataTable from "../../../components/mui/DataTable"
import MetaData from "../../../components/MetaData"
import { getTicketTemplatesListFunc, deleteTicketTemplateFunc } from "../../../components/httpRequests/TicketTemplateRequests"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import { axiosErrorMessage } from "../../../components/axios"
import ButtonMenu from "../../../components/mui/ButtonMenu"
import ConfirmDialog from "../../../components/mui/ConfirmDialog"
import TicketTagBar from "../../../components/utility/TicketTagBar"
import TicketTemplateIcon from "../../../components/TicketTemplateIcon"
import { dateFormat, dateTimeFormat } from "../../../components/utility/ConvertTime"
import Tooltip from "../../../components/mui/Tooltip"

/**
 * Basic column with a link
 */
const basicColumns = (params) => {
    return <Link to={`/admin/ticket-templates/${params.id}/edit`}>{params.value}</Link>
}

/**
 * Formated column with a link and tool tip
 */
const formatedColumnsWithTip = (id, formatedValue, toolTip) => {
    return (
        <Tooltip text={toolTip}>
            <Link to={`/admin/ticket-templates/${id}/edit`}>{formatedValue}</Link>
        </Tooltip>
    )
}

/**
 * Ticket Templates page
 */
const TicketTemplates = () => {
    const navigate = useNavigate()
    const { addSnackbarMessage } = useAlertContext()
    const [templates, setTemplates] = useState(null)
    const [deleteItem, setDeleteItem] = useState(null)

    /**
     * Load the page data
     */
    const getInitialData = async (abortController) => {
        try {
            const response = await getTicketTemplatesListFunc(abortController, true)
            setTemplates(response.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        }
    }

    /**
     * Edit item menu clicked
     */
    const editItemMenuClick = (itemUuid) => {
        navigate(`/admin/ticket-templates/${itemUuid}/edit`)
    }

    /**
     * Set the delete item (menu clicked)
     */
    const deleteItemMenuClick = (templateId) => {
        const item = templates?.find((d) => d.id === templateId)
        if (!item) return
        setDeleteItem(item)
    }

    const handelCloseModal = () => {
        setDeleteItem(null)
    }

    /**
     * Send the delete to the server
     */
    const deleteItemConfirm = async () => {
        if (deleteItem == null) return
        handelCloseModal()

        try {
            const res = await deleteTicketTemplateFunc(deleteItem.id)
            if (res.status === 200) addSnackbarMessage("Ticket Template successfully deleted.", AlertTypes.Success)
            await getInitialData()
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        }
    }

    /**
     * Data Table fields
     */
    const columns = [
        {
            field: "id",
            headerName: "Id",
            flex: 1,
            renderCell: basicColumns,
        },
        {
            field: "icon",
            headerName: "Icon",
            minWidth: 28,
            maxWidth: 28,
            renderHeader: () => <>&nbsp;</>,
            renderCell: (params) => <>{<TicketTemplateIcon icon={params.value} />}</>,
        },
        {
            field: "tags",
            headerName: "Tags",
            minWidth: 28,
            maxWidth: 28,
            renderHeader: () => <>&nbsp;</>,
            renderCell: (params) => {
                return (
                    <div className='flex-row flex-gap-none'>
                        {params.value &&
                            params.value.map((t) => {
                                return <TicketTagBar color={t.extendedLabel} label={t.label} key={t.value} />
                            })}
                    </div>
                )
            },
        },
        {
            field: "title",
            headerName: "Template Title",
            flex: 1,
            minWidth: 100,
            hideable: false,
            renderCell: basicColumns,
        },
        {
            field: "enabled",
            headerName: "Enabled",
            flex: 0.5,
            type: "boolean",
            minWidth: 50,
        },
        {
            field: "isPrivate",
            headerName: "Ticket Visibility",
            renderCell: (params) =>
                formatedColumnsWithTip(
                    params.id,
                    params.value ? <VisibilityOffIcon /> : <VisibilityIcon />,
                    `${params.value ? "Only Admins" : "Anyone"} can see tickets created with this template`
                ),
            align: "center",
        },
        {
            field: "adminOnly",
            headerName: "Admin Only",
            flex: 0.5,
            type: "boolean",
            minWidth: 50,
        },
        {
            field: "category",
            headerName: "Category",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <Link to={`/admin/ticket-templates/${params.id}/edit`}>{params.value?.label}</Link>,
        },
        {
            field: "slaDays",
            headerName: "SLA",
            type: "number",
            renderCell: (params) => <Link to={`/admin/ticket-templates/${params.id}/edit`}>{params.value ? `${params.value} days` : "none"}</Link>,
        },
        {
            field: "updatedAt",
            headerName: "Updated",
            flex: 1,
            minWidth: 50,
            renderCell: (params) => formatedColumnsWithTip(params.id, dateFormat(params.value), dateTimeFormat(params.value)),
        },
        {
            field: "inputCount",
            headerName: "Inputs",
            flex: 0.5,
            type: "number",
            minWidth: 100,
            renderCell: basicColumns,
        },
        {
            field: "__actions",
            headerName: "...",
            flex: 1,
            hideable: false,
            headerAlign: "right",
            align: "right",
            maxWidth: 50,
            renderCell: (params) => (
                <>
                    <ButtonMenu
                        id={params.id}
                        menuItems={[
                            {
                                label: "Edit",
                                icon: EditIcon,
                                func: () => editItemMenuClick(params.id),
                            },
                            {
                                label: "Delete",
                                icon: DeleteForeverIcon,
                                func: () => deleteItemMenuClick(params.id),
                            },
                        ]}
                    />
                </>
            ),
        },
    ]

    /**
     * Called when the page loads
     */
    useEffect(() => {
        const filtersController = new AbortController()

        getInitialData(filtersController)

        // Clean up function
        return () => filtersController.abort()
    }, [])

    return (
        <>
            <ConfirmDialog
                isOpen={deleteItem != null}
                title={`Delete ${deleteItem?.title}`}
                description='Are you sure that you want to permanently delete this item?'
                onClose={handelCloseModal}
                onNoClick={handelCloseModal}
                onYesClick={deleteItemConfirm}
            />
            <div className='page-wrapper'>
                <MetaData title={`Ticket Templates`} />
                <div className='padding-16 divider-line-black-bottom'>
                    <Link to='/admin/ticket-templates/new' style={{ textDecoration: "none" }} className='button-contained'>
                        <AddBoxIcon />
                        New Template
                    </Link>
                </div>
                <div className='padding-16'>
                    <Header2 title='Ticket Templates' />

                    <DataTable
                        tableId={"ticket-templates-table"}
                        rows={templates}
                        columns={columns}
                        getRowId={(u) => u.id}
                        defaultColumnVisibility={{ id: false, updatedAt: false }}
                        checkboxSelection={false}
                        hideFooter={false}
                    />
                </div>
            </div>
        </>
    )
}

export default TicketTemplates
