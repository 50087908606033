import React, { useEffect, useState } from "react"

import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation"
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import ClearIcon from "@mui/icons-material/Clear"
import RefreshIcon from "@mui/icons-material/Refresh"

import "../../pages/styling/tickets.css"

import axios from "../../components/axios"
import DropdownMenu from "../../components/nav/DropdownMenu"
import useTicketFilter from "../../hooks/useTicketFilter"
import { defaultFilters } from "../../common/searchFilters"
import TicketFilterSettingsModal from "../../pages/tickets/TicketFilterSettingsModal"
import { getFiltersFunc } from "../../components/httpRequests/TicketsRequests"
import NewFilterModal from "../../pages/tickets/NewFilterModal"
import MetaData from "../MetaData"
import IconButton from "../mui/IconButton"
import TicketTable from "./TicketTable"
import useRepeatingFetch from "../../hooks/useRepeatingFetch"
import useDebounceState from "../../hooks/useDebounceState"

import useUser from "../../hooks/useUser"

/**
 * Main tickits table
 */
const TicketTableWapper = () => {
    const { filterSettings, resetFilters, setFilterSettings } = useTicketFilter()
    const [tickets, fetchTickets] = useRepeatingFetch("/api/ticket/all", 900, true)
    const [filteredTickets, setFilteredTickets] = useState(null)
    const { hasAuthRole_Admin } = useUser()

    /**
     * A list of the users custom filters
     */
    const [usersFilters, setUsersFilters] = useState(null)

    const [searchValue, setSearchValue] = useDebounceState("")
    const [usersFiltersOptions, setUsersFiltersOptions] = useState(null)
    const [openFilterSettingsModal, setOpenFilterSettingsModal] = useState(false)
    const [openNewFilterModal, setOpenNewFilterModal] = useState(false)
    const [initialLoad, setInitialLoad] = useState(false)

    /**
     * Array of selected ticket ids
     */
    const [selectedTickets, setSelectedTickets] = useState([])

    const isAdmin = hasAuthRole_Admin()

    /**
     * Refresh the users filters
     */
    const refreshUserFilters = async (abortController) => {
        try {
            const response = await getFiltersFunc(abortController)
            setUsersFilters(response.data)
            setupFilterOptionsDropdown(response.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Gets the filtered list of tickts
     */
    const refreshTickets = async () => {
        fetchTickets(filterSettings)
    }

    /**
     * TODO move all this into helper functions
     */
    const markAllAsRead = async () => {
        await axios.patch("/api/ticket/readall")
        refreshTickets()
    }

    const subscribeToTickets = async (data) => {
        await axios.patch("api/ticket/subscribe-multiple", { bool: data.data.subscribe, ids: selectedTickets })
        refreshTickets()
    }

    const unsubscribeToTickets = async (data) => {
        await axios.patch("api/ticket/subscribe-multiple", { bool: data.data.subscribe, ids: selectedTickets })
        refreshTickets()
    }

    const closeTickets = async (data) => {
        if (!isAdmin) return
        await axios.patch("api/ticket/close-multiple", { bool: data.data.close, ids: selectedTickets })
        refreshTickets()
    }

    const openTickets = async (data) => {
        if (!isAdmin) return
        await axios.patch("api/ticket/close-multiple", { bool: data.data.close, ids: selectedTickets })
        refreshTickets()
    }

    const deleteTickets = async () => {
        if (!isAdmin) return
        await axios.patch("api/ticket/delete-tickets", { ids: selectedTickets })
        refreshTickets()
    }

    /**
     * Ticked Actions dropdown menu options
     */
    const ticketOptions = [
        {
            text: "Mark all as read",
            icon: MarkEmailReadIcon,
            func: markAllAsRead,
            confirmation: false,
            hidden: false,
        },
        {
            text: "Subscribe to tickets",
            icon: NotificationsActiveIcon,
            func: subscribeToTickets,
            confirmation: false,
            data: {
                subscribe: true,
            },
            hidden: false,
        },
        {
            text: "Unsubscribe to tickets",
            icon: NotificationsOffIcon,
            func: unsubscribeToTickets,
            confirmation: false,
            data: {
                subscribe: false,
            },
            hidden: false,
        },
        {
            text: "Close tickets",
            icon: CancelPresentationIcon,
            func: closeTickets,
            confirmation: false,
            data: {
                close: true,
            },
            hidden: !isAdmin,
        },
        {
            text: "Open tickets",
            icon: LibraryAddCheckIcon,
            func: openTickets,
            confirmation: false,
            data: {
                close: false,
            },
            hidden: !isAdmin,
        },
        {
            text: "Delete tickets",
            icon: DeleteForeverIcon,
            func: deleteTickets,
            confirmation: true,
            confirmationTitle: "Delete Tickets",
            confirmationDescription: "Are you sure that you want to delete the selected tickets?",
            data: {},
            hidden: !isAdmin,
        },
    ]

    /**
     * Callback for when the change filter option is selected
     */
    const loadFilter = (option) => {
        setFilterSettings(option.data)
    }

    const openSavedFiltersModal = (bool) => {
        setOpenFilterSettingsModal(bool)
        if (!bool) {
            refreshUserFilters()
            refreshTickets()
        }
    }

    const setNewFilterModalFunc = (bool) => {
        setOpenNewFilterModal(bool)
    }

    const openNewFilterModalFunc = () => {
        setOpenNewFilterModal(true)
    }

    /**
     * Reset the filters and clear the input box
     */
    const doResetFilters = () => {
        resetFilters()
    }

    /**
     * Check if searchValueLC is in org
     */
    const ticketIncludes = (item, searchValue) => {
        const searchValueLC = searchValue?.toLowerCase()?.trim()
        if (!searchValueLC) return true
        if (!item) return true

        const tags = item.tags?.map((t) => t.label).join(" ")

        // get the search string, lowercase and trimed
        const searchStr = `${tags ?? ""} ${item.org ?? ""} ${item.category ?? ""} ${item.title ?? ""} ${item.createdBy ?? ""} ${item.firstMessage ?? ""}`
            .toLowerCase()
            .trim()
        return searchStr.includes(searchValue)
    }

    /**
     * Filters the array
     * @param {Array} arr
     * @param {string} searchValue
     * @returns Array
     */
    const getNewFilterArray = (arr, searchValue) => {
        if (!arr) return null
        return !searchValue?.trim() ? arr : arr.filter((o) => ticketIncludes(o, searchValue))
    }

    /**
     * Setup the filters dropdown
     */
    const setupFilterOptionsDropdown = (f) => {
        let isFirst = false
        let tempUsersFilters = f.map((filter) => {
            if (isFirst) {
                isFirst = false
                setFilterSettings(filter)
            }
            return {
                text: filter.filterName,
                func: loadFilter,
                data: { ...filter },
            }
        })

        if (!isFirst) {
            tempUsersFilters.push({
                isDivider: true,
            })
        }

        // Default filters
        defaultFilters.forEach((filter) => {
            if (filter.adminOnly && !isAdmin) return

            if (isFirst) {
                isFirst = false
                setFilterSettings(filter)
            }
            tempUsersFilters.push({
                text: filter.filterName,
                func: loadFilter,
                data: { ...filter },
            })
        })

        /**
         * Extra menu items (advanced, update, save)
         */

        tempUsersFilters.push({
            isDivider: true,
        })

        tempUsersFilters.push({
            text: "Advanced Settings",
            icon: OpenInNewIcon,
            func: openSavedFiltersModal,
        })

        tempUsersFilters.push({
            text: "Save Current Filter As New",
            icon: AddCircleOutlineIcon,
            func: openNewFilterModalFunc,
        })

        setUsersFiltersOptions(tempUsersFilters)
    }

    // ******* SIDE EFFECTS *******

    /**
     * Load the users filters and settings
     */
    useEffect(() => {
        const filtersController = new AbortController()

        // Fetch the settings and filters list
        const fetchData = async () => {
            await refreshUserFilters(filtersController)
            setInitialLoad(true)
        }

        fetchData().catch((e) => {
            if (!filtersController?.signal?.aborted) {
                console.error(e)
            }
        })

        // Clean up function
        return () => filtersController.abort()
    }, [])

    /**
     * Refresh the tickets (used on init page load)
     */
    useEffect(() => {
        if (initialLoad) {
            refreshTickets()
        }
    }, [filterSettings, initialLoad])

    /**
     * Called when the ticket search values changes
     */
    useEffect(() => {
        setFilteredTickets(getNewFilterArray(tickets, searchValue))
    }, [searchValue, tickets])

    return (
        <>
            <MetaData title={`${filterSettings ? `${filterSettings.filterName} | ` : ""}Tickets`} />
            {openNewFilterModal && <NewFilterModal closeModal={setNewFilterModalFunc} refreshFilters={refreshUserFilters} />}
            {openFilterSettingsModal && (
                <TicketFilterSettingsModal closeModal={openSavedFiltersModal} usersFilters={usersFilters} refreshUserFilters={refreshUserFilters} />
            )}

            <div className='padding-16'>
                <div className='ticket-filter-wrapper flex-row-bottom flex-wrap'>
                    <DropdownMenu options={ticketOptions} type='BUTTON' text='Actions' size='large' />
                    <input
                        id='searchValue'
                        className='rs-input flex-100'
                        type='text'
                        placeholder='Search Here'
                        defaultValue={searchValue ?? ""}
                        onChange={(e) => {
                            setSearchValue(e.target.value?.toLowerCase())
                        }}
                    />
                    <DropdownMenu
                        options={usersFiltersOptions}
                        type='BUTTON'
                        label='Saved Filters:'
                        text={filterSettings && filterSettings.filterName ? filterSettings.filterName : "No Default"}
                        size='large'
                    />
                    <div className='ticket-filter-buttons'>
                        <IconButton hintText='Clear Filters' size='large' onClick={doResetFilters}>
                            <ClearIcon className='faded-icon' />
                        </IconButton>
                        <IconButton hintText='Refresh' size='large' onClick={refreshTickets}>
                            <RefreshIcon />
                        </IconButton>
                    </div>
                </div>
            </div>

            <TicketTable tickets={filteredTickets} showCheckboxSelection={true} onRowsSelectionChange={setSelectedTickets} />
        </>
    )
}

export default TicketTableWapper
