import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import LoginPageWapper from "../components/LoginPageWapper"
import PasswordInputTips from "../components/forms/PasswordInputTips"
import useAlertContext, { AlertTypes } from "../hooks/useAlertContext"
import { axiosErrorMessage } from "../components/axios"
import { patchConfirmPasswordReset } from "../components/httpRequests/LoginRequests"

/**
 * The re-set users page
 * :userUuid/:passwordResetCode
 */
function ResetPassword() {
    const navigate = useNavigate()
    const { userUuid, passwordResetCode } = useParams()
    const { addSnackbarMessage } = useAlertContext()
    const [newPassword, setNewPassword] = useState(null)
    const [confirmNewPassword, setConfirmNewPassword] = useState(null)
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    /**
     * Log the user into the side
     */
    const handleFormSubmit = async (e) => {
        e.preventDefault()

        if (!newPassword || isSubmitting || !userUuid || !passwordResetCode) return

        setIsSubmitting(true)
        try {
            await patchConfirmPasswordReset(userUuid, newPassword, passwordResetCode)
            setNewPassword(null)
            setConfirmNewPassword(null)
            setIsPasswordValid(false)
            addSnackbarMessage("Your password has been changed", AlertTypes.Success)
            navigate("/")
        } catch (e) {
            addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
        } finally {
            setIsSubmitting(false)
        }
    }

    /**
     * make sure we have a valid code
     */
    useEffect(() => {
        if (!userUuid || !passwordResetCode) navigate("/")
    }, [userUuid, passwordResetCode])

    return (
        <LoginPageWapper showForgotPassword={false} showRegister={false} showLogin={true}>
            <form onSubmit={(e) => handleFormSubmit(e)}>
                <div className='form-section'>
                    <label htmlFor='newPassword'>New Password</label>
                    <input
                        id='newPassword'
                        name='newPassword'
                        type='password'
                        value={newPassword ?? ""}
                        required
                        onChange={(e) => setNewPassword(e.target.value)}
                        className='rs-input'
                        autoComplete='new-password'
                    />
                </div>
                <PasswordInputTips value1={newPassword} value2={confirmNewPassword} setIsValid={(value) => setIsPasswordValid(value)} />
                <div className='form-section'>
                    <label htmlFor='confirmNewPassword'>New Password Confirmation</label>
                    <input
                        id='confirmNewPassword'
                        name='confirmNewPassword'
                        value={confirmNewPassword ?? ""}
                        type='password'
                        required
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        className='rs-input'
                        autoComplete='new-password'
                    />
                </div>

                <div className='form-section'>
                    <button className='button-contained-100' disabled={isSubmitting || !isPasswordValid} type='submit'>
                        Changed Password
                    </button>
                </div>
            </form>
        </LoginPageWapper>
    )
}

export default ResetPassword
