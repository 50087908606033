import React, { forwardRef, useState } from "react"
import Dropzone from "react-dropzone"

import useAlertContext, { AlertTypes } from "../../hooks/useAlertContext"
import Tooltip from "../../components/mui/Tooltip"

/**
 * The file dropzone
 * @param {{children, setAcceptedFiles, acceptedFiles, clickToOpen: boolean, showAcceptedFiles: boolean}} props component props
 * @param {*} ref forward ref for the dropzone
 */
const TicketAttachmentDropzone = forwardRef(function AutoSizeTextArea(props, ref) {
    const { addSnackbarMessage } = useAlertContext()
    const [isDragOver, setIsDragOver] = useState(false)
    const maxFiles = 5

    /**
     * called after a file is added to the dropzone
     */
    const onAddNewFile = (acceptedFiles) => {
        setIsDragOver(false)
        if (acceptedFiles.length > 0 && props.setAcceptedFiles instanceof Function) {
            props.setAcceptedFiles((currentFiles) => [...currentFiles, ...acceptedFiles])
        }
    }

    /**
     * Validates the file is ok
     */
    const fileValidation = (file) => {
        if (props.acceptedFiles?.length >= maxFiles) {
            return {
                code: "too-many-files",
                message: `A minimum of ${maxFiles} files can be added`,
            }
        }

        const fileFound = props.acceptedFiles?.find((f) => f.name === file.name)
        if (fileFound) {
            return {
                code: "file-already-added",
                message: "File with the same name has already been added",
            }
        }

        const invalidFileName = !file.name || file.name.length === 0 || file.name.length >= 128
        if (invalidFileName) {
            return {
                code: "invalid-file-name",
                message: "The filename is not valid",
            }
        }

        return null
    }

    /**
     * Called when a drop is rejected
     */
    const onDropRejected = (fileRejections) => {
        const errorMsg = []
        fileRejections?.forEach((fileRejection) => {
            errorMsg.push(`${fileRejection.file.name} was rejected!`)
            fileRejection.errors?.forEach((error) => {
                errorMsg.push(error.message)
            })
        })

        addSnackbarMessage(
            <div>
                {errorMsg.map((t, index) => (
                    <p key={index}>{t}</p>
                ))}
            </div>,
            AlertTypes.Error
        )
    }
    return (
        <Dropzone
            ref={ref}
            noClick={!props.clickToOpen}
            noKeyboard={!props.clickToOpen}
            multiple
            accept={{
                "image/bmp": [".bmp"],
                "image/png": [".png"],
                "image/gif": [".gif"],
                "image/jpeg": [".jpg", ".jpeg"],
                "image/webp": [".webp"],
                "image/svg+xml": [".svg"],
                "application/pdf": [".pdf"],
                "text/plain": [".txt"],
                "text/csv": [".csv"],
                "text/html": [".html", ".htm"],
                "application/rtf": [".rtf"],
            }}
            validator={fileValidation}
            maxSize={5290080}
            maxFiles={maxFiles}
            onDragEnter={() => setIsDragOver(true)}
            onDragLeave={() => setIsDragOver(false)}
            onFileDialogOpen={() => setIsDragOver(true)}
            onFileDialogCancel={() => setIsDragOver(false)}
            onDropRejected={onDropRejected}
            onDrop={onAddNewFile}
        >
            {({ getRootProps, getInputProps }) => {
                return (
                    <div {...getRootProps({ className: `dropzone ${isDragOver ? "dropzone-over" : ""}` })}>
                        {props.showAcceptedFiles && props.acceptedFiles.length > 0 ? (
                            <div className='flex-row-stretch padding-8 dropzone-files' onClick={(e) => e.stopPropagation()}>
                                {props.acceptedFiles.map((f) => (
                                    <AcceptedFile
                                        key={f.name}
                                        filename={f.name}
                                        onClick={() => {
                                            props.setAcceptedFiles((files) => files.filter((file) => file.name !== f.name))
                                        }}
                                    />
                                ))}
                            </div>
                        ) : null}

                        <div className={props.children ? "dropzone-hint" : "dropzone-target"}>
                            <div>
                                <h3>Add Attachment</h3>
                                <p className='small'>Files added to tickets will be deleted when the ticket is closed.</p>
                                <p className='small'>Max size 5mb</p>
                            </div>
                        </div>
                        <input {...getInputProps()} />
                        {props.children}
                    </div>
                )
            }}
        </Dropzone>
    )
})

/**
 * Accepted file
 */
const AcceptedFile = ({ filename, onClick }) => {
    return (
        <Tooltip text='Click to remove'>
            <span onClick={onClick} className='small clickable pill pill-primary'>
                {filename ?? "untitled"}
            </span>
        </Tooltip>
    )
}

export default TicketAttachmentDropzone
