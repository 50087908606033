import React from "react"
import { Link } from "react-router-dom"

/**
 * The nav back button
 */
function NavBack({ to }) {
    return (
        <Link to={to} style={{ textDecoration: "none" }} className='button-contained'>
            Back
        </Link>
    )
}

export default NavBack
