import { useState } from "react"

export function useLocalStorageState(key, defaultValue) {
    return useStorageState(localStorage, key, defaultValue)
}

export function useSessionStorageState(key, defaultValue) {
    return useStorageState(sessionStorage, key, defaultValue)
}

/**
 * Sets the value in the passed storage container
 * @param {Storage} storage sessionStorage | localStorage
 * @param {string} key the storage key
 * @param {any} defaultValue default value if no value is found in storge
 * @returns [storageValue, setValue]
 */
function useStorageState(storage, key, defaultValue) {
    const [storageValue, setStorageValue] = useState(() => getStorageValue(storage, key, defaultValue))

    const setValue = (value) => {
        // Check if value is a function
        const valueToStore = value instanceof Function ? value(storageValue) : value

        // Set the value to state
        setStorageValue(valueToStore)

        // Set the value to storage
        if (valueToStore === null || valueToStore === undefined) storage?.removeItem(key)
        else storage?.setItem(key, JSON.stringify(valueToStore))
    }

    return [storageValue, setValue]
}

/**
 * Gets the value from the storage matching the key paramater
 */
function getStorageValue(storage, key, defaultValue) {
    const itemFromStorgage = storage?.getItem(key)
    return itemFromStorgage ? JSON.parse(itemFromStorgage) : defaultValue
}
