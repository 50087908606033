import React, { useRef, useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import PreviewIcon from "@mui/icons-material/Preview"
import AddLinkIcon from "@mui/icons-material/AddLink"
import HelpIcon from "@mui/icons-material/Help"
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"

import "./mui_styles.css"

import { TabPanel } from "./Tabs"
import AutoSizeTextArea from "./AutoSizeTextArea"
import MarkdownWrapper from "../MarkdownWrapper"
import IconButton from "./IconButton"
import ConfirmDialog from "./ConfirmDialog"

const helpText =
    "## Headings start with one or more &#x23; \n" +
    "- **Bold** text is wrapped in 2 &#42;, e.g. &#42;&#42;**I'm bold**&#42;&#42; \n" +
    "- *Italic* text is wrapped in &#42;, e.g. &#42;*I'm Italic*&#42; \n" +
    "- ~Strikeout~ text is wrapped in &#42;, e.g. &tilde;~I'm Strikeout~&tilde; \n" +
    "- `Code` is wrapped in &#96;, e.g. &#96;`I'm code`&#96; \n " +
    "- Embed an image with &excl;[Image Title]&lpar;http://url/a.png&rpar;\n\n" +
    "- HTML entities are supported using the &amp;code; e.g. &amp;euro; = &euro; \n" +
    "- In tables use HTML entity &amp;NewLine; for a line break.  \n" +
    "- Add a horizontal line with 3 dashes e.g. ---  \n" +
    "---\n" +
    "### Lists\n" +
    "- &ndash; Lists start each item with a dash or 1. 2. 3. \n " +
    "- &ndash; Item 2\n " +
    "- &ndash; Item 3\n " +
    "---\n" +
    "### Blockquote\n" +
    "> Lines that start with a &gt; become a blockquote \n\n" +
    "> Two or more consecutive lines that start &gt; look like a \n> multi-line blockquote \n\n" +
    "> [!NOTE]  \n" +
    "> Blockquote that have &gt;[!NOTE] as the first line look like this \n\n" +
    "> [!TIP]  \n" +
    "> Blockquote that have &gt;[!TIP] as the first line look like this \n\n" +
    "> [!IMPORTANT]  \n" +
    "> Blockquote that have &gt;[!IMPORTANT] as the first line look like this \n\n" +
    "> [!WARNING]  \n" +
    "> Blockquote that have &gt;[!WARNING] as the first line look like this \n\n" +
    "> [!CAUTION]  \n" +
    "> Blockquote that have &gt;[!CAUTION] as the first line look like this \n\n"
/**
 * Textarea that supports markdown
 */
function MarkdownTextArea(props) {
    const textareaRef = useRef()
    const [tabIndex, setTabIndex] = useState(0)

    const propsCopy = { ...props }

    // get the classname and extra props
    const className = `${propsCopy.className ? propsCopy.className : ""} md-textarea`
    delete propsCopy.className

    /**
     * Form submit
     */
    const onToolbarAction = (action) => {
        if (action && action.markdown && textareaRef.current) {
            const markdown = action.markdown
            const selectionStart = action.data.selectionStart ?? 0
            const selectionEnd = action.data.selectionEnd ?? 0

            // TODO wrap this in a helper function
            const valueSetter = Object.getOwnPropertyDescriptor(textareaRef.current, "value").set
            const prototype = Object.getPrototypeOf(textareaRef.current)
            const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, "value").set

            const currentText = textareaRef.current.value
            const newValue = currentText.slice(0, selectionStart) + markdown + currentText.slice(selectionEnd)

            if (valueSetter && valueSetter !== prototypeValueSetter) {
                prototypeValueSetter.call(textareaRef.current, newValue)
            } else {
                valueSetter.call(textareaRef.current, newValue)
            }
            textareaRef.current.dispatchEvent(new Event("input", { bubbles: true }))
        }
    }

    return (
        <>
            <div className='md-textarea-hint flex-row-center-vertical full-width flex-row-spacebetween'>
                <div className='icon-btn-group'>
                    <IconButton
                        hintText={tabIndex !== 0 ? "Back to edit mode" : "Markdown preview"}
                        onClick={() => setTabIndex(tabIndex !== 0 ? 0 : 1)}
                        color='primary'
                    >
                        {tabIndex !== 0 ? <EditIcon /> : <PreviewIcon />}
                    </IconButton>
                    {tabIndex === 0 ? (
                        <>
                            <MarkdownTextAreaToolbar onToolbarAction={onToolbarAction} textareaRef={textareaRef} />
                        </>
                    ) : null}
                </div>

                <a href='https://www.markdownguide.org/cheat-sheet/' target='_blank' rel='noreferrer'>
                    This box supports Markdown
                </a>

                <IconButton hintText='Markdown help' onClick={() => setTabIndex(2)}>
                    <HelpIcon />
                </IconButton>
            </div>
            <TabPanel value={tabIndex} index={0}>
                <AutoSizeTextArea {...propsCopy} className={`${className}`} ref={textareaRef} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <MarkdownWrapper className='md-textarea-preview'>{props.value ?? props.defaultValue ?? ""}</MarkdownWrapper>
            </TabPanel>
            <TabPanel value={tabIndex} index={2} className='full-width'>
                <MarkdownWrapper className='md-textarea-preview md-textarea-preview-h450'>{helpText}</MarkdownWrapper>
            </TabPanel>
        </>
    )
}

/**
 * The toolbar component for the text area, the parent is responsible for positioning this
 * @param {{onToolbarAction: ({action: string, markdown: string, data: {}}) => {}, textareaRef}} param0
 * @returns
 */
export const MarkdownTextAreaToolbar = ({ onToolbarAction, textareaRef }) => {
    const formRef = useRef()
    const [popupData, setPopupData] = useState(null)

    const doMarkdownHelpOpen = (isImage) => {
        const data = { isImage, selectionStart: textareaRef?.current?.selectionStart, selectionEnd: textareaRef?.current?.selectionEnd }
        console.log(data)
        setPopupData(data)
    }

    const doMarkdownHelpClose = () => {
        setPopupData(null)
    }

    /**
     * Form submit
     */
    const doMarkdownHelpSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (popupData) {
            try {
                const prefix = popupData.isImage ? "!" : ""
                const markdown = ` ${prefix}[${popupData.title}](${popupData.link}) `
                onToolbarAction({ action: "insert", markdown, data: popupData })
            } finally {
                setPopupData(null)
            }
        }
    }

    // Render the toolbar
    return onToolbarAction instanceof Function ? (
        <>
            <ConfirmDialog
                title={`Insert ${popupData?.isImage ? "Image" : "Link"}`}
                isOpen={Boolean(popupData)}
                noLabel='Cancel'
                yesLabel='OK'
                onNoClick={doMarkdownHelpClose}
                onClose={doMarkdownHelpClose}
                yesDisabled={!popupData?.title || !popupData?.link}
                onYesClick={() => {
                    if (formRef?.current) {
                        formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))
                    }
                }}
            >
                <form onSubmit={doMarkdownHelpSubmit} ref={formRef}>
                    <div className='form-section'>
                        <label htmlFor='markdown-title'>Title</label>
                        <input
                            type='text'
                            name='markdown-title'
                            id='markdown-title'
                            className='rs-input'
                            required
                            value={popupData?.title ?? ""}
                            onChange={(e) => {
                                setPopupData((cv) => {
                                    return { ...cv, title: e.target.value }
                                })
                            }}
                        />
                    </div>
                    <div className='form-section'>
                        <label htmlFor='markdown-link'>Link</label>
                        <input
                            type='url'
                            name='markdown-link'
                            id='markdown-link'
                            className='rs-input'
                            required
                            value={popupData?.link ?? ""}
                            onChange={(e) => {
                                setPopupData((cv) => {
                                    return { ...cv, link: e.target.value }
                                })
                            }}
                        />
                    </div>
                </form>
            </ConfirmDialog>

            <IconButton hintText='Add image' onClick={() => doMarkdownHelpOpen(true)}>
                <AddPhotoAlternateIcon />
            </IconButton>
            <IconButton hintText='Add link' onClick={() => doMarkdownHelpOpen(false)}>
                <AddLinkIcon />
            </IconButton>
        </>
    ) : null
}

export default MarkdownTextArea
