import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import BlockIcon from "@mui/icons-material/Block"
import EditIcon from "@mui/icons-material/Edit"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import NicknamesIcon from "@mui/icons-material/AlternateEmail"
import CodeIcon from "@mui/icons-material/Tag"
import AddressIcon from "@mui/icons-material/Home"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import GroupsIcon from "@mui/icons-material/Groups"
import ContactsIcon from "@mui/icons-material/Contacts"

import axios, { axiosErrorMessage } from "../../../components/axios"
import Tooltip from "../../../components/mui/Tooltip"
import useAlertContext, { AlertTypes } from "../../../hooks/useAlertContext"
import MarkdownWrapper from "../../../components/MarkdownWrapper"
import Divider from "../../../components/dividers/Divider"
import Loading from "../../../components/Loading"
import MetaData from "../../../components/MetaData"
import DataSnippet from "../../../components/data/DataSnippet"
import { Tab, TabPanel, Tabs } from "../../../components/mui/Tabs"
import Card from "../../../components/mui/Card"
import StorageList from "../storage/StorageList"
import OrganisationKPIs from "../../../components/OrganisationKPIs"
import TicketTable from "../../../components/data/TicketTable"
import { inProgressStatus } from "../../../common/searchFilters"
import LabelledCheckbox from "../../../components/forms/LabelledCheckbox"
import useDebounceState from "../../../hooks/useDebounceState"
import useUser from "../../../hooks/useUser"

import DataTable, { CommonColumnUserRole } from "../../../components/mui/DataTable"
import ButtonMenu from "../../../components/mui/ButtonMenu"
import UserRolesModal, { userRoleObject } from "../users/UserRolesModal"
import NavBack from "../../../components/nav/NavBack"
import useTicketFilter from "../../../hooks/useTicketFilter"
import { getOrganisationFunc, getOrganisationKPIsFunc, postDisableOrganisationFunc } from "../../../components/httpRequests/OrganisationRequests"
import ProfilePhoto from "../../../components/ProfilePhoto"

/**
 * A single org
 */
const Organisation = () => {
    const navigate = useNavigate()
    const { orgUuid } = useParams()
    const { addSnackbarMessage } = useAlertContext()
    const { setCurrentOrgUuid, hasAuthRole_StoreAdminOrAbove, hasAuthRole_TechnicianOrAbove } = useUser()

    const [orgInfo, setOrgInfo] = useState(null)
    const [orgKPIs, setOrgKPIs] = useState(null)

    const isTechnicianOrAbove = hasAuthRole_TechnicianOrAbove()
    const isStoreAdminOrAbove = hasAuthRole_StoreAdminOrAbove()

    /**
     * Load the data
     */
    const findQuery = async (abortController) => {
        if (!orgUuid) returnToOrgList()

        try {
            const [orgData, kpiRes] = await Promise.all([getOrganisationFunc(orgUuid, abortController), getOrganisationKPIsFunc(orgUuid, abortController)])

            setCurrentOrgUuid(orgData.data?.uuid ?? null)
            setOrgInfo(orgData.data)
            setOrgKPIs(kpiRes.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
                returnToOrgList()
            }
        }
    }

    /**
     * Toggle to organisation disabled state
     */
    const disableOrgClick = async () => {
        if (!isTechnicianOrAbove) return
        await postDisableOrganisationFunc(orgUuid, !orgInfo.isDisabled)
        findQuery()
    }

    /**
     * Go back to the organisations list
     */
    const returnToOrgList = () => {
        navigate(`/organisations`)
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        findQuery(abortController)

        // Clean up function
        return () => {
            setCurrentOrgUuid(null)
            abortController.abort()
        }
    }, [orgUuid])

    if (!orgInfo) return <Loading />

    return (
        <div className='page-wrapper'>
            <MetaData title={`${orgInfo?.name} | Organisation`} />
            <div className='padding-16 divider-line-black-bottom'>
                <div className='flex-row'>
                    <NavBack to={isStoreAdminOrAbove ? "/organisations" : "/dashboard"} />
                    {isStoreAdminOrAbove ? (
                        <Link to={`/organisation/${orgUuid}/edit`} style={{ textDecoration: "none" }} className='button-outlined'>
                            Edit Organisation
                        </Link>
                    ) : null}
                </div>
            </div>
            <div className='padding-16'>
                <div className='flex-row-spacebetween'>
                    <div className='flex-column'>
                        {orgInfo.isDisabled && <span className='pill-danger'>DISABLED</span>}
                        <h2 className='heading'>{orgInfo.name} </h2>
                        <p className='text-faded'>{orgInfo.description && orgInfo.description}</p>
                    </div>
                    {isTechnicianOrAbove ? (
                        <div>
                            {!orgInfo.isDisabled ? (
                                <Tooltip text='Disable Organisation'>
                                    <BlockIcon className='faded-icon' onClick={disableOrgClick} />
                                </Tooltip>
                            ) : (
                                <Tooltip text='Enable Organisation'>
                                    <CheckCircleOutlineIcon className='faded-icon' onClick={disableOrgClick} />
                                </Tooltip>
                            )}
                        </div>
                    ) : null}
                </div>
                <Divider padding={16} />
                <div className='flex-row flex-spacebetween flex-wrap flex-gap-16'>
                    <DataSnippet icon={CodeIcon} title='Code' data={orgInfo.code} />
                    {orgInfo.nicknames && <DataSnippet icon={NicknamesIcon} title='Nicknames' data={orgInfo.nicknames} />}
                    {orgInfo.defaultContact && <DataSnippet icon={ContactsIcon} title='Contact' data={orgInfo.defaultContact} />}
                    {orgInfo.phone1 && <DataSnippet icon={PhoneIcon} title='Phone 1' data={orgInfo.phone1} />}
                    {orgInfo.phone2 && <DataSnippet icon={PhoneIcon} title='Phone 2' data={orgInfo.phone2} />}
                    {orgInfo.email && (
                        <a href={"mailto:" + orgInfo.email}>
                            <DataSnippet icon={EmailIcon} title='Email' data={orgInfo.email} />
                        </a>
                    )}
                    {orgInfo.address && <DataSnippet icon={AddressIcon} title='Address' data={orgInfo.address} />}
                    {orgInfo.groups && (
                        <div className='data-snippet-wrapper flex-column'>
                            <div className='flex-row-center-vertical flex-gap-4'>
                                <GroupsIcon id='data-snippet-icon' />
                                <p>Groups</p>
                            </div>
                            <p>
                                {orgInfo.groups.map((g) => {
                                    return (
                                        <span key={g.uuid}>
                                            {g.value}
                                            <br />
                                        </span>
                                    )
                                })}
                            </p>
                        </div>
                    )}
                </div>
                <Divider padding={16} />
                <OrganisationTabs orgUuid={orgUuid} orgInfo={orgInfo} orgKPIs={orgKPIs} />
            </div>
        </div>
    )
}

/**
 * Organisation Tabs
 */
const OrganisationTabs = ({ orgUuid, orgInfo, orgKPIs }) => {
    const { hasAuthRole_Admin, hasAuthRole_StoreAdminOrAbove, hasAuthRole_TechnicianOrAbove } = useUser()
    const [tabIndex, setTabIndex] = useState(0)

    const isAdmin = hasAuthRole_Admin()
    const isTechnicianOrAbove = hasAuthRole_TechnicianOrAbove()
    const isStoreAdminOrAbove = hasAuthRole_StoreAdminOrAbove()

    return isStoreAdminOrAbove ? (
        <>
            <Tabs
                value={tabIndex}
                onChange={(event, newValue) => {
                    setTabIndex(newValue)
                }}
                className='margin-vertical-8'
            >
                <Tab label='KPIs' />
                <Tab label='Tickets' />
                <Tab label='Users' disabled={!isAdmin} className={isAdmin ? "" : "hidden"} />
                <Tab label='Documents' />
                <Tab label='Notes' disabled={!isTechnicianOrAbove} className={isTechnicianOrAbove ? "" : "hidden"} />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
                <OrganisationTabKPIs orgKPIs={orgKPIs} />
            </TabPanel>

            <TabPanel value={tabIndex} index={1} className='width-100'>
                <OrganisationTabTickets orgUuid={orgUuid} />
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>
                <OrganisationTabUsers orgUuid={orgUuid} />
            </TabPanel>

            <TabPanel value={tabIndex} index={3}>
                <div className='padding-16'>
                    <StorageList isOrgStorage={true} orgUuid={orgUuid} />
                </div>
            </TabPanel>

            <TabPanel value={tabIndex} index={4}>
                <div className='padding-16'>
                    <Card title='Technician Notes'>
                        <MarkdownWrapper>{orgInfo?.notes}</MarkdownWrapper>
                    </Card>
                </div>
            </TabPanel>
        </>
    ) : null
}

/**
 * Organisation Tickets
 */
const OrganisationTabTickets = ({ orgUuid }) => {
    const { getDefaultFilterForUser } = useTicketFilter()

    const [tickets, setTickets] = useState(null)
    const [showClosed, setShowClosed] = useState(false)
    const [searchValue, setSearchValue] = useDebounceState("")

    const customFilter = (orgUuid) => {
        const filter = { ...getDefaultFilterForUser() }
        filter.filterName = "Organisation KPIs"
        filter.organisations = [orgUuid]
        filter.ticketStatus = showClosed ? [] : [inProgressStatus]
        filter.titleContains = searchValue ? searchValue : null
        return filter
    }

    /**
     * Get the data
     */
    const getTickets = async (abortController) => {
        try {
            if (orgUuid) {
                try {
                    const response = await axios.post("/api/ticket/all", { ...customFilter(orgUuid) }, { signal: abortController?.signal })

                    setTickets(response.data)
                } catch (e) {
                    if (!abortController?.signal?.aborted) console.error(e)
                    setTickets(null)
                }
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
            setTickets(null)
        }
    }

    /**
     * Called when the control fist mounts
     */
    useEffect(() => {
        const controller = new AbortController()
        getTickets(controller)

        // Clean up function
        return () => controller.abort()
    }, [showClosed, searchValue])

    return (
        <>
            <div className='padding-16 divider-line-black-bottom flex-column flex-column-small'>
                <input
                    id='searchValue'
                    className='rs-input width-100'
                    type='text'
                    placeholder='Search Here'
                    defaultValue={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value?.toLowerCase())
                    }}
                />
                <LabelledCheckbox
                    onChange={(e) => {
                        setShowClosed(e.target.checked)
                    }}
                    label='Show Closed Tickets'
                    id='show-closed'
                    className='padding-vertical-8'
                />
            </div>
            {tickets ? <TicketTable tickets={tickets} showCheckboxSelection={false} /> : <Loading />}
        </>
    )
}

/**
 * Organisation Users
 */
const OrganisationTabUsers = ({ orgUuid }) => {
    const { addSnackbarMessage } = useAlertContext()
    const { hasAuthRole_Admin } = useUser()

    const [userRole, setUserRole] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [orgUsers, setOrgUsers] = useState(null)

    const isAdmin = hasAuthRole_Admin()

    /**
     * Load the data
     */
    const findUserData = async (abortController) => {
        if (!orgUuid) return

        setIsLoading(true)
        try {
            const orgUserList = await axios.get(`/api/user/org-users/${orgUuid}`, { signal: abortController?.signal })
            setOrgUsers(orgUserList.data)
        } catch (e) {
            if (e.request?.status === 404) {
                setOrgUsers([])
            } else if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
            }
        } finally {
            setUserRole(null)
            setIsLoading(false)
        }
    }

    /**
     * Close the user permission modal
     */
    const doCloseRolesModal = (updated = false) => {
        setUserRole(null)
        if (updated) findUserData()
    }

    /**
     * Opens the roles model
     */
    const doOpenRolesModal = (action, userUuid, roleId = undefined) => {
        setUserRole({ ...userRoleObject, userUuid, itemUuid: orgUuid, roleId, action })
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        findUserData(abortController)

        // Clean up function
        return () => {
            abortController.abort()
        }
    }, [orgUuid])

    /**
     * Data Table fields (Users)
     */
    const userColumns = [
        {
            field: "uuid",
            headerName: "Id",
            flex: 1,
            renderCell: (params) => <Link to={`/admin/user/${params.id}`}>{params.value}</Link>,
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => (
                <Link to={`/admin/user/${params.id}`} className='profile-cell'>
                    <ProfilePhoto userUuid={params.id} userName={params.value} size='small' />
                    {params.value}
                </Link>
            ),
        },
        {
            field: "role",
            headerName: "Organisation Role",
            flex: 1,
            renderCell: CommonColumnUserRole,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            renderCell: (params) => <Link to={`/admin/user/${params.id}`}>{params.value}</Link>,
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            renderCell: (params) => <Link to={`/admin/user/${params.id}`}>{params.value}</Link>,
        },
        {
            field: "ticketsOpened",
            headerName: "Tickets Opened",
            flex: 0.5,
            type: "number",
            renderCell: (params) => <Link to={`/admin/user/${params.id}`}>{params.value}</Link>,
        },
        {
            field: "__actions",
            headerName: "...",
            flex: 1,
            hideable: false,
            headerAlign: "right",
            align: "right",
            maxWidth: 50,
            renderCell: (params) =>
                isAdmin ? (
                    <>
                        <ButtonMenu
                            id={params.id}
                            menuItems={[
                                {
                                    label: "Edit User Permissions",
                                    icon: EditIcon,
                                    func: () => doOpenRolesModal("edit", params.row.uuid, params.row.role?.value),
                                },
                                {
                                    label: "Delete User",
                                    icon: DeleteForeverIcon,
                                    func: () => doOpenRolesModal("delete", params.row.uuid),
                                },
                            ]}
                        />
                    </>
                ) : null,
        },
    ]

    return isLoading ? (
        <Loading />
    ) : (
        <>
            {userRole && <UserRolesModal isAdmin={isAdmin} isOrganisation={true} onCloseModal={doCloseRolesModal} userRole={userRole} />}
            <div>
                <DataTable
                    tableId={"org-users-table"}
                    rows={orgUsers}
                    columns={userColumns}
                    getRowId={(u) => u.uuid}
                    defaultColumnVisibility={{
                        uuid: false,
                        description: false,
                    }}
                    checkboxSelection={false}
                    hideFooter={false}
                />

                {isAdmin ? (
                    <button className='button-contained' onClick={() => doOpenRolesModal("create", undefined)}>
                        Add User To Organisation
                    </button>
                ) : null}
            </div>
        </>
    )
}

/**
 * Organisation KPIs
 */
const OrganisationTabKPIs = ({ orgKPIs }) => {
    return orgKPIs ? (
        <>
            <div className='flex-row padding-16'>
                <OrganisationKPIs
                    orgUuid={orgKPIs.orgUuid}
                    ticketsLoggedPastWeek={orgKPIs.ticketsLoggedPastWeek}
                    ticketsLoggedPastMonth={orgKPIs.ticketsLoggedPastMonth}
                />
            </div>
            <div>
                {orgKPIs.openTickets.length > 0 && (
                    <table className='ticket-table'>
                        <thead>
                            <tr>
                                <th>Ticket ID</th>
                                <th>Title</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orgKPIs.openTickets.map((ot) => {
                                return (
                                    <tr key={ot.id}>
                                        <td>
                                            <Link to={`/ticket/${ot.id}`}>{ot.id}</Link>
                                        </td>
                                        <td>
                                            <Link to={`/ticket/${ot.id}`}>{ot.name}</Link>
                                        </td>
                                        <td>
                                            <Link to={`/ticket/${ot.id}`}>{ot.category}</Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    ) : (
        <Loading />
    )
}

export default Organisation
