import { axiosNoInterceptors } from "../axios"

/**
 * Tried to log the user into the site
 * @param {string} email
 * @param {string} password
 * @param {string|undefined} authCode
 * @param {AbortController} abortController
 * @returns
 */
export const postLogin = async (email, password, authCode = undefined, deviceId = undefined, abortController) => {
    const data = {
        email,
        password,
        authCode,
        deviceId,
    }
    if (!data.authCode) delete data.authCode
    if (!data.deviceId) delete data.deviceId

    return axiosNoInterceptors.post("/api/login", data, {
        signal: abortController?.signal,
    })
}

/**
 * Request a reset password code
 * @param {string} email
 * @param {AbortController} abortController
 * @returns
 */
export const postRequestPasswordReset = async (email, abortController) => {
    return axiosNoInterceptors.post(
        "/api/login/reset-password",
        {
            email,
        },
        {
            signal: abortController?.signal,
        }
    )
}

/**
 * Confirm a reset password code
 * @param {string} email
 * @param {AbortController} abortController
 */
export const patchConfirmPasswordReset = async (userUuid, password, passwordResetCode, abortController) => {
    return axiosNoInterceptors.patch(
        `/api/login/reset-password/${userUuid}`,
        {
            email: "", // email not used but param is required
            password,
            authCode: passwordResetCode,
        },
        {
            signal: abortController?.signal,
        }
    )
}

/**
 * Unsubscribe the user from the email
 * @param {string} userUuid
 * @param {AbortController} abortController
 */
export const patchUnsubscribeRequest = async (userUuid, abortController) => {
    return axiosNoInterceptors.patch(
        `/api/login/unsubscribe/${userUuid}`,
        { uuid: userUuid },
        {
            signal: abortController?.signal,
        }
    )
}
