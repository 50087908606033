import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import useAlertContext, { AlertTypes } from "../hooks/useAlertContext"
import { getCurrentUserNotificationsFunc } from "../components/httpRequests/CurrentUserRequests"
import { axiosErrorMessage } from "../components/axios"
import MetaData from "../components/MetaData"
import { Header2 } from "../components/headers/Headers"
import Loading from "../components/Loading"
import ErrorBlock from "../components/errors/ErrorBlock"
import NotificationItem from "../components/notifications/NotificationItem"

/**
 * The users notifications page
 */
function UserNotifications() {
    const navigate = useNavigate()
    const { addSnackbarMessage } = useAlertContext()
    const [notifications, setNotifications] = useState()

    /**
     * Gets the current user info
     */
    const getUserInfo = async (abortController) => {
        try {
            const res = await getCurrentUserNotificationsFunc("all", 100, abortController)
            setNotifications(res.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                addSnackbarMessage(axiosErrorMessage(e), AlertTypes.Error)
                navigate("/")
            }
        }
    }

    /**
     * Called when the page loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getUserInfo(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [])

    return (
        <div className='page-wrapper'>
            <div className='padding-16'>
                <MetaData title={`Notifications`} />
                <Header2 title='Notifications' />
                {notifications ? (
                    <div className='flex-column-stretch'>
                        {notifications.length === 0 ? (
                            <ErrorBlock errorTitle='No Notifications' error='No notifications found, your all caught up!' />
                        ) : (
                            notifications.map((item) => {
                                return <NotificationItem key={item.uuid} item={item} />
                            })
                        )}
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default UserNotifications
