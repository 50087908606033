import axios from "../axios"

/**
 * Gets the a single organisation info
 * @param {string} orgUuid
 * @param {AbortController} abortController
 * @returns
 */
export const getOrganisationFunc = async (orgUuid, abortController) => {
    return axios.get(`/api/organisation/${orgUuid}`, { signal: abortController?.signal })
}

/**
 * Gets the a list organisations
 */
export const getOrganisationListFunc = async (abortController) => {
    return axios.get("/api/organisation/list", { signal: abortController?.signal })
}

/**
 * Gets the a list organisations as a key-value pair list
 */
export const getOrganisationKeyValueListFunc = async (abortController) => {
    return axios.get("/api/organisation/pairlist", { signal: abortController?.signal })
}

/**
 * Gets the KPIs for the organisation
 * @param {string} orgUuid
 * @param {AbortController} abortController
 */
export const getOrganisationKPIsFunc = async (orgUuid, abortController) => {
    return axios.get(`/api/organisation/KPI/${orgUuid}`, { signal: abortController?.signal })
}

/**
 * Disable / enable the passed organisation
 * @param {string} orgUuid
 * @param {boolean} disable
 * @param {AbortController} abortController
 * @returns
 */
export const postDisableOrganisationFunc = async (uuid, isDisabled, abortController) => {
    const itemData = {
        uuid,
        isDisabled,
    }

    const res = await axios.post("/api/organisation/disable", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Update an organisation
 * @param {object} itemData
 * @param {AbortController} abortController
 * @returns
 */
export const patchOrganisationFunc = async (itemData, abortController) => {
    const res = await axios.patch("/api/organisation/", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}

/**
 * Creates an organisation
 * @param {object} itemData
 * @param {AbortController} abortController
 * @returns
 */
export const postOrganisationFunc = async (itemData, abortController) => {
    const res = await axios.post("/api/organisation/", itemData, {
        signal: abortController?.signal,
    })
    return new Promise((resolve) => {
        resolve(res)
    })
}
