import React from "react"

import MetaData from "../../components/MetaData"
import TemplateList from "../../components/TemplateList"
import { Header2 } from "../../components/headers/Headers"

/**
 * Liat of new ticket templates
 */
function NewTicketTemplates() {
    return (
        <div className='page-wrapper'>
            <MetaData title={`New Ticket`} />
            <div className='padding-16'>
                <Header2 title='New Ticket' />
            </div>
            <TemplateList />
        </div>
    )
}

export default NewTicketTemplates
