import React from "react"
import TicketTableWapper from "../../components/data/TicketTableWapper"

const Tickets = () => {
    return (
        <div className='page-wrapper'>
            <TicketTableWapper />
        </div>
    )
}

export default Tickets
