import { default as axiosStatic } from "axios"
import { returnToQueryParam } from "./utility/Helpers"

const BASE_URL = "/"

const axios = axiosStatic.create({
    baseURL: BASE_URL,
})

/**
 * response interceptors to redirect on a 401 error
 */
axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error?.response?.status === 401) {
            const returnTo = returnToQueryParam(window.location.pathname, "?")
            window.location.replace(`/${returnTo}`)
        }
        return Promise.reject(error)
    }
)
export const axiosNoInterceptors = axiosStatic.create({
    baseURL: BASE_URL,
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
})

/**
 * Unpacks the axios error message, or returns the default if no error message was found
 */
export const axiosErrorMessage = (error, defaultError = undefined) => {
    if (error.response && error.response.data) {
        // If the data prop is an object look for reason / title props
        if (typeof error.response.data === "object") {
            if (typeof error.response.data.reason === "string") return error.response.data.reason
            if (typeof error.response.data.title === "string") return error.response.data.title
        } else if (typeof error.response.data === "string") {
            return error.response.data
        }
    }

    // if we have a default error, show that rather then the default javascript error
    if (defaultError !== undefined) return defaultError

    if (typeof error.message === "string") {
        return error.message
    }

    return "An unknown error occurred"
}

export default axios
