import React from "react"
import "../ComponentStyles.css"
import DoneIcon from "@mui/icons-material/Done"

const Tick = () => {
    return (
        <div className='notification-tick flex-center-item'>
            <DoneIcon fontSize='small' className='notification-icon' />
        </div>
    )
}

export default Tick
