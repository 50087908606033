import React from "react"

import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner"
import LocalOfferIcon from "@mui/icons-material/LocalOffer"
import MobiledataOffIcon from "@mui/icons-material/MobiledataOff"
import MobileOffIcon from "@mui/icons-material/MobileOff"
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop"
import NewspaperIcon from "@mui/icons-material/Newspaper"
import LoyaltyIcon from "@mui/icons-material/Loyalty"
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import BugReportIcon from "@mui/icons-material/BugReport"
import RadarIcon from "@mui/icons-material/Radar"
import PointOfSaleIcon from "@mui/icons-material/PointOfSale"
import PaymentIcon from "@mui/icons-material/Payment"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"
import ImportExportIcon from "@mui/icons-material/ImportExport"
import SignalWifiStatusbarConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4"
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits"
import SupportIcon from "@mui/icons-material/Support"
import MenuBookIcon from "@mui/icons-material/MenuBook"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket"
import TouchAppIcon from "@mui/icons-material/TouchApp"
import SchoolIcon from "@mui/icons-material/School"
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import HelpIcon from "@mui/icons-material/Help"

export const ticketTemplateIconOptions = [
    {
        value: 1,
        label: <ConfirmationNumberIcon />,
    },
    {
        value: 2,
        label: <QrCodeScannerIcon />,
    },
    {
        value: 3,
        label: <LocalOfferIcon />,
    },
    {
        value: 4,
        label: <MobiledataOffIcon />,
    },
    {
        value: 5,
        label: <MobileOffIcon />,
    },
    {
        value: 6,
        label: <InstallDesktopIcon />,
    },
    {
        value: 7,
        label: <NewspaperIcon />,
    },
    {
        value: 8,
        label: <LoyaltyIcon />,
    },
    {
        value: 9,
        label: <AccountCircleIcon />,
    },
    {
        value: 10,
        label: <BugReportIcon />,
    },
    {
        value: 11,
        label: <RadarIcon />,
    },
    {
        value: 12,
        label: <PointOfSaleIcon />,
    },
    {
        value: 13,
        label: <PaymentIcon />,
    },
    {
        value: 14,
        label: <ReceiptLongIcon />,
    },
    {
        value: 15,
        label: <ImportExportIcon />,
    },
    {
        value: 16,
        label: <SignalWifiStatusbarConnectedNoInternet4Icon />,
    },
    {
        value: 17,
        label: <ProductionQuantityLimitsIcon />,
    },
    {
        value: 18,
        label: <SupportIcon />,
    },
    {
        value: 19,
        label: <MenuBookIcon />,
    },
    {
        value: 20,
        label: <ShoppingCartIcon />,
    },
    {
        value: 21,
        label: <ShoppingBasketIcon />,
    },
    {
        value: 22,
        label: <TouchAppIcon />,
    },
    {
        value: 23,
        label: <AccessAlarmIcon />,
    },
    {
        value: 24,
        label: <SchoolIcon />,
    },
    {
        value: 25,
        label: <AccountBalanceIcon />,
    },
    {
        value: 26,
        label: <ManageAccountsIcon />,
    },
    {
        value: 27,
        label: <HelpIcon />,
    },
]
Object.freeze(ticketTemplateIconOptions)

/**
 * Ticket template icons
 */
function TicketTemplateIcon({ icon = 0 }) {
    const iconOption = ticketTemplateIconOptions.find((t) => t.value == icon)
    const iconSvg = iconOption ? iconOption.label : <ConfirmationNumberIcon />

    return <>{iconSvg}</>
}

export default TicketTemplateIcon
