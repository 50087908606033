import React from "react"
import "../ComponentStyles.css"
import CloseIcon from "@mui/icons-material/Close"

const Cross = () => {
    return (
        <div className='notification-cross flex-center-item'>
            <CloseIcon fontSize='small' className='notification-icon' />
        </div>
    )
}

export default Cross
