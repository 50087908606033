import React, { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"

import "../pages/styling/general.css"
import "../pages/styling/storage.css"

import useUser from "../hooks/useUser"
import MetaData from "../components/MetaData"
import Loading from "../components/Loading"
import Divider from "../components/dividers/Divider"
import MarkdownWrapper from "../components/MarkdownWrapper"
import FileIcon from "../components/FileIcon"
import { getPublicStorageItemFunc } from "../components/httpRequests/StorageRequests"
import { axiosErrorMessage } from "../components/axios"
import { dateFormat, dateTimeClass, monthsBetween } from "../components/utility/ConvertTime"
import Tooltip from "../components/mui/Tooltip"
import Card from "../components/mui/Card"
import { returnToQueryParam } from "../components/utility/Helpers"

/**
 * A single storage page, note tis page is accessable to the public
 */
function ArticlePage() {
    const loc = useLocation()
    const { storageId } = useParams()
    const { auth, hasAuthRole_Admin } = useUser()

    const [pageData, setPageData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(undefined)
    const [errorStatus, setErrorStatus] = useState(0)

    const isAdmin = hasAuthRole_Admin()
    const returnTo = returnToQueryParam(loc.pathname, "?")

    /**
     * Gets the filtered list of tickts
     * const controller = new AbortController();
     */
    const loadData = async (abortController) => {
        setLoading(true)
        try {
            const response = await getPublicStorageItemFunc(storageId, false, abortController)
            setPageData(response.data)
            setErrorStatus(0)
            setError(undefined)
        } catch (e) {
            if (!abortController?.signal?.aborted) {
                console.error(e)
                setPageData(null)
                setError(axiosErrorMessage(e, "Unable to load page"))
                setErrorStatus(e?.response?.status ?? 400)
            }
        } finally {
            setLoading(false)
        }
    }

    /**
     * Called when the page is loaded
     */
    useEffect(() => {
        const aboutController = new AbortController()
        loadData(aboutController)
        return () => aboutController.abort()
    }, [storageId])

    return (
        <>
            <MetaData title={`Knowledge Base Article`} />
            {pageData && !loading && errorStatus === 0 ? (
                <>
                    <MetaData title={pageData?.title} />
                    {isAdmin && pageData?.orgUuid === null ? (
                        <div className='padding-16 divider-line-black-bottom hidden-print flex-column'>
                            <div className='flex-row'>
                                <Link to={`/admin/knowledge-base/${storageId}/edit`} className='button-outlined'>
                                    Edit Article
                                </Link>
                                <span className='pill-danger'>
                                    Visibility <br />
                                    {pageData?.roleName ?? ""}
                                </span>
                            </div>
                            <Keywords keywords={pageData?.keywords} />
                        </div>
                    ) : null}
                    <div className='storage-info padding-16'>
                        <h2>{pageData?.categoryName}</h2>
                        <div className='flex-row flex-row-spacebetween margin-vertical-8 '>
                            <div className='flex-gap-4'>
                                <p>
                                    Organisation: <span>{pageData?.orgName ?? "Knowledge Base"}</span>
                                </p>
                                <p>
                                    Code: <span>{pageData?.orgCode ?? "KB"}</span>
                                </p>
                            </div>
                            <div className='flex-gap-4'>
                                <p>
                                    Created On: <time dateTime={pageData?.createdAt}>{dateFormat(pageData?.createdAt)}</time>
                                </p>
                                <p>
                                    Last Update: <time dateTime={pageData?.updatedAt}>{dateFormat(pageData?.updatedAt)}</time>
                                </p>
                            </div>
                        </div>
                    </div>

                    {pageData?.showFiles && pageData?.files && pageData?.files.length > 0 ? (
                        <div className='flex-row padding-16 storage-info hidden-print'>
                            {pageData?.files?.map((f) => {
                                return (
                                    <Tooltip key={f.key} text={f.value}>
                                        <Link
                                            className='storage-file flex-column flex-center-item'
                                            to={`/api/public/attachment/${encodeURIComponent(f.key)}/${encodeURIComponent(f.value)}`}
                                            target='_blank'
                                        >
                                            <FileIcon fileName={f.value} />
                                            <span>{f.value}</span>
                                        </Link>
                                    </Tooltip>
                                )
                            })}
                        </div>
                    ) : null}

                    <div className='padding-16'>
                        <Card title={<h1>{pageData?.title}</h1>}>
                            {pageData?.isContract ? (
                                <>
                                    <h3>Contract Terms</h3>
                                    <dl>
                                        <dt>Start Date:</dt>
                                        <dd>
                                            <time dateTime={pageData?.contractStartDate}>{dateFormat(pageData?.contractStartDate)}</time>
                                        </dd>
                                        <dt>End Date:</dt>
                                        <dd>
                                            <time dateTime={pageData?.contractEndDate} className={dateTimeClass(pageData?.contractEndDate)}>
                                                {dateFormat(pageData?.contractEndDate)}
                                            </time>
                                        </dd>
                                        <dt>Contract Length: </dt>
                                        <dd>{pageData?.contractLength} month(s)</dd>
                                        <dt>Remaining: </dt>
                                        <dd>{monthsBetween(pageData?.contractEndDate)} month(s)</dd>
                                    </dl>
                                    <Divider padding={16} />
                                </>
                            ) : null}
                            <MarkdownWrapper>{pageData.notes}</MarkdownWrapper>
                            {pageData.technicianNotes ? (
                                <div className='padding-top-16'>
                                    <Divider padding={16} />
                                    <MarkdownWrapper>{pageData.technicianNotes}</MarkdownWrapper>
                                </div>
                            ) : null}
                        </Card>
                    </div>
                </>
            ) : (
                <>
                    {loading || errorStatus === 0 ? (
                        <Loading />
                    ) : (
                        <div className='flex-center-item flex-100'>
                            <div className='center-404'>
                                <h1>{errorStatus}</h1>
                                <h3>{error}</h3>
                                <p>
                                    <Link to={auth?.loggedIn ? "/" : `/login/${returnTo}`}>{auth?.loggedIn ? "Return" : "Login"} by clicking HERE.</Link>
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

/**
 * Displays the keywords list if any
 * @param {{keywords: string|undefined}} param0
 */
const Keywords = ({ keywords = undefined }) => {
    const keywordsArr = keywords?.split(",") ?? null

    if (!Array.isArray(keywordsArr) || keywordsArr.length === 0) return null

    return (
        <div className='flex-row'>
            {keywordsArr.map((keyword, index) => {
                return (
                    <span key={index} className='pill pill-primary'>
                        {keyword}
                    </span>
                )
            })}
        </div>
    )
}

export default ArticlePage
