import React from "react"
import "../pages/styling/general.css"
import "../pages/styling/animations.css"

const Loading = () => {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className='loadingio-spinner-ripple-5jvviodeubo'>
                <div className='ldio-z1ak9e2kq2a'>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Loading
